@if (asset(); as asset) {
  <iot-platform-ui-association-card-container>
    <iot-platform-ui-overview-card [overviewCard]="card()" fxFlex>
      <ng-container cardNameMeta>
        @if (asset.assetGroup?.name) {
          <span class="asset-card__separator"> | </span>
          <mat-icon class="asset-card__icon" svgIcon="asset-group-grey" />
          <span class="asset-card__group-name"> {{ asset.assetGroup?.name }}</span>
        }
        <span class="asset-card__business-id text-secondary">{{ asset.businessId }}</span>
      </ng-container>
    </iot-platform-ui-overview-card>
  </iot-platform-ui-association-card-container>
}
