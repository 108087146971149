@if (data.event()) {
  <mat-card-content
    class="p-20 mve-detail-popup-container full-height"
    data-cy="iot4bos-ui-event-detail-popup"
    fxLayout="column"
    fxLayoutAlign=""
    fxLayoutGap="10px"
  >
    <!-- POPUP HEADER -->
    <section fxLayout="row" fxLayoutGap="20px">
      <div fxFlex="25" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
        <div class="mve-detail-popup-title" id="title-event">
          <span class="capitalize-first-letter">{{ 'EVENTS.DETAIL_POPUP.EVENT_ID' | translate }}:&nbsp;</span>
          <span cdkFocusInitial class="fw-600">{{ data.event().id | infoDisplay }}</span>
        </div>
        <div class="event-detail-popup-status">
          <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.STATUS' | translate }}:</span>
          <span [class]="'fw-600 capitalize-first-letter event-detail-popup-status-' + data.event().status" class="fw-600">{{
            'EVENTS.STATUS.' + data.event().status | translate
          }}</span>
        </div>
      </div>

      <div fxFlex="75" fxLayout="row" fxLayoutGap="6px" style="height: fit-content">
        <div class="with-border" fxFlex="65%" fxLayout="column">
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.LABEL' | translate }}:"
            value="{{ data.event().label | infoDisplay }}"
          />
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'IOT_DICTIONARY.TYPE' | translate }}:"
            value="{{ 'EVENTS.EVENT_TYPES.' + data.event().type | translate }}"
          />
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.SEVERITY' | translate }}:</span>
            <iot-platform-ui-event-severity-display [severity]="data.event().severity" />
          </div>
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'IOT_DICTIONARY.NOTE' | translate }}:"
            value="{{ data.event().eventNote | infoDisplay }}"
          />
        </div>

        <div class="with-border" fxFlex="35%" fxLayout="column">
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.OCCURRENCE_DATE' | translate }}:"
            value="{{ data.event().occurrenceTime | dateFormat | infoDisplay }}"
          />
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.RECEPTION_DATE' | translate }}:"
            value="{{ data.event().receptionTime | dateFormat | infoDisplay }}"
          />
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.ACKNOWLEDGE_DATE' | translate }}:"
            value="{{ data.event().acknowledge?.datetime | dateFormat | infoDisplay }}"
          />
          <iot-platform-ui-detail-popup-display-property
            property="{{ 'EVENTS.DETAIL_POPUP.CLOSE_DATE' | translate }}:"
            value="{{ data.event().close?.datetime | dateFormat | infoDisplay }}"
          />
        </div>
      </div>
    </section>

    <!-- POPUP BODY -->
    <section>
      <mat-tab-group color="accent" mat-align-tabs="center">
        <!-- INFORMATION TAB -->
        <mat-tab label="{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.INFORMATION' | translate | uppercase }}">
          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container" fxLayout="column" fxLayoutGap="20px">
              <section fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-section-header
                  headerTitle="{{ (eventTags().length <= 1 ? 'IOT_DICTIONARY.TAG' : 'IOT_DICTIONARY.TAGS') | translate }} ({{ eventTags?.length }})"
                />
                <div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  @if (!eventTags()) {
                    <iot-platform-ui-card-loader [backgroundClass]="'tag-loader mv-detail'" />
                  } @else {
                    <mat-chip-set class="event-detail-popup-tag-list">
                      @for (tag of eventTags(); track tag) {
                        <iot-platform-ui-chip
                          [chipButton]="{ display: false }"
                          [chip]="tag"
                          [isTag]="true"
                          [ngClass]="{ invisible: !eventTags(), visible: eventTags() }"
                        />
                      } @empty {
                        <span>{{ 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG' | translate }}</span>
                      }
                    </mat-chip-set>
                  }
                </div>
              </section>

              <div fxLayout="row" fxLayoutGap="10px">
                <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                  <iot-platform-ui-detail-popup-section-header headerTitle="{{ 'IOT_DICTIONARY.site' | translate }}" />
                  @if (!eventSite()) {
                    <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-card h-140'" />
                  } @else {
                    <iot-platform-ui-detail-site-card (selectSite)="navigateToSite.emit($event)" [site]="eventSite()" />
                  }
                </section>

                <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                  <iot-platform-ui-detail-popup-section-header headerTitle="{{ 'IOT_DICTIONARY.asset' | translate }}" />
                  @if (!!data.event().context.asset.id) {
                    @if (!eventAsset()) {
                      <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-card h-140'" />
                    } @else {
                      <iot-platform-ui-detail-asset-variable-card
                        [assetVariable]="eventAssetVariable()"
                        [asset]="eventAsset()"
                        [event]="data.event()"
                        [ngClass]="{ invisible: !eventAsset(), visible: eventAsset() }"
                      />
                    }
                  } @else {
                    <span class="empty-context-placeholder">{{ 'EVENTS.DETAIL_POPUP.NO_ASSET' | translate }}</span>
                  }
                </section>

                <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                  <iot-platform-ui-detail-popup-section-header headerTitle="{{ 'IOT_DICTIONARY.device' | translate }}" />
                  @if (!!data.event().context.device.id) {
                    @if (!eventDevice()) {
                      <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-card h-140'" />
                    } @else {
                      <iot-platform-ui-detail-device-variable-card
                        [deviceVariable]="eventDeviceVariable()"
                        [device]="eventDevice()"
                        [event]="data.event()"
                        [ngClass]="{ invisible: !eventDevice(), visible: eventDevice() }"
                      />
                    }
                  } @else {
                    <span class="empty-context-placeholder">{{ 'EVENTS.DETAIL_POPUP.NO_DEVICE' | translate }}</span>
                  }
                </section>
              </div>
            </div>
          </ng-template>
        </mat-tab>

        <!-- TIMELINE TAB -->
        <mat-tab label="{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TIMELINE' | translate | uppercase }}">
          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container">
              @if (data.commentsLoading()) {
                <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-no-bg h-240'" />
              } @else {
                <iot4bos-ui-event-timeline
                  (updateStatus)="updateStatus.emit($event)"
                  [canUpdateEvent]="data.canUpdateEvent"
                  [event]="data.event()"
                  [logs]="data.comments()"
                  [ngClass]="{ invisible: data.commentsLoading(), visible: !data.commentsLoading() }"
                />
              }
            </div>
          </ng-template>
        </mat-tab>

        <!-- COMMENTS TAB -->
        <mat-tab>
          <ng-template matTabLabel>
            {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.COMMENTS' | translate | uppercase }}
            @if (data.event().totalComments > 0) {
              ({{ data.event().totalComments }})
            }
          </ng-template>

          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container event-detail-popup-tab-comments">
              <iot4bos-ui-comments-panel
                (commentsEvent)="addComment.emit($event.value)"
                [comments]="data.comments()"
                [footerBackgroundColor]="'#fff'"
                [footerTextColor]="'#394c5a'"
                [loading]="data.commentsLoading()"
                [panelWidth]="'100%'"
                [withFooter]="data.canUpdateEvent"
                [withHeader]="false"
              />
            </div>
          </ng-template>
        </mat-tab>

        <!-- TOPICS TAB -->
        <mat-tab>
          <ng-template matTabLabel>
            <span>
              {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TOPICS' | translate | uppercase }}
              ({{ eventTopics()?.length | infoDisplay }})
            </span>
          </ng-template>

          <ng-template matTabContent>
            <div class="event-detail-popup-tab-container">
              @if (!eventTopics()) {
                <iot-platform-ui-card-loader [backgroundClass]="'mv-detail-no-bg h-240'" />
              } @else {
                <iot4bos-ui-event-topic-list [topics]="eventTopics()" />
              }
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </section>
  </mat-card-content>
}
