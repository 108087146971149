import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { AssetGroup, AssetGroupCreate, AssociationByAsset } from '@iot-platform/models/i4b';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupsService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  getAssetGroup(assetGroupId: string): Observable<AssetGroup> {
    return this.httpClient.get<AssetGroup>(`${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}/${assetGroupId}`);
  }

  addAssetGroup(assetGroup: AssetGroupCreate): Observable<AssetGroup> {
    return this.httpClient.post<AssetGroup>(`${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}`, assetGroup);
  }

  editAssetGroup(assetGroup: AssetGroup): Observable<AssetGroup> {
    return this.httpClient.put<AssetGroup>(`${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}/${assetGroup.id}`, {
      name: assetGroup.name,
      erpReference: assetGroup.erpReference,
      description: assetGroup.description
    });
  }

  updateRelatedAssets(assetGroupId: string, assetIds: string[]): Observable<AssetGroup> {
    return this.httpClient.patch<AssetGroup>(
      `${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}/${assetGroupId}${this.environment.api.endpoints?.assets}`,
      { assetIds }
    );
  }

  deleteAssetGroup(assetGroup: AssetGroup): Observable<AssetGroup> {
    return this.httpClient
      .delete<AssetGroup>(`${this.environment.api.url}${this.environment.api.endpoints?.assetGroups}/${assetGroup.id}`)
      .pipe(map(() => assetGroup));
  }

  isNameUnique(assetGroupName: string, siteId: string): Observable<boolean> {
    return this.httpClient
      .get<AssociationByAsset>(`${this.environment.api.url}${this.environment.api.endpoints?.sites}/${siteId}/associations-by-asset`)
      .pipe(map((data: AssociationByAsset) => !data.assetGroups?.some((assetGroup) => assetGroup.name?.toLowerCase() === assetGroupName?.toLowerCase())));
  }
}
