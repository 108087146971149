import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { CachedUsersService } from '@iot-platform/core';
import { InfoDisplayPipe, UserNamePipe } from '@iot-platform/pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get, isEmpty } from 'lodash';
import { CustomCellParams } from '../../../models/custom-cell.params';

type CellParams = CustomCellParams & { isFilterRenderer?: boolean };

@Component({
  selector: 'grid-engine-user-cell',
  templateUrl: './user-cell.component.html',
  imports: [TranslateModule, NgClass, InfoDisplayPipe],
  providers: [UserNamePipe],
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CellParams> = signal(null);
  protected readonly cachedUsersService: CachedUsersService = inject(CachedUsersService);
  protected readonly translateService: TranslateService = inject(TranslateService);
  protected readonly userNamePipe: UserNamePipe = inject(UserNamePipe);
  value: Signal<string> = computed(() => {
    const params = this.params();
    if(params) {
      if (isEmpty(params.value)) {
        return params.isFilterRenderer
          ? get(params, ['colDef', 'cellRendererParams', 'cellOptions', 'defaultValue'], 'AG_GRID.blanks')
          : get(params, ['cellOptions', 'defaultValue'], params.value);
      } else if (params.value === this.translateService.instant('AG_GRID.selectAll')) {
        return params.value;
      }
      return this.userNamePipe.transform(params.value);
    }
    return null;
  });

  agInit(params: CellParams): void {
    if (params?.colDef?.filterParams) {
      params.colDef.filterParams.users = this.cachedUsersService.cachedUsers$.getValue();
    }
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
