<mat-sidenav-container (backdropClick)="closeAllPanels()" class="layout site-overview--sidenav-container"
                       hasBackdrop="true">
  <mat-sidenav-content>
    <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)" color="accent" mat-align-tabs="center">
      <mat-tab label="tab_info">
        <ng-template matTabLabel>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
            <span>{{ 'SITES.TABS.INFO' | translate | uppercase }}</span>
          </div>
        </ng-template>
        <div class="layout__wrapper pl-12 pr-12 pt-8 pb-8 sm:p-4 max-w-4xl xl:max-w-4xl">
          <iot4bos-ui-site-info
            [canCreateAsset]="canCreateAsset"
            [canCreateDevice]="canCreateDevice"
            [canDeleteSite]="canDeleteSite"
            [canDisplayTags]="!(isSiteOfTypeMobileFleet$ | async)"
            [canReadAuditTrail]="canReadAuditTrail"
            [canUpdateSite]="canUpdateSite"
          />
        </div>
      </mat-tab>
      @if (canReadEvent) {
        <mat-tab label="tab_asset_events">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'IOT_DICTIONARY.asset-events' | translate | uppercase }}</span
              >&nbsp;
            </div>
          </ng-template>
          <ng-template matTabContent>
            <div class="layout__wrapper min-h-full">
              <iot4bos-ui-site-events-tab
                fxFlex
                [eventType]="'asset-events'"
                [selectedSite]="site()"
                [userPermissions]="eventsPermissions"
              />
            </div>
          </ng-template>
        </mat-tab>
      }
      @if (canReadEvent) {
        <mat-tab label="tab_device_events">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'IOT_DICTIONARY.device-events' | translate | uppercase }}</span
              >&nbsp;
            </div>
          </ng-template>
          <ng-template matTabContent>
            <div class="layout__wrapper min-h-full">
              <iot4bos-ui-site-events-tab
                fxFlex
                [eventType]="'device-events'"
                [selectedSite]="site()"
                [userPermissions]="eventsPermissions"
              />
            </div>
          </ng-template>
        </mat-tab>
      }
      @if (canReadGraph) {
        <mat-tab label="tab_graphs">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'SITES.TABS.GRAPH' | translate | uppercase }}</span
              >&nbsp;
              @if (!(graphLoading$ | async)) {
                <span>({{ (allGraphs$ | async)?.length }}) </span>
              } @else {
                <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" />
              }
            </div>
          </ng-template>
          <ng-template matTabContent>
            <div class="layout__wrapper p-4 max-w-4xl xl:max-w-4xl">
              <iot-platform-feature-graphs-shell
                (addWidget)="onAddGraph()"
                (deleteGraph)="onDeleteGraph($event)"
                (editGraph)="onEditGraph($event)"
                (openGraph)="onOpenGraph($event)"
                (widgetsOrderChange)="onWidgetsOrderChange($event)"
                [graphs]="allGraphs$ | async"
                [enableDragAndDrop]="true"
              />
            </div>
          </ng-template>
        </mat-tab>
      }
      @if (canReadContact) {
        <mat-tab label="tab_contacts">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'SITES.TABS.CONTACTS' | translate | uppercase }}</span
              >&nbsp;
              @if (!(contactsLoading$ | async)) {
                <span>({{ totalContacts$ | async }}) </span>
              } @else {
                <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" />
              }
            </div>
          </ng-template>
          <ng-template matTabContent>
            <div class="layout__wrapper">
              <iot4bos-ui-site-contacts-tab
                [assets]="assets()"
                [canCreateContact]="canCreateContact"
                [canDeleteContact]="canDeleteContact"
                [canUpdateContact]="canUpdateContact"
                [contacts$]="contacts()"
                [site]="site()"
                [totalContacts$]="totalContacts$"
              />
            </div>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  </mat-sidenav-content>
  <mat-sidenav [opened]="dataGuardOpened()" mode="over" position="end">
    @if (dataGuardOpened()) {
      <iot-platform-feature-data-guard />
    }
  </mat-sidenav>
</mat-sidenav-container>
