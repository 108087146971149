import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact } from '@iot-platform/models/common';
import { ContactNotification, ExportSpreadsheet } from '@iot-platform/models/i4b';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'iot4bos-ui-contact-manage-notifications-form',
    templateUrl: './contact-manage-notifications-form.component.html',
    styleUrls: ['./contact-manage-notifications-form.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false
})
export class ContactManageNotificationsFormComponent implements OnInit {
  manageNotificationsOrExportsForm!: UntypedFormGroup;

  // Notifications
  selectedNotification?: ContactNotification;
  totalCheckedItems = 0;

  // Export Spreadsheets
  selectedExportSpreadsheet?: ExportSpreadsheet;

  popupTitle = `SITES.MANAGE_NOTIFICATIONS_OR_EXPORTS_FORM.TITLE_${this.data.notifications ? 'NOTIFICATIONS' : 'EXPORTS'}`;
  selectionCountMessage = `SITES.MANAGE_NOTIFICATIONS_OR_EXPORTS_FORM.SELECTED_${this.data.notifications ? 'NOTIFICATIONS' : 'EXPORTS'}`;

  destroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ContactManageNotificationsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: Contact; notifications?: ContactNotification[]; exportSpreadsheets?: ExportSpreadsheet[] }
  ) {}

  ngOnInit() {
    this.manageNotificationsOrExportsForm = new UntypedFormGroup({});

    if (this.data.notifications) {
      this.data.notifications.forEach((notification) =>
        this.manageNotificationsOrExportsForm.addControl(
          notification.id as string,
          new UntypedFormControl({ value: notification.contactIds.includes(this.data.contact.id as string), disabled: false })
        )
      );
      this.totalCheckedItems = this.getCheckedElementTotal();
    } else if (this.data.exportSpreadsheets) {
      this.data.exportSpreadsheets.forEach((exportSpreadsheet) =>
        this.manageNotificationsOrExportsForm.addControl(
          exportSpreadsheet.id as string,
          new UntypedFormControl({ value: exportSpreadsheet.contactIds.includes(this.data.contact.id as string), disabled: false })
        )
      );
      this.totalCheckedItems = this.getCheckedElementTotal();
    }
    this.manageNotificationsOrExportsForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.totalCheckedItems = this.getCheckedElementTotal();
    });
  }

  selectNotification(notification: ContactNotification): void {
    this.selectedNotification = notification;
  }

  selectExport(exportSpreadsheet: ExportSpreadsheet): void {
    this.selectedExportSpreadsheet = exportSpreadsheet;
  }

  onCheckChange(event: { checked: boolean; item: ContactNotification | ExportSpreadsheet }): void {
    this.manageNotificationsOrExportsForm.get(event.item.id as string)?.setValue(event.checked);
  }

  getCheckedElementTotal(): number {
    if (this.data.notifications) {
      return this.data.notifications.filter((notification) => (this.manageNotificationsOrExportsForm.get(notification.id as string) as AbstractControl).value)
        .length;
    } else if (this.data.exportSpreadsheets) {
      return this.data.exportSpreadsheets.filter((eS) => (this.manageNotificationsOrExportsForm.get(eS.id as string) as AbstractControl).value).length;
    } else {
      return 0;
    }
  }

  getFutureAssociatedElementIds(): string[] {
    if (this.data.notifications) {
      return this.data.notifications
        .filter(
          (notification) =>
            (this.manageNotificationsOrExportsForm.get(notification.id as string) as AbstractControl).value &&
            !notification.contactIds?.includes(this.data.contact.id as string)
        )
        .map((c) => c.id) as string[];
    } else if (this.data.exportSpreadsheets) {
      return this.data.exportSpreadsheets
        .filter(
          (eS) =>
            (this.manageNotificationsOrExportsForm.get(eS.id as string) as AbstractControl).value && !eS.contactIds?.includes(this.data.contact.id as string)
        )
        .map((c) => c.id) as string[];
    } else {
      return [];
    }
  }

  getRemovedAssociatedElementIds(): string[] {
    if (this.data.notifications) {
      return (
        (this.data.notifications
          .filter(
            (notification) =>
              !(this.manageNotificationsOrExportsForm.get(notification.id as string) as AbstractControl).value &&
              notification.contactIds?.includes(this.data.contact.id as string)
          )
          .map((c) => c.id) as string[]) ?? []
      );
    } else if (this.data.exportSpreadsheets) {
      return (
        (this.data.exportSpreadsheets
          .filter(
            (eS) =>
              !(this.manageNotificationsOrExportsForm.get(eS.id as string) as AbstractControl).value && eS.contactIds?.includes(this.data.contact.id as string)
          )
          .map((c) => c.id) as string[]) ?? []
      );
    } else {
      return [];
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({
      futureAssociatedElementIds: this.getFutureAssociatedElementIds(),
      removedAssociatedElementIds: this.getRemovedAssociatedElementIds()
    });
  }
}
