import { ComponentRef, Directive, effect, inject, input, OnInit, ViewContainerRef } from '@angular/core';
import { WidgetInstance, WidgetsMapper, WidgetTypes } from '@iot-platform/models/widgets';
import { DynamicWidgetComponent } from '../components/dynamic-widget/dynamic-widget.component';
import { LastValueWidgetComponent } from '../components/last-value-widget/last-value-widget.component';

const WIDGET_COMPONENT_MAPPER: WidgetsMapper = {
  [WidgetTypes.line_chart]: DynamicWidgetComponent,
  [WidgetTypes.last_value]: LastValueWidgetComponent
};

@Directive({
  standalone: true,
  selector: '[iotPlatformUiWidgetLoader]'
})
export class WidgetLoaderDirective implements OnInit {
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  widget = input<WidgetInstance>();
  componentRef!: ComponentRef<any>;

  initComponentEffect = effect(
    () => {
      const widget = this.widget();
      if (widget && this.componentRef) {
        this.componentRef.setInput('widget', widget);
      }
    }
  );

  ngOnInit(): void {
    const widgetType = this.widget()?.type;

    if (widgetType) {
      this.componentRef = this.viewContainerRef.createComponent(WIDGET_COMPONENT_MAPPER[widgetType]);
      this.componentRef.setInput('widget', this.widget());
    }
  }
}
