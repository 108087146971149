import { I4BColumnConfiguration } from './configurations';
import { DefaultActiveAssetEventsPopupGrid } from './default-active-asset-events-popup-grid.definition';
import {
  DefaultAssetEventsByAssetGrid,
  GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW
} from './default-asset-events-by-asset-grid.definition';
import {
  DefaultAssetEventsByPERuleGrid,
  GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW
} from './default-asset-events-by-pe-rule-grid.definition';
import {
  DefaultAssetEventsBySiteGrid,
  GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW
} from './default-asset-events-by-site-grid.definition';
import {
  DefaultAssetEventsByTopicGrid,
  GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW
} from './default-asset-events-by-topic-grid.definition';
import {
  DefaultAssetEventsGrid,
  GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  GET_DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW
} from './default-asset-events-grid.definition';

import {
  DefaultAssetsGrid,
  GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW,
  GET_DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW,
  GET_DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW,
  GET_FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW,
  GET_VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW
} from './default-asset-grid.definition';
import { DefaultAssetTemplatesGrid, GET_DEFAULT_ASSET_TEMPLATES_COLUMNS_FOR_ASSET_TEMPLATES_VIEW } from './default-asset-templates-grid.definition';
import { GET_DEFAULT_DEVICES_COLUMNS_FOR_BAM_DEVICES_VIEW } from './default-bam-device-grid.definition';
import { DefaultCalendarsGrid, GET_DEFAULT_CALENDARS_COLUMNS_FOR_CALENDARS_VIEW } from './default-calendars-grid.definition';
import { DefaultConnectorsGrid, GET_DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW } from './default-connectors-grid.definition';
import {
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DALIA_DEVICES_VIEW,
  GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DALIA_DEVICE_VIEW
} from './default-dalia-device-grid.definition';
import { GET_DEFAULT_DEVICE_TEMPLATES_COLUMNS_FOR_DALIA_DEVICE_TEMPLATES_VIEW } from './default-dalia-device-templates-grid.definition';
import { GET_DEFAULT_FIRMWARES_COLUMNS_FOR_DALIA_FIRMWARES_VIEW } from './default-dalia-firmwares-grid.definition';
import { GET_DEFAULT_SENSORS_COLUMNS_FOR_DALIA_SENSORS_VIEW } from './default-dalia-sensors-grid.definition';
import {
  DefaultDeviceEventsByDeviceGrid,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW
} from './default-device-events-by-device-grid.definition';
import {
  DefaultDeviceEventsByPERuleGrid,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW
} from './default-device-events-by-pe-rule-grid.definition';
import {
  DefaultDeviceEventsBySiteGrid,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW
} from './default-device-events-by-site-grid.definition';
import {
  DefaultDeviceEventsByTopicGrid,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW
} from './default-device-events-by-topic-grid.definition';
import {
  DefaultDeviceEventsGrid,
  GET_DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  GET_DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW
} from './default-device-events-grid.definition';
import {
  DefaultDevicesGrid,
  GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW,
  GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW,
  GET_DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW,
  GET_DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW
} from './default-device-grid.definition';

import { DefaultEmailTemplatesGrid, GET_DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW } from './default-email-templates-grid.definition';
import { GET_DEFAULT_DEVICES_COLUMNS_FOR_KERCOM_DEVICES_VIEW } from './default-kercom-device-grid.definition';
import { GET_DEFAULT_PO_EVENT_RULES_COLUMNS_FOR_PO_EVENT_RULES_VIEW } from './default-po-event-rules-grid.definition';
import {
  DefaultSitesGrid,
  GET_DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW,
  GET_DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW,
  GET_DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW
} from './default-sites-grid.definition';
import {
  DefaultStockSiteDevicesGrid,
  GET_DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW,
  GET_DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW
} from './default-stock-site-devices-grid.definition';
import { GET_DEFAULT_USERS_COLUMNS_FOR_USERS_VIEW } from './default-users-grid.definition';
import { GET_DEFAULT_DEVICES_COLUMNS_FOR_XMQTT_DEVICES_VIEW } from './default-xmqtt-device-grid.definition';
import { I4BColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { I4BColumnHeader } from './models';
import { I4BColumnOptions } from './options';

export const defaultGridsDefinitions: {
  viewType: string;
  dataLibraries: {
    libraryName: string;
    columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[];
  }[];
}[] = [
  {
    viewType: 'SITES',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: GET_DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'GEOLOCATION',
        columns: GET_DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW()
      },
      {
        libraryName: 'TECHNICAL',
        columns: GET_DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW()
      }
    ]
  },
  {
    viewType: 'STOCK-SITE-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW()
      },
      {
        libraryName: 'TECHNICAL',
        columns: GET_DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW()
      }
    ]
  },
  {
    viewType: 'ASSETS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: GET_DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW()
      },
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW()
      },
      {
        libraryName: 'FOLLOWED VARIABLES',
        columns: GET_FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW()
      },
      {
        libraryName: 'GROUPED VARIABLES',
        columns: GET_VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'TECHNICAL',
        columns: GET_DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW()
      }
    ]
  },
  {
    viewType: 'DEVICES',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: GET_DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW()
      },
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW()
      },
      {
        libraryName: 'DIAGNOSTIC VARIABLES',
        columns: GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'TECHNICAL',
        columns: GET_DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW()
      }
    ]
  },
  {
    viewType: 'DALIA-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DALIA_DEVICES_VIEW()
      },
      {
        libraryName: 'DIAGNOSTIC VARIABLES',
        columns: GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DALIA_DEVICE_VIEW()
      }
    ]
  },
  {
    viewType: 'XMQTT-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_XMQTT_DEVICES_VIEW()
      }
    ]
  },
  {
    viewType: 'BAM-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_BAM_DEVICES_VIEW()
      }
    ]
  },
  {
    viewType: 'KERCOM-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_KERCOM_DEVICES_VIEW()
      }
    ]
  },
  {
    viewType: 'DALIA-FIRMWARES',
    dataLibraries: [
      {
        libraryName: 'FIRMWARES',
        columns: GET_DEFAULT_FIRMWARES_COLUMNS_FOR_DALIA_FIRMWARES_VIEW()
      }
    ]
  },
  {
    viewType: 'DALIA-SENSORS',
    dataLibraries: [
      {
        libraryName: 'SENSORS',
        columns: GET_DEFAULT_SENSORS_COLUMNS_FOR_DALIA_SENSORS_VIEW()
      }
    ]
  },
  {
    viewType: 'DALIA-DEVICE-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'DEVICE TEMPLATES',
        columns: GET_DEFAULT_DEVICE_TEMPLATES_COLUMNS_FOR_DALIA_DEVICE_TEMPLATES_VIEW()
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: GET_DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW()
      },
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW()
      },
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: GET_DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW()
      },
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW()
      },
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'EMAIL-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'EMAIL TEMPLATES',
        columns: GET_DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW()
      }
    ]
  },
  {
    viewType: 'CONNECTORS',
    dataLibraries: [
      {
        libraryName: 'CONNECTORS',
        columns: GET_DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW()
      }
    ]
  },
  {
    viewType: 'ASSET-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'ASSET TEMPLATES',
        columns: GET_DEFAULT_ASSET_TEMPLATES_COLUMNS_FOR_ASSET_TEMPLATES_VIEW()
      }
    ]
  },
  {
    viewType: 'ACTIVE-ASSET-EVENTS-POPUP',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: GET_DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW()
      },
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW()
      },
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-PE-RULE',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_PE_RULE_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-PE-RULE',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_PE_RULE_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-SITE',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_SITE_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-SITE',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_SITE_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-ASSET',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_ASSET_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-DEVICE',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_DEVICE_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS-BY-TOPIC',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: GET_DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_BY_TOPIC_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS-BY-TOPIC',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW()
      },
      {
        libraryName: 'EVENTS',
        columns: GET_DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_BY_TOPIC_VIEW()
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'PO-EVENT-RULES',
    dataLibraries: [
      {
        libraryName: 'PO EVENT RULES',
        columns: GET_DEFAULT_PO_EVENT_RULES_COLUMNS_FOR_PO_EVENT_RULES_VIEW()
      }
    ]
  },
  {
    viewType: 'USERS',
    dataLibraries: [
      {
        libraryName: 'USERS',
        columns: GET_DEFAULT_USERS_COLUMNS_FOR_USERS_VIEW()
      }
    ]
  },
  {
    viewType: 'CALENDARS',
    dataLibraries: [
      {
        libraryName: 'CALENDARS',
        columns: GET_DEFAULT_CALENDARS_COLUMNS_FOR_CALENDARS_VIEW()
      }
    ]
  }
];

export const getDefaultColumnsByConcept = (concept: I4bCellConcept | string): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] =>
  defaultGridsDefinitions
    .filter((defaultGrid) => defaultGrid.viewType.toLowerCase() === concept.toLowerCase())[0]
    ?.dataLibraries.reduce((acc: any, value) => {
      acc = [...acc, ...value.columns.filter((col) => col.configuration.isDefault)];
      return acc;
    }, [])
    .sort((colA: any, colB: any) => (colA.options.order > colB.options.order ? 1 : -1)) ?? [];

export const getAllColumnsByConcept = (concept: I4bCellConcept | string): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] =>
  defaultGridsDefinitions
    .filter((defaultGrid) => defaultGrid.viewType.toLowerCase() === concept.toLowerCase())[0]
    ?.dataLibraries.reduce((acc: any, value) => {
      acc = [...acc, ...value.columns];
      return acc;
    }, []) ?? [];

export const defaultSitesGrid: DefaultSitesGrid = new DefaultSitesGrid();
export const defaultStockSiteDevicesGrid: DefaultStockSiteDevicesGrid = new DefaultStockSiteDevicesGrid();
export const defaultAssetsGrid: DefaultAssetsGrid = new DefaultAssetsGrid();
export const defaultDevicesGrid: DefaultDevicesGrid = new DefaultDevicesGrid();
export const defaultDeviceEventsGrid: DefaultDeviceEventsGrid = new DefaultDeviceEventsGrid();
export const defaultAssetEventsGrid: DefaultAssetEventsGrid = new DefaultAssetEventsGrid();
export const defaultEmailTemplatesGrid: DefaultEmailTemplatesGrid = new DefaultEmailTemplatesGrid();
export const defaultConnectorsGrid: DefaultConnectorsGrid = new DefaultConnectorsGrid();
export const defaultActiveAssetEventsPopupGrid: DefaultActiveAssetEventsPopupGrid = new DefaultActiveAssetEventsPopupGrid();
export const defaultAssetEventsByPERuleGrid: DefaultAssetEventsByPERuleGrid = new DefaultAssetEventsByPERuleGrid();
export const defaultDeviceEventsByPERuleGrid: DefaultDeviceEventsByPERuleGrid = new DefaultDeviceEventsByPERuleGrid();
export const defaultAssetTemplatesGrid: DefaultAssetTemplatesGrid = new DefaultAssetTemplatesGrid();
export const defaultAssetEventsByTopicGrid: DefaultAssetEventsByTopicGrid = new DefaultAssetEventsByTopicGrid();
export const defaultDeviceEventsByTopicGrid: DefaultDeviceEventsByTopicGrid = new DefaultDeviceEventsByTopicGrid();
export const defaultAssetEventsBySiteGrid: DefaultAssetEventsBySiteGrid = new DefaultAssetEventsBySiteGrid();
export const defaultDeviceEventsBySiteGrid: DefaultDeviceEventsBySiteGrid = new DefaultDeviceEventsBySiteGrid();
export const defaultAssetEventsByAssetGrid: DefaultAssetEventsByAssetGrid = new DefaultAssetEventsByAssetGrid();
export const defaultDeviceEventsByDeviceGrid: DefaultDeviceEventsByDeviceGrid = new DefaultDeviceEventsByDeviceGrid();
export const defaultCalendarsGrid: DefaultCalendarsGrid = new DefaultCalendarsGrid();
