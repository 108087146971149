export enum I4bCellConcept {
  ASSETS = 'ASSETS',
  ASSET_EVENTS = 'ASSET EVENTS',
  ASSET_TAGS = 'ASSET TAGS',
  ASSET_TEMPLATES = 'ASSET TEMPLATES',
  ASSET_VARIABLES = 'ASSET_VARIABLES',
  AUDIT_TRAIL = 'AUDIT TRAIL',
  CALENDARS = 'CALENDARS',
  CONNECTORS = 'CONNECTORS',
  DEVICES = 'DEVICES',
  DEVICE_EVENTS = 'DEVICE EVENTS',
  DEVICE_EVENTS_TAGS = 'DEVICE EVENTS TAGS',
  DEVICE_TAGS = 'DEVICE TAGS',
  DEVICE_TEMPLATES = 'DEVICE TEMPLATES',
  DEVICE_VARIABLES = 'DEVICE_VARIABLES',
  DIAGNOSTIC_VARIABLES = 'DIAGNOSTIC VARIABLES',
  EMAIL_TEMPLATES = 'EMAIL TEMPLATES',
  EVENTS = 'EVENTS',
  EVENTS_TAGS = 'EVENTS TAGS',
  EVENT_TAGS = 'EVENT TAGS',
  FAVORITE_VIEWS = 'FAVORITE VIEWS',
  FIRMWARES = 'FIRMWARES',
  FOLLOWED_VARIABLES = 'FOLLOWED VARIABLES',
  GEOLOCATION = 'GEOLOCATION',
  GRIDS = 'GRIDS',
  GROUPED_VARIABLES = 'GROUPED VARIABLES',
  PO_EVENT_RULES = 'PO EVENT RULES',
  SENSORS = 'SENSORS',
  SITES = 'SITES',
  SITE_TAGS = 'SITE TAGS',
  TAGS = 'TAGS',
  TECHNICAL = 'TECHNICAL',
  USERS = 'USERS',
  VARIABLES = 'VARIABLES',
  DEVICE_CALL_LOGS = 'DEVICE CALL LOGS',
  KERCOM = 'KERCOM'
}
