import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class SiteTypeColumn extends I4BBasicColumn {
  columnId = '9fa4c7f0-5c1d-11ec-80a5-0023a40210d0-site-type';
  className = SiteTypeColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        ...customHeader,
        displayName: customOptions?.overrideHeaderName ? customOptions?.overrideHeaderName : customHeader?.displayName ? customHeader?.displayName : 'SITE TYPE'
      },
      {
        ...customConfig,
        id: 'type',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_TYPE',
        concept: I4bCellConcept.SITES,
        isDefault: true,
        isConfigurable: true,
        width: customOptions?.width ? customOptions.width : 120,
        cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.TYPES.' } }
      },
      { ...customOptions }
    );
  }
}
