import { ChangeDetectionStrategy, Component, computed, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { AssociationCardContainerComponent, OverviewCardComponent } from '@iot-platform/iot-platform-ui';
import { OverviewCard } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-asset-card',
  imports: [OverviewCardComponent, AssociationCardContainerComponent, FlexLayoutModule, MatIconModule],
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetCardComponent {
  asset = input<Asset>();
  clicked = output<Asset>();

  card: Signal<OverviewCard | null> = computed(() => {
    const asset = this.asset();
    return asset ? this.getCardMetadata(asset) : null;
  });

  private onClick(): void {
    this.clicked.emit(this.asset());
  }

  private getCardMetadata(asset: Asset): OverviewCard {
    const width = '50%';
    return {
      name: asset?.priority ? `${asset?.priority}. ${asset?.name}` : `${asset?.name}`,
      justifyContent: false,
      image: asset?.imageUrl,
      defaultImage: 'assets/images/asset/asset_default.png',
      onClick: () => this.onClick(),
      sections: [
        {
          properties: [
            { label: 'SITES.ASSOCIATIONS.SHIP_TO', value: asset?.erpReference?.shipTo, width },
            { label: 'ASSETS.CARD.PRODUCT_1', value: asset?.product1?.name, width },
            { label: 'SITES.ASSOCIATIONS.STATUS', value: asset?.status?.name, width },
            { label: 'ASSETS.CARD.PRODUCT_2', value: asset?.product2?.name, width }
          ]
        }
      ]
    };
  }
}
