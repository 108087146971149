import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { NgClass } from '@angular/common';
import { Component, signal, WritableSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderShellComponent } from '../header-shell/header-shell.component';

@Component({
  selector: 'grid-engine-basic-header-cell',
  templateUrl: './basic-header-cell.component.html',
  imports: [HeaderShellComponent, TranslateModule, NgClass],
  styleUrls: ['./basic-header-cell.component.scss']
})
export class BasicHeaderCellComponent implements IHeaderAngularComp {
  params: WritableSignal<IHeaderParams & { skipTextTransform?: boolean }> = signal(null);

  agInit(params: IHeaderParams & { skipTextTransform?: boolean }): void {
    this.params.set(params);
  }

  refresh(): boolean {
    return false;
  }
}
