import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'iot-platform-ui-tab-group-image',
  imports: [FlexLayoutModule, MatIcon, MatIconButton],
  template: `
    <div class="iot-platform-ui-tab-group__image-container" fxLayoutAlign.lt-md="center center">
      @if (image() || defaultImage()) {
        <img
          [src]="image()"
          alt="image"
          data-cy="iot-platform-ui-tab-group-image"
          class="iot-platform-ui-tab-group__image"
          #imageRef
          (error)="imageRef.src = defaultImage() ?? ''"
        />
      } @else if (svgIcon()) {
        <mat-icon [color]="'accent'" class="icon iot-platform-ui-tab-group__icon" data-cy="iot-platform-ui-tab-group-icon" fxFlex [svgIcon]="svgIcon()" />
      }
      @if (canUpdateImage()) {
        <button mat-icon-button class="iot-platform-ui-tab-group__edit-image-button regular-round-button" (click)="onClick()">
          <mat-icon>edit</mat-icon>
        </button>
      }
    </div>
  `
})
export class TabGroupImageComponent {
  image = input<string>();
  svgIcon = input<string>();
  defaultImage = input<string>();
  canUpdateImage = input<boolean>(false);
  editImageClicked = output<void>();

  onClick() {
    this.editImageClicked.emit();
  }
}
