import { EnvironmentProviders, NgModule } from '@angular/core';
import { AuditTrailModule, provideAuditTrailSettings } from '@iot-platform/audit-trail';
import { AuditTrailService } from './audit-trail.service';

export { TrackingSettingsProviderService } from './tracking-settings-provider.service';
export { GoogleAnalyticsMapperService } from './google-analytics-mapper.service';

export const provideI4bAuditTrailSettings = (): EnvironmentProviders =>
  provideAuditTrailSettings({
    auditTrailService: AuditTrailService,
    exportLogDetails: false
  });

@NgModule({
  imports: [
    AuditTrailModule.forRoot({
      auditTrailService: AuditTrailService,
      exportLogDetails: false
    })
  ]
})
export class I4bAuditTrailModule {}
