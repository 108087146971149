import { NgModule } from '@angular/core';
import { DynamicWidgetComponent } from './components/dynamic-widget/dynamic-widget.component';
import { LastValueWidgetComponent } from './components/last-value-widget/last-value-widget.component';
import { WidgetItemComponent } from './components/widget-item/widget-item.component';
import { WidgetsContainerComponent } from './components/widgets-container/widgets-container.component';
import { WidgetLoaderDirective } from './directives/widget-loader.directive';

@NgModule({
  imports: [WidgetLoaderDirective, WidgetItemComponent, DynamicWidgetComponent, LastValueWidgetComponent, WidgetsContainerComponent],
  exports: [WidgetsContainerComponent, DynamicWidgetComponent, LastValueWidgetComponent]
})
export class WidgetsModule {}
