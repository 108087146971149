import { Type } from '@angular/core';

export interface OverViewCardValueMeta {
  visibleExpression: (data?: any) => boolean;
  icon: string;
  tooltip: string;
}

export interface OverViewCardAction {
  icon?: string;
  label?: string;
  type: OverviewCardActions;
  tooltip?: string;
  disabled?: boolean;
  visible?: boolean;
  divider?: boolean;
  valueMeta?: Partial<OverViewCardValueMeta>;
}

export enum OverviewCardActions {
  ACTIVATE = 'ACTIVATE',
  CONFIGURE = 'CONFIGURE',
  CONFIGURE_PRODUCTS = 'CONFIGURE_PRODUCTS',
  CONFIGURE_PROPERTIES = 'CONFIGURE_PROPERTIES',
  CONFIGURE_VARIABLES_WITH_TEMPLATE = 'CONFIGURE_VARIABLES_WITH_TEMPLATE',
  DECOMMISSION = 'DECOMMISSION',
  DELETE = 'DELETE',
  DISPLAY_HISTORY = 'DISPLAY_HISTORY',
  EDIT = 'EDIT',
  EDIT_MAINTENANCE = 'EDIT_MAINTENANCE',
  END_MAINTENANCE = 'END_MAINTENANCE',
  EXPORT_TO_JSON = 'EXPORT_TO_JSON',
  GET_ALARM_COMMAND = 'GET_ALARM_COMMAND',
  MANAGE_RELATED_ASSETS = 'MANAGE_RELATED_ASSETS',
  MANAGE_TAGS = 'MANAGE_TAGS',
  MOVE_TO = 'MOVE_TO',
  NAVIGATE_TO_ASSET_GROUP = 'NAVIGATE_TO_ASSET_GROUP',
  NAVIGATE_TO_ASSET = 'NAVIGATE_TO_ASSET',
  NAVIGATE_TO_DEVICE = 'NAVIGATE_TO_DEVICE',
  REFRESH_COMMAND = 'REFRESH_COMMAND',
  REMOVE_PRODUCTS = 'REMOVE_PRODUCTS',
  RESET = 'RESET',
  SELFCONF_COMMAND = 'SELFCONF_COMMAND',
  START_MAINTENANCE = 'START_MAINTENANCE',
  THRESHOLD_COMMAND = 'THRESHOLD_COMMAND',
  UNBLOCK_COMMAND = 'UNBLOCK_COMMAND'
}

export interface OverviewCardEvent {
  type: OverviewCardActions;
  data?: any;
}

interface OverViewCardCommonOptions {
  key: string;
  cssClass: string;
  style: any;
  data: any;
  visibleExpression: (data?: any) => boolean;
}

export interface OverViewCardSectionProperty extends OverViewCardCommonOptions {
  label: string;
  value: unknown;
  innerHTMLValue: unknown;
  valueMeta: Partial<OverViewCardValueMeta>;
  width: string;
  componentRef: {
    ref: Type<any>;
    inputs: any;
  };
  onClick?: (event?: any) => void;
}

export interface OverViewCardSection extends OverViewCardCommonOptions {
  title: string;
  titleComponentRef: { ref: Type<any>; inputs: any };
  collapsible: boolean;
  expandedChange: (index: number, expanded: boolean) => void;
  expanded: boolean;
  action: OverViewCardAction & { dispatchEvent: (data?: any) => void };
  componentRef?: {
    ref: Type<any>;
    inputs: any;
  };
  properties: Partial<OverViewCardSectionProperty>[];
}

export interface ExpansionPanelsOptions {
  enableCache: boolean;
  clearOnDestroy: boolean;
  key: string;
  entityId?: string;
}

export interface OverviewCard {
  name?: string;
  valueMeta?: OverViewCardValueMeta;
  nameMetaAction?: OverViewCardAction & { dispatchEvent: (data?: any) => void };
  expansionPanelsOptions?: ExpansionPanelsOptions;
  image?: string | null;
  defaultImage?: string | null;
  defaultSvgIcon?: string | null;
  imageWidth?: string;
  canUpdateImage?: boolean;
  imageMetaAction?: OverViewCardAction & { dispatchEvent: (data?: any) => void };
  onClick?: (event?: any) => void;
  cssClass?: string;
  justifyContent?: boolean;
  sections: Partial<OverViewCardSection>[];
  actions?: OverViewCardAction[];
}
