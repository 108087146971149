import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Graph } from '@iot-platform/models/common';
import { WidgetEvent, WidgetInstance } from '@iot-platform/models/widgets';
import { WidgetItemComponent } from '../widget-item/widget-item.component';

@Component({
  imports: [FlexLayoutModule, WidgetItemComponent, NgClass, DragDropModule],
  selector: 'iot-platform-ui-widgets-container',
  templateUrl: './widgets-container.component.html',
  styleUrls: ['./widgets-container.component.scss']
})
export class WidgetsContainerComponent {
  enableDragAndDrop = input<boolean>(false);
  widgets = input<WidgetInstance[], WidgetInstance[]>([], {
    transform: (value: WidgetInstance[]) => value?.slice()?.sort((widgetA, widgetB) => widgetA.data.displayOrder - widgetB.data.displayOrder)
  });

  dispatchEvent = output<WidgetEvent>();
  widgetsOrderChange = output<WidgetInstance[]>();

  drop(event: CdkDragDrop<WidgetInstance[]>) {
    moveItemInArray(this.widgets(), event.previousIndex, event.currentIndex);
    this.widgetsOrderChange.emit(this.getReorderedWidgets(this.widgets()));
  }

  getReorderedWidgets(widgets: WidgetInstance[]): WidgetInstance[] {
    return widgets.reduce((acc: WidgetInstance[], widget: WidgetInstance, currentIndex) => {
      if ((widget.data as Graph).displayOrder !== currentIndex) {
        (widget.data as Graph).displayOrder = currentIndex;
        acc.push(widget);
      }
      return acc;
    }, []);
  }
}
