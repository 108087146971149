export enum CommandType {
  REFRESH = 'refresh',
  SELFCONF = 'selfconf',
  BACKUP = 'backup',
  THRESHOLD = 'threshold',
  UNBLOCK = 'unblock',
  GET_ALARM = 'getalarm',
  CONFIGURE = 'configure',
  SENDFCFG = 'SENDFCFG',
  GPS = 'GPS',
  SRTM = 'SRTM',
  TRANSPORT = 'TRANSPORT',
  RESET = 'RESET'
}
