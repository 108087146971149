import { DeviceCallLog } from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';

export const DeviceCallLogsActions = createActionGroup({
  source: 'Device Call Logs',
  events: {
    'Load Device Call Logs': props<{ device: Device }>(),
    'Load Device Call Logs Success': props<{ response: DeviceCallLog[] }>(),
    'Load Device Call Logs Failure': props<{ error: unknown }>(),

    'Load Device Call Log Message': props<{ device: Device; deviceCallLog: DeviceCallLog; download?: boolean }>(),
    'Load Device Call Log Message Success': props<{ deviceCallLogMessage: string; download?: boolean }>(),
    'Load Device Call Log Message Failure': props<{ error: unknown }>(),

    'Download Device Call Log Message': props<{ deviceCallLogMessage: string }>()
  }
});
