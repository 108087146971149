@if (!notifications.length && canUpdateContact) {
  <iot-platform-ui-default-card
    (click)="addNotification.emit()"
    [backgroundClass]="'h-100'"
    [title]="'SITES.NOTIFICATIONS_TAB.ADD' | translate"
    [icon]="'add'"
    fxFlex="0 1 calc(33.33% - 15px)"
  >
  </iot-platform-ui-default-card>
}
<div fxFlex fxLayout="row" fxLayoutGap="20px">
  <iot4bos-ui-notifications-exports-tabs-checkbox-list
    (checkItem)="checkNotification.emit($event)"
    (selectItem)="selectNotification.emit($event)"
    [currentContact]="currentContact"
    [displayCheckbox]="displayCheckbox"
    [list]="notifications"
    [selectedItem]="selectedNotification"
    fxFlex="15"
  ></iot4bos-ui-notifications-exports-tabs-checkbox-list>
  @if (selectedNotification) {
    <div class="site-notifications--notification-card" fxFlex fxLayout="column" fxLayoutGap="10px">
      @if (canUpdateContact) {
        <div class="site-notifications--notification-card-button app-round-button">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="configureNotification.emit(selectedNotification)" mat-menu-item>{{ 'IOT_DICTIONARY.CONFIGURE' | translate }}</button>
            <button (click)="deleteNotification.emit(selectedNotification)" [disabled]="selectedNotification.status === 'active'" mat-menu-item>
              {{ 'IOT_DICTIONARY.DELETE' | translate }}
            </button>
          </mat-menu>
        </div>
      }
      <div class="site-notifications--notification-card-title fw-600" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <span>{{ selectedNotification.name }}</span>
        <span
          [ngClass]="{
            'capitalize-first-letter': true,
            'notification-status-active': selectedNotification.status === 'active',
            'notification-status-inactive': selectedNotification.status !== 'active'
          }"
          >{{ 'IOT_DICTIONARY.' + selectedNotification.status | translate }}</span
        >
      </div>
      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span class="site-notifications--notification-property-name capitalize-first-letter" fxFlex="20">{{
          'SITES.NOTIFICATION_FORM.EMAIL_TEMPLATE' | translate
        }}</span>
        <span>{{ emailTemplate?.name | infoDisplay }}</span>
      </section>
      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span class="site-notifications--notification-property-name capitalize-first-letter" fxFlex="20">{{
          'SITES.NOTIFICATION_FORM.ASSETS_NAMES' | translate
        }}</span>
        <span>{{ selectedNotificationAssetNames | infoDisplay }}</span>
      </section>
      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span class="site-notifications--notification-property-name capitalize-first-letter" fxFlex="20">{{
          'SITES.NOTIFICATION_FORM.EVENT_CLASSES' | translate
        }}</span>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="6px">
          @for (eventClass of selectedNotificationEventClasses; track eventClass) {
            <mat-chip-option [disableRipple]="true" [selectable]="false" color="accent">{{ eventClass }} </mat-chip-option>
          }
        </div>
      </section>
      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span class="site-notifications--notification-property-name capitalize-first-letter" fxFlex="20">{{
          'SITES.NOTIFICATION_FORM.EVENT_SEVERITIES' | translate
        }}</span>
        @for (severity of selectedNotificationEventSeverities; track severity) {
          <iot-platform-ui-event-severity-display [severity]="severity"></iot-platform-ui-event-severity-display>
        }
      </section>
      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span class="site-notifications--notification-property-name capitalize-first-letter" fxFlex="20">{{
          'SITES.NOTIFICATION_FORM.NOTIFIED_CONTACTS' | translate
        }}</span>
        <iot4bos-ui-notifications-exports-tabs-related-contacts
          [currentContact]="currentContact"
          [selectedItem]="selectedNotification"
        ></iot4bos-ui-notifications-exports-tabs-related-contacts>
      </section>
    </div>
  }
  @if (!selectedNotification && notifications.length) {
    <div class="site-notifications--select-message fw-600" fxFlex>
      <span>{{ 'SITES.NOTIFICATIONS_TAB.SELECT_NOTIFICATION_MESSAGE' | translate }}</span>
    </div>
  }
</div>
