import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CustomEncoder, ENVIRONMENT, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, Entity, Environment, Filter, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import {
  Asset,
  AssociationByAsset,
  AssociationByDevice,
  CommonBulkOperationType,
  Concept,
  Device,
  I4BBulkOperationApiResponse,
  Log,
  Site
} from '@iot-platform/models/i4b';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly storage: LocalStorageService = inject(LocalStorageService);

  getAllSites(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<Site[]>(`${this.environment.api.url}${this.environment.api.endpoints.sites}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}`);
  }

  getSiteByIdWithAssetsAndDevices(
    siteId: string,
    activeId: string,
    origin: string
  ): Observable<{
    site: Site;
    assets: Asset[];
    devices: Device[];
  }> {
    this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, siteId);
    this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, activeId);
    this.storage.set(LocalStorageKeys.STORAGE_ORIGIN_KEY, origin);

    const site$ = this.getSiteById(siteId);
    const assets$ = this.getAssetsBySiteId({ limit: 25, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });
    const devices$ = this.getDevicesBySiteId({
      limit: 25,
      page: 0,
      filters: [{ criteriaKey: 'siteId', value: siteId }]
    });

    const combined$ = combineLatest([site$, assets$, devices$]);

    const subscribe = combined$.pipe(
      map(([site, assets, devices]) => ({
        site,
        assets: assets.data,
        devices: devices.data
      }))
    );

    return subscribe;
  }

  getAssetsBySiteId(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<PlatformResponse>(`${this.environment.api.url + this.environment.api.endpoints.assets}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  getDevicesBySiteId(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<PlatformResponse>(`${this.environment.api.url + this.environment.api.endpoints.devices}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  selectSite(selectedSite: Site): Observable<Site> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_SITES_TABLE_STATE_KEY, JSON.stringify(selectedSite));
    return of(selectedSite);
  }

  getEntities(): Observable<Entity[]> {
    return this.httpClient.get<Entity[]>(this.environment.api.url + this.environment.api.endpoints.entities).pipe(map((entities: any) => entities.content));
  }

  save(site: Site): Observable<Site> {
    return this.httpClient.post<Site>(this.environment.api.url + this.environment.api.endpoints.sites, site);
  }

  update(site: Site): Observable<Site> {
    return this.httpClient.put<Site>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + site.id, site);
  }

  delete(site: Site): Observable<Site> {
    return this.httpClient.delete<any>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + site.id).pipe(map((_) => site));
  }

  getTagsBySiteId(siteId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/tags`)
      .pipe(map((data: { page: NonNullable<unknown>; content: TagCategory[] }) => data.content));
  }

  putTagsBySiteId(siteId: string, tags: TagCategory[]): Observable<TagCategory[]> {
    const tagLabelIds: string[] = tags.map((t) => t.labels?.map((l) => l.id)).flat();
    return this.httpClient
      .put<CommonApiListResponse<TagCategory>>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/tags`, { tags: tagLabelIds })
      .pipe(map((data: CommonApiListResponse<TagCategory>) => data.content));
  }

  getSiteFromERP(data: { source: string; shipto: string }): Observable<any> {
    return this.httpClient.get(`${this.environment.api.url}${this.environment.api.endpoints.erpProxySAP}/${data.shipto}`);
  }

  getCountries(): Observable<any[]> {
    return this.httpClient.get<any[]>(`assets/data/countries.json`);
  }

  getDeviceFamilies(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.environment.api.url}${this.environment.api.endpoints.connectorFamilies}`);
  }

  getTotalDevicesByFamilyAndSiteId(request: PlatformRequest): Observable<number> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient
      .get<Device[]>(`${this.environment.api.url}${this.environment.api.endpoints.devices}`, { params })
      .pipe(map((data: any) => data.page.total));
  }

  bulkOperationOnTag(operationType: CommonBulkOperationType, sitesIds: string[], tagLabelId: string): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(
      `${this.environment.api.url}${this.environment.api.endpoints[`sitesBulkOperationOnTag${StringUtils.capitalizeFirstCharacter(operationType)}`]}`,
      {
        sitesIds,
        tagLabelId
      }
    );
  }

  loadComments(siteId: string): Observable<Log[]> {
    return this.httpClient
      .get<Log[]>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.logs}`)
      .pipe(map((logs: Log[]) => logs.map((log) => ({ ...log, concept: Concept.SITE, icon: 'site' }))));
  }

  addComment(siteId: string, comment: string): Observable<Log> {
    return this.httpClient
      .post<Log>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.logs}`, {
        comment
      })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' })));
  }

  editComment(siteId: string, comment: Log): Observable<Log> {
    return this.httpClient
      .put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.logs}/${comment.id}`, {
        comment: comment.comment
      })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' })));
  }

  deleteComment(siteId: string, commentId: string): Observable<string> {
    return this.httpClient
      .delete<void>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}${this.environment.api.endpoints.logs}/${commentId}`)
      .pipe(map(() => commentId));
  }

  isNameUnique(siteName: string, entityId: string) {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('page', '0');
    params = params.set('limit', '0');
    params = params.append('name', siteName);
    params = params.append('entityId', entityId);
    params = params.append('exactParam', 'name');
    return this.httpClient
      .get<CommonApiListResponse<Site>>(`${this.environment.api.url}${this.environment.api.endpoints.sites}`, { params })
      .pipe(map((response) => response.page.total === 0));
  }
}
