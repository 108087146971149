<mat-sidenav-container (backdropClick)="closeAllPanels()" class="layout device-overview--sidenav-container" hasBackdrop="true">
  <mat-sidenav-content>
    <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)" color="accent" mat-align-tabs="center">
      <mat-tab label="tab_info">
        <ng-template matTabLabel>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
            <span>{{ 'DEVICES.TABS.INFO' | translate | uppercase }}</span>
          </div>
        </ng-template>
        <div class="layout__wrapper pl-12 pr-12 pt-8 pb-8 sm:p-4 max-w-4xl  xl:max-w-4xl">
          <iot4bos-ui-device-info
            (configureDeviceConnector)="onConfigureDeviceConnector($event)"
            [canCreate]="canCreate()"
            [canDelete]="canDelete()"
            [canReadAuditTrail]="canReadAuditTrail"
            [canRead]="canRead()"
            [canUpdate]="canUpdate()"
            [site]="site$ | async"
          />
        </div>
      </mat-tab>

      <mat-tab class="mat-tab" label="tab_variables">
        <ng-template mat-tab-label>
          {{ 'DEVICES.TABS.DEVICE_VARIABLES' | translate | uppercase }}
        </ng-template>
        <ng-template matTabContent>
          <div class="layout__wrapper min-h-full p-4">
            <iot4bos-ui-device-variables-table fxFlex fxLayout="column" />
          </div>
        </ng-template>
      </mat-tab>

      @if (canReadEvent) {
        <mat-tab label="tab_events">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'IOT_DICTIONARY.device-events' | translate | uppercase }}</span>
            </div>
          </ng-template>
          <ng-template matTabContent>
            <div class="layout__wrapper min-h-full">
              <iot4bos-ui-device-events-tabs fxFlex [selectedDevice]="device$ | async" [userPermissions]="userPermissions" />
            </div>
          </ng-template>
        </mat-tab>
      }

      <mat-tab class="mat-tab" label="tab_call_logs">
        <ng-template mat-tab-label>
          {{ 'DEVICES.TABS.CALL_LOG' | translate | uppercase }}
        </ng-template>
        <ng-template matTabContent>
          <div class="layout__wrapper min-h-full">
            <iot-platform-feature-device-call-logs-tab [device]="device$ | async" fxFlex />
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav-content>
  <mat-sidenav [opened]="dataGuardOpened()" mode="over" position="end">
    @if (dataGuardOpened()) {
      <iot-platform-feature-data-guard />
    }
  </mat-sidenav>
</mat-sidenav-container>
