import { AfterContentInit, Directive, ElementRef, inject, input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusInitial]',
  standalone: true
})
export class FocusInitialDirective implements AfterContentInit {
  private readonly el: ElementRef = inject(ElementRef);
  focusInitial = input();

  ngAfterContentInit(): void {
    if (this.focusInitial()) {
      this.el.nativeElement.focus();
    }
  }
}
