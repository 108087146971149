import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';

import { ProfileActions } from '../../../profile/state/actions';
import { PreferencesActions } from '../actions';

export const userPreferencesDbFeatureKey = 'userPreferencesDb';

export interface State {
  currentUser: UserAccount;
  preferences: UserPreferences;
}

export const initialState: State = {
  currentUser: null,
  preferences: {
    appNumberFormat: 'fr',
    appNumberOfDecimals: 3,
    appLanguage: 'en',
    appTheme: 'default-theme',
    filterEngineOpenByDefault: true,
    siteAssociationDisplay: 'assets',
    displayDecommissionedAssets: false
  }
};

export const reducer = createReducer(
  initialState,
  on(PreferencesActions.loadUserPreferences, () => initialState),
  on(PreferencesActions.loadUserPreferencesSuccess, (state: State, { user, preferences }) => ({
    ...state,
    currentUser: user,
    preferences: {
      appDateFormats: preferences.appDateFormats,
      appNumberFormat: preferences.appNumberFormat ?? state.preferences.appNumberFormat,
      appNumberOfDecimals: preferences.appNumberOfDecimals ?? state.preferences.appNumberOfDecimals,
      appLanguage: preferences.appLanguage ?? state.preferences.appLanguage,
      appTheme: preferences.appTheme ?? state.preferences.appTheme,
      favoriteFilters: preferences.favoriteFilters ?? state.preferences.favoriteFilters,
      filterEngineOpenByDefault: preferences.filterEngineOpenByDefault ?? state.preferences.filterEngineOpenByDefault,
      siteAssociationDisplay: preferences.siteAssociationDisplay ?? state.preferences.siteAssociationDisplay,
      displayDecommissionedAssets: preferences.displayDecommissionedAssets ?? state.preferences.displayDecommissionedAssets
    }
  })),
  on(PreferencesActions.saveUserPreferencesSuccess, (state: State, { user, preferences }) => ({ ...state, currentUser: user, preferences })),

  on(ProfileActions.saveUserProfileSuccess, (state: State, { updatedUser }) => ({ ...state, currentUser: updatedUser, preferences: updatedUser.preferences }))
);

export const getCurrentUser = (state: State) => state.currentUser;
export const getPreferences = (state: State) => state.preferences;
