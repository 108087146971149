import { inject, Injectable, Signal } from '@angular/core';
import { AssociationByAsset, AssociationByDevice } from '@iot-platform/models/i4b';
import { AssociationAssetDeviceStore } from '../stores/association-asset-device.store';

@Injectable({
  providedIn: 'root'
})
export class AssociationAssetDeviceFacade {
  private readonly store = inject(AssociationAssetDeviceStore);

  associationByAsset: Signal<AssociationByAsset> = this.store.associationByAsset;
  associationByDevice: Signal<AssociationByDevice> = this.store.associationByDevice;
  loading: Signal<boolean> = this.store.loading;

  loadAssociations(siteId: string): void {
    this.store.loadAssociations(siteId);
  }
}
