import { I4BCellType, I4BColumnConfiguration } from './configurations/i4b-column-configuration';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { I4BPoEventRulesButtonColumn } from './definitions';
import { I4BBasicColumn } from './definitions/i4b-basic.column';
import { I4BColumn } from './definitions/i4b-column';
import { I4bCellConcept } from './enums/i4b-cell-concept.enum';
import { HeaderType, I4BColumnHeader } from './models/i4b-column-header.model';
import { PoEventRulesGridData } from './models/i4b-grid-data.model';
import { I4BGridOptions } from './models/i4b-grid-options.model';
import { I4BGrid } from './models/i4b-grid.model';
import { I4BColumnOptions } from './options/i4b-column-options';

export const GET_DEFAULT_PO_EVENT_RULES_COLUMNS_FOR_PO_EVENT_RULES_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  new I4BBasicColumn(
    { displayName: 'NAME', type: HeaderType.BASIC },
    {
      id: 'name',
      isDefault: true,
      sortProperty: 'name',
      translationKey: 'IOT_DICTIONARY.NAME',
      concept: I4bCellConcept.PO_EVENT_RULES,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigate' } }
    },
    { order: 1 }
  ),

  new I4BBasicColumn(
    { displayName: 'RULE TYPE', type: HeaderType.BASIC },
    {
      id: 'algo',
      isDefault: true,
      sortProperty: 'algo',
      translationKey: 'IOT_DICTIONARY.RULE_TYPE',
      concept: I4bCellConcept.PO_EVENT_RULES,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 2 }
  ),

  new I4BBasicColumn(
    { displayName: 'SEVERITY', type: HeaderType.BASIC },
    {
      id: 'severity',
      isDefault: true,
      sortProperty: 'severity',
      translationKey: 'IOT_DICTIONARY.SEVERITY',
      concept: I4bCellConcept.PO_EVENT_RULES,
      cell: { type: I4BCellType.SEVERITY_CELL }
    },
    { order: 3 }
  ),

  new I4BBasicColumn(
    { displayName: 'CLASS', type: HeaderType.BASIC },
    {
      id: 'class',
      isDefault: true,
      sortProperty: 'class',
      translationKey: 'IOT_DICTIONARY.CLASS',
      concept: I4bCellConcept.PO_EVENT_RULES,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 4 }
  ),

  new I4BBasicColumn(
    { displayName: 'CONCEPT', type: HeaderType.BASIC },
    {
      id: 'concept',
      isDefault: true,
      sortProperty: 'concept',
      translationKey: 'IOT_DICTIONARY.CONCEPT',
      concept: I4bCellConcept.PO_EVENT_RULES,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 5 }
  ),

  new I4BBasicColumn(
    { displayName: 'ENTITY', type: HeaderType.BASIC },
    {
      id: 'entity.name',
      isDefault: true,
      sortProperty: 'entity.name',
      translationKey: 'IOT_DICTIONARY.ENTITY',
      concept: I4bCellConcept.PO_EVENT_RULES,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 6 }
  ),

  new I4BBasicColumn(
    { displayName: 'STATUS' },
    {
      id: 'isActive',
      width: 50,
      sortProperty: 'isActive',
      translationKey: 'IOT_DICTIONARY.STATUS',
      isDefault: true,
      concept: I4bCellConcept.PO_EVENT_RULES,
      cell: {
        type: I4BCellType.BASIC,
        options: {
          capitalize: true,
          translatedValues: {
            true: { color: '#262836', backgroundColor: 'rgba(164, 255, 0, 0.1)', value: 'Active' },
            false: { color: '#262836', backgroundColor: 'rgba(255, 0, 99, 0.1)', value: 'Inactive' }
          }
        }
      }
    },
    { order: 7 }
  )
];

export class DefaultPoEventRulesGrid implements I4BGrid<I4BGridOptions, PoEventRulesGridData> {
  masterview = 'po-event-rules';
  isDefault = true;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: PoEventRulesGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new I4BPoEventRulesButtonColumn() },
    gridSort: []
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];
}
