<iot-platform-ui-toolbar-v2
  (dispatchToolbarEvent)="onToolbarEvent($event)"
  [buttonList]="organizationsButtonList()"
  [isDataLoaded]="!organizationsPendingStatus()"
  [name]="'ADMIN.ORGANIZATIONS.TOOLBAR_TITLE' | translate"
  [size]="toolbarSize"
  [total]="organizations().length"
/>

<div class="bp-page">
  <div class="full-height-with-overflow-y organization-list" fxFlex="30">
    <iot4bos-ui-backoffice-organizations-list
      (addOrganization)="onAddOrganization($event)"
      (lockUnlockOrganization)="onLockUnlockOrganization($event)"
      (selectOrganization)="onSelectOrganization($event)"
      [canCreateOrganization]="canCreateOrganization"
      [canUpdateOrganization]="canUpdateOrganization"
      [isTopLevelAdmin]="isTopLevelAdmin()"
      [organizations]="organizations()"
      [selectedOrganization]="selectedOrganization()"
    />
  </div>

  <div class="full-height-width" fxFlex="70">
    <iot4bos-ui-backoffice-organization-detail
      (addAdministratorToOrganization)="onAddAdministratorToOrganization()"
      (addBusinessProfile)="onAddBusinessProfile($event)"
      (addRole)="onAddRole($event)"
      (addTags)="onAddTags($event)"
      (authorizationsChanged)="onAuthorizationsChanged($event)"
      (deleteBusinessProfile)="onDeleteBusinessProfile($event)"
      (deleteOrganization)="onDeleteOrganization($event)"
      (deleteRole)="onDeleteRole($event)"
      (lockUnlockOrganization)="onLockUnlockOrganization($event)"
      (manageConcepts)="onManageConceptsForOrganization($event)"
      (manageTags)="onManageTagsForOrganization($event)"
      (renameRole)="onRenameRole($event)"
      (selectBusinessProfile)="onSelectBusinessProfile($event)"
      (selectRole)="onSelectRole($event)"
      (updateOrganization)="onUpdateOrganization($event)"
      [adminConceptsFromEntitySession]="adminConceptsFromEntitySession()"
      [administratorsPendingStatus]="administratorsPendingStatus()"
      [administrators]="administrators()"
      [businessProfilesPendingStatus]="businessProfilesPendingStatus()"
      [businessProfiles]="businessProfiles()"
      [isCurrentUserAdmin]="isAdmin()"
      [isTopLevelAdmin]="isTopLevelAdmin()"
      [rolesPendingStatus]="rolesPendingStatus()"
      [roles]="roles()"
      [selectedOrganization]="selectedOrganization()"
      [selectedRole]="selectedRole()"
      [tagsPendingStatus]="tagsPendingStatus()"
      [tags]="tags()"
    />
  </div>
</div>
