import { inject } from '@angular/core';
import { Event, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { DeviceEventsService } from '../../services/device-events.service';
import { DeviceEventsActions } from '../actions';
import { DeviceEventsFacade } from '../facades/device-events.facade';

const updateDeviceEventStatus$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.updateDeviceEventStatus),
      concatMap(({ eventId, status }) =>
        deviceEventsService.updateStatus(eventId, status).pipe(
          map((event: Event) => DeviceEventsActions.updateDeviceEventStatusSuccess({ event })),
          catchError((error) => of(DeviceEventsActions.updateDeviceEventStatusFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateDeviceEventStatusSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsFacade = inject(DeviceEventsFacade)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.updateDeviceEventStatusSuccess),
      tap(({ event }) => deviceEventsFacade.updateEventInAllGrids(event))
    ),
  { functional: true, dispatch: false }
);

const bulkUpdateDeviceEventsStatus$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.bulkUpdateDeviceEventsStatus),
      concatMap(({ eventsIds, status }) =>
        deviceEventsService.bulkUpdateStatusByEventType('device-events', eventsIds, status).pipe(
          map((response: I4BBulkOperationApiResponse) => DeviceEventsActions.bulkUpdateDeviceEventsStatusSuccess({ response })),
          catchError((error) => of(DeviceEventsActions.bulkUpdateDeviceEventsStatusFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkUpdateDeviceEventsStatusSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.bulkUpdateDeviceEventsStatusSuccess),
      tap(({ response, type }) => notificationService.displaySuccess(type + ' [ ' + I4BBulkOperationApiResponseStatuses[response.status] + ' ] '))
    ),
  { functional: true, dispatch: false }
);

const reloadLogs$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsFacade = inject(DeviceEventsFacade)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.updateDeviceEventStatusSuccess),
      tap(({ event }) => deviceEventsFacade.loadComments(event))
    ),
  { functional: true, dispatch: false }
);

const reloadGrid$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsFacade = inject(DeviceEventsFacade)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.bulkUpdateDeviceEventsStatusSuccess),
      tap(() => deviceEventsFacade.reloadGrid())
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.updateDeviceEventStatus, DeviceEventsActions.bulkUpdateDeviceEventsStatus),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsActions.updateDeviceEventStatusSuccess,
        DeviceEventsActions.updateDeviceEventStatusFailure,
        DeviceEventsActions.bulkUpdateDeviceEventsStatusSuccess,
        DeviceEventsActions.bulkUpdateDeviceEventsStatusFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.updateDeviceEventStatusSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceEventsActions.updateDeviceEventStatusFailure, DeviceEventsActions.bulkUpdateDeviceEventsStatusFailure),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const DeviceEventsEffects = {
  updateDeviceEventStatus$,
  updateDeviceEventStatusSuccess$,
  bulkUpdateDeviceEventsStatus$,
  bulkUpdateDeviceEventsStatusSuccess$,
  reloadLogs$,
  reloadGrid$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
