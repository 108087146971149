import { signal, WritableSignal } from '@angular/core';
import { FeatureMasterViewSettings } from '@iot-platform/feature/master-view';
import { FilterEngineMode } from '@iot-platform/iot-platform-ui';
import { DeviceCallLogDetailsComponent } from '../components/device-call-log-details/device-call-log-details.component';
import { FeatureDeviceCallLogsSettings } from '../models/device-call-logs-settings.model';

const deviceCallLogDrawerDetailsOpened: WritableSignal<boolean> = signal(false);

export const toggleDeviceCallLogDrawerDetails = (value = false) => deviceCallLogDrawerDetailsOpened.set(value);

export const getDeviceCallLogsMasterViewSettings = (settings: FeatureDeviceCallLogsSettings): Partial<FeatureMasterViewSettings> => ({
  masterViewName: signal(settings.masterViewName),
  grid: {
    staticDefinitionType: signal(true),
    hidePaginator: signal(true)
  },
  toolbar: {
    enabled: signal(false),
    withFavoriteViews: signal(false),
    withGrids: signal(false)
  },
  sectionHeader: {
    enabled: signal(false)
  },
  filterEngine: {
    enabled: signal(false),
    mode: signal(FilterEngineMode.CLASSIC)
  },
  drawerDetails: {
    enabled: signal(true),
    position: signal('end'),
    mode: signal('side'),
    opened: deviceCallLogDrawerDetailsOpened,
    openedOnDestroy: signal(false),
    componentRef: signal({
      ref: DeviceCallLogDetailsComponent,
      inputs: { toggleDrawerDetails: (opened: boolean) => toggleDeviceCallLogDrawerDetails(opened) }
    })
  }
});
