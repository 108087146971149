@if (displaySearchBar()) {
  <mat-sidenav-container (backdropClick)="closeNav()" [class.fullscreen]="fullscreenOn()">
    <mat-sidenav-content class="variable-chart-side-nav-container" fxLayout="column" style="overflow-x: hidden">
      <div class="variable-chart-export-bar" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="variable-chart-days-filter" [ngClass.lt-md]="{'mb-2': true}" fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="space-around center">
            <button (click)="onPeriodClicked(1)" [class.selected]="chartPeriod() === 1"
                    class="variable-chart-selector-button" mat-raised-button>
              1 {{ 'VARIABLE_CHART.DAY' | translate }}
            </button>
            <button (click)="onPeriodClicked(7)" [class.selected]="chartPeriod() === 7"
                    class="variable-chart-selector-button" mat-raised-button>
              7 {{ 'VARIABLE_CHART.DAY' | translate }}s
            </button>
            <button (click)="onPeriodClicked(30)" [class.selected]="chartPeriod() === 30"
                    class="variable-chart-selector-button" mat-raised-button>
              30 {{ 'VARIABLE_CHART.DAY' | translate }}s
            </button>
          </div>
          <div class="variable-chart-dates-filter" [ngClass.lt-md]="{'mb-2': true}"  fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div
              class="variable-chart-datepicker"
              fxLayout="row"
              style="width: 140px; background-color: #ececec; margin-left: 8px; margin-right: 8px; padding-left: 8px"
            >
              <input #startDate [matDatepicker]="startPicker" matInput
                     placeholder="{{ 'VARIABLE_CHART.FROM' | translate }}" />
              <mat-datepicker-toggle [for]="startPicker" matTextSuffix />
              <mat-datepicker #startPicker />
            </div>
            <div class="variable-chart-datepicker" fxLayout="row"
                 style="width: 140px; background-color: #ececec; padding-left: 8px">
              <input #endDate [matDatepicker]="endPicker" matInput
                     placeholder="{{ 'VARIABLE_CHART.TO' | translate }}" />
              <mat-datepicker-toggle [for]="endPicker" matTextSuffix />
              <mat-datepicker #endPicker />
            </div>
          </div>
          <button
            (click)="onSelectDates(startDate.value, endDate.value)"
            [class.selected]="chartPeriod() === 0"
            [disabled]="!startDate.value"
            class="variable-chart-selector-button"
            fxFlex
            mat-raised-button
          >
            {{ 'VARIABLE_CHART.APPLY' | translate | uppercase }}
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          @if (!displayTable()) {
            <mat-slide-toggle (change)="onAutoScaleChange($event)" [checked]="scale() === null"
            >{{ 'VARIABLE_CHART.AUTO_SCALE' | translate }}
            </mat-slide-toggle>
          }
          <button
            (click)="openVariables()"
            [matTooltip]="'VARIABLE_CHART.ADD_VARIABLES' | translate"
            class="variable-chart-add-variables-button"
            mat-icon-button
            matTooltipClass="regular-tooltip"
          >
            <mat-icon>addchart</mat-icon>
          </button>

          @if (showCustomExportButton() && canExportData() && chartDataSet()?.length) {
            <button
              [matMenuTriggerFor]="menu"
              [matTooltip]="'AG_GRID.export' | translate"
              class="variable-chart-add-variables-button"
              mat-icon-button
              matTooltipClass="regular-tooltip"
              [disabled]="displayLoader()"
            >
              <mat-icon>get_app</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onExportData(ExportType.CSV)">{{ 'EXPORTS.DOWNLOAD' | translate }} CSV
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="onExportData(ExportType.XLSX)">{{ 'EXPORTS.DOWNLOAD' | translate }} XSL
              </button>
            </mat-menu>
          }
        </div>
      </div>
      @if (displayLoader()) {
        <mat-progress-bar class="variable-chart-loader" color="accent" mode="indeterminate" />
      }
      @if(displayTable()) {
        <ng-container *ngxInit="grid() as grd">
          <grid-engine-grid-page
            [ngClass]="{'variable-chart-grid--hidden': !displayTable()}"
            class="variable-chart-grid"
            [grid]="grd"
            [gridSort]="grd?.gridOptions?.gridSort"
            (gridReady)="onGridReady($event)"
            [hidePaginator]="true"
          />
        </ng-container>
      }

      <div class="variables-closed"
           [ngClass]="{
           'variable-chart--hidden': displayTable(),
           'show-custom-export-button': showCustomExportButton(),
           }">
        <highcharts-chart
          #highChart
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructorType"
          [oneToOne]="true"
          [options]="mainChartOptions()"
          fxFlex
          fxLayout="column"
        />
      </div>
    </mat-sidenav-content>
    <mat-sidenav #sidenavVariables (keydown.escape)="closeNav()" [opened]="variablesOpened()" mode="over"
                 position="end">
      @if (displayAllVariablesLoader()) {
        <div fxLayoutAlign="center center" style="width: 450px; height: 100%">
          <mat-spinner [diameter]="30" color="accent" />
        </div>
      } @else if (allVariables()) {
        <div fxLayout="column" fxFlex="450px" fxFlex.lt-sm="100%">
          <mat-form-field class="filter-input" color="accent">
            <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
            <mat-label>{{ 'VARIABLE_CHART.FILTER_VARIABLES' | translate }}</mat-label>
            <input (keyup)="filterVariables($event, allVariables())" matInput type="text" />
          </mat-form-field>

          <cdk-virtual-scroll-viewport class="variable-list" itemSize="30"
                                       minBufferPx="50" maxBufferPx="100">
            <iot-platform-feature-variable-chart-item
              class="variable-list__item"
              *cdkVirtualFor="let variable of filteredVariables()"
              (toggle)="toggleVariable(variable, $event)"
              [locked]="getLockedVariables(variable)"
              [selected]="getSelectedVariables(variable)"
              [variable]="variable"
            />
          </cdk-virtual-scroll-viewport>
        </div>
      }
    </mat-sidenav>
  </mat-sidenav-container>
} @else if (!displayTable()) {
  <highcharts-chart
    [ngClass]="{'variable-chart--hidden': displayTable(), 'show-custom-export-button': showCustomExportButton()}"
    #highChart
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructorType"
    [oneToOne]="true"
    [options]="previewChartOptions()"
    fxFlex
    style="width: 100%; height: 100%; display: block"
  />
}
