import { Injectable } from '@angular/core';
import { LocalStorageKeys } from './local-storage-keys.class';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageOptions {
  public prefix = '';
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private options: LocalStorageOptions;

  constructor(options: LocalStorageOptions) {
    this.options = options;
  }

  get(key: string, skipPrefix = false): string {
    return localStorage.getItem(skipPrefix ? key : `${this.options.prefix}.${key}`) as string;
  }

  set(key: string, data: string, skipPrefix = false): void {
    const k = skipPrefix ? key : `${this.options.prefix}.${key}`;
    localStorage.setItem(k, data);
  }

  remove(key: string, skipPrefix = false): void {
    const k = skipPrefix ? key : `${this.options.prefix}.${key}`;
    localStorage.removeItem(k);
  }

  clear(): void {
    localStorage.clear();
  }

  clearStorageOnBusinessProfileSelection(): void {
    this.remove(LocalStorageKeys.STORAGE_SELECTED_ROW_ID);
    this.remove(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY);
    this.remove(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY);
    this.remove(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY);
    this.remove(LocalStorageKeys.STORAGE_SITES_PAGE_TYPE_KEY);
    this.remove(LocalStorageKeys.STORAGE_ASSETS_PAGE_TYPE_KEY);
    this.remove(LocalStorageKeys.STORAGE_DEVICES_PAGE_TYPE_KEY);
    this.remove(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY);
    this.remove(LocalStorageKeys.STORAGE_MV_SITES_TABLE_STATE_KEY);
    this.remove(LocalStorageKeys.STORAGE_MV_ASSETS_TABLE_STATE_KEY);
    this.remove(LocalStorageKeys.STORAGE_MV_DEVICES_TABLE_STATE_KEY);
    this.remove(LocalStorageKeys.STORAGE_MV_CONNECTORS_TABLE_STATE_KEY);
  }
}
