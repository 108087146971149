<div class="iccid-message-content">
  @if (loading()) {
    <div fxFlex fxLayoutAlign="center center">
      <iot-platform-ui-card-loader fxFlex />
    </div>
  } @else {
    <pre>{{ message() | json | prettifyXML: 4 }}</pre>

    @if (message()) {
      <button
        (click)="copyMessageToClipboard()"
        class="reverse-accent-button copy-button"
        mat-icon-button
        matTooltipClass="regular-tooltip"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    }
  }
</div>
