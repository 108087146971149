import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW } from './default-device-grid.definition';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { ArrayOfObjectColumn, DeviceTypeGroupColumn, I4BBasicColumn, I4BColumn, I4BSelectionColumn, KercomDeviceButtonColumn, NameColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions, KercomDevicesGridData } from './models';
import { I4BColumnOptions } from './options';

export const GET_DEFAULT_DEVICES_COLUMNS_FOR_KERCOM_DEVICES_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] =>
  [...GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW()]
    .map((cell) => {
      cell.configuration.isDefault = false;
      if (
        cell.configuration.id === 'name' ||
        cell.configuration.id === 'identifier' ||
        cell.configuration.id === 'deviceType' ||
        cell.configuration.id === 'communication.endpoint' ||
        cell.configuration.id === 'communication.operator' ||
        cell.configuration.id === 'communication.iccid' ||
        cell.configuration.id === 'affiliate' ||
        cell.configuration.id === 'status.name' ||
        cell.configuration.id === 'entity.name'
      ) {
        cell.configuration.isDefault = true;
      }
      if (cell.configuration.id === 'deviceType') {
        cell.options.order = 8;
      }
      return cell;
    })
    .filter(
      (cell) =>
        cell.configuration.id !== 'hasCredential' &&
        cell.configuration.id !== 'totalComments' &&
        cell.configuration.id !== 'name' &&
        cell.configuration.id !== 'deviceType'
    )
    .concat([
      new NameColumn(
        { displayName: 'NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
        {
          id: 'name',
          translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
          concept: I4bCellConcept.DEVICES,
          isDefault: true,
          autoSize: true,
          cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
        },
        { order: 1, pinned: 'left', width: 200 }
      ),
      new DeviceTypeGroupColumn(
        { displayName: 'DEVICE TYPE FULL' },
        {
          id: 'deviceType',
          isDefault: true,
          cell: {
            type: I4BCellType.BASIC,
            options: {}
          }
        },
        { order: 13 }
      )
    ]);

export const GET_DEFAULT_CUSTOM_KERCOM_COLUMNS_FOR_KERCOM_DEVICES_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  new I4BBasicColumn(
    { displayName: 'COUNTRY CODE', type: HeaderType.BASIC },
    {
      id: 'countryCode',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_COUNTRY_CODE',
      isDefault: false,
      sortProperty: 'countryCode',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.KERCOM
    },
    { order: 8 }
  ),
  new I4BBasicColumn(
    { displayName: 'HTTP PORT', type: HeaderType.BASIC },
    {
      id: 'httpPort',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_HTTP_PORT',
      isDefault: false,
      sortProperty: 'httpPort',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.KERCOM
    },
    { order: 11 }
  ),
  new I4BBasicColumn(
    { displayName: 'TIMEZONE', type: HeaderType.BASIC },
    {
      id: 'timezone',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TIMEZONE',
      isDefault: false,
      sortProperty: 'timezone',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.KERCOM
    },
    { order: 12 }
  ),
  new ArrayOfObjectColumn(
    { displayName: 'FILES', type: HeaderType.BASIC },
    {
      id: 'files',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_FILES',
      isDefault: false,
      sortProperty: 'files',
      width: 300,
      cell: {
        type: I4BCellType.ARRAY_OF_OBJECTS,
        options: {
          attributes: ['name'],
          expressions: [{ key: 'download', value: true }]
        }
      },
      concept: I4bCellConcept.KERCOM
    },
    { order: 13 }
  ),
  new I4BBasicColumn(
    { displayName: 'MODBUS ADDRESS', type: HeaderType.BASIC },
    {
      id: 'modbusAddress',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_MODBUS_ADDRESS',
      isDefault: false,
      sortProperty: 'modbusAddress',
      cell: { type: I4BCellType.BASIC },
      concept: I4bCellConcept.KERCOM
    },
    { order: 14 }
  )
];

export class DefaultKercomDevicesGrid implements I4BGrid<I4BGridOptions, KercomDevicesGridData> {
  masterview = 'kercom-devices';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: KercomDevicesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: true, className: new KercomDeviceButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}
