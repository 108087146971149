import { Component, computed, input, Signal } from '@angular/core';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import { InfoDisplayPipe } from '@iot-platform/pipes';

@Component({
  imports: [InfoDisplayPipe],
  selector: 'iot-platform-ui-device-endpoint',
  template: `
    @if (endpointUrl()) {
      <a [href]="endpointUrl()" class="link" target="_blank">{{ endpoint() | infoDisplay }}</a>
    } @else {
      {{ endpoint() | infoDisplay }}
    }
  `
})
export class DeviceEndpointComponent {
  endpoint = input<string>();
  httpPort = input<string>();
  support = input<string>();

  endpointUrl: Signal<string | null> = computed(() => {
    const endpoint = this.endpoint();
    if (StringUtils.isValidIPv4(endpoint?.trim())) {
      const httpPort = this.httpPort();
      const protocol = !httpPort || Number(httpPort) === 443 ? 'https' : 'http';
      return httpPort ? `${protocol}://${endpoint}:${httpPort}` : `${protocol}://${endpoint}`;
    }
    return null;
  });
}
