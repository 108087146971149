import { Component, effect, inject, input, output, signal, Signal, untracked, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageSelectorV2Component } from '@iot-platform/iot-platform-ui';
import { TagCategory } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';
import { SitesDirectoryService } from '../../services/sites-directory.service';

@Component({
  selector: 'iot4bos-ui-site-image-form',
  templateUrl: './site-image-form.component.html',
  styleUrls: ['./site-image-form.component.scss'],
  imports: [FlexLayoutModule, MatFormFieldModule, ReactiveFormsModule, ImageSelectorV2Component],
  providers: [TranslateService]
})
export class SiteImageFormComponent {
  private readonly sitesDirectoryService: SitesDirectoryService = inject(SitesDirectoryService);
  site = input<Site | undefined>();
  tags = input<TagCategory[]>([]);

  updateForm = output<string>();

  image: WritableSignal<string | null> = signal(null);

  tags$ = toObservable(this.tags);
  filteredImages: Signal<string[]> = toSignal(this.tags$.pipe(switchMap((tags) => this.sitesDirectoryService.getFilteredImagesByTag(tags))), {
    initialValue: []
  });
  defaultImage = signal(this.sitesDirectoryService.getDefaultImage());

  onSelectImage(image: string): void {
    this.image.set(image);
    this.updateForm.emit(image);
  }

  private siteEffect = effect(() => {
    const site = this.site();
    untracked(() => {
      const defaultImage = this.defaultImage();
      if (site) {
        if (site.id) {
          this.image.set(site.imageUrl ?? defaultImage);
        } else {
          this.image.set(site.imageUrl ?? null);
        }
      }
    });
  });
}
