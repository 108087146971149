import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { DashboardWidget } from '@iot-platform/models/dashboards';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { get } from 'lodash';
import { DashboardsDataTableDialogComponent } from '../components/dashboards-data-table-dialog/dashboards-data-table-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardsHelper {
  translateService = inject(TranslateService);
  legendItemCSS = { fontSize: '12px', color: '394C5A' };
  numberFormatPipe = inject(NumberFormatPipe);
  dialog = inject(MatDialog);

  static getTotal(data: { legend: string; color: string; count: number }[]): number {
    return data.reduce((acc, value) => {
      acc += value.count;
      return acc;
    }, 0);
  }

  getHighchartsOptions(widget: DashboardWidget): Highcharts.Options {
    const maxSlices = 10;
    let processedSeries: {
      name: string;
      colorByPoint: boolean;
      data: {
        name: string;
        y: number;
        color: string;
        drilldown: null | string;
      }[];
    }[] = [];

    let processedDrilldown: {
      series: { name: string; id: string; data: { name: string; y: number; color: string }[] }[];
    } = { series: [] };

    if (widget.data.length > maxSlices) {
      const mainData: { legend: string; color: string; count: number }[] = widget.data.slice(0, maxSlices);
      const rest: { legend: string; color: string; count: number }[] = widget.data.slice(maxSlices);

      processedSeries = [
        {
          name: this.translateService.instant(widget.title),
          colorByPoint: true,
          data: mainData.map((w) => ({
            name: this.getLabel(widget, w.legend),
            y: w.count,
            color: w.legend ? w.color : '#96A5AF',
            drilldown: null
          }))
        }
      ];

      processedSeries[0].data.push({
        name: this.translateService.instant('DASHBOARDS.OTHER'),
        y: rest.reduce((acc, value) => {
          acc += value.count;
          return acc;
        }, 0),
        color: '#E54569',
        drilldown: 'restId'
      });

      processedDrilldown = {
        series: [
          {
            name: this.translateService.instant('DASHBOARDS.OTHER'),
            id: 'restId',
            data: rest.map((w) => ({
              name: this.getLabel(widget, w.legend),
              y: w.count,
              color: w.legend ? w.color : '#96A5AF'
            }))
          }
        ]
      };
    } else {
      processedSeries = [
        {
          name: this.translateService.instant(widget.title),
          colorByPoint: true,
          data: widget.data.sort(SortUtil.sortBy('count', false)).map((w) => ({
            name: this.getLabel(widget, w.legend),
            y: w.count,
            color: w.legend ? w.color : '#96A5AF',
            drilldown: null
          }))
        }
      ];
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
    const _this = this;

    return {
      chart: {
        events: {
          exportData: (data) => {
            // HC only exports displayed data. Hidden data (in drilldown for exemple) is not exported by default. We need to update dataRows to include all data
            const dataToExport: [string, number][] = widget.data.map((element) => [_this.getLabel(widget, element.legend), element.count]);
            // Remove all data expect tje header at index 0
            data.dataRows.splice(1);
            // Push all the data line by line
            dataToExport.forEach((d) => data.dataRows.push(d));
          }
        },
        height: 300,
        type: 'pie',
        width: 400
      },
      title: {
        align: 'left',
        text: this.translateService.instant(widget.title),
        x: 30
      },
      subtitle: {
        align: 'left',
        text: this.translateService.instant(widget.subtitle),
        x: 30
      },
      tooltip: {
        headerFormat: null,
        pointFormat: widget.options.showLegends
          ? '<span style="color:{point.color}">\u25CF</span> {point.name}: <b>{point.y}</b><br/>'
          : '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b><br/>'
      },
      legend: {
        align: 'right',
        itemStyle: this.legendItemCSS,
        labelFormatter() {
          return widget.options.showLegends
            ? `${get(this, 'name')} - ${_this.numberFormatPipe.transform(get(this, 'y'))}`
            : `${_this.numberFormatPipe.transform(get(this, 'y'))}`;
        },
        layout: 'vertical',
        maxHeight: 240,
        useHTML: true,
        verticalAlign: 'middle',
        x: 0,
        y: 10
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          borderRadius: 4,
          cursor: 'pointer',
          dataLabels: { enabled: false },
          innerSize: '60%',
          showInLegend: true,
          size: '80%'
        }
      },
      exporting: {
        menuItemDefinitions: {
          table: {
            onclick: () => {
              _this.dialog.open(DashboardsDataTableDialogComponent, {
                data: {
                  data: this.getDataForTable(widget),
                  showLegends: widget.options.showLegends,
                  title: this.translateService.instant(widget.title),
                  total: DashboardsHelper.getTotal(widget.data)
                },
                disableClose: false,
                width: '400px'
              });
            },
            text: 'Show table'
          }
        },
        buttons: {
          contextButton: {
            buttonSpacing: 2,
            menuItems: ['viewFullscreen', 'downloadPNG', 'downloadPDF', 'downloadCSV', 'downloadXLS', 'table']
          }
        }
      },
      series: processedSeries,
      drilldown: processedDrilldown
    } as unknown as Highcharts.Options;
  }

  getLabel(widget: DashboardWidget, legend: string): string {
    if (!legend) {
      return 'N/A';
    } else if (widget.options.translationKey) {
      return this.translateService.instant(widget.options.translationKey + legend);
    } else {
      return legend;
    }
  }

  getDataForTable(widget: DashboardWidget): { name: string; count: number }[] {
    return widget.data.map((w) => ({
      name: this.getLabel(widget, w.legend),
      color: w.color,
      count: w.count
    }));
  }
}
