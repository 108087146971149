import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  untracked,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput, MatSuffix } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTooltip } from '@angular/material/tooltip';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { NgxInitModule } from '@iot-platform/directives';
import { GridPageComponent } from '@iot-platform/grid-engine';
import { ExportType, I4BBasicColumn, I4BCellType } from '@iot-platform/models/grid-engine';

import { AssetVariable, AssetVariableThreshold, DeviceVariable } from '@iot-platform/models/i4b';
import { TimeseriesWidgetOptions } from '@iot-platform/models/widgets';

import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Options, SeriesOptionsType } from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts/highcharts';
import HC_export_data from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_NoData from 'highcharts/modules/no-data-to-display';
import { chunk, flatten, get, uniq } from 'lodash';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectedVariableChart } from '../../models/selected-variable-chart';
import { VariableChart } from '../../models/variable-chart';
import { VariableChartService } from '../../services/variable-chart.service';
import { getVariable } from '../../utils/variable-chart.utils';
import { VariableItemComponent } from '../variable-item/variable-item.component';
import { VariableChartGrid } from './variable-chart-grid-definition';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_NoData(Highcharts);

/* eslint-disable @typescript-eslint/dot-notation */
@Component({
  imports: [
    FlexLayoutModule,
    NgxInitModule,
    TranslateModule,
    MatSidenavModule,
    MatButton,
    MatDatepickerModule,
    MatInput,
    MatSuffix,
    UpperCasePipe,
    MatSlideToggle,
    MatTooltip,
    MatIconButton,
    MatIcon,
    MatProgressBar,
    HighchartsChartModule,
    MatProgressSpinner,
    MatFormFieldModule,
    MatListModule,
    VariableItemComponent,
    NgClass,
    GridPageComponent,
    ScrollingModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem
  ],
  providers: [DateFormatPipe],
  selector: 'iot-platform-feature-variable-chart',
  templateUrl: './variable-chart.component.html',
  styleUrls: ['./variable-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableChartComponent implements OnInit, OnDestroy {
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);
  private readonly variableChartService: VariableChartService = inject(VariableChartService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructorType = 'chart';
  commonChartOptions: Options = {
    title: { text: '' },
    navigator: { enabled: false },
    navigation: { buttonOptions: { enabled: false } },
    credits: { enabled: false },
    rangeSelector: { enabled: false, inputEnabled: false }
  };
  thousandSep = '';
  decimalPoint = '';
  // End of manage charts data
  scalableVariables: string[] = ['%', 'BAR', 'V', 'A', 'INH2O', 'M3'];
  // left sidenav data
  mainVariableSeries = [];
  // thresholds line variables
  deviceVariableThresholdDefaultColorList: string[] = ['#FFF2CC', '#FAC7A7', '#F59B81', '#F06F5B', '#EA4335'];
  assetVariableThresholdDefaultColorList: string[] = ['#FFF500', '#F58C2D', '#E75D70', '#C76CCF', '#963ADE'];
  maxThresholds = 5;
  thresholds: { value: number; color: string; width: number; zIndex: number; label: { text: string } }[] = [];
  timeseriesLimit = 50000;
  @ViewChild('highChart') highChart!: ElementRef;

  data = input<any[]>([]);
  showCustomExportButton = input<boolean>(false);
  displayTable = input<boolean>(false);
  variableType = input<string>();
  options = input<TimeseriesWidgetOptions>();
  variables: WritableSignal<VariableChart[]> = signal([]);
  allVariables: WritableSignal<DeviceVariable[] | AssetVariable[]> = signal([]);
  filteredVariables: WritableSignal<(DeviceVariable | AssetVariable)[]> = signal([]);
  selectedVariables: WritableSignal<SelectedVariableChart> = signal({
    variables: [],
    start: '',
    end: '',
    limit: this.timeseriesLimit
  });
  mainChartOptions: WritableSignal<Options> = signal(this.commonChartOptions);
  previewChartOptions: WritableSignal<Options> = signal({
    ...this.commonChartOptions,
    plotOptions: {
      series: {
        animation: {
          duration: 0
        },
        events: {
          afterAnimate: () => this.reflow()
        }
      }
    },
    exporting: { buttons: { contextButton: { enabled: false } } }
  });
  scale: WritableSignal<number | null> = signal(null);
  timezone: WritableSignal<string> = signal(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.timezoneDetails?.name ?? 'utc');
  userNumberLocale: WritableSignal<string> = signal(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberFormat ?? 'en');
  // are sidenav opened
  variablesOpened: WritableSignal<boolean> = signal(false);
  displayAllVariablesLoader: WritableSignal<boolean> = signal(false);
  // display scale button
  scalable: WritableSignal<boolean> = signal(true);
  // display loader
  displayLoader: WritableSignal<boolean> = signal(false);
  // chart selected period
  chartPeriod: WritableSignal<number> = signal(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.chartPeriod as number);
  customChartPeriod: WritableSignal<{ start: string; end: string }> = signal({ start: '', end: '' });
  highchartsDateFormat: WritableSignal<string> = signal(
    JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appDateFormats?.highchartsFullFormat ?? '%Y-%m-%d %H:%M:%S'
  );
  momentNoTimeFormat: WritableSignal<string> = signal(
    JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appDateFormats?.momentNoTime ?? 'yyyy-MM-DD'
  );
  displaySearchBar: Signal<boolean> = computed(() => {
    const options = this.options();
    return get(options, ['chart', 'searchBar', 'enabled'], true);
  });
  fullscreenOn: WritableSignal<boolean> = signal(false);
  chartPeriodEffect = effect(() => {
    const chartPeriod = this.chartPeriod();
    if (chartPeriod) {
      this.customChartPeriod.set({
        start: moment().subtract(chartPeriod, 'days').toISOString(false),
        end: moment().toISOString(false)
      });
    }
  });
  triggerViewData: WritableSignal<boolean> = signal(false);
  chartDataSet: WritableSignal<any[]> = signal([]);
  allTimeStamps: WritableSignal<any[]> = signal([]);

  grid = signal(new VariableChartGrid());
  timeSeriesDataSet: WritableSignal<any[]> = signal([]);
  canExportData: Signal<boolean> = computed(() => get(this.options(), ['chart', 'export', 'enabled'], true));

  timeoutID: any;
  gridApi!: GridApi;
  ExportType = ExportType;

  onDisplayLoaderEffect = effect(
    () => {
      const displayLoader = this.displayLoader();
      const allTimeStamps = this.allTimeStamps();
      let noData = this.translateService.instant('VARIABLE_CHART.NO_DATA');
      if (displayLoader) {
        noData = this.translateService.instant('AG_GRID.loadingOoo');
      }
      const chart = (this.highChart as any)?.chart;
      if (chart) {
        chart.hideNoData();
        if (displayLoader || !allTimeStamps?.length) {
          chart.showNoData(noData);
        }
        chart.reflow();
      }
    },
    { injector: this.injector }
  );

  initGridDataSetEffect = effect(
    () => {
      const dataSet: any[] = this.chartDataSet();
      const allTimeStamps = this.allTimeStamps();
      this.clearInterval();
      untracked(() => {
        const timestamps: any[] = uniq(allTimeStamps);
        const chunks: any[][] = chunk(timestamps, 1000);
        this.timeSeriesDataSet.set([]);
        if (chunks.length > 0) {
          // this.displayLoader.set(true);
          const timeout = setTimeout(() => {
            let n = -1;
            this.timeoutID = setInterval(() => {
              n++;
              if (n === chunks.length && this.timeoutID) {
                // this.displayLoader.set(false);
                this.clearInterval();
              }
              const arr = chunks[n];
              this.appendGridDataSet(arr, dataSet);
            }, 100);
            clearTimeout(timeout);
          }, 300);
        }
      });
    },
    { injector: this.injector }
  );

  initGridDataSetDefEffect = effect(
    () => {
      const chartDataSet = this.chartDataSet();
      const columnDefs: any[] = [
        new I4BBasicColumn(
          { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATE_TIME' },
          {
            id: 'dateTime',
            cell: { type: I4BCellType.DATE },
            isDefault: true,
            autoSize: true,
            sortProperty: 'dateTime',
            filterParams: {
              enabled: true
            }
          },
          {
            order: 1,
            width: 80,
            pinned: 'left',
            lockPinned: true
          }
        )
      ].concat(
        chartDataSet.map(
          (e) =>
            new I4BBasicColumn(
              { displayName: e.name, skipTextTransform: true },
              {
                id: `${e.index}_${e.name}`.replace(' ', ''),
                cell: {
                  type: I4BCellType.NUMBER
                },
                autoSize: true,
                sortProperty: `${e.index}_${e.name}`.replace(' ', ''),
                filterParams: {
                  enabled: true
                }
              }
            )
        )
      );
      this.grid.update((grid) => {
        grid.columns = columnDefs;
        return { ...grid };
      });
    },
    { injector: this.injector }
  );

  ngOnInit() {
    this.setLocaleFormatEffect();
    this.initVariablesEffect();
    this.initScaleEffect();
    this.initChartMainOptionsEffect();
    this.initSelectedVariablesEffect();
    this.initDisplayModeEffect();
    this.initTriggerViewDataEffect();
  }

  onExportData(type: ExportType): void {
    const data = this.timeSeriesDataSet();
    const chart = (this.highChart as any)?.chart;
    if (chart && data?.length) {
      if (type === ExportType.XLSX) {
        chart.downloadXLS();
      } else {
        chart.downloadCSV();
      }
    }
  }

  ngOnDestroy() {
    this.displayLoader?.set(false);
    this.clearInterval();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    effect(
      () => {
        const timeSeriesDataSet = this.timeSeriesDataSet();
        // Add some delay to wait for grid first render
        const timeout = setTimeout(() => {
          this.gridApi.setGridOption('rowData', timeSeriesDataSet);
          clearTimeout(timeout);
        }, 0);
      },
      { injector: this.injector }
    );
  }

  setLocaleFormatEffect(): void {
    effect(
      () => {
        const locale = this.userNumberLocale();
        switch (locale) {
          case 'fr':
            this.thousandSep = ' ';
            this.decimalPoint = ',';
            break;
          case 'en':
          default:
            this.thousandSep = ',';
            this.decimalPoint = '.';
            break;
        }
        Highcharts.setOptions({
          lang: {
            thousandsSep: this.thousandSep,
            decimalPoint: this.decimalPoint
          }
        });
      },
      { injector: this.injector }
    );
  }

  initSelectedVariablesEffect() {
    effect(
      () => {
        const variables = this.variables();
        const customChartPeriod = this.customChartPeriod();
        if (variables) {
          this.selectedVariables.update((selectedVariables) => {
            const variableDiff = get(selectedVariables, ['variables'], []).filter(
              (selectedVariable) => !variables.find((variable) => variable.id === selectedVariable.id)
            );
            return {
              ...selectedVariables,
              variables: [...variables, ...variableDiff],
              start: customChartPeriod.start,
              end: customChartPeriod.end,
              limit: this.timeseriesLimit
            };
          });
        }
      },
      { injector: this.injector }
    );
  }

  initChartMainOptionsEffect() {
    effect(
      () => {
        const selectedVariables: SelectedVariableChart = this.selectedVariables();
        const options = this.options();
        const highchartsDateFormat = this.highchartsDateFormat();
        const scale = this.scale();
        untracked(() => {
          if (selectedVariables) {
            this.displayLoader.set(true);
            this.triggerViewData.set(false);
            const variables$ = this.variableChartService.loadTimeseriesByVariables(
              selectedVariables.variables,
              selectedVariables.start,
              selectedVariables.end,
              selectedVariables.limit
            );
            variables$
              .pipe(
                filter((vars) => vars && vars.length > 0),
                finalize(() => {
                  this.displayLoader.set(false);
                  this.triggerViewData.set(true);
                }),
                takeUntilDestroyed(this.destroyRef)
              )
              .subscribe((vars) => {
                const newSeriz: any[] = [];
                this.chartDataSet.set([]);
                this.allTimeStamps.set([]);
                this.mainVariableSeries = { ...vars[0].series };
                const uniqueUnits = vars
                  .map((variable) => variable.unit)
                  .reduce((acc: any[], current: string) => {
                    if (!acc.includes(current)) {
                      acc.push(current);
                    }
                    return acc;
                  }, []);

                vars.forEach((variable, idx) => {
                  const timeseries: [number, number][] = [];
                  const data: any = {};
                  Object.entries(variable.series).forEach((timeserie) => {
                    const v = parseInt(timeserie[0], 10);
                    timeseries.push([v, timeserie[1]] as [number, number]);
                    data[v] = timeserie[1];
                  });

                  const sortedTimeseries = [...timeseries].sort();
                  const addedSerie: SeriesOptionsType = {
                    name: variable.name,
                    type: 'line',
                    data: sortedTimeseries,
                    tooltip: {
                      valueSuffix: variable.unit,
                      valueDecimals: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberOfDecimals,
                      xDateFormat: highchartsDateFormat
                    },
                    yAxis: uniqueUnits.findIndex((unit) => unit === variable.unit)
                  };
                  if (options?.chart?.legend?.labelFormat) {
                    addedSerie.name = options.chart.legend.labelFormat(variable);
                  }
                  newSeriz.push(addedSerie);

                  this.chartDataSet.update((dataSet) => [
                    ...dataSet,
                    {
                      name: variable.name,
                      data,
                      index: idx
                    }
                  ]);
                  this.allTimeStamps.update((allTimeStamps) => [...allTimeStamps, ...sortedTimeseries.map((e) => e[0])]);
                });
                this.mainChartOptions.set(this.getChartOptions(vars[0], scale as number, newSeriz, uniqueUnits, options));
                this.previewChartOptions.set(this.getChartOptions(vars[0], scale as number, newSeriz, uniqueUnits, options));
              });
          }
        });
      },
      { injector: this.injector }
    );
  }

  initScaleEffect() {
    effect(
      () => {
        const variables = this.variables();
        if (variables[0]?.unit && this.scalableVariables.includes(variables[0]?.unit.toUpperCase())) {
          this.scalable.set(true);
          this.scale.set(0);
        } else {
          this.scale.set(null);
        }
      },
      { injector: this.injector }
    );
  }

  initVariablesEffect() {
    effect(
      () => {
        const data = this.data();
        const variableType = this.variableType();
        this.thresholds = [];
        const variables = data.reduce((acc: VariableChart[], value: AssetVariable, index: number) => {
          // For graphs, thresholds are set and displayed only for the first variable aka the reference variable
          if (index === 0) {
            if (variableType === 'assetVariable') {
              this.setAssetVariableThresholds(value);
            } else if (variableType === 'deviceVariable') {
              this.setDeviceVariableThresholds(value);
            }
          }
          return [...acc, getVariable(value)];
        }, []);
        this.variables.set([...variables]);
      },
      { injector: this.injector }
    );
  }

  initDisplayModeEffect() {
    effect(
      () => {
        const displayTable = this.displayTable();
        this.toggleTableView(displayTable);
      },
      { injector: this.injector }
    );
  }

  initTriggerViewDataEffect() {
    effect(
      () => {
        const triggerViewData = this.triggerViewData();
        const displayTable = untracked(this.displayTable);
        if (triggerViewData && displayTable) {
          setTimeout(() => this.toggleTableView(displayTable), 0);
        }
      },
      { injector: this.injector }
    );
  }

  setAssetVariableThresholds(variable: AssetVariable): void {
    for (let i = 0; i < this.maxThresholds; i++) {
      let currentThreshold: AssetVariableThreshold | undefined;

      if (!!variable.thresholds?.values?.length && !!variable.thresholds?.values[0].position) {
        currentThreshold = variable.thresholds.values.find((t) => t.position === i + 1);
      }

      if (!!variable.thresholds?.values?.length && !variable.thresholds?.values[0].position) {
        currentThreshold = variable.thresholds?.values[i];
      }

      if (currentThreshold) {
        this.thresholds.push({
          value: currentThreshold.value as number,
          color: currentThreshold.lineColor ?? this.assetVariableThresholdDefaultColorList[i],
          zIndex: 4 + i,
          width: 1,
          label: {
            text: this.translateService?.instant('VARIABLE_CHART.LABEL_ASSET_VARIABLE_THRESHOLD', {
              thresholdOperator: variable.thresholds?.operator,
              thresholdName: currentThreshold.name,
              variableName: variable.name
            })
          }
        });
      }
    }
  }

  setDeviceVariableThresholds(variable: any): void {
    for (let i = 0; i < this.maxThresholds; i++) {
      const thresholdParameterName: string = 'threshold' + (i + 1).toString();
      if (variable[thresholdParameterName] !== null && variable[thresholdParameterName] !== undefined) {
        this.thresholds.push({
          value: variable[thresholdParameterName],
          color: this.deviceVariableThresholdDefaultColorList[i],
          zIndex: 4 + i,
          width: 1,
          label: {
            text: this.translateService?.instant('VARIABLE_CHART.LABEL_DEVICE_VARIABLE_THRESHOLD', {
              thresholdNumber: i + 1,
              variableName: variable.name
            })
          }
        });
      }
    }
  }

  getChartOptions(variable: VariableChart, scale: number, series: SeriesOptionsType[], units: any[], options: any): Highcharts.Options {
    const timezone = this.timezone();
    const dateFormat = this.highchartsDateFormat();
    return {
      title: {
        text: '',
        style: {
          opacity: 0
        }
      },
      chart: {
        style: {
          fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
          fontSize: '12px'
        },
        zooming: {
          type: 'x',
          singleTouch: true
        }
      },
      time: {
        moment,
        timezone
      } as never,
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e. %b %y',
          week: '%e. %b %y',
          month: '%b %y',
          year: '%Y'
        }
      },
      yAxis: units.map((unit, index) => ({
        labels: {
          format: `{value} ${unit}`,
          style: {
            color: `${(Highcharts.getOptions() as any).colors[index]}`
          }
        },
        title: {
          text: '',
          style: {
            color: `${(Highcharts.getOptions() as any).colors[index]}`
          }
        },
        opposite: index !== 0,
        min: scale,
        max:
          scale !== null
            ? Math.max(
                // eslint-disable-next-line prefer-spread
                Math.max.apply(
                  Math,
                  this.thresholds.map((e) => e.value)
                ),
                // eslint-disable-next-line prefer-spread
                Math.max.apply(
                  Math,
                  flatten(series.map((e: any) => e.data)).map((e) => e[1])
                )
              )
            : null,
        plotLines: index === 0 ? this.thresholds : []
      })),
      tooltip: {
        shared: true
      },
      series,
      navigator: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          enabled: true
        }
      },
      credits: { enabled: false },
      rangeSelector: {
        enabled: false,
        inputEnabled: false
      },
      legend: {
        enabled: get(options, ['chart', 'legend', 'enabled'], true)
      },
      exporting: {
        enabled: get(options, ['chart', 'export', 'enabled'], true),
        filename: `${this.getCurrentTime()}-${variable.parentName}-${variable.name}`,
        showTable: false,
        csv: {
          itemDelimiter: ';',
          dateFormat,
          decimalPoint: this.decimalPoint,
          columnHeaderFormatter(item: any) {
            if (item.name) {
              if (item.options?.tooltip?.valueSuffix) {
                return `${item.name} (${item.options.tooltip.valueSuffix})`;
              }
              return item.name;
            }
            return 'DateTime';
          }
        },
        buttons: {
          contextButton: {
            menuItems: ['downloadCSV', 'downloadXLS']
          }
        }
      }
    };
  }

  getCurrentTime(): string {
    const momentNoTimeFormat = this.momentNoTimeFormat();
    return this.dateFormatPipe.transform(Date.now(), momentNoTimeFormat);
  }

  downloadFile(file: any[], fileName: string) {
    const blob = new Blob(file, { endings: 'transparent', type: 'data:text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.csv`);
    a.click();
  }

  toggleVariable(variable: DeviceVariable | AssetVariable, checked: boolean): void {
    this.selectedVariables.update((previous) => {
      const transformed: VariableChart = getVariable(variable);
      const newVariables: VariableChart[] = [...previous.variables];
      if (checked) {
        newVariables.push(transformed);
      } else {
        newVariables.splice(
          newVariables.findIndex((v) => v.id === transformed.id),
          1
        );
      }
      return {
        variables: [...newVariables],
        start: previous.start,
        end: previous.end,
        limit: previous.limit
      };
    });
  }

  onPeriodClicked(period: number): void {
    this.chartPeriod.set(period);
  }

  getSelectedVariables(variable: DeviceVariable | AssetVariable): boolean {
    const selectedVariables = this.selectedVariables();
    if (selectedVariables) {
      const selected = selectedVariables.variables;
      return selected.findIndex((v) => v.id === variable.id) !== -1;
    }
    return false;
  }

  getLockedVariables(variable: DeviceVariable | AssetVariable): boolean {
    const data = this.data();
    return !!data.find((v: DeviceVariable | AssetVariable) => v.id === variable.id);
  }

  onSelectDates(startDate: string, endDate: string): void {
    this.chartPeriod.set(0);
    this.customChartPeriod.set({
      start: moment(startDate).toISOString(false),
      end: endDate ? moment(endDate).toISOString(false) : moment().toISOString(false)
    });
  }

  onAutoScaleChange(event: MatSlideToggleChange): void {
    this.scale.set(event.checked ? null : 0);
  }

  closeNav(): void {
    this.variablesOpened.set(false);
  }

  openVariables(): void {
    this.variablesOpened.set(true);
    if (!this.allVariables()?.length) {
      const variables = this.variables();
      this.displayAllVariablesLoader.set(true);
      this.variableChartService
        .loadVariables(variables)
        .pipe(
          finalize(() => this.displayAllVariablesLoader.set(false)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((result) => {
          this.allVariables.set(result);
          this.filteredVariables.set([...result]);
        });
    }
  }

  filterVariables(variablesFilter: any, listToFilter: DeviceVariable[] | AssetVariable[]): void {
    const filteredVariables = this.getFilteredVariables(listToFilter, variablesFilter);
    this.filteredVariables.set(filteredVariables);
  }

  getFilteredVariables(listToFilter: any[], filterEvent: any): DeviceVariable[] | AssetVariable[] {
    return listToFilter.filter((variable) => variable.name.toLowerCase().includes(filterEvent.target.value.toLowerCase()));
  }

  setFullscreenOn(fullscreenOn: boolean) {
    this.fullscreenOn.set(fullscreenOn);
  }

  reflow() {
    (this.highChart as any)?.chart?.reflow();
  }

  toggleTableView(displayTable: boolean) {
    if (!displayTable) {
      (this.highChart as any)?.chart?.hideData();
    }
  }

  private clearInterval() {
    if (this.timeoutID) {
      clearInterval(this.timeoutID);
    }
  }

  private appendGridDataSet(uniqTimeStamps, dataSet) {
    if (uniqTimeStamps?.length && dataSet) {
      const result: any[] = uniqTimeStamps.reduce((acc, x, index) => {
        const obj = dataSet.reduce((acc1, item) => ({ ...acc1, [`${item.index}_${item.name}`.replace(' ', '')]: item.data[x] }), {
          id: `${index}_${x}`,
          dateTime: moment(new Date(x), this.highchartsDateFormat()).toISOString()
        });
        return [...acc, obj];
      }, []);
      this.timeSeriesDataSet.update((timeSeriesDataSet) => [...timeSeriesDataSet, ...result]);
    }
  }
}
