import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { ENVIRONMENT } from '@iot-platform/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Affiliate, BusinessProfile, Environment } from '@iot-platform/models/common';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DevicesDirectoryService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly environment: Environment = inject(ENVIRONMENT);

  getDeviceImages(): Observable<string[]> {
    return this.http.get<string[]>('assets/data/devices-gallery.json');
  }

  getDefaultDeviceImage(): string {
    return 'assets/images/device/Device_Default.png';
  }

  getAffiliates(): Observable<Affiliate[]> {
    const businessProfile: BusinessProfile = this.authFacade.selectedBusinessProfile();
    return this.http.get<Affiliate[]>(`${this.environment.api.url}${this.environment.api.endpoints.affiliates}?entityId=${businessProfile.entityId}`).pipe(
      map((data: Affiliate[]) =>
        data.sort(SortUtil.sortBy('id', true)).map((affiliate: Affiliate) => ({
          ...affiliate,
          label: `${affiliate.id} : ${affiliate.name}`
        }))
      ),
      catchError(() => of([]))
    );
  }
}
