export interface AssetVariableThresholds {
  operator: string | null;
  values: AssetVariableThreshold[];
}

export interface AssetVariableThreshold {
  calculate?: {
    formulaName?: string;
  };
  cellColor?: string;
  isFromSourceVariable?: boolean;
  lineColor?: string;
  name?: string;
  position?: number;
  value?: number;
}

export enum AssetVariableThresholdOperator {
  INFERIOR = '<',
  EQUAL = '=',
  SUPERIOR = '>'
}

export enum ThresholdType {
  STATIC = 'static',
  DYNAMIC = 'dynamic'
}

export const THRESHOLD_COLORS = {
  lineColors: ['#96A5AF', '#645F9B', '#797EE3', '#66AEF2', '#7CCA6F', '#AFE400', '#FFF500', '#F58C2D', '#E75D70', '#C76CCF', '#963ADE'],
  cellColors: ['#96A5AF', '#A9A3E8', '#CFD0FD', '#D0E8FF', '#ADF1A2', '#D0F556', '#FDF9A8', '#FFDFC2', '#FBBAC3', '#DFBEF9', '#BCB6FB']
};
