<div class="panel-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <h2 class="panel-title">{{ title() }}</h2>
  <hr class="regular-1px-line" fxFlex />
  @if (hasButton()) {
    <div class="panel-button app-round-button" [matTooltip]="buttonTooltip() | translate" matTooltipClass="regular-tooltip">
      <button mat-icon-button (click)="onClick()">
        <mat-icon>{{ icon() }}</mat-icon>
      </button>
    </div>
  }
  @if (withCustomAction()) {
    <ng-content select="[panelCustomAction]"></ng-content>
  }
</div>
@if (hasToolbar()) {
  <div class="panel-toolbar">
    <ng-content select="[panelToolbar]"></ng-content>
  </div>
}
<div class="panel-grid" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15px">
  <ng-content select="[panelGrid]"></ng-content>
</div>
<div class="panel-content">
  <ng-content select="[panelContent]"></ng-content>
</div>
