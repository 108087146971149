import { DecimalPipe, NgClass } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, input, model, OnInit, output, Signal, untracked, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SELECT_CONFIG, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FocusInitialDirective } from '@iot-platform/directives';
import { GetUtils, SortUtil } from '@iot-platform/iot-platform-utils';
import { FavoriteView, IotToolbarEvent, ToolbarSize } from '@iot-platform/models/common';
import { Dashboard } from '@iot-platform/models/dashboards';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { NumberFormatPipe, TruncatePipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { BreadcrumbItemModule } from '../breadcrumb-item/breadcrumb-item.module';
import { SubscriptionButtonModule } from '../subscription-button';
import { IotToolbarMenuButtonOption, ToolbarAutoRefreshOptions, ToolbarFilterTextOptions, ToolbarPageType, ToolbarPageTypeOption } from './models';
import { IotToolbarDispatchActionType } from './toolbar-button/configuration-files/button-dispatch-action-type.enum';
import { ADD_BUTTON_CONFIG, CONFIGURE_GRIDS_BUTTON_CONFIG, DELETE_BUTTON_CONFIG, EDIT_BUTTON_CONFIG } from './toolbar-button/configuration-files/button.config';
import { IotToolbarDefaultButton } from './toolbar-button/configuration-files/default-button';
import { IotToolbarMenuButton } from './toolbar-button/configuration-files/menu-button';
import { ToolbarButtonComponent } from './toolbar-button/toolbar-button.component';

interface FVGroup {
  name: string;
  favoriteViews: FavoriteView[];
}

interface GridGroup {
  name: string;
  grids: I4BGrid<any, any>[];
  shared: boolean;
}

interface ToolbarPageTypeOptions {
  visible: boolean;
  pageType: ToolbarPageType;
  options?: ToolbarPageTypeOption[];
}

interface ToolbarBreadCrumbConfiguration {
  entityName: string;
  icon: string;
}

interface ToolbarFavoriteViewConfiguration {
  sortedFavoriteViews: FavoriteView[];
  currentFavoriteView: FavoriteView | undefined;
  isFavoriteViewsLoading: boolean;
}

interface ToolbarGridsConfiguration {
  sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
  currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
  isGridsLoading: boolean;
}

interface ToolbarDashboardsConfiguration {
  sortedDashboards: Dashboard[];
  currentDashboard: Dashboard | undefined;
  isDashboardsLoading: boolean;
}

const TOOLBAR_FAVORITEVIEW_BUTTONS = [
  new IotToolbarDefaultButton(
    {
      ...ADD_BUTTON_CONFIG,
      tooltip: 'FILTER_ENGINE.CREATE_FAVORITE_VIEW',
      disabled: false,
      dispatchAction: { type: IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW, options: undefined }
    },
    1
  ),
  new IotToolbarDefaultButton(
    {
      ...EDIT_BUTTON_CONFIG,
      tooltip: 'FILTER_ENGINE.EDIT_FAVORITE_VIEW',
      dispatchAction: { type: IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW, options: undefined }
    },
    1
  ),
  new IotToolbarDefaultButton(
    {
      ...DELETE_BUTTON_CONFIG,
      tooltip: 'FILTER_ENGINE.DELETE_FAVORITE_VIEW',
      dispatchAction: { type: IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW, options: undefined }
    },
    1
  )
];

const TOOLBAR_GRID_BUTTONS = [
  new IotToolbarDefaultButton(
    {
      ...CONFIGURE_GRIDS_BUTTON_CONFIG,
      displayButton: true,
      tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_GRIDS.DEFAULT',
      dispatchAction: { type: IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS, options: undefined }
    },
    1
  )
];

type ToolbarButton = IotToolbarDefaultButton | IotToolbarMenuButton;

@Component({
  imports: [
    NumberFormatPipe,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    ReactiveFormsModule,
    BreadcrumbItemModule,
    SubscriptionButtonModule,
    ToolbarButtonComponent,
    MatButtonToggleModule,
    FormsModule,
    FocusInitialDirective,
    TruncatePipe,
    NgClass,
    DecimalPipe
  ],
  selector: 'iot-platform-ui-toolbar-v2',
  templateUrl: './toolbar-v2.component.html',
  styleUrls: ['./toolbar-v2.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-mdc-select-fv-overlay-pane' }
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarV2Component implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  buttonList = model<ToolbarButton[]>([]);
  name = input<string>();
  size = input<string>(ToolbarSize.REGULAR);
  total = input<number>();
  isDataLoaded = input<boolean>(true);
  withGrids = input<boolean>(true);
  withFavoriteViews = input<boolean>(true);
  breadCrumbConfiguration = input<ToolbarBreadCrumbConfiguration>();
  gridsConfiguration = input<ToolbarGridsConfiguration>();
  favoriteViewConfiguration = input<ToolbarFavoriteViewConfiguration>();
  dashboardsConfiguration = input<ToolbarDashboardsConfiguration>();
  pageTypeOptions = model<ToolbarPageTypeOptions>({ visible: false, pageType: ToolbarPageType.GRID, options: [] });
  autoRefresh = input<ToolbarAutoRefreshOptions>({ counter: 0, timeLeft: 0, displaySpinner: false });
  filterText = input<Partial<ToolbarFilterTextOptions>>({
    visible: false,
    debounceTime: 300,
    minLength: 0,
    autoFocus: false
  });
  dispatchToolbarEvent = output<IotToolbarEvent>();
  filterTextControl: UntypedFormControl = new UntypedFormControl('');
  favoriteviewButtons = [...TOOLBAR_FAVORITEVIEW_BUTTONS];
  gridButtons = [...TOOLBAR_GRID_BUTTONS];
  ToolbarPageType = ToolbarPageType;
  orderedButtonList: Signal<ToolbarButton[]> = computed(() => {
    const buttonList = this.buttonList();
    return buttonList?.sort(SortUtil.sortByOrder)?.map((button) => {
      if (button instanceof IotToolbarMenuButton) {
        button.menuOptions = button.menuOptions.sort(SortUtil.sortByOrder);
      }
      return button;
    });
  });
  currentGrid: Signal<I4BGrid<I4BGridOptions, I4BGridData> | undefined> = computed(() => {
    const gridsConfiguration = this.gridsConfiguration();
    return get(gridsConfiguration, ['currentGrid'], undefined);
  });
  currentFavoriteView: Signal<FavoriteView | null> = computed(() => {
    const favoriteViewConfiguration = this.favoriteViewConfiguration();
    return get(favoriteViewConfiguration, ['currentFavoriteView'], null);
  });
  groupedGrids: Signal<GridGroup[]> = computed(() => {
    const gridsConfiguration = this.gridsConfiguration();
    const defaultValue = [
      { name: 'IOT_TOOLBAR.MY_GRIDS', grids: [], shared: false },
      { name: 'IOT_TOOLBAR.SHARED', grids: [], shared: true }
    ];

    return gridsConfiguration?.sortedGridsWithoutAppDefault
      ? gridsConfiguration.sortedGridsWithoutAppDefault.reduce((acc: GridGroup[], grid: I4BGrid<any, any>) => {
          if (grid.businessProfileId !== null) {
            acc.find((gr) => gr?.name === 'IOT_TOOLBAR.SHARED')?.grids.push(grid);
          }
          if (grid.businessProfileId === null) {
            acc.find((gr) => gr?.name === 'IOT_TOOLBAR.MY_GRIDS')?.grids.push(grid);
          }
          return acc;
        }, defaultValue)
      : defaultValue;
  });
  groupedFavoriteViews: Signal<FVGroup[]> = computed(() => {
    const favoriteViewConfiguration = this.favoriteViewConfiguration();
    const defaultValue = [
      { name: 'IOT_TOOLBAR.MY_FAVORITE_VIEWS', favoriteViews: [] },
      { name: 'IOT_TOOLBAR.SHARED', favoriteViews: [] }
    ];
    return favoriteViewConfiguration?.sortedFavoriteViews
      ? favoriteViewConfiguration?.sortedFavoriteViews?.reduce((acc: FVGroup[], favoriteView: FavoriteView) => {
          if (favoriteView.shared) {
            acc.find((gr) => gr?.name === 'IOT_TOOLBAR.SHARED')?.favoriteViews.push(favoriteView);
          }
          if (!favoriteView.shared) {
            acc.find((gr) => gr?.name === 'IOT_TOOLBAR.MY_FAVORITE_VIEWS')?.favoriteViews.push(favoriteView);
          }
          return acc;
        }, defaultValue)
      : defaultValue;
  });
  displayButtonSection: Signal<boolean> = computed(() => {
    const buttonList = this.orderedButtonList();
    return buttonList?.reduce((acc: boolean, value) => {
      acc = acc || value.displayButton;
      return acc;
    }, false);
  });
  setButtonStatusEffect = effect(() => {
    const buttonList = this.orderedButtonList();
    const favoriteViewConfiguration = this.favoriteViewConfiguration();
    const currentFavoriteView = get(favoriteViewConfiguration, ['currentFavoriteView'], null);

    if (buttonList || !currentFavoriteView) {
      this.setButtonStatus(buttonList);
    }
  });
  manageExportButtonEffect = effect(() => {
    const pageTypeOptions = this.pageTypeOptions();
    const buttonList = untracked(this.buttonList);

    if (buttonList && pageTypeOptions) {
      this.buttonList.update((value: ToolbarButton[]) =>
        value?.map((button) => {
          if (button instanceof IotToolbarDefaultButton && button.dispatchAction.type === IotToolbarDispatchActionType.EXPORT_DATA) {
            button.disabled = pageTypeOptions.pageType === ToolbarPageType.MAP;
          }
          return button;
        })
      );
    }
  });
  setFilterTextEffect = effect(() => {
    const filterText = this.filterText();
    if (filterText.defaultValue !== this.filterTextControl.getRawValue()) {
      this.filterTextControl.setValue(filterText.defaultValue, { emitEvent: false });
    }
  });

  ngOnInit(): void {
    this.listenForFilterInputChanges();
  }

  onApplyGrid(event: MatSelectChange): void {
    this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.APPLY_GRID, options: { grid: event.value } });
  }

  onApplyFavoriteView(element: MatSelectChange): void {
    this.dispatchToolbarEvent.emit({
      type: IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW,
      options: element.value ? element.value : null
    });
  }

  onDashboardSelection(event: MatSelectChange): void {
    this.dispatchToolbarEvent.emit({
      type: IotToolbarDispatchActionType.APPLY_DASHBOARD,
      options: { dashboard: event.value }
    });
  }

  onTogglePageType(event: MatButtonToggleChange): void {
    this.pageTypeOptions.update((options) => ({ ...options, pageType: event.value }));
    this.dispatchToolbarEvent.emit({
      type: IotToolbarDispatchActionType.TOGGLE_PAGE_TYPE,
      options: { pageType: event.value }
    });

    if (this.pageTypeOptions().pageType === ToolbarPageType.DASHBOARD && get(this.dashboardsConfiguration(), ['sortedDashboards'], []).length) {
      this.onDashboardSelection({ value: get(this.dashboardsConfiguration(), ['sortedDashboards'], [])[0] } as MatSelectChange);
    }
  }

  compareFn(item1: { id: string | number }, item2: { id: string | number }): boolean {
    return item1?.id === item2?.id;
  }

  private listenForFilterInputChanges(): void {
    this.filterTextControl.valueChanges
      .pipe(
        debounceTime(Number(GetUtils.get(this.filterText(), 'debounceTime', 300))),
        tap((term) => {
          if (term === null || term === undefined || !term.length || term.length < GetUtils.get(this.filterText(), 'minLength', 0)) {
            this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.FILTER_TEXT_CHANGE, options: null });
          }
        }),
        filter((term: string) => !!term && term.length >= Number(GetUtils.get(this.filterText(), 'minLength', 0)))
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((term: string) => {
        this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.FILTER_TEXT_CHANGE, options: term });
      });
  }

  private setButtonStatus(buttonList: ToolbarButton[]): void {
    if (buttonList) {
      setTimeout(() => {
        buttonList.forEach((button) => {
          if (button instanceof IotToolbarMenuButton) {
            button.menuOptions.forEach((menuOption: IotToolbarMenuButtonOption) => {
              const idx = this.favoriteviewButtons.findIndex(
                (fvButton) => fvButton.tooltip === menuOption.label && fvButton.tooltip !== 'FILTER_ENGINE.CREATE_FAVORITE_VIEW'
              );
              this.favoriteviewButtons[idx] = { ...this.favoriteviewButtons[idx], disabled: menuOption.disableOption };
            });
          }
        });
      }, 1000);
    }
  }
}
