import { Component, effect, inject, input, output, signal, Signal, untracked, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageSelectorV2Component } from '@iot-platform/iot-platform-ui';
import { TagCategory } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';
import { AssetsDirectoryService } from '../../services/assets-directory.service';

@Component({
  selector: 'iot4bos-ui-asset-image-form',
  templateUrl: './asset-image-form.component.html',
  imports: [FlexLayoutModule, MatFormFieldModule, ReactiveFormsModule, ImageSelectorV2Component],
  providers: [TranslateService]
})
export class AssetImageFormComponent {
  private readonly assetsDirectoryService: AssetsDirectoryService = inject(AssetsDirectoryService);
  asset = input<Asset | undefined>();
  tagCategories = input<TagCategory[]>();

  updateForm = output<string>();

  image: WritableSignal<string | null> = signal(null);

  tagCategories$ = toObservable(this.tagCategories);
  filteredImages: Signal<string[]> = toSignal(this.tagCategories$.pipe(switchMap((tags) => this.assetsDirectoryService.getFilteredImagesByTag(tags))), {
    initialValue: []
  });
  defaultImage = signal(this.assetsDirectoryService.getDefaultImage());

  onSelectImage(image: string): void {
    this.image.set(image);
    this.updateForm.emit(image);
  }

  private assetEffect = effect(() => {
    const asset = this.asset();
    const filteredImages = this.filteredImages();
    untracked(() => {
      const defaultImage = this.defaultImage();
      if (asset) {
        if (asset.id) {
          this.image.set(asset.imageUrl ?? defaultImage);
        } else if (filteredImages.includes(asset.imageUrl)) {
          this.image.set(asset.imageUrl);
        } else {
          this.image.set(null);
          this.updateForm.emit(null);
        }
      }
    });
  });
}
