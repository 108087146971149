<ul fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
  @for (profile of bPSortedByEntityLevel(); track profile.id) {
    <li class="" [ngClass]="{ 'out-of-scope-bp': profile.notCoveredByUserEntityAt !== null }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
      <mat-icon>people</mat-icon>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span>{{ profile.name }}</span>
        @if (profile.notCoveredByUserEntityAt === null) {
          <span class="grey fs-10">
            <span> {{ profile.entityName }} </span>
          </span>
        } @else {
          <span class="fs-10">
            {{ 'ADMIN.USERS.TABLE.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate }}
          </span>
        }
      </div>
    </li>
  }
</ul>
