<mat-card class="dialog-card dynamic-widget">
  @if (widgetOptions()?.header?.enabled) {
    <mat-card-title class="dialog-card-header">
      <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
        <h1 class="dialog-card-title dynamic-widget_title">{{ widgetOptions()?.header?.title }}</h1>
      </mat-toolbar>
    </mat-card-title>
  }

  <mat-card-content class="dialog-card-content" fxLayout="row">
    @if (widgetOptions()?.componentRef?.ref) {
      <div fxFlex class="dynamic-widget_chart dynamic-widget_chart--{{ widgetOptions().size.toLowerCase() }}">
        <ng-container
          *ngComponentOutlet="widgetOptions().componentRef.ref; inputs: widgetOptions().componentRef.inputs" />
      </div>
    }
  </mat-card-content>

  @if (widgetOptions()?.footer?.enabled) {
    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="center center">
      <div class="dynamic-widget_legend">{{ widgetOptions()?.footer?.legend }}</div>
    </mat-card-actions>
  }
</mat-card>
