import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class EntityNameColumn extends I4BBasicColumn {
  columnId = 'e262102a-5c1d-11ec-80a5-0023a40210d0-entity-name';
  className = EntityNameColumn;
  dataLibs: [DataLibrary.SITES, DataLibrary.ASSETS, DataLibrary.DEVICES];

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        ...customHeader,
        displayName: 'ENTITY'
      },
      {
        ...customConfig,
        id: 'entity.name',
        isDefault: true,
        width: customOptions?.width ? customOptions.width : 150,
        isConfigurable: true
      },
      { ...customOptions }
    );
  }
}
