<div class="association-by-device" fxLayout="column" fxLayoutGap="12px">
  @if (laoding()) {
    <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex />
  } @else {
    @if (association()?.devices?.length) {
      @for (device of association()?.devices; track device.id) {
        <ng-container [ngTemplateOutlet]="deviceTemplate" [ngTemplateOutletContext]="{ device: device }"></ng-container>
      }
    }

    @if (association()?.assets?.length) {
      <ng-container [ngTemplateOutlet]="unassignedAssetsTemplate" [ngTemplateOutletContext]="{ assets: association()?.assets }"></ng-container>
    }

    @if (!association()?.devices?.length && !association()?.assets?.length) {
      <ng-container [ngTemplateOutlet]="noAssociationsTemplate"></ng-container>
    }
  }
</div>

<ng-template #deviceTemplate let-device="device">
  <div class="association-by-device__device" [fxLayout]="isPageMode() ? 'row' : 'column'" fxLayout.lt-md="column" fxLayoutGap="8px">
    <iot4bos-ui-device-card
      (clicked)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_DEVICE, data: $event.id })"
      [device]="device"
      [width]="'100%'"
      [ngClass]="{ 'association-by-device__device-card--selected': device?.id === selectedItemId }"
      class="association-by-device__device-card"
      fxFlex
    />

    <section [fxFlex]="isPageMode() ? '50%' : '100%'" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px">
      @for (asset of device.assets; track asset.id) {
        <iot4bos-ui-asset-card
          (clicked)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_ASSET, data: $event.id })"
          [asset]="asset"
          class="association-by-device__asset"
          [ngClass]="{ 'association-by-device__asset--selected': asset?.id === selectedItemId }"
        />
      } @empty {
        <iot-platform-ui-default-card [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate" [backgroundClass]="'h-82'" fxFlex />
      }
    </section>
  </div>
</ng-template>

<ng-template #unassignedAssetsTemplate let-assets="assets">
  <div
    class="association-by-device__device"
    [fxLayout]="isPageMode() ? 'row' : 'column'"
    fxLayout.lt-md="column"
    [fxLayoutAlign]="isPageMode() ? 'space-between start' : 'start'"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="8px"
  >
    <iot-platform-ui-default-card
      [fxFlex]="isPageMode() ? '50%' : '100%'"
      fxFlex.lt-md="100%"
      [backgroundClass]="'h-82'"
      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate"
    />

    <section
      [fxFlex]="isPageMode() ? '50%' : '100%'"
      fxFlex.lt-md="100%"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      [fxLayoutGap]="isPageMode() ? '8px' : '4px'"
    >
      @for (asset of assets; track asset.id) {
        <iot4bos-ui-asset-card
          (clicked)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_ASSET, data: $event.id })"
          [asset]="asset"
          class="association-by-device__asset"
          [ngClass]="{ 'association-by-device__asset--selected': asset?.id === selectedItemId }"
          fxFlex
        />
      }
    </section>
  </div>
</ng-template>

<ng-template #noAssociationsTemplate>
  <div class="association-by-device__device" [fxLayout]="isPageMode() ? 'row' : 'column'" fxLayout.lt-md="column" fxLayoutGap="8px">
    <iot-platform-ui-default-card
      [backgroundClass]="'h-82'"
      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate"
      [fxFlex]="isPageMode() ? '50%' : '100%'"
      fxFlex.lt-md="100%"
    />
    <iot-platform-ui-default-card
      [backgroundClass]="'h-82'"
      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate"
      [fxFlex]="isPageMode() ? '50%' : '100%'"
      fxFlex.lt-md="100%"
    />
  </div>
</ng-template>
