import { Component, effect, input, Input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ControlContainer, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { GraphType } from '@iot-platform/models/common';
import { Asset, AssetVariable, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, TranslateModule, MatSelectModule, FlexLayoutModule],
  selector: 'iot-platform-feature-graphs-asset-variable-selector',
  templateUrl: './asset-variable-selector.component.html',
  styleUrls: ['./asset-variable-selector.component.scss']
})
export class AssetVariableSelectorComponent implements OnInit {
  site = input<Site>();
  assets = input<Asset[]>([]);
  control = input<AbstractControl>();
  events = input<AssetVariable[]>();
  disabled = input<boolean>(false);
  allowMultiSelection = input<boolean>(false);
  @Input() variablesLoading = signal(true);

  initialAssetVariablesState = output<string>();

  graphForm: UntypedFormGroup;
  allVariables: AssetVariable[] = [];
  allVariablesFilteredByAsset: DeviceVariable[] | AssetVariable[] = [];
  selectedAssetId: string;
  selectedVariables: WritableSignal<AssetVariable[]> = signal([]);
  GraphTypes = GraphType;

  constructor(
    private readonly assetsService: AssetVariablesService,
    private readonly controlContainer: ControlContainer
  ) {
    effect(() => {
      const events = this.events() ?? [];
      this.selectedVariables.set(events);
    });
  }

  get variables() {
    return this.graphForm.get('variables');
  }

  ngOnInit() {
    this.initForm();

    if (this.assets() && this.assets().length > 0) {
      this.fetchAssetVariables();
    }
  }

  initForm(): void {
    const parent = this.control().parent;
    this.graphForm = new UntypedFormGroup({
      site: new UntypedFormControl({ value: this.site().name, disabled: true }, [Validators.required]),
      asset: new UntypedFormControl({ value: '', disabled: this.disabled() }),
      variables: this.control()
    });
    this.control().setParent(parent);

    if (this.disabled()) {
      this.variables.disable();
    }
  }

  onAssetSelection(event: MatSelectChange) {
    this.selectedAssetId = event.value.id;
    this.allVariablesFilteredByAsset = this.allVariables.filter((v: AssetVariable) => v.asset.id === event.value.id);
  }

  onVariableSelection(event: MatSelectChange) {
    const eventValues: AssetVariable[] = this.allowMultiSelection() ? event.value : [event.value];

    if (!this.allowMultiSelection()) {
      this.selectedVariables.set([]);
    }

    eventValues.forEach((v) => {
      if (!this.selectedVariables().includes(v)) {
        this.selectedVariables.update((currentValue) => {
          currentValue.push(v);
          return currentValue;
        });
      }
    });

    this.selectedVariables.update((currentValue) =>
      currentValue.reduce((acc, value: AssetVariable) => {
        if (value.asset.id === this.selectedAssetId) {
          if (eventValues.includes(value)) {
            acc.push(value);
          }
        } else {
          acc.push(value);
        }
        return acc;
      }, [])
    );
    this.setVariablesValue(this.selectedVariables());
  }

  setVariablesValue(selectedAssetVariables: AssetVariable[]): void {
    this.variables.setValue(selectedAssetVariables);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.controlContainer['form'].updateValueAndValidity();
  }

  fetchAssetVariables() {
    forkJoin(this.assets().map((value) => this.assetsService.getManyByAssetId(value.id, 1000)))
      .pipe(first())
      .subscribe((data: AssetVariable[][]) => {
        this.allVariables = [].concat(...data);
        this.variables.enable();
        this.setSelectedVariables();
        this.initialAssetVariablesState.emit(JSON.stringify(this.variables.value));
        this.variablesLoading.set(false);

        if (this.disabled()) {
          this.graphForm.disable();
        }
      });
  }

  setSelectedVariables(): void {
    this.variables.value?.forEach((v: DeviceVariable | AssetVariable) => {
      const variable = this.allVariables.find((allVar) => allVar.id === v.id);
      if (variable) {
        this.selectedVariables.update((currentValue) => {
          currentValue.push(variable);
          return currentValue;
        });
      }
    });

    this.setVariablesValue(this.selectedVariables());
  }
}
