import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { IotMapFacade } from '@iot-platform/iot-platform-maps';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import { PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses, Site } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';
import { SitesService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { SitesDbActions } from '../actions';
import { SitesActions } from '../actions/sites.actions';

@Injectable()
export class SitesEffects {
  addSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.addSite),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultSitesGrid)),
      concatMap(([action, grid]) =>
        this.sitesService.save(action.siteToAdd).pipe(
          switchMap((newSite: Site) =>
            grid
              ? [
                  SitesDbActions.addSiteSuccess({ siteAdded: newSite }),
                  GridsDbActions.addItemInGridData({
                    gridId: grid.id as string,
                    item: newSite
                  })
                ]
              : [SitesDbActions.addSiteSuccess({ siteAdded: newSite })]
          ),
          catchError((error) => of(SitesDbActions.addSiteFailure({ error })))
        )
      )
    )
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.updateSite),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultSitesGrid)),
      concatMap(([action, grid]) =>
        this.sitesService.update(action.siteToUpdate).pipe(
          switchMap((updated: Site) =>
            grid?.id
              ? [
                  SitesDbActions.updateSiteSuccess({ updatedSite: updated }),
                  GridsDbActions.updateItemInGridData({ gridId: grid.id, item: updated, concept: 'sites' })
                ]
              : [SitesDbActions.updateSiteSuccess({ updatedSite: updated })]
          ),
          catchError((error) => of(SitesDbActions.updateSiteFailure({ error })))
        )
      )
    )
  );

  deleteSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.deleteSite),
      concatLatestFrom(() => this.store.select(fromGrids.getDefaultSitesGrid)),
      concatMap(([action, grid]) =>
        this.sitesService.delete(action.siteToDelete).pipe(
          switchMap((deletedSite) => {
            this.router.navigate(['/', `${this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY)}s`]);
            if (grid) {
              return [
                SitesDbActions.deleteSiteSuccess({ deletedSite }),
                GridsDbActions.removeItemInGridData({
                  gridId: grid.id as string,
                  item: deletedSite
                })
              ];
            } else {
              return [SitesDbActions.deleteSiteSuccess({ deletedSite })];
            }
          }),
          catchError((error) => of(SitesDbActions.deleteSiteFailure({ error })))
        )
      )
    )
  );

  loadAssetsBySiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.loadAssetsBySiteId),
      switchMap((action) =>
        this.sitesService.getAssetsBySiteId(action.request).pipe(
          map((response: PlatformResponse) => SitesDbActions.loadAssetsBySiteIdSuccess({ response })),
          catchError((error) => of(SitesDbActions.loadAssetsBySiteIdFailure({ error })))
        )
      )
    )
  );

  loadDevicesBySiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.loadDevicesBySiteId),
      switchMap((action) =>
        this.sitesService.getDevicesBySiteId(action.request).pipe(
          map((response: PlatformResponse) => SitesDbActions.loadDevicesBySiteIdSuccess({ response })),
          catchError((error) => of(SitesDbActions.loadDevicesBySiteIdFailure({ error })))
        )
      )
    )
  );

  loadSiteById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.loadSiteById),
      switchMap((action) =>
        this.sitesService.getSiteById(action.siteId).pipe(
          map((site: Site) => SitesDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(SitesDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  openSiteDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.openSiteDetail),
      switchMap((action) =>
        this.sitesService.getSiteById(action.siteId).pipe(
          map((site: Site) => SitesDbActions.openSiteDetailSuccess({ site })),
          catchError((error) => of(SitesDbActions.openSiteDetailFailure({ error })))
        )
      )
    )
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.loadSites),
      switchMap((action) =>
        this.sitesService.getAllSites(action.request).pipe(
          map((response) => SitesDbActions.loadSitesSuccess({ response })),
          catchError((error) => of(SitesDbActions.loadSitesFailure({ error })))
        )
      )
    )
  );

  navigateToNewlyAddedSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SitesDbActions.addSiteSuccess),
        tap((action) => {
          this.nav.selectLeSite(action.siteAdded);
          this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'site');
        })
      ),
    { dispatch: false }
  );

  selectSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.selectSite),
      switchMap((action) =>
        this.sitesService.selectSite(action.selectedSite).pipe(
          map((selectedSite: Site) => SitesDbActions.selectSiteSuccess({ selectedSite })),
          catchError((error) => of(SitesDbActions.selectSiteFailure({ error })))
        )
      )
    )
  );

  loadTagsBySiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.loadTagsBySiteId),
      switchMap((action) =>
        this.sitesService.getTagsBySiteId(action.siteId).pipe(
          map((tags: TagCategory[]) => SitesDbActions.loadTagsBySiteIdSuccess({ tags })),
          catchError((error) => of(SitesDbActions.loadTagsBySiteIdFailure({ error })))
        )
      )
    )
  );

  updateTagsBySiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesDbActions.updateTagsBySiteId),
      concatMap((action) =>
        this.sitesService.putTagsBySiteId(action.siteId, action.tags).pipe(
          map((tags: TagCategory[]) => SitesDbActions.updateTagsBySiteIdSuccess({ tags })),
          catchError((error) => of(SitesDbActions.updateSiteFailure({ error })))
        )
      )
    )
  );

  bulkOperationOnTagBySiteIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesActions.bulkOperationOnTag),
      concatMap((action) =>
        this.sitesService.bulkOperationOnTag(action.bulkOperationType, action.sitesIds, action.tagLabelId).pipe(
          map((response: I4BBulkOperationApiResponse) =>
            SitesActions[`bulk${StringUtils.capitalizeFirstCharacter(action.bulkOperationType)}Tag-`]({ response })
          ),
          catchError((error) => of(SitesActions[`bulk${StringUtils.capitalizeFirstCharacter(action.bulkOperationType)}TagFailure`]({ error })))
        )
      )
    )
  );

  bulkActionsThenReloadGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesActions['bulkAddTag-'], SitesActions['bulkReplaceTag-'], SitesActions['bulkRemoveTag-']),
      withLatestFrom(this.store.select(fromGrids.getDefaultSitesGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewSites)),
      filter(([_, grid, __]) => !!grid),
      map(([_, grid, filters]) =>
        GridsDbActions.loadGridData({
          request: {
            filters,
            limit: grid?.data.response.pagination.limit,
            concept: grid?.masterview.toLowerCase(),
            page: grid?.data.response.pagination.currentPage,
            variables: grid?.gridOptions.variableNames,
            tags: grid?.gridOptions.tagIds,
            endPoint: grid?.gridOptions.endPoint
          }
        })
      )
    )
  );

  displaySuccessAfterBulkAddOrRemoveTagBySitesIds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SitesActions['bulkAddTag-'], SitesActions['bulkRemoveTag-'], SitesActions['bulkReplaceTag-']),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SitesDbActions.addSiteSuccess,
          SitesDbActions.updateSiteSuccess,
          SitesDbActions.deleteSiteSuccess,
          SitesDbActions.updateTagsBySiteIdSuccess,
          SitesDbActions.saveMVSettingsSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayErrorV8$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SitesDbActions.addSiteFailure,
          SitesDbActions.deleteSiteFailure,
          SitesDbActions.loadAssetsBySiteIdFailure,
          SitesDbActions.loadDevicesBySiteIdFailure,
          SitesDbActions.loadSiteByIdFailure,
          SitesDbActions.loadSitesFailure,
          SitesDbActions.openSiteDetailFailure,
          SitesDbActions.openAssetDetailFailure,
          SitesDbActions.openDeviceDetailFailure,
          SitesDbActions.selectSiteFailure,
          SitesDbActions.updateSiteFailure,
          SitesDbActions.updateTagsBySiteIdFailure,
          SitesDbActions.loadMVSettingsFailure,
          SitesDbActions.saveMVSettingsFailure,
          SitesActions.bulkAddTagFailure,
          SitesActions.bulkRemoveTagFailure,
          SitesActions.bulkReplaceTagFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoaderV8$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SitesDbActions.loadSites,
          SitesDbActions.loadSiteById,
          SitesDbActions.saveMVSettings,
          SitesDbActions.loadMVSettings,
          SitesActions.bulkOperationOnTag
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponseV8$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SitesDbActions.loadSitesSuccess,
          SitesDbActions.loadSiteByIdSuccess,
          SitesDbActions.loadDevicesBySiteIdSuccess,
          SitesDbActions.loadAssetsBySiteIdSuccess,
          SitesDbActions.loadTagsBySiteIdSuccess,
          SitesDbActions.loadSitesFailure,
          SitesDbActions.loadSiteByIdFailure,
          SitesDbActions.loadDevicesBySiteIdFailure,
          SitesDbActions.loadAssetsBySiteIdFailure,
          SitesDbActions.loadTagsBySiteIdFailure,
          SitesDbActions.loadMVSettingsSuccess,
          SitesDbActions.loadMVSettingsFailure,
          SitesDbActions.saveMVSettingsSuccess,
          SitesDbActions.saveMVSettingsFailure,
          SitesActions['bulkAddTag-'],
          SitesActions.bulkAddTagFailure,
          SitesActions['bulkRemoveTag-'],
          SitesActions.bulkRemoveTagFailure,
          SitesActions['bulkReplaceTag-'],
          SitesActions.bulkReplaceTagFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  setCurrentFavoriteView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.setCurrentFavoriteView),
        filter((action) => get(action, 'masterView') === 'sites'),
        tap((action) => {
          this.mapFacade.getAll({
            concept: action.masterView,
            displayMode: 'assetsDevices',
            filters: get(action, 'favoriteView.filters', [])
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly sitesService: SitesService,
    private readonly nav: NavigationApi,
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    private readonly mapFacade: IotMapFacade
  ) {}
}
