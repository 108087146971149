import { I4BColumn, I4BColumnConfiguration, I4BColumnHeader, I4BColumnOptions, I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';

export const VARIABLE_CHART_MASTER_VIEW_NAME = 'variable-chart-mv';

export class VariableChartGrid implements I4BGrid<I4BGridOptions, I4BGridData> {
  masterview = VARIABLE_CHART_MASTER_VIEW_NAME;
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = 'variable-chart';
  userId: string | null = null;
  data: I4BGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    gridSort: [{ colId: 'dateTime', sort: 'desc', sortIndex: 0 }],
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: false },
    selectionColumn: { enabled: false }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [];

  constructor(columns?: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]) {
    this.columns = columns ?? [];
  }
}
