import { NgModule } from '@angular/core';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabGroupImageComponent } from './tab-group-image/tab-group-image.component';
import { TabGroupMetaComponent } from './tab-group-meta/tab-group-meta.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { TabComponent } from './tab/tab.component';

const COMPONENTS = [TabGroupComponent, TabComponent, TabHeaderComponent, TabContentComponent, TabGroupImageComponent, TabGroupMetaComponent];

@NgModule({
  imports: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class TabsModule {}
