import { FormControl, FormGroup } from '@angular/forms';
import { SiteType } from '@iot-platform/models/i4b';

export enum SiteInfoFormControlNames {
  NAME = 'name',
  NAME2 = 'name2',
  ENTITY = 'entity',
  PARENT_ENTITY = 'parentEntity',
  TYPE = 'type',
  BUSINESS_ID = 'businessId',
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  ZIP_CODE = 'zipCode',
  STATE = 'state',
  COUNTRY = 'country',
  CITY = 'city',
  DESCRIPTION = 'description'
}

export type DeviceInfoFormGroup = FormGroup<
  Partial<{
    [SiteInfoFormControlNames.NAME]: FormControl<string>;
    [SiteInfoFormControlNames.NAME2]: FormControl<string>;
    [SiteInfoFormControlNames.PARENT_ENTITY]: FormControl<string>;
    [SiteInfoFormControlNames.ENTITY]: FormControl<string>;
    [SiteInfoFormControlNames.TYPE]: FormControl<SiteType>;
    [SiteInfoFormControlNames.BUSINESS_ID]: FormControl<string>;
    [SiteInfoFormControlNames.ADDRESS1]: FormControl<string>;
    [SiteInfoFormControlNames.ADDRESS2]: FormControl<string>;
    [SiteInfoFormControlNames.ZIP_CODE]: FormControl<string>;
    [SiteInfoFormControlNames.STATE]: FormControl<string>;
    [SiteInfoFormControlNames.COUNTRY]: FormControl<string>;
    [SiteInfoFormControlNames.CITY]: FormControl<string>;
    [SiteInfoFormControlNames.DESCRIPTION]: FormControl<string>;
  }>
>;
