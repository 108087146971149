import { inject, Injectable, Signal } from '@angular/core';
import { AbstractDeviceCallLogsService } from '@iot-platform/feature/device-call-logs';
import { DeviceCallLogsFacade, DevicesFacade } from '@iot-platform/iot4bos/data-access/devices';
import { DeviceCallLog } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Device } from '@iot-platform/models/i4b';
import { Observable, of } from 'rxjs';
import { DeviceCallLogsGrid } from '../grids/device-call-logs-grid-definition';

@Injectable({ providedIn: 'root' })
export class DeviceCallLogsCtaService extends AbstractDeviceCallLogsService {
  private readonly deviceCallLogsFacade: DeviceCallLogsFacade = inject(DeviceCallLogsFacade);
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);

  selectedDevice: Signal<Device> = this.devicesFacade.currentEntity;

  getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null> {
    return of(new DeviceCallLogsGrid());
  }

  onOpen(deviceCallLog: DeviceCallLog): void {
    this.deviceCallLogsFacade.loadDeviceCallLogMessage(this.selectedDevice(), deviceCallLog);
  }

  onDownloadMessage(deviceCallLog: DeviceCallLog): void {
    this.deviceCallLogsFacade.loadAndDownloadDeviceCallLogMessage(this.selectedDevice(), deviceCallLog);
  }
}
