<div class="ag-theme-material grid-wrapper" data-cy="grid-engine-grid-page">
  <ag-grid-angular
    (firstDataRendered)="onFirstDataRendered()"
    (gridReady)="onGridReady($event)"
    (newColumnsLoaded)="onGridColumnsChanged()"
    (sortChanged)="onSortChange()"
    [accentedSort]="true"
    [colResizeDefault]="'shift'"
    [columnDefs]="columnDefs()"
    [debug]="false"
    [defaultColDef]="defaultColDef"
    [getLocaleText]="getLocaleText"
    [gridOptions]="gridOptions"
    [navigateToNextCell]="navigateToNextCell"
    [navigateToNextHeader]="navigateToNextHeader"
    [rowClassRules]="rowClassRules()"
    [rowData]="rowData()"
    [sideBar]="sideBar"
    [tabToNextCell]="tabToNextCell"
    [tabToNextHeader]="tabToNextHeader"
    style="flex: 2"
  />
  @if (!hidePaginator()) {
    <grid-engine-paginator [ngClass]="{'ag-max-count-reached': !!grid()?.data?.response?.pagination?.maxCountReached }" (pageChange)="onPageChange($event)" [pagination]="grid()?.data?.response?.pagination" />
  }
</div>
<ng-template #exportViewRef />
