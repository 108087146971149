import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW, GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW } from './default-device-grid.definition';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { DaliaFirmwareVersionColumn, DeviceTypeGroupColumn, ExpandedVariableColumn, I4BColumn, I4BSelectionColumn, NameColumn } from './definitions';
import { DaliaDeviceButtonColumn } from './definitions/dalia-device-button.column';
import { DiagnosticVariableGroupColumn } from './definitions/i4b-diagnostic-variable-group.column';
import { I4bCellConcept } from './enums';
import { DevicesGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions, I4BVariableColumnOptions } from './options';

export const GET_DEFAULT_DEVICES_COLUMNS_FOR_DALIA_DEVICES_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] =>
  [...GET_DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW()]
    .map((cell) => {
      cell.configuration.isDefault = false;
      if (
        cell.configuration.id === 'name' ||
        cell.configuration.id === 'identifier' ||
        cell.configuration.id === 'deviceType' ||
        cell.configuration.id === 'communication.operator' ||
        cell.configuration.id === 'lastCallStatus.datetime'
      ) {
        cell.configuration.isDefault = true;
      }
      if (cell.configuration.id === 'deviceType') {
        cell.options.order = 8;
      }
      return cell;
    })
    .filter(
      (cell) =>
        cell.configuration.id !== 'name' &&
        cell.configuration.id !== 'type.firmware' &&
        cell.configuration.id !== 'deviceType' &&
        cell.configuration.id !== 'hasCredential' &&
        cell.configuration.id !== 'totalComments'
    )
    .concat([
      new NameColumn(
        { displayName: 'NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
        {
          id: 'name',
          translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
          concept: I4bCellConcept.DEVICES,
          autoSize: true,
          isDefault: true,
          cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
        },
        { order: 1, pinned: 'left', width: 200 }
      ),
      new DeviceTypeGroupColumn(
        { displayName: 'DEVICE TYPE FULL' },
        {
          id: 'deviceType',
          isDefault: true,
          cell: {
            type: I4BCellType.BASIC,
            options: {
              toFixed: 3
            }
          }
        },
        { order: 13 }
      ),
      new DaliaFirmwareVersionColumn(
        { displayName: 'FIRMWARE' },
        {
          id: 'type.firmware',
          translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
          cell: {
            type: I4BCellType.BASIC,
            options: {
              toFixed: 3
            }
          },
          isDefault: false,
          sortProperty: 'type.firmware',
          concept: I4bCellConcept.DEVICES,
          width: 130
        },
        {
          order: 5
        }
      )
    ]);

export const GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DALIA_DEVICE_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  ...GET_DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW()
]
  .map((cell) => {
    cell.configuration.isDefault = false;
    (cell.options as I4BVariableColumnOptions).displayDateTime = false;
    if (cell.configuration.id === 'BATTERY_LEVEL') {
      cell.configuration.isDefault = true;
    }
    return cell;
  })
  .filter((cell) => cell.configuration.id !== 'ENERGY_MODE')
  .concat([
    new DiagnosticVariableGroupColumn(
      { displayName: 'ENERGY_MODE' },
      {
        id: 'ENERGY_MODE',
        translationKey: 'ENERGY_MODE',
        cell: {
          type: I4BCellType.DIAGNOSTIC_VARIABLE,
          cellClassRules: {
            'bg-green200': 'value === "FPM"',
            'bg-yellow300': 'value === "ECO1"',
            'bg-orange300': 'value === "ECO2"',
            'bg-red300': 'value === "PSM"'
          },
          options: {
            textAlign: 'center',
            translatedValues: {
              0: {
                value: 'FPM',
                color: '#FFFFFF',
                align: 'center'
              },
              1: {
                value: 'ECO1',
                color: '#333333',
                align: 'center'
              },
              2: {
                value: 'ECO2',
                color: '#333333',
                align: 'center'
              },
              3: {
                value: 'PSM',
                color: '#FFFFFF',
                align: 'center'
              }
            }
          }
        },
        isDefault: true,
        concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
        width: 100
      },
      {
        order: 34,
        displayDateTime: false
      }
    ),
    new ExpandedVariableColumn(
      { displayName: 'POWER SUPPLY', type: HeaderType.ICON_AND_TEXT, showHeaderName: false },
      {
        id: 'expandedVariables.pws.lastValue.value',
        isDefault: true,
        isConfigurable: true,
        sortProperty: 'expandedVariables.pws.lastValue.value',
        concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
        width: 120,
        cell: {
          type: I4BCellType.ICON,
          options: {
            textAlign: 'center',
            translatedValues: {
              0: {
                value: 'power-supply'
              }
            }
          }
        }
      },
      { order: 35, customId: 'expandedVariables.pws' }
    )
  ]);

export class DefaultDaliaDevicesGrid implements I4BGrid<I4BGridOptions, DevicesGridData> {
  masterview = 'dalia-devices';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: DevicesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: true, className: new DaliaDeviceButtonColumn() },
    selectionColumn: { enabled: false, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}
