import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiActions } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { DevicesActions, DevicesService } from '@iot-platform/iot4bos/data-access/devices';
import { Asset, AssetStatusName, Device, Site, SiteType } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatAll, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AssetsActions } from '../../../features/assets/+state/actions';
import { NavigationActions } from '../actions';
import { NavigationApi } from '../navigation.api';

@Injectable()
export class NavigationEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.loadData),
      tap((action) => {
        this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.siteId);
        this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.activeId);
        this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, action.origin);
        this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, action.pageType);
      }),
      map((action) => {
        const urlParts = this.router.routerState.snapshot.url.split('/');
        if ((urlParts.includes('sites') && urlParts.includes('device')) || (urlParts.includes('stocks') && urlParts.includes('device'))) {
          return NavigationActions.openDeviceDetail(action);
        }
        if (urlParts.includes('sites') && urlParts.includes('asset')) {
          return NavigationActions.openAssetDetail(action);
        }
        return NavigationActions.openSiteDetail(action);
      })
    )
  );

  navigateToSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.openSiteDetail),
        filter((action) => !!action.siteId),
        tap((action) => {
          if (action.pageType === 'info') {
            this.router.navigate(['/', 'sites', action.siteId]);
            this.navigationApi.loadSiteById(action.siteId);
            this.navigationApi.loadTagsBySiteId(action.siteId);
            this.navigationApi.loadAssetsBySiteId(action.siteId);
            this.navigationApi.loadDevicesBySiteId(action.siteId);
            this.navigationApi.loadGraphsBySiteId(action.siteId);
            this.navigationApi.loadContactBySiteId(action.siteId);
            this.navigationApi.loadNotificationBySiteId(action.siteId);
            this.navigationApi.loadExportSpreadsheetsBySiteId(action.siteId);
          } else if (action.pageType === 'stock') {
            this.router.navigate(['/', 'stocks', action.siteId]);
            this.navigationApi.loadSiteById(action.siteId);
            this.store.dispatch(NavigationActions.loadDeviceCountByFamily({ siteId: action.siteId }));
            this.store.dispatch(
              GridsDbActions.selectGridAndLoadData({
                gridId: 'default',
                filters: [{ criteriaKey: 'siteId', value: action.siteId }],
                masterview: 'stock-site-devices',
                endPoint: '/devices'
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  selectLeSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.selectLeSite),
        tap((action) => {
          if (action.selectedSite.id) {
            if (action.selectedSite.type === 'stock') {
              this.router.navigate(['/', 'stocks', action.selectedSite.id]);
              this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.selectedSite.id);
              this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.selectedSite.id);
              this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, 'stock');
              this.navigationApi.loadSiteById(action.selectedSite.id);
              this.store.dispatch(NavigationActions.loadDeviceCountByFamily({ siteId: action.selectedSite.id }));
              this.store.dispatch(
                GridsDbActions.selectGridAndLoadData({
                  gridId: 'default',
                  filters: [{ criteriaKey: 'siteId', value: action.selectedSite.id }],
                  masterview: 'stock-site-devices',
                  endPoint: '/devices'
                })
              );
            } else {
              this.router.navigate(['/', 'sites', action.selectedSite.id]);
              this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.selectedSite.id);
              this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.selectedSite.id);
              this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, 'info');
              this.navigationApi.loadSiteById(action.selectedSite.id);
              this.navigationApi.loadTagsBySiteId(action.selectedSite.id);
              this.navigationApi.loadAssetsBySiteId(action.selectedSite.id);
              this.navigationApi.loadDevicesBySiteId(action.selectedSite.id);
              this.navigationApi.loadGraphsBySiteId(action.selectedSite.id);
              this.navigationApi.loadContactBySiteId(action.selectedSite.id);
              this.navigationApi.loadNotificationBySiteId(action.selectedSite.id);
              this.navigationApi.loadExportSpreadsheetsBySiteId(action.selectedSite.id);
            }
          }
        })
      ),
    { dispatch: false }
  );

  selectLeStock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.selectLeStock),
        tap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.stock.id);
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.stock.id);
          this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, 'stock');
          this.router.navigate(['/', 'stocks', action.stock.id]);
          this.navigationApi.loadSiteById(action.stock.id);
          this.store.dispatch(NavigationActions.loadDeviceCountByFamily({ siteId: action.stock.id }));
          this.store.dispatch(
            GridsDbActions.selectGridAndLoadData({
              gridId: 'default',
              filters: action.request.filters,
              masterview: 'stock-site-devices',
              endPoint: '/devices'
            })
          );
        })
      ),
    { dispatch: false }
  );

  loadDeviceCountByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.loadDeviceCountByFamily),
      switchMap((action) =>
        this.devicesService.getDeviceCountByFamily(action.siteId).pipe(
          map((deviceCountByFamily) => NavigationActions.loadDeviceCountByFamilySuccess({ deviceCountByFamily })),
          catchError((error) => of(NavigationActions.loadDeviceCountByFamilyFailure({ error })))
        )
      )
    )
  );

  moveDevicesThenUpdateDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DevicesActions.moveDevicesSuccess),
        tap(({ devices, currentSite, originSite }) => {
          if (devices.length > 1) {
            if (!!originSite) {
              // More than one device moved => stay on stock page and refresh the data
              this.navigationApi.selectLeStock(originSite, {
                limit: 100,
                page: 0,
                filters: [{ criteriaKey: 'siteId', value: originSite.id }]
              });
            }
          } else if (currentSite.type === 'stock') {
            // One device moved to stock => go to new stock page
            this.navigationApi.selectLeStock(currentSite, {
              limit: 100,
              page: 0,
              filters: [{ criteriaKey: 'siteId', value: currentSite.id }]
            });
          } else {
            // One device moved from devices MV or device info page to customer/production site => go to new device page
            this.navigationApi.selectDeviceAvecLeSite(devices[0]);
          }
        })
      ),
    { dispatch: false }
  );

  clearSelectedItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.clearSelectedItem),
      map((action) => NavigationActions.selectLeSite({ selectedSite: action.site }))
    )
  );

  selectAssetAvecLeSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.selectAssetAvecLeSite),
        tap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.selectedAsset.site.id);
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.selectedAsset.id);
          this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, 'info');
          this.router.navigate(['/', 'sites', action.selectedAsset.site.id, 'asset', action.selectedAsset.id]);
          this.navigationApi.loadAssetById(action.selectedAsset.id);
          this.navigationApi.loadTagsByAssetId(action.selectedAsset.id);
          this.navigationApi.loadVariablesByAssetId(action.selectedAsset.id);
          this.navigationApi.loadSiteById(action.selectedAsset.site.id);
          this.navigationApi.loadTagsBySiteId(action.selectedAsset.site.id);
          this.navigationApi.loadAssetsBySiteId(action.selectedAsset.site.id);
          this.navigationApi.loadDevicesBySiteId(action.selectedAsset.site.id);
        })
      ),
    { dispatch: false }
  );

  navigateToAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.openAssetDetail),
      map((action) => {
        const selectedAsset: Asset = {
          id: action.activeId,
          site: {
            id: action.siteId,
            name: '',
            address: null,
            businessId: '',
            type: '' as SiteType,
            expandedTagCategories: {}
          },
          name: '',
          erpReference: null,
          businessId: '',
          status: {
            name: '' as AssetStatusName,
            datetime: '',
            scheduledMaintenance: { active: false, switchbackDate: null }
          },
          expandedTagCategories: {},
          expandedVariables: {},
          optionalProperties: {
            shipTo: false,
            product1: false,
            product2: false,
            quantity1: false,
            quantity2: false,
            businessId: false,
            deliveryDate: false
          }
        };
        return NavigationActions.selectAssetAvecLeSite({ selectedAsset });
      })
    )
  );

  selectAsset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.selectAsset),
        tap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.assetId);
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.siteId);
          this.router.navigate(['sites', action.siteId, 'asset', action.assetId]);
          this.navigationApi.loadTagsByAssetId(action.assetId);
          this.navigationApi.loadVariablesByAssetId(action.assetId);
        })
      ),
    { dispatch: false }
  );

  assetDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.deleteOneSuccess),
      map((action) => NavigationActions.selectLeSite({ selectedSite: action.deleted.site as Site }))
    )
  );

  selectDeviceAvecLeSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.selectDeviceAvecLeSite),
        tap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.selectedDevice.site.id);
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.selectedDevice.id);
          if (action.selectedDevice.site.type === 'stock') {
            this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, 'stock');
            this.router.navigate(['/', 'stocks', action.selectedDevice.site.id, 'device', action.selectedDevice.id]);
            this.navigationApi.loadDeviceById(action.selectedDevice.id);
            this.navigationApi.loadTagsByDeviceId(action.selectedDevice.id);
            this.navigationApi.loadSiteById(action.selectedDevice.site.id);
            this.navigationApi.loadTagsBySiteId(action.selectedDevice.site.id);
            this.navigationApi.changeStockPage({
              page: 0,
              limit: 25,
              filters: [{ criteriaKey: 'siteId', value: action.selectedDevice.site.id }]
            });
          } else {
            this.storage.set(LocalStorageKeys.STORAGE_PAGE_TYPE_KEY, 'info');
            this.router.navigate(['/', 'sites', action.selectedDevice.site.id, 'device', action.selectedDevice.id]);
            this.navigationApi.loadDeviceById(action.selectedDevice.id);
            this.navigationApi.loadTagsByDeviceId(action.selectedDevice.id);
            this.navigationApi.loadSiteById(action.selectedDevice.site.id);
            this.navigationApi.loadTagsBySiteId(action.selectedDevice.site.id);
            this.navigationApi.loadAssetsBySiteId(action.selectedDevice.site.id);
            this.navigationApi.loadDevicesBySiteId(action.selectedDevice.site.id);
          }
        })
      ),
    { dispatch: false }
  );

  selectDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.selectDevice),
        tap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.deviceId);
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.siteId);
          this.router.navigate(['/', 'sites', action.siteId, 'device', action.deviceId]);
          this.navigationApi.loadDeviceById(action.deviceId);
          this.navigationApi.loadTagsByDeviceId(action.deviceId);
          this.navigationApi.loadVariablesByDeviceId(action.deviceId);
        })
      ),
    { dispatch: false }
  );

  navigateToDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.openDeviceDetail),
      map((action) => {
        const selectedDevice: Device = {
          id: action.activeId,
          site: {
            id: action.siteId,
            name: '',
            address: null,
            businessId: '',
            type: action.pageType === 'stock' ? SiteType.STOCK : ('' as SiteType),
            expandedTagCategories: {}
          },
          communication: null,
          connector: null,
          name: '',
          type: null,
          identifier: '',
          expandedTagCategories: {},
          expandedVariables: {},
          hasCredential: false,
          totalTags: 0,
          totalEvents: 0,
          totalActiveEvents: 0,
          totalVariables: 0
        };
        return NavigationActions.selectDeviceAvecLeSite({ selectedDevice });
      })
    )
  );

  deviceDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DevicesActions.deleteDeviceSuccess),
      map((action: any) => {
        if (action.device.site.type === SiteType.STOCK) {
          return NavigationActions.selectLeStock({
            stock: action.device.site,
            request: { page: 0, limit: 25, filters: [{ criteriaKey: 'siteId', value: action.device.site.id }] }
          });
        } else {
          return NavigationActions.selectLeSite({ selectedSite: action.device.site });
        }
      })
    )
  );

  moveDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.moveDevices),
      switchMap((action) => this.devicesService.updateDevices(action.devicesToMove)),
      mergeMap((devices$) =>
        of(devices$).pipe(
          concatAll(),
          map((devs) => NavigationActions.moveDevicesSuccess({ movedDevices: [devs] }))
        )
      )
    )
  );

  moveDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.moveDevice),
      switchMap((action) =>
        this.devicesService.updateDevice(action.deviceToMove).pipe(
          map((movedDevice) => NavigationActions.moveDeviceSuccess({ movedDevice })),
          catchError((error) => of(NavigationActions.moveDeviceFailure({ error })))
        )
      )
    )
  );

  moveDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.moveDeviceSuccess),
      map((action) => NavigationActions.selectDeviceAvecLeSite({ selectedDevice: action.movedDevice }))
    )
  );

  loadDataWhenBusinessProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.retrieveSessionSuccess, AuthApiActions.retrieveSsoSessionSuccess),
        tap(() => {
          if (this.router.routerState.snapshot.url.includes('sites/')) {
            this.store.dispatch(
              NavigationActions.loadData({
                siteId: this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY),
                activeId: this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY),
                origin: this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY),
                pageType: 'info'
              })
            );
          } else if (this.router.routerState.snapshot.url.includes('stocks/')) {
            this.store.dispatch(
              NavigationActions.loadData({
                siteId: this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY),
                activeId: this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY),
                origin: this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY),
                pageType: 'stock'
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  refreshStoreData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.loadDataSuccess),
        tap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, action.site.id);
          this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, action.site.id);
        })
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.loadData, NavigationActions.openDeviceDetail, NavigationActions.changeStockPage),
        tap(() => this.notificationService.displayLoader(true))
      ),
    { dispatch: false }
  );

  displayLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.loadDataSuccess, NavigationActions.changeStockPageSuccess),
        tap(() => this.notificationService.displayLoader(false))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly navigationApi: NavigationApi,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private readonly devicesService: DevicesService,
    private readonly storage: LocalStorageService
  ) {}
}
