import { EnvironmentProviders, InjectionToken, Type, makeEnvironmentProviders } from '@angular/core';
import { AbstractAssociationAssetDeviceSettingsService } from './abstract-association-asset-device-settings.service';

export interface AssoctiationAssetDeviceSettings {
  associationAssetDeviceSettingsService: Type<AbstractAssociationAssetDeviceSettingsService>;
}

export const ASSOCIATION_ASSET_DEVICE_SETTINGS = new InjectionToken<AssoctiationAssetDeviceSettings>('ASSOCIATION_ASSET_DEVICE_SETTINGS');

export function provideAssociationAssetDeviceSettings(settings: AssoctiationAssetDeviceSettings): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: AbstractAssociationAssetDeviceSettingsService, useClass: settings.associationAssetDeviceSettingsService }]);
}
