import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IotPlatformDashboardsModule } from '@iot-platform/feature/dashboards';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { DetailPopupCardsModule, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { FavoriteViewsModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { BulkManageTagDialogModule } from '../../../../components/bulk-manage-tag-dialog/bulk-manage-tag-dialog.module';
import { CommentsPanelComponent } from '../../../../components/comments/comments-panel/comments-panel.component';
import { SiteDetailComponent } from '../../components/site-detail/site-detail.component';
import { StockSiteDetailPopupComponent } from '../../components/stock-site-detail-popup/stock-site-detail-popup.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    IotPlatformUiModule,
    DetailPopupCardsModule,
    FavoriteViewsModule,
    GridEngineModule,
    BulkManageTagDialogModule,
    IotPlatformDashboardsModule,
    CommentsPanelComponent,
    InfoDisplayPipe
  ],
  declarations: [SiteDetailComponent, StockSiteDetailPopupComponent]
})
export class SitesShellModule {}
