import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, ModelSignal, output, signal, Signal } from '@angular/core';
import { OverviewCardActions, OverviewCardEvent, UserPreferences } from '@iot-platform/models/common';
import { PreferencesFacade } from '@iot-platform/users';
import { AssociationByDeviceComponent } from '../../components/association-by-device/association-by-device.component';
import { AssociationByAssetComponent } from '../../components/association-by-asset/association-by-asset.component';
import { AssociationAssetDeviceFacade, AssociationAssetDeviceStore } from '@iot-platform/iot4bos/data-access/association-asset-device';
import { AssetStatusName, AssociationByAsset, AssociationByDevice, DisplayMode, Site } from '@iot-platform/models/i4b';
import {
  IotToolbarDispatchActionType,
  SectionHeaderAction,
  SectionHeaderActionsType,
  SectionHeaderComponent,
  SectionHeaderEvent
} from '@iot-platform/iot-platform-ui';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { AbstractAssociationAssetDeviceSettingsService } from '../../settings/abstract-association-asset-device-settings.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'iot4bos-association-asset-device-shell',
  imports: [
    AssociationByDeviceComponent,
    AssociationByAssetComponent,
    SectionHeaderComponent,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    NgClass
  ],
  providers: [AssociationAssetDeviceFacade, AssociationAssetDeviceStore],
  templateUrl: './association-asset-device-shell.component.html',
  styleUrl: './association-asset-device-shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationAssetDeviceShellComponent {
  private readonly associationAssetDeviceFacade: AssociationAssetDeviceFacade = inject(AssociationAssetDeviceFacade);
  private readonly preferencesFacade: PreferencesFacade = inject(PreferencesFacade);
  private readonly associationAssetDeviceSettingsService = inject(AbstractAssociationAssetDeviceSettingsService);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  displayMode = input<DisplayMode>(DisplayMode.DRAWER);
  headerActions = input([], {
    transform: (value: SectionHeaderAction[]) =>
      value.map((elem: SectionHeaderAction) =>
        elem.action === IotToolbarDispatchActionType.CREATE_ASSET_GROUP.toString()
          ? {
              ...elem,
              disabled: computed(() => elem.disabled() || this.maxAssetGroupsReached()),
              icon: computed(() => (this.maxAssetGroupsReached() ? 'info_outlined' : '')),
              tooltip: computed(() => (this.maxAssetGroupsReached() ? 'SITES.ASSOCIATIONS.MAX_ASSET_GROUP_TOOLTIP' : ''))
            }
          : elem
      )
  });

  dispatchEvent = output<SectionHeaderEvent>();

  displayDecommissionedAssets: ModelSignal<boolean> = model();
  siteId: Signal<string> = toSignal(this.activatedRoute.paramMap.pipe(map((params) => params.get('siteId'))));
  selectedSite: Signal<Site> = this.associationAssetDeviceSettingsService.selectedSite;
  preferences: Signal<UserPreferences> = this.preferencesFacade.preferences;
  associationLoading: Signal<boolean> = this.associationAssetDeviceFacade.loading;
  associationByAsset: Signal<AssociationByAsset> = this.associationAssetDeviceFacade.associationByAsset;
  associationByDevice: Signal<AssociationByDevice> = this.associationAssetDeviceFacade.associationByDevice;

  filteredAssociationByAsset: Signal<AssociationByAsset> = computed(() => {
    const associationByAsset = this.associationByAsset();
    const displayDecommissionedAssets = this.displayDecommissionedAssets();
    return associationByAsset
      ? displayDecommissionedAssets
        ? associationByAsset
        : {
            ...associationByAsset,
            assetGrous: associationByAsset?.assetGroups?.map((assetGroup) => ({
              ...assetGroup,
              assets: assetGroup?.assets?.filter((asset) => asset.status.name !== AssetStatusName.decommissioned)
            })),
            assets: associationByAsset?.assets?.filter((asset) => asset.status.name !== AssetStatusName.decommissioned)
          }
      : associationByAsset;
  });

  filteredAssociationByDevice: Signal<AssociationByDevice> = computed(() => {
    const associationByDevice = this.associationByDevice();
    const displayDecommissionedAssets = this.displayDecommissionedAssets();
    return associationByDevice
      ? displayDecommissionedAssets
        ? associationByDevice
        : {
            ...associationByDevice,
            devices: associationByDevice?.devices?.map((device) => ({
              ...device,
              assets: device?.assets?.filter((asset) => asset.status.name !== AssetStatusName.decommissioned)
            })),
            assets: associationByDevice?.assets?.filter((asset) => asset.status.name !== AssetStatusName.decommissioned)
          }
      : associationByDevice;
  });

  setDisplayDecommissionedAssets = effect(() => {
    const preferences = this.preferences();
    this.displayDecommissionedAssets.set(preferences.displayDecommissionedAssets);
  });

  decomissionnedAssetsCount: Signal<number> = computed(() => {
    const associationByDevice = this.associationByDevice();
    return associationByDevice
      ? associationByDevice?.assets?.filter((asset) => asset.status.name === AssetStatusName.decommissioned)?.length +
          associationByDevice?.devices?.flatMap((device) => device.assets)?.filter((asset) => asset.status.name === AssetStatusName.decommissioned)?.length
      : 0;
  });

  isDrawerMode: Signal<boolean> = computed(() => this.displayMode() === DisplayMode.DRAWER);
  isPageMode: Signal<boolean> = computed(() => this.displayMode() === DisplayMode.PAGE);

  loadAssociations = effect(() => {
    const siteId = this.siteId();
    this.selectedSite();
    if (siteId) {
      this.associationAssetDeviceFacade.loadAssociations(siteId);
    }
  });

  maxAssetGroupsReached = computed(() => {
    const assetGroupNumber = this.associationByAsset()?.assetGroups?.length;
    return assetGroupNumber >= 5;
  });

  viewByAssetAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.VIEW_BY_ASSET,
    text: signal('IOT_DICTIONARY.VIEW_BY_ASSET')
  });

  viewByDeviceAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.VIEW_BY_DEVICE,
    text: signal('IOT_DICTIONARY.VIEW_BY_DEVICE')
  });

  sectionHeaderTitle: Signal<string> = computed(() => {
    const preferences = this.preferences();
    return preferences.siteAssociationDisplay === 'assets' ? 'IOT_DICTIONARY.VIEW_BY_ASSET' : 'IOT_DICTIONARY.VIEW_BY_DEVICE';
  });

  sectionHeaderTitleActions: Signal<SectionHeaderAction[]> = computed(() => {
    const preferences = this.preferences();
    return preferences.siteAssociationDisplay === 'assets' ? [this.viewByDeviceAction] : [this.viewByAssetAction];
  });

  actionsType: Signal<SectionHeaderActionsType> = computed(() =>
    this.displayMode() === DisplayMode.PAGE ? SectionHeaderActionsType.MENU : SectionHeaderActionsType.NONE
  );

  onDispatchSectionHeaderEvent(event: SectionHeaderEvent): void {
    if (event.type === this.viewByAssetAction.action) {
      this.updateAssociationPreferences('assets');
    } else if (event.type === this.viewByDeviceAction.action) {
      this.updateAssociationPreferences('devices');
    } else {
      this.dispatchEvent.emit(event);
    }
  }

  onDispatchCardEvent(event: OverviewCardEvent): void {
    if (event.type === OverviewCardActions.NAVIGATE_TO_ASSET_GROUP) {
      this.router.navigate(['asset-groups', event.data]);
    } else if (event.type === OverviewCardActions.NAVIGATE_TO_ASSET) {
      // TODO: USE ROUTER TO NAVIGATE WHEN ASSET OVERVIEW IS REFACTORED
      this.associationAssetDeviceSettingsService.selectAsset(this.siteId(), event.data as string);
    } else if (event.type === OverviewCardActions.NAVIGATE_TO_DEVICE) {
      // TODO: USE ROUTER TO NAVIGATE WHEN DEVICE OVERVIEW IS REFACTORED
      this.associationAssetDeviceSettingsService.selectDevice(this.siteId(), event.data as string);
    }
  }

  updateDisplayDecommissionedAssetsPreferences(): void {
    this.preferencesFacade.updateUserPreferences({ ...this.preferences(), displayDecommissionedAssets: this.displayDecommissionedAssets() });
  }

  close(): void {
    this.dispatchEvent.emit({ type: IotToolbarDispatchActionType.CLOSE });
  }

  private updateAssociationPreferences(display: string): void {
    this.preferencesFacade.updateUserPreferences({ ...this.preferences(), siteAssociationDisplay: display });
  }
}
