<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ popupTitle }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="exportForm">
      <mat-tab-group color="accent" mat-align-tabs="center">
        <mat-tab [label]="'IOT_DICTIONARY.PARAMETERS' | translate | uppercase">
          <div class="tab-container parameters-tab" fxLayout="row">
            <div class="container left" fxFlex="50">
              <mat-slide-toggle color="accent" formControlName="status" labelPosition="after"
                >{{ 'IOT_DICTIONARY.ACTIVE_CAPITALIZE' | translate }}
              </mat-slide-toggle>
              <div class="form-section grey-bg">
                <mat-form-field color="accent">
                  <mat-label>{{ 'IOT_DICTIONARY.NAME_CAPITALIZE' | translate }}</mat-label>
                  <input #nameInput formControlName="name" matInput maxlength="50" pattern="\S.*" placeholder="" required />
                  @if (name.invalid && (name.errors?.maxlength || name.errors?.pattern)) {
                    <mat-error>{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
                  }
                  <mat-hint align="end">{{ nameInput.value?.length || 0 }}/50</mat-hint>
                </mat-form-field>

                <mat-form-field color="accent">
                  <mat-label>{{ 'IOT_DICTIONARY.DESCRIPTION' | translate }}</mat-label>
                  <input #descriptionInput formControlName="description" matInput maxlength="100" placeholder="" />
                  <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/100</mat-hint>
                </mat-form-field>

                <div [class.four]="frequency.value === FREQUENCY.EVERY_MONTH || frequency.value === FREQUENCY.EVERY_WEEK" class="periodicity-selector">
                  <mat-form-field color="accent">
                    <mat-label>{{ 'IOT_DICTIONARY.PERIODICITY' | translate }}</mat-label>
                    <mat-select formControlName="frequency" required>
                      @for (frequency of FREQUENCY | keyvalue; track frequency.key) {
                        <mat-option [value]="frequency.value">{{ 'SITES.EXPORT_SPREADSHEET_FORM.FREQUENCIES.' + frequency.key | translate }} </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  @if (frequency.value === FREQUENCY.EVERY_MONTH) {
                    <mat-form-field color="accent">
                      <mat-label>{{ 'IOT_DICTIONARY.DAY' | translate }}</mat-label>
                      <mat-select formControlName="dayOfMonthControl" required>
                        @for (day of daysOfMonthList; track day) {
                          <mat-option [value]="day">{{ day }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  } @else if (frequency.value === FREQUENCY.EVERY_WEEK) {
                    <mat-form-field color="accent">
                      <mat-label>{{ 'IOT_DICTIONARY.DAY' | translate }}</mat-label>
                      <mat-select formControlName="dayOfWeekControl" required>
                        @for (day of daysOfWeekList; track day; let index = $index) {
                          <mat-option [value]="index + ''">{{ day }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }
                  @if (frequency.value === FREQUENCY.EVERY_DAY || frequency.value === FREQUENCY.EVERY_WEEK || frequency.value === FREQUENCY.EVERY_MONTH) {
                    <mat-form-field color="accent">
                      <mat-label>{{ 'IOT_DICTIONARY.HOUR' | translate }}</mat-label>
                      <mat-select formControlName="hourOfDayControl" required>
                        @for (hour of hoursOfDayList; track hour) {
                          <mat-option [value]="hour">{{ hour }}:00</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }

                  <mat-form-field>
                    <mat-label>{{ 'IOT_DICTIONARY.MARGIN' | translate }}</mat-label>
                    <mat-select formControlName="margin">
                      @for (value of marginValues; track value) {
                        <mat-option [value]="value">{{ value }}%</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-section">
                <h4>{{ 'SITES.EXPORT_SPREADSHEET_FORM.SELECT_VARIABLES' | translate }}:</h4>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                  <iot-platform-feature-graphs-asset-variable-selector
                    (initialAssetVariablesState)="getAssetVariablesInitialState($event)"
                    [allowMultiSelection]="true"
                    [assets]="data.assets"
                    [control]="assetVariables"
                    [events]="eventsSubject$ | async"
                    [site]="data.site"
                    [variablesLoading]="variablesLoading"
                  />
                </div>
              </div>
              <div class="form-section">
                <mat-radio-group class="sms-notif" formControlName="model">
                  <h4 class="inline">{{ 'SITES.EXPORT_SPREADSHEET_FORM.EXCEL_MODEL' | translate }} :</h4>
                  @for (model of MODEL | keyvalue; track model.key) {
                    <mat-radio-button [value]="model.value">{{ 'SITES.EXPORT_SPREADSHEET_FORM.' + model.key | translate }} </mat-radio-button>
                  }
                </mat-radio-group>
              </div>
              <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
            </div>
            <div class="container right variables-container" fxFlex="50">
              @if (downloadPreviewEnabled) {
                <span (click)="downloadPreview()">
                  <mat-icon [class.disabled]="(exportForm.statusChanges | async) === 'INVALID'" class="download-icon">file_download</mat-icon>
                </span>
              }

              <iot-platform-feature-graphs-asset-variable-selector-list
                (removeAssetVariable)="removeAssetVariable()"
                [canDeleteVariables]="true"
                [control]="assetVariables"
                [variablesLoading]="variablesLoading()"
                [withCounter]="true"
                [withDragAndDrop]="true"
                emptyMessage="SITES.EXPORT_SPREADSHEET_FORM.NO_VARIABLE_SELECTED"
                nbSelectedLabel="SITES.EXPORT_SPREADSHEET_FORM.SELECTED_VARIABLES_COUNT"
              />
            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="'SITES.EXPORT_SPREADSHEET_FORM.RELATED_CONTACTS' | translate: { total: contactCheckedTotal, max: data.contacts.length } | uppercase">
          <div class="tab-container related-contacts-tab p-20" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <span class="capitalize-first-letter related-contacts-label" fxFlex="30%">{{ 'IOT_DICTIONARY.NAME' | translate }}</span>
              <span class="capitalize-first-letter related-contacts-label">{{ 'SITES.EXPORT_SPREADSHEET_FORM.NOTIFICATION_SUPPORTS' | translate }}</span>
            </div>
            @for (contact of data.contacts; track contact.id) {
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-checkbox [formControlName]="contact.id" fxFlex="30%">{{ contact.firstname }}&nbsp;{{ contact.lastname }} </mat-checkbox>
                <mat-icon
                  [matTooltipClass]="!contact.email ? 'warning-tooltip' : 'regular-tooltip capitalize-first-letter'"
                  [matTooltip]="(!contact.email ? 'SITES.NOTIFICATIONS_TAB.INVALID_EMAIL' : 'IOT_DICTIONARY.EMAIL') | translate"
                  [ngClass]="{ warning: !contact.email }"
                  >mail
                </mat-icon>
              </div>
            } @empty {
              <span>{{ 'SITES.EXPORT_SPREADSHEET_FORM.NO_CONTACT' | translate }}</span>
            }
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button
      (click)="save()"
      [disabled]="!formStateChanged() || !validAssetVariables() || !exportForm.valid"
      class="save-btn button-regular"
      color="accent"
      mat-raised-button
    >
      {{ actionButtonLabel }}
    </button>
  </mat-card-actions>
</mat-card>
