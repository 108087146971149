import { Sort } from '@angular/material/sort';
import { FavoriteView, Filter, gridSortModel, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { AssociationByAsset, AssociationByDevice, CommonBulkOperationType, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createActionGroup, props } from '@ngrx/store';
import { I4BActionHelper } from '../../../../helpers/ngrx';

class SitesActionHelper extends I4BActionHelper<Site> {}
const sitesActionHelper = new SitesActionHelper();

export const SitesActions = createActionGroup({
  source: 'Sites',
  events: {
    ...sitesActionHelper.getDefaultEvents(),
    'Load Assets By Site Id': props<{ request: PlatformRequest }>(),
    'Load Assets By Site Id Success': props<{ response: PlatformResponse }>(),
    'Load Assets By Site Id Failure': props<{ error: any }>(),
    'Load Devices By Site Id': props<{ request: PlatformRequest }>(),
    'Load Devices By Site Id Success': props<{ response: PlatformResponse }>(),
    'Load Devices By Site Id Failure': props<{ error: any }>(),
    'Load Tags By Site Id': props<{ siteId: string }>(),
    'Load Tags By Site Id Success': props<{ tags: TagCategory[] }>(),
    'Load Tags By Site Id Failure': props<{ error: any }>(),

    'Open Site Detail': props<{ siteId: string }>(),
    'Open Site Detail Success': props<{ site: Site }>(),
    'Open Site Detail Failure': props<{ error: any }>(),

    'Open Asset Detail': props<{ siteId: string }>(),
    'Open Asset Detail Success': props<{ site: Site }>(),
    'Open Asset Detail Failure': props<{ error: any }>(),

    'Open Device Detail': props<{ siteId: string }>(),
    'Open Device Detail Success': props<{ site: Site }>(),
    'Open Device Detail Failure': props<{ error: any }>(),

    'Update Tags By Site Id': props<{ siteId: string; tags: TagCategory[] }>(),
    'Update Tags By Site Id Success': props<{ tags: TagCategory[] }>(),
    'Update Tags By Site Id Failure': props<{ error: any }>(),

    'Load Sites Settings': props<{ settingName: string }>(),
    'Load MV Sites Settings Success': props<{ settings: any }>(),
    'Load MV Sites Settings Failure': props<{ error: any }>(),

    'Save Sites Settings': props<{ name: string; values: any }>(),
    'Save MV Sites Settings Success': props<{ settings: any }>(),
    'Save MV Sites Settings Failure': props<{ error: any }>(),

    'Bulk Operation On Tag': props<{
      bulkOperationType: CommonBulkOperationType;
      tagLabelId: string;
      sitesIds: string[];
    }>(),
    'Bulk Add Tag - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Add Tag Failure': props<{ error: any }>(),

    'Bulk Remove Tag - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Remove Tag Failure': props<{ error: any }>(),

    'Bulk Replace Tag - ': props<{ response: I4BBulkOperationApiResponse }>(),
    'Bulk Replace Tag Failure': props<{ error: any }>(),

    'Save Current Favorite View': props<{ currentFavoriteView: FavoriteView }>(),
    'Set Filters': props<{ filters: Filter[] }>(),
    'Save Initial sort': props<{ initialSort: gridSortModel[] | Sort }>()
  }
});
