import { Component, input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-tab-header',
  standalone: true,
  template: `
    <ng-template>
      {{ textLabel() }}
      <ng-content />
    </ng-template>
  `
})
export class TabHeaderComponent {
  textLabel = input<string>('');
  tooltip = input<string>('');
  cssClassName = input<string>('');

  @ViewChild(TemplateRef) public template!: TemplateRef<any>;
}
