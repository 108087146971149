/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Type } from '@angular/core';
import { AbstractTopicsService } from './services/abstract-topics.service';

export interface TopicsSettings {
  eventDetailPopupComponent: Type<any>;
  topicsService: Type<AbstractTopicsService>;
}

export const TOPICS_SETTINGS = new InjectionToken<TopicsSettings>('TOPICS_SETTINGS');

export function provideTopicsSettings(settings: TopicsSettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: TOPICS_SETTINGS,
      useValue: {
        eventDetailPopupComponent: settings?.eventDetailPopupComponent
      }
    },
    { provide: AbstractTopicsService, useClass: settings?.topicsService }
  ]);
}
