import { Component, input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsService } from '@iot-platform/core';
import { ContactFormComponent, PopupComponent } from '@iot-platform/iot-platform-ui';
import { Contact } from '@iot-platform/models/common';
import { Asset, ContactNotification, ExportSpreadsheet, Site } from '@iot-platform/models/i4b';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ContactsFacade } from '../../+state/facades/contacts.facade';
import { ExportSpreadsheetsFacade } from '../../+state/facades/export-spreadsheets.facade';
import { NotificationsFacade } from '../../+state/facades/notifications.facade';
import { ContactAssociatedNotificationsExportsPopupComponent } from '../contact-associated-notifications-exports-popup/contact-associated-notifications-exports-popup.component';
import { ContactManageNotificationsFormComponent } from '../contact-manage-notifications-form/contact-manage-notifications-form.component';
import { ExportSpreadsheetCreationFormComponent } from '../export-spreadsheet-creation-form/export-spreadsheet-creation-form.component';
import { NotificationCreationFormComponent } from '../notification-creation-form/notification-creation-form.component';

@Component({
  selector: 'iot4bos-ui-site-contacts-tab',
  templateUrl: './site-contacts-tab.component.html',
  styleUrls: ['./site-contacts-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SiteContactsTabComponent implements OnInit, OnDestroy {
  site = input(null, {
    transform: (value: Site) => ({ ...value, contacts: value?.contacts?.sort((a, b) => a.lastname.localeCompare(b.lastname)) })
  });
  assets = input<Asset[]>([]);
  canCreateContact = input<boolean>(false);
  canUpdateContact = input<boolean>(false);
  canDeleteContact = input<boolean>(false);

  contacts$: Observable<Contact[]> = this.contactsFacade.contacts$;
  contactsLoading$: Observable<boolean> = this.contactsFacade.contactsLoading$;
  totalContacts$: Observable<number> = this.contactsFacade.totalContacts$;
  maxContacts = 20;
  contacts: Contact[] = [];

  // Notifications tab
  maxNotifications = 10;
  notificationsLoading$ = this.notificationsFacade.notificationsLoading$;
  totalNotifications$ = this.notificationsFacade.totalNotifications$;
  notifications$ = this.notificationsFacade.notifications$;
  notifications: ContactNotification[] = [];
  selectedNotification$ = this.notificationsFacade.selectedNotification$;

  // Export spreadsheets tab
  maxExportsSpreadsheets = 10;
  exportsSpreadsheetsLoading$ = this.exportSpreadsheetsFacade.exportSpreadsheetsLoading$;
  totalExportsSpreadsheets$ = this.exportSpreadsheetsFacade.totalExportSpreadsheets$;
  exportSpreadsheets$ = this.exportSpreadsheetsFacade.exportSpreadsheets$;
  exportSpreadsheets: ExportSpreadsheet[] = [];
  selectedExportSpreadsheets$ = this.exportSpreadsheetsFacade.selectedExportSpreadsheet$;

  destroyed$ = new Subject<void>();

  analytic: AnalyticsService = new AnalyticsService('site_info_page');

  constructor(
    private readonly dialog: MatDialog,
    private readonly contactsFacade: ContactsFacade,
    private readonly notificationsFacade: NotificationsFacade,
    private readonly exportSpreadsheetsFacade: ExportSpreadsheetsFacade
  ) {}

  ngOnInit() {
    this.contacts$.pipe(takeUntil(this.destroyed$)).subscribe((contacts) => (this.contacts = contacts));
    this.notifications$.pipe(takeUntil(this.destroyed$)).subscribe((notifs) => (this.notifications = notifs));
    this.exportSpreadsheets$.pipe(takeUntil(this.destroyed$)).subscribe((exportSpreadsheet) => (this.exportSpreadsheets = exportSpreadsheet));
  }

  trackByContactId(i: number, contact: Contact) {
    return contact.id;
  }

  handleContact(type: string, contact?: Contact) {
    switch (type) {
      case 'create': {
        this.createContact();
        break;
      }

      case 'update': {
        this.updateContact(contact);
        break;
      }

      case 'delete': {
        this.deleteContact(contact);
        break;
      }
    }
  }

  configureNotification(contact: Contact) {
    this.analytic.log('tab_contacts_actions', 'open_manage_notifications');
    this.dialog
      .open(ContactManageNotificationsFormComponent, {
        width: '1100px',
        disableClose: true,
        data: { notifications: this.notifications, contact }
      })
      .afterClosed()
      .subscribe((response: { futureAssociatedElementIds: string[]; removedAssociatedElementIds: string[] }) => {
        if (response) {
          this.analytic.log('tab_contacts_actions', 'manage_notifications');
          this.notificationsFacade.configureContactNotifications(contact, response.futureAssociatedElementIds, response.removedAssociatedElementIds);
        }
      });
  }

  configureSpreadSheetExport(contact: Contact): void {
    this.analytic.log('tab_contacts_actions', 'open_manage_export_spreadsheet');
    this.dialog
      .open(ContactManageNotificationsFormComponent, {
        width: '1100px',
        disableClose: true,
        data: { exportSpreadsheets: this.exportSpreadsheets, contact }
      })
      .afterClosed()
      .subscribe((response: { futureAssociatedElementIds: string[]; removedAssociatedElementIds: string[] }) => {
        if (response) {
          this.analytic.log('tab_contacts_actions', 'manage_export_spreadsheet');
          const exportsToUpdate: ExportSpreadsheet[] = this.exportSpreadsheets.reduce((acc: ExportSpreadsheet[], eS: ExportSpreadsheet) => {
            if (response.futureAssociatedElementIds.find((exportId) => exportId === eS.id)) {
              acc.push({ ...eS, contactIds: [...eS.contactIds, contact.id as string] });
            } else if (response.removedAssociatedElementIds.find((exportId) => exportId === eS.id)) {
              const index = eS.contactIds.findIndex((id) => id === contact.id);
              const contactIds = [...eS.contactIds];
              contactIds.splice(index, 1);
              acc.push({ ...eS, contactIds });
            }
            return acc;
          }, []);

          this.exportSpreadsheetsFacade.configureContactExportSpreadsheets(exportsToUpdate, this.site()?.id as string);
        }
      });
  }

  openAssociatedExportSpreadsheetsPopup(contact: Contact): void {
    this.analytic.log('tab_contacts_actions', 'open_associated_export_spreadsheets_popup');
    this.dialog.open(ContactAssociatedNotificationsExportsPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: { contact, displayExportSpreadsheets: true }
    });
  }

  openAssociatedNotificationsPopup(contact: Contact): void {
    this.analytic.log('tab_contacts_actions', 'open_associated_notifications_popup');
    this.dialog.open(ContactAssociatedNotificationsExportsPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: { contact, displayNotifications: true }
    });
  }

  addNotification(): void {
    this.analytic.log('tab_contacts_actions', 'open_add_notification');
    this.dialog
      .open(NotificationCreationFormComponent, {
        width: '700px',
        disableClose: true,
        data: { site: this.site(), contacts: this.contacts, notification: { site: { id: this.site()?.id } } }
      })
      .afterClosed()
      .subscribe((response: { notification: ContactNotification; contactsToSub: string[] }) => {
        if (response) {
          this.analytic.log('tab_contacts_actions', 'add_notification');
          this.notificationsFacade.addNotification(response.notification, this.site()?.id as string, response.contactsToSub);
        }
      });
  }

  updateNotification(notification: ContactNotification): void {
    this.analytic.log('tab_contacts_actions', 'open_edit_notification');
    this.dialog
      .open(NotificationCreationFormComponent, {
        width: '700px',
        disableClose: true,
        data: { site: this.site(), contacts: this.contacts, notification }
      })
      .afterClosed()
      .subscribe((response: { notification: ContactNotification; contactsToSub: string[]; contactsToUnsub: string[] }) => {
        if (response) {
          this.analytic.log('tab_contacts_actions', 'update_notification');
          this.notificationsFacade.updateNotification(response.notification, this.site()?.id as string, response.contactsToSub, response.contactsToUnsub);
        }
      });
  }

  deleteNotification(notification: ContactNotification): void {
    this.analytic.log('tab_contacts_actions', 'open_delete_notification');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: `${notification.name}` }
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.analytic.log('tab_contacts_actions', 'delete_notification');
          this.notificationsFacade.deleteNotification(notification, this.site()?.id as string);
        }
      });
  }

  selectNotification(notification: ContactNotification): void {
    this.notificationsFacade.selectNotification(notification);
  }

  addExportSpreadsheet(): void {
    this.analytic.log('tab_contacts_actions', 'open_add_export_spreadsheet');
    this.dialog
      .open(ExportSpreadsheetCreationFormComponent, {
        width: '1000px',
        disableClose: true,
        data: { site: this.site(), contacts: this.contacts, assets: this.assets() }
      })
      .afterClosed()
      .subscribe((exportSpreadsheetToAdd: ExportSpreadsheet) => {
        if (exportSpreadsheetToAdd) {
          this.analytic.log('tab_contacts_actions', 'add_export_spreadsheet');
          this.exportSpreadsheetsFacade.addExportSpreadsheet(exportSpreadsheetToAdd, this.site()?.id as string);
        }
      });
  }

  updateExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet): void {
    this.analytic.log('tab_contacts_actions', 'open_edit_export_spreadsheet');
    this.dialog
      .open(ExportSpreadsheetCreationFormComponent, {
        width: '1000px',
        disableClose: true,
        data: { site: this.site(), contacts: this.contacts, exportSpreadsheet, assets: this.assets() }
      })
      .afterClosed()
      .subscribe((exportSpreadsheetToUpdate: ExportSpreadsheet) => {
        if (exportSpreadsheetToUpdate) {
          this.analytic.log('tab_contacts_actions', 'udpate_export_spreadsheet');
          this.exportSpreadsheetsFacade.updateExportSpreadsheet(exportSpreadsheetToUpdate, this.site()?.id as string);
        }
      });
  }

  deleteExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet): void {
    this.analytic.log('tab_contacts_actions', 'open_delete_export_spreadsheet');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: `${exportSpreadsheet.name}` }
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.analytic.log('tab_contacts_actions', 'delete_export_spreadsheet');
          this.exportSpreadsheetsFacade.deleteExportSpreadsheet(exportSpreadsheet, this.site()?.id as string);
        }
      });
  }

  selectExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet): void {
    this.exportSpreadsheetsFacade.selectExportSpreadsheet(exportSpreadsheet);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private createContact() {
    this.analytic.log('tab_contacts_actions', 'open_add_contact');
    const dialogRef = this.dialog.open(ContactFormComponent, {
      width: '600px',
      disableClose: true,
      data: {
        contact: {
          site: {
            id: this.site()?.id
          }
        },
        site: this.site()
      }
    });

    dialogRef.afterClosed().subscribe((createdContact: Contact) => {
      if (createdContact) {
        this.analytic.log('tab_contacts_actions', 'add_contact');
        this.contactsFacade.addContact(createdContact);
      }
    });
  }

  private updateContact(contact: Contact) {
    this.analytic.log('tab_contacts_actions', 'open_edit_contact');
    const dialogRef = this.dialog.open(ContactFormComponent, {
      width: '600px',
      disableClose: true,
      data: {
        contact: {
          ...contact,
          site: {
            id: this.site()?.id
          }
        },
        site: this.site()
      }
    });

    dialogRef.afterClosed().subscribe((editedContact: Contact) => {
      if (editedContact) {
        this.analytic.log('tab_contacts_actions', 'update_contact');
        this.contactsFacade.updateContact(editedContact);
      }
    });
  }

  private deleteContact(contact: Contact) {
    this.analytic.log('tab_contacts_actions', 'open_delete_contact');
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: `${contact.firstname} ${contact.lastname}` }
    });

    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.analytic.log('tab_contacts_actions', 'delete_contact');
        this.contactsFacade.deleteContact(contact);
      }
    });
  }
}
