import { I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BVariableColumnOptions } from '../options/i4b-variable-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

interface DeviceCommunicationGroupColumnConfiguration extends I4BColumnConfiguration {
  rowGroupParams: Partial<{
    support: Partial<I4BColumnRowGroupConfiguration>;
    endpoint: Partial<I4BColumnRowGroupConfiguration>;
  }>;
}

export class DeviceCommunicationGroupColumn extends I4BBasicColumn {
  columnId = 'e1f820e6-9f86-11ec-9da8-0023a40210d0-device-communication-group';
  className = DeviceCommunicationGroupColumn;

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<DeviceCommunicationGroupColumnConfiguration>,
    customOptions?: Partial<I4BVariableColumnOptions>
  ) {
    super(
      {
        ...customHeader,
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'COMMUNICATION FULL',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig.id,
        concept: I4bCellConcept.DEVICES,
        isDefault: false,
        marryChildren: true,
        rowGroupParams: customConfig?.rowGroupParams,
        children: [
          new I4BBasicColumn(
            { displayName: 'SUPPORT' },
            {
              id: 'communication.support',
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_SUPPORT',
              cell: { type: I4BCellType.BASIC },
              isDefault: true,
              sortProperty: 'communication.support',
              concept: I4bCellConcept.DEVICES,
              width: 120,
              rowGroupParams: customConfig?.rowGroupParams?.support
            },
            {
              order: 900
            }
          ),
          new I4BBasicColumn(
            { displayName: 'ENDPOINT' },
            {
              id: 'communication.endpoint',
              translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_ENDPOINT',
              cell: { type: I4BCellType.BASIC },
              isDefault: false,
              sortProperty: 'communication.endpoint',
              concept: I4bCellConcept.DEVICES,
              width: 125,
              rowGroupParams: customConfig?.rowGroupParams?.endpoint
            },
            {
              order: 901
            }
          )
        ]
      },
      { ...customOptions, customId: customConfig.id }
    );
  }
}
