<mat-card class="dialog-card">
  <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="40px">
    <div fxLayout="column" fxLayoutGap="20px">
      <span class="contact-name fw-600">{{ data.contact.firstname }}&nbsp;{{ data.contact.lastname }}</span>
      <div class="contact-info" fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>{{ data.contact.function | infoDisplay }}</span>
        <span class="contact-info-separator"></span>
        <mat-icon>phone</mat-icon>
        <span>{{ data.contact.phone | infoDisplay }}</span>
        <span class="contact-info-separator"></span>
        <mat-icon>email</mat-icon>
        <span>{{ data.contact.email | infoDisplay }}</span>
      </div>
    </div>
    @if (data.displayNotifications) {
      <iot4bos-ui-site-notifications
        (selectNotification)="selectNotification($event)"
        [currentContact]="data.contact"
        [notifications]="data.contact.associatedNotifications"
        [selectedNotification]="selectedNotification"
      ></iot4bos-ui-site-notifications>
    }
    @if (data.displayExportSpreadsheets) {
      <iot4bos-ui-site-export-spreadsheets
        (selectExportSpreadsheet)="selectExportSpreadsheet($event)"
        [currentContact]="data.contact"
        [exportSpreadsheets]="data.contact.associatedExportSpreadsheets"
        [selectedExportSpreadsheet]="selectedExportSpreadsheet"
      ></iot4bos-ui-site-export-spreadsheets>
    }
  </mat-card-content>
</mat-card>
