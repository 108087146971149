import { IHeaderParams } from '@ag-grid-community/core';
import { NgClass } from '@angular/common';
import { Component, effect, input, OnDestroy, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'grid-engine-header-shell',
  templateUrl: './header-shell.component.html',
  styleUrls: ['./header-shell.component.scss'],
  imports: [FlexLayoutModule, MatIcon, NgClass]
})
export class HeaderShellComponent implements OnDestroy {
  params = input<IHeaderParams & { skipTextTransform?: boolean }>();

  sorted: WritableSignal<string> = signal('');
  ascSort: WritableSignal<string> = signal('');
  descSort: WritableSignal<string> = signal('');
  noSort: WritableSignal<string> = signal('');

  displayMenu: WritableSignal<boolean> = signal(false);
  displayFilterIcon: WritableSignal<boolean> = signal(false);

  onSortChangedEventHandler = this.onSortChanged.bind(this);
  isFilterActiveEventHandler = this.isFilterActive.bind(this);

  constructor() {
    effect(() => {
      const params = this.params();
      if (params?.enableSorting) {
        params?.column?.removeEventListener?.('sortChanged', this.onSortChangedEventHandler);
        params?.column?.addEventListener?.('sortChanged', this.onSortChangedEventHandler);
        this.onSortChanged();
      }
      if (params?.column) {
        params?.column?.removeEventListener?.('filterChanged', this.isFilterActiveEventHandler);
        params?.column?.addEventListener?.('filterChanged', this.isFilterActiveEventHandler);
      }
      this.displayMenu.set(!!params?.enableMenu);
      this.isFilterActive();
    });
  }

  ngOnDestroy() {
    const params = this.params();
    params?.column?.removeEventListener?.('sortChanged', this.onSortChangedEventHandler);
    params?.column?.removeEventListener?.('filterChanged', this.isFilterActiveEventHandler);
  }

  onSortRequested(order, event): void {
    const params = this.params();
    params.setSort(order, event.shiftKey);
    if (params.api) {
      params.api.refreshHeader();
    }
  }

  onSortChanged(): void {
    this.ascSort.set('inactive');
    this.descSort.set('inactive');
    this.noSort.set('inactive');
    const params = this.params();
    if (params.column?.getSort?.() === 'asc') {
      this.sorted.set('asc');
      this.ascSort.set('active');
    } else if (params.column?.getSort?.() === 'desc') {
      this.sorted.set('desc');
      this.descSort.set('active');
    } else {
      this.sorted.set('');
      this.noSort.set('active');
    }
  }

  onDisplayMenuClick(): void {
    const params = this.params();
    params.showColumnMenu(params.eGridHeader);
  }

  isFilterActive(): void {
    const params = this.params();
    this.displayFilterIcon.set(!!params?.column?.isFilterActive?.());
  }
}
