@if (group) {
  <div class="group" [formGroup]="group" fxLayout="row wrap">
    @for (field of fields; track $index) {
      @if (field?.visible) {
        @if (field?.visible()) {
          <ng-container [ngTemplateOutlet]='fieldTmpl'
                        [ngTemplateOutletContext]='{ group: group, field: field }' />
        }
      } @else {
        <ng-container [ngTemplateOutlet]='fieldTmpl'
                      [ngTemplateOutletContext]='{ group: group, field: field }' />
      }
    }
  </div>
}

<ng-template #fieldTmpl let-field='field' let-group='group'>
  @if (group?.get(field?.name?.())) {
    <iot-platform-ui-form-field
      [class]="field?.cssClassName?.()"
      [control]="group?.get(field.name())"
      [field]="field"
      [fxFlex.lt-md]="field.type === FormControlType.CHECKBOX_GROUP  ? '100%' :  field?.fxFlex?.() ? '0 1 calc(50% - 10px)' :  'calc(50% - 10px)'"
      [fxFlex]="field?.fxFlex?.() ? '0 1 calc('+field?.fxFlex?.()+' - 10px)' :  '1 1 0'"
      [ngStyle]="{ width: field?.fxFlex?.() ? 'calc('+field?.fxFlex?.()+' - 10px)' :  '100%'}"
      [ngStyle.lt-md]="{ width: field?.fxFlex?.() ? 'calc(50% - 10px)': 'calc(50% - 10px)'}"
      fxFlex.lt-sm="100%" style="margin-right: 10px; place-content: center;" />
  }
</ng-template>
