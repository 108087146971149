<mat-card class="drawer-card log-details" data-cy="log-details" fxFlex="32rem" fxFlex.lt-sm="100%">
  <mat-card-header class="drawer-card__header drawer-card__header--sticky" fxLayout="row"
                   fxLayoutAlign="space-between center" fxLayoutGap="10px">

    @if (!loading() && entity(); as entity) {
      <mat-card-title class="drawer-card__header_title" data-cy="log-details-title" fxFlexAlign="flex-start">
        <div
          [ngClass]="{ 'log-details--selected-log': true }"
          class="log-details__log-summary"
          fxLayout="row wrap"
          fxLayoutGap="5px"
          fxLayoutAlign="start center"
        >
          <span
            [ngClass]="{ 'log-details__log-summary-system-modification': !entity?.context?.userId }">{{
              !!entity?.context?.userId ? (entity.context.userId | userName) : ('AUDIT_TRAIL.HISTORY_LOG_POPUP.SYSTEM' | translate)
            }}</span>

          <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.CONCEPT_EVENTS_LIST.' + entity?.conceptEvent?.toUpperCase() | translate }}</span>

          <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE_CONCEPTS.' + entity?.concept?.toUpperCase() | translate }}</span>
          @if (!!entity?.updatedAttributes?.length) {
            <span
              [matTooltip]="entity?.updatedAttributes?.join(', ')"
              matTooltipClass="regular-tooltip"
              class="log-details__log-summary-attributes"
              fxFlex
            >"{{ entity?.updatedAttributes?.join('", "') }}"</span
            >
          }
          <div>{{ entity?.datetime | dateFormat }}</div>
        </div>

      </mat-card-title>
    }
    <button
      (click)="toggleDrawerDetails(false)"
      [matTooltip]="'IOT_DICTIONARY.CLOSE' | translate"
      class="regular-round-button drawer-card__header_close-btn light-button"
      color="primary"
      data-cy="log-details-close-button"
      mat-mini-fab
      matTooltipClass="regular-tooltip"

    >
      <mat-icon>close</mat-icon>
    </button>
    @if (!!settings?.exportLogDetails) {
      <button
        (click)="export()"
        [matTooltip]="'AUDIT_TRAIL.HISTORY_LOG_POPUP.EXPORT' | translate"
        matTooltipClass="regular-tooltip"
        class="regular-round-button drawer-card__header_close-btn light-button"
        color="primary"
        data-cy="log-details-export-button"
        mat-mini-fab
      >
        <mat-icon>get_app</mat-icon>
      </button>
    }
  </mat-card-header>
  <mat-card-content class="drawer-card__content log-details__card-content p-4">

    <section
      class="log-details__main-section log-details__detail-section"
      fxFlex
    >
      @if (loading()) {
        <iot-platform-ui-card-loader class="log-details__loader-container" />
      } @else {
        @if (entity(); as entity) {
          <div class="full-width" fxLayout="column" fxLayoutGap="10px">
            @for (updatedAttribute of entity.updatedAttributes; track updatedAttribute) {
              <div fxLayout="row" fxLayout.lt-sm="column">
                <div class="log-details__detail-section-old-image" fxFlex="50%" fxFlex.lt-sm="100%">
                  <!-- @formatter:off -->
                  <pre>{{ updatedAttribute }}: {{ (getParsedAttribute(entity.oldImage, updatedAttribute) | json) ?? 'null' }}</pre>
                </div>
                <div class="log-details__detail-section-new-image" fxFlex="50%" fxFlex.lt-sm="100%">
                  <!-- @formatter:off -->
                  <pre>{{ updatedAttribute }}: {{ (getParsedAttribute(entity.newImage, updatedAttribute) | json) ?? 'null' }}</pre>
                </div>
              </div>
            }

            @if (!entity.oldImage && entity.newImage) {
              <div>
                <div class="log-details__detail-section-new-image" fxFlex="50%" fxFlex.lt-sm="100%">
                  <pre> {{ entity.newImage | json }} </pre>
                </div>
              </div>
            }

            @if (!entity.newImage && entity.oldImage) {
              <div>
                <div class="log-details__detail-section-old-image" fxFlex="50%" fxFlex.lt-sm="100%">
                  <pre> {{ entity.oldImage | json }} </pre>
                </div>
              </div>
            }
          </div>
        } @else {
          <div fxFlex fxLayoutAlign="center center" class="log-details__select-log">
            <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.SELECTED_LOG_PLACEHOLDER' | translate }}</span>
          </div>
          }
      }
    </section>

  </mat-card-content>
</mat-card>
