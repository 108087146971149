import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { AssociationByAsset, AssociationByDevice } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssociationAssetDeviceService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  getAssociationByAsset(siteId: string): Observable<AssociationByAsset> {
    return this.httpClient.get<AssociationByAsset>(`${this.environment.api.url}${this.environment.api.endpoints?.sites}/${siteId}/associations-by-asset`);
  }

  getAssociationByDevice(siteId: string): Observable<AssociationByDevice> {
    return this.httpClient.get<AssociationByDevice>(`${this.environment.api.url}${this.environment.api.endpoints?.sites}/${siteId}/associations-by-device`);
  }
}
