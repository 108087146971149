<!--<div fxLayout="column">-->
<iot-platform-ui-toolbar-v2
  (dispatchToolbarEvent)="onToolbarEvent($event)"
  [breadCrumbConfiguration]="{ entityName: '', icon: 'person' }"
  [buttonList]="toolbarButtonsList"
  [name]="user()?.id | userName"
  [size]="toolbarSize"
/>

<mat-tab-group color="accent" mat-align-tabs="center">
  <mat-tab label="tab_information">
    <ng-template matTabLabel>
      <div fxLayout="row" fxLayoutAlign="start center">
        <span>{{ 'IOT_DICTIONARY.INFORMATION' | translate | uppercase }}</span>
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="user-profile-info-container">
        @if (user()) {
          <div class="layout__wrapper pl-12 pr-12 pt-8 pb-8 sm:p-4 max-w-4xl xl:max-w-4xl fs-12" fxLayout="column" fxLayoutGap="20px">
            <!-- ACCOUNT INFO -->
            <div fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <iot-platform-ui-detail-popup-section-header [headerTitle]="'USERS.PROFILE_INFO.ACCOUNT_INFO' | translate | uppercase" fxFlex />
              </div>

              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="6px">
                <div fxFlex="50" fxLayout="column" fxLayoutGap="6px">
                  <iot-platform-ui-detail-popup-display-property property="{{ 'IOT_DICTIONARY.ENTITIES' | translate }}" [value]="userEntities()" />
                </div>

                <div fxFlex="50" fxLayout="column" fxLayoutGap="6px">
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.CREATED_AT' | translate"
                    [value]="user()?.createdAt | dateFormat"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'IOT_DICTIONARY.UPDATED_AT' | translate"
                    [value]="user()?.updatedAt | dateFormat"
                  />
                  <iot-platform-ui-detail-popup-display-property [property]="'IOT_DICTIONARY.UPDATED_BY' | translate" [value]="user()?.updatedBy | userName" />
                </div>
              </div>
            </div>

            <!-- PERSONAL INFO -->
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <iot-platform-ui-detail-popup-section-header [headerTitle]="'USERS.PROFILE_INFO.PERSONAL_INFO' | translate | uppercase" fxFlex />

                <button
                  mat-mini-fab
                  class="light-button"
                  matTooltipClass="regular-tooltip"
                  [matTooltip]="'USERS.PROFILE_INFO.EDIT_TOOLTIP' | translate"
                  (click)="editUserProfile()"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </div>

              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="6px">
                <div fxFlex="50" fxLayout="column" fxLayoutGap="6px">
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_INFO_FORM.FIRSTNAME' | translate"
                    [value]="user()?.firstname | infoDisplay"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_INFO_FORM.LASTNAME' | translate"
                    [value]="user()?.lastname | infoDisplay"
                  />
                </div>

                <div fxFlex="50" fxLayout="column" fxLayoutGap="6px">
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_INFO_FORM.PRIMARY_EMAIL' | translate"
                    [value]="user()?.email | infoDisplay"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_INFO_FORM.SECONDARY_EMAIL' | translate"
                    [value]="user()?.secondaryEmail | infoDisplay"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_INFO_FORM.PHONE_NUMBER' | translate"
                    [value]="user()?.phoneNumber | infoDisplay"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_PREFERENCES.NOTIFICATIONS' | translate"
                    [value]="userNotifications()"
                  />
                </div>
              </div>
            </div>

            <!-- PREFERENCES -->
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                <iot-platform-ui-detail-popup-section-header [headerTitle]="'USERS.PROFILE_PREFERENCES.TITLE' | translate | uppercase" fxFlex />

                <button
                  mat-mini-fab
                  class="light-button"
                  matTooltipClass="regular-tooltip"
                  [matTooltip]="'USERS.PROFILE_PREFERENCES.EDIT_TOOLTIP' | translate"
                  (click)="editUserPreferences()"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </div>

              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="6px">
                <div fxFlex="50" fxLayout="column" fxLayoutGap="6px">
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate"
                    [value]="'USERS.PROFILE_PREFERENCES.LANGUAGES.' + user()?.preferences?.appLanguage | translate"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_PREFERENCES.DATE_FORMAT' | translate"
                    [value]="currentDate | dateFormat: user()?.preferences?.appDateFormats?.momentFullFormat ?? 'yyyy-MM-DD HH:mm:ss'"
                  />
                </div>

                <div fxFlex="50" fxLayout="column" fxLayoutGap="6px">
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_PREFERENCES.NUMBER_FORMAT' | translate"
                    [value]="'USERS.PROFILE_PREFERENCES.NUMBER_FORMATS.' + (user()?.preferences?.appNumberFormat ?? 'fr') | translate"
                  />
                  <iot-platform-ui-detail-popup-display-property
                    [property]="'USERS.PROFILE_PREFERENCES.NUMBER_OF_DECIMALS' | translate"
                    [value]="user()?.preferences?.appNumberOfDecimals ?? 3"
                  />
                </div>
              </div>
            </div>

            <!-- RELATED BUSINESS PROFILES -->
            <div fxLayout="column" fxLayoutGap="20px" style="padding-top: 15px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <iot-platform-ui-detail-popup-section-header
                  [headerTitle]="'USERS.RELATED_BUSINESS_PROFILES.TITLE' | translate: { totalBP: user()?.totalBusinessProfile } | uppercase"
                  fxFlex
                />
              </div>
              <iot-platform-users-user-related-business-profiles [currentUser]="user()" />
            </div>

            <!-- TODO: UNCOMMENT WHEN FEATURE IS NEEDED -->
            <!-- API KEYS -->
            <!-- <iot-platform-users-user-profile-api-keys
                (addApiKey)="addApiKey()"
                (deleteApiKey)="deleteApiKey($event)"
                (getApiKey)="getApiKey($event)"
                [apiKeys]="apiKeys$ | async"
                [currentUser]="currentUser$ | async"
                [maxApiKeysReached]="maxApiKeysReached$ | async"
              ></iot-platform-users-user-profile-api-keys> -->
          </div>
        }
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<!--</div>-->
