import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-tab-group-meta',
  template: `
    <ng-template>
      <ng-content />
    </ng-template>
  `
})
export class TabGroupMetaComponent {
  @ViewChild(TemplateRef) public template!: TemplateRef<any>;
}
