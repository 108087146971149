import { Component, computed, inject, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Environment } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';

@Component({
  imports: [FlexLayoutModule, TranslateModule, MatButtonModule, MatIconModule],
  selector: 'iot-platform-login-select-authentication',
  templateUrl: './login-select-authentication.component.html',
  styleUrls: ['./login-select-authentication.component.scss', '../../scss/style.scss']
})
export class LoginSelectAuthenticationComponent {
  private readonly environment: Environment = inject('environment' as never);
  ssoDisabled = input<boolean>(false);
  signInWithSSO = output<void>();
  signInWithEmailPassword = output<void>();
  carmUrl: Signal<string> = computed(() => get(this.environment, ['carmUrl'], ''));
}
