import { NgClass } from '@angular/common';
import { Component, computed, inject, Input, input, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDisplayPipe } from '@iot-platform/pipes';
import { DeviceOperatorInfoComponent } from '../device-operator-info-popup/device-operator-info.component';

@Component({
  imports: [InfoDisplayPipe, NgClass],
  selector: 'iot-platform-ui-device-iccid',
  template: ` <span (click)="handleOnClick()" [ngClass]="{ link: hasLink() }">{{ iccid() | infoDisplay }}</span> `
})
export class DeviceIccidComponent {
  deviceId = input<string>();
  iccid = input<string>();
  operator = input<string>();
  hasLink: Signal<boolean> = computed(() => !!this.deviceId() && !!this.operator() && !!this.iccid());

  @Input() onClick!: () => void;

  public readonly dialog: MatDialog = inject(MatDialog);

  handleOnClick(): void {
    if (this.hasLink()) {
      this.dialog.open(DeviceOperatorInfoComponent, {
        data: { deviceId: this.deviceId() },
        disableClose: false,
        width: '900px'
      });
      this.onClick?.();
    }
  }
}
