import { Component, computed, inject, input, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonGenericModel } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { MapMarkersHelper } from '../../helpers/map-markers.helper';
import { IotMapMarkerPopup, IotMapMarkerPopupRawData } from '../../models';
import { IotGeoJsonFeature } from '../../models/iot-geo-json-object.model';
import { SimpleMapComponent } from '../simple-map/simple-map.component';

@Component({
  imports: [FlexLayoutModule, SimpleMapComponent],
  selector: 'iot-platform-maps-geo-coordinates',
  styles: `
    :host {
      display: contents;
    }

    .iot-platform-maps-geo-coordinates {
      min-height: 220px !important;
    }
  `,
  template: `
    <iot-platform-maps-simple-map
      class="iot-platform-maps-geo-coordinates"
      [features]="mapFeatures()"
      [concept]="concept()"
      [zoom]="zoom()"
      [popup]="popup()"
      data-cy="iot-platform-maps-geo-coordinates"
    />
  `
})
export class MapGeoCoordinatesComponent<T extends CommonGenericModel> {
  longitude = input.required<number>();
  latitude = input.required<number>();
  element = input.required<T>();
  concept = input.required<string>();
  options = input<any>();
  zoom = input<number>(7);
  mapFeatures: Signal<IotGeoJsonFeature[]> = computed(() => {
    const concept = this.concept();
    const latitude = this.latitude();
    const longitude = this.longitude();
    const element = this.element();
    const options = this.options();
    const coordinates: [number, number] = [longitude, latitude];
    const geoFeature = MapMarkersHelper.createMarkerFeatureFromCoordinates(
      coordinates,
      {
        ...element,
        concept
      },
      options
    );
    return [geoFeature];
  });
  protected translateService: TranslateService = inject(TranslateService);
  popup: Signal<IotMapMarkerPopup | null> = computed(() => {
    const options = this.options();
    if (options && options.hasOwnProperty('matchScore')) {
      return new IotMapMarkerPopup()
        .addTemplateRow(
          null,
          (rawData: IotMapMarkerPopupRawData) =>
            `${this.translateService.instant('IOT_DICTIONARY.NAME_CAPITALIZE')} : ${get(rawData, 'feature.properties.name')}`
        )
        .addTemplateRow(
          null,
          (rawData: IotMapMarkerPopupRawData) =>
            `${this.translateService.instant('MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ADDRESS_ACCURACY')} : ${get(rawData, 'feature.properties.geolocation.matchScore')}`
        );
    }
    return null;
  });
}
