@if (!exportSpreadsheets.length && canUpdateContact) {
  <iot-platform-ui-default-card
    (click)="addExportSpreadsheet.emit()"
    [backgroundClass]="'h-100'"
    [title]="'SITES.EXPORT_SPREADSHEETS_TAB.ADD' | translate"
    [icon]="'add'"
    fxFlex="0 1 calc(33.33% - 15px)"
  >
  </iot-platform-ui-default-card>
}

<div fxFlex fxLayout="row" fxLayoutGap="20px">
  <iot4bos-ui-notifications-exports-tabs-checkbox-list
    (checkItem)="checkExportSpreadsheet.emit($event)"
    (selectItem)="selectExportSpreadsheet.emit($event)"
    [currentContact]="currentContact"
    [displayCheckbox]="displayCheckbox"
    [list]="exportSpreadsheets"
    [selectedItem]="selectedExportSpreadsheet"
    fxFlex="15"
  ></iot4bos-ui-notifications-exports-tabs-checkbox-list>

  @if (selectedExportSpreadsheet) {
    <div fxFlex class="site-export-spreadsheets--export-spreadsheet-card" fxLayoutGap="10px" fxLayout="column">
      @if (canUpdateContact) {
        <div class="site-export-spreadsheets--export-spreadsheet-card-button app-round-button">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="configureExportSpreadsheet.emit(selectedExportSpreadsheet)">{{ 'IOT_DICTIONARY.CONFIGURE' | translate }}</button>
            <button mat-menu-item (click)="deleteExportSpreadsheet.emit(selectedExportSpreadsheet)" [disabled]="selectedExportSpreadsheet.status === 'active'">
              {{ 'IOT_DICTIONARY.DELETE' | translate }}
            </button>
          </mat-menu>
        </div>
      }
      <div class="site-export-spreadsheets--export-spreadsheet-card-title fw-600" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center">
        <span>{{ selectedExportSpreadsheet.name }}</span>
        <span
          [ngClass]="{
            'capitalize-first-letter': true,
            'export-spreadsheet-status-active': selectedExportSpreadsheet.status === 'active',
            'export-spreadsheet-status-inactive': selectedExportSpreadsheet.status !== 'active'
          }"
          >{{ 'IOT_DICTIONARY.' + selectedExportSpreadsheet.status | translate }}</span
        >
      </div>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'IOT_DICTIONARY.DESCRIPTION' | translate
        }}</span>
        <span>{{ selectedExportSpreadsheet.description | infoDisplay }}</span>
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'IOT_DICTIONARY.NEXT_RUN' | translate
        }}</span>
        <span>{{ selectedExportSpreadsheet.nextRun | dateFormat }}</span>
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'IOT_DICTIONARY.LAST_RUN' | translate
        }}</span>
        @if (exportPreviewLoading$ | async) {
          <mat-spinner [diameter]="14" mode="indeterminate" color="accent"></mat-spinner>
        } @else if (!!exportPreview && selectedExportSpreadsheet.status === EXPORT_SPREADSHEET_STATUS.ACTIVE && selectedExportSpreadsheet?.lastRun) {
          <a [href]="exportPreview.url" [download]="exportPreview.name" target="_blank" rel="noopener">{{
            selectedExportSpreadsheet?.lastRun | dateFormat | infoDisplay
          }}</a>
        } @else {
          <span>{{ selectedExportSpreadsheet?.lastRun | dateFormat | infoDisplay }}</span>
        }
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'SITES.EXPORT_SPREADSHEETS_TAB.PERIODICITY' | translate
        }}</span>
        <span>{{ selectedExportSpreadsheet.frequency | infoDisplay }}</span>
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'IOT_DICTIONARY.MARGIN' | translate
        }}</span>
        <span>{{ selectedExportSpreadsheet.margin | infoDisplay }}</span>
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'IOT_DICTIONARY.asset-variables' | translate
        }}</span>
        <div fxLayout="column" class="full-width" fxLayoutGap="10px">
          @for (asset of formattedAssets; track asset.id) {
            <div fxLayout="row wrap" fxLayoutAlign="start start" class="full-width">
              <div fxFlex="30" class="site-export-spreadsheets--export-spreadsheet-asset-name" fxLayoutAlign="start center" fxLayoutGap="6px">
                <mat-icon [svgIcon]="'asset'"></mat-icon>
                <span>{{ asset.name }}</span>
              </div>
              <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                @for (assetVariable of asset.variables; track assetVariable.id) {
                  <span class="site-export-spreadsheets--export-spreadsheet-variable">{{ assetVariable.name }}</span>
                }
              </div>
            </div>
          }
        </div>
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'SITES.EXPORT_SPREADSHEETS_TAB.EXCEL_MODEL' | translate
        }}</span>
        <span>{{ selectedExportSpreadsheet.model | infoDisplay }}</span>
      </section>

      <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <span fxFlex="20" class="site-export-spreadsheets--export-spreadsheet-property-name capitalize-first-letter">{{
          'SITES.NOTIFICATION_FORM.NOTIFIED_CONTACTS' | translate
        }}</span>
        <iot4bos-ui-notifications-exports-tabs-related-contacts
          [selectedItem]="selectedExportSpreadsheet"
          [currentContact]="currentContact"
        ></iot4bos-ui-notifications-exports-tabs-related-contacts>
      </section>
    </div>
  }

  @if (!selectedExportSpreadsheet && exportSpreadsheets.length) {
    <div fxFlex class="site-export-spreadsheets--select-message fw-600">
      <span>{{ 'SITES.EXPORT_SPREADSHEETS_TAB.SELECT_EXPORT_MESSAGE' | translate }}</span>
    </div>
  }
</div>
