import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CustomEncoder, ENVIRONMENT, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { SortUtil, StringUtils } from '@iot-platform/iot-platform-utils';
import { CommonApiListResponse, Connector, DeviceCallLog, Environment, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { CommandType, CommonBulkOperationType, Concept, Device, I4BBulkOperationApiResponse, Log, Site } from '@iot-platform/models/i4b';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  getAll(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<Device[]>(`${this.environment.api.url}${this.environment.api.endpoints.devices}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  getById(deviceId: string): Observable<Device> {
    return this.httpClient.get<Device>(`${this.environment.api.url + this.environment.api.endpoints.devices}/${deviceId}`);
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}`);
  }

  getManyBySiteId(siteId: string, limit: number = 100, page: number = 0): Observable<Device[]> {
    return this.httpClient
      .get<Device[]>(`${this.environment.api.url + this.environment.api.endpoints.devices}?siteId=${siteId}&limit=${limit}&page=${page}`)
      .pipe(map((results: any) => results.content));
  }

  getCallLogById(deviceId: string): Observable<DeviceCallLog[]> {
    return this.httpClient
      .get<CommonApiListResponse<DeviceCallLog>>(`${this.environment.api.url}${this.environment.api.endpoints?.devices}/${deviceId}/call-logs`)
      .pipe(
        map((response: CommonApiListResponse<DeviceCallLog>) =>
          response.content.map((callLog) => ({
            ...callLog,
            id: callLog.timestamp.toString()
          }))
        )
      );
  }

  getCallLogMessage(direction: string, deviceIdentifier: string, callLogTimestamp: number): Observable<string> {
    return this.httpClient.get<string>(
      `${this.environment.api.url_v2}${this.environment.api.endpoints?.messages}/${direction}/devices/${deviceIdentifier}/timestamps/${callLogTimestamp}`
    );
  }

  post(device: Device): Observable<Device> {
    return this.httpClient.post<Device>(this.environment.api.url + this.environment.api.endpoints.devices, device);
  }

  updateDevice(device: Device): Observable<Device> {
    return this.httpClient.put<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + device.id, device);
  }

  activateDevice(device: Device): Observable<Device> {
    return this.httpClient.post<Device>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${device.id}/activate`, {});
  }

  updateDevices(devices: Device[]): Observable<Device>[] {
    return devices.map((device) => this.updateDevice(device));
  }

  updateDevices2(devices: Device[]): Observable<{ devices: Device[] }> {
    return forkJoin(devices.map((device) => this.updateDevice(device))).pipe(map((result) => ({ devices: result })));
  }

  bulkUpdateDevices(devices: Device[]): Observable<{ devices: Device[]; errors: HttpErrorResponse[] }> {
    return forkJoin(devices.map((device) => this.updateDevice(device))).pipe(
      map((results: any) => ({
        devices: results.filter((result: any) => !(result instanceof HttpErrorResponse)),
        errors: results.filter((result: any) => result instanceof HttpErrorResponse)
      }))
    );
  }

  resetDevice(deviceToReset: Device): Observable<Device> {
    return this.httpClient
      .put<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + deviceToReset.id + '/reset', {})
      .pipe(switchMap(() => this.getById(deviceToReset.id)));
  }

  delete(device: Device) {
    return this.httpClient.delete<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + device.id).pipe(map(() => device));
  }

  saveTableState(selectedDevice): Observable<Device> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_DEVICES_TABLE_STATE_KEY, JSON.stringify(selectedDevice));
    return of(selectedDevice);
  }

  filterSitesByName(filterValue: string, page: number): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', '10');
    params = params.set('page', page.toString(10));
    params = params.set('siteName', filterValue);

    return this.httpClient.get<PlatformResponse>(`${this.environment.api.url}${this.environment.api.endpoints.sites}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        limit: data.page.limit,
        currentPage: data.page.curPage,
        total: data.page.total,
        maxPage: data.page.maxPage,
        hasMore: data.page.hasMore
      }))
    );
  }

  getTagsByDeviceId(deviceId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}/tags`)
      .pipe(map((data: { page: NonNullable<unknown>; content: TagCategory[] }) => data.content));
  }

  putTagsByDeviceId(deviceId: string, tags: TagCategory[]): Observable<TagCategory[]> {
    const tagLabelIds: string[] = tags.map((t) => t.labels?.map((l) => l.id)).flat();
    return this.httpClient
      .put(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}/tags`, {
        tags: tagLabelIds
      })
      .pipe(map((data: { page: NonNullable<unknown>; content: TagCategory[] }) => data.content));
  }

  getDeviceFamilies(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.environment.api.url}${this.environment.api.endpoints.connectorFamilies}`);
  }

  getDeviceCountByFamily(siteId: string): Observable<{ family: string; total: number }[]> {
    return this.getDeviceFamilies()
      .pipe(
        switchMap((families) =>
          forkJoin(
            families.reduce((acc: Observable<{ family: string; total: number }>[], family) => {
              const request: PlatformRequest = {
                limit: 0,
                page: 0,
                filters: [
                  { criteriaKey: 'siteId', value: siteId },
                  { criteriaKey: 'deviceTypeFamily', value: family }
                ]
              };
              acc.push(
                this.getAll(request).pipe(
                  map((response: PlatformResponse) => ({
                    family,
                    total: response.total
                  }))
                )
              );
              return acc;
            }, [])
          )
        )
      )
      .pipe(map((deviceCountByFamily) => deviceCountByFamily.sort(SortUtil.sortByProperty('total')).reverse()));
  }

  bulkOperationOnTag(operationType: CommonBulkOperationType, devicesIds: string[], tagLabelId: string): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(
      `${this.environment.api.url}${this.environment.api.endpoints[`devicesBulkOperationOnTag${StringUtils.capitalizeFirstCharacter(operationType)}`]}`,
      {
        devicesIds,
        tagLabelId
      }
    );
  }

  getConnectorsByDeviceId(deviceId: string): Observable<Connector[]> {
    return this.httpClient
      .get<CommonApiListResponse<Connector>>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}` + '/connectors')
      .pipe(map((data: CommonApiListResponse<Connector>) => data.content));
  }

  sendCommand(deviceId: string, command: { command: CommandType }): Observable<any> {
    return this.httpClient.post(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}/commands`, command);
  }

  bulkSendCommand(devicesIds: string[], command: CommandType): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.devicesBulkSendCommands}`, {
      devicesIds,
      command
    });
  }

  loadComments(device: Device): Observable<Log[]> {
    const canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);

    return forkJoin([
      this.httpClient.get<Log[]>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${device.id}${this.environment.api.endpoints.logs}`),
      canReadSite
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints.sites}/${device.site?.id}${this.environment.api.endpoints.logs}`
          )
        : of([])
    ]).pipe(
      map(([deviceLogs, siteLogs]) => [
        ...deviceLogs.map((log: Log) => ({ ...log, concept: Concept.DEVICE, icon: 'device' })),
        ...siteLogs.map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' }))
      ])
    );
  }

  addComment(deviceId: string, comment: string): Observable<Log> {
    return this.httpClient
      .post<Log>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}${this.environment.api.endpoints.logs}`, {
        comment
      })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.DEVICE, icon: 'device' })));
  }

  editComment(deviceId: string, comment: Log): Observable<Log> {
    return this.httpClient
      .put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}${this.environment.api.endpoints.logs}/${comment.id}`, {
        comment: comment.comment
      })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.DEVICE, icon: 'device' })));
  }

  deleteComment(deviceId: string, commentId: string): Observable<string> {
    return this.httpClient
      .delete<void>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}${this.environment.api.endpoints.logs}/${commentId}`)
      .pipe(map(() => commentId));
  }
}
