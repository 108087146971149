@if (control(); as control) {
  @if (field(); as field) {
    <div
      class="iot-platform-ui-form-field iot-platform-ui-form-field__{{ field?.name?.() }}"
      data-cy="iot-platform-ui-form-field"
      fxLayout="row"
      fxLayoutGap="12px"
      fxLayoutAlign="start center"
    >

      @if (field.type === FormControlType.CHECKBOX_GROUP) {
        <div
          fxFlex
          fxLayout="column"
          fxLayoutGap="10px">
          <iot-platform-ui-checkbox-group
            fxFlex
            class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
            data-cy="form-control"
            [formControl]="control"
            [items]="field.items?.() ?? []"
            [displayBy]="field?.displayBy"
            [selectBy]="field?.selectBy"
            [required]="required()"
            [capitalized]="field?.capitalized?.()"
            [disabled]="field?.disabled?.()"
            [isOptionDisabled]="field?.isOptionDisabled"
            [fxFlexOption]="field?.fxFlexOption?.()"
          />

          @if (control?.invalid && control?.hasError('required')) {
            <mat-error class="fs-8">{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
          } @else if (control?.invalid && field?.error?.enabled?.() && field?.error?.message?.()) {
            <mat-error class="fs-8">{{ field?.error?.message?.() | translate }}</mat-error>
          }
        </div>

      } @else {
        <mat-form-field fxFlex color="accent">
          @if (field.type === FormControlType.AUTO_COMPLETE && field?.displaySearchIcon?.()) {
            <mat-icon class="iot-platform-ui-form-field__search-icon" matPrefix>search</mat-icon>
          }

          @if (field?.label?.(); as label) {
            <mat-label [ngClass]="{ 'capitalize-first-letter': field?.capitalized?.()}">{{ label | translate }}</mat-label>
          }

          @switch (field.type) {
            @case (FormControlType.TEXT) {
              @if (field?.restrictedInput?.()) {
                <input
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  #inputText
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [uppercase]="field?.uppercase?.() ?? false"
                  matInput
                  restrictedInput
                  [placeholder]="field?.placeholder?.()"
                  [textMask]="field?.textMask?.()"
                />
              } @else {
                <input
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  #inputText
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [placeholder]="field?.placeholder?.()"
                  [textMask]="field?.textMask?.()"
                  matInput
                />
              }
            }
            @case (FormControlType.PASSWORD) {
              @if (field?.restrictedInput?.()) {
                <input
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  #inputText
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [type]="hidePassword() ? 'password' : 'text'"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [uppercase]="field?.uppercase?.() ?? false"
                  [placeholder]="field?.placeholder?.()"
                  [textMask]="field?.textMask?.()"
                  matInput
                  restrictedInput
                />
              } @else {
                <input
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  #inputText
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [type]="hidePassword() ? 'password' : 'text'"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [placeholder]="field?.placeholder?.()"
                  [textMask]="field?.textMask?.()"
                  matInput
                />
              }
            }
            @case (FormControlType.TEXT_AREA) {
              @if (field?.restrictedInput?.()) {
                <textarea
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  type="text-area"
                  #inputText
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [uppercase]="field?.uppercase?.() ?? false"
                  [rows]="field?.rows?.() ?? 3"
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [placeholder]="field?.placeholder?.()"
                  [textMask]="field?.textMask?.()"
                  matInput
                  restrictedInput
                ></textarea>
              } @else {
                <textarea
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  #inputText
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [rows]="field?.rows?.() ?? 3"
                  [placeholder]="field?.placeholder?.()"
                  [textMask]="field?.textMask?.()"
                  matInput
                ></textarea>
              }
            }
            @case (FormControlType.NUMBER) {
              <input
                [formControl]="control"
                class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                data-cy="form-control"
                matInput
                #inputText
                (focus)="field?.onFocus?.($event)"
                (click)="field?.onClick?.($event)"
                [max]="field?.max?.() ?? null"
                [min]="field?.min?.() ?? null"
                [placeholder]="field?.placeholder?.()"
                [required]="required()"
                type="number"
              />
            }
            @case (FormControlType.DROP_DOWN_LIST) {
              <mat-select
                class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                data-cy="form-control"
                [multiple]="field?.multiple?.()"
                [formControl]="control"
                (selectionChange)="field?.selectionChange?.($event)"
              >
                @if (field?.selectTriggerRef?.()) {
                  <mat-select-trigger>
                    <ng-container [ngTemplateOutlet]="field.selectTriggerRef?.()"
                                  [ngTemplateOutletContext]="{ item: control?.getRawValue() }" />
                  </mat-select-trigger>
                }

                @for (item of field?.items?.(); track $index) {
                  <mat-option
                    [value]="item"
                    [disabled]="field?.isOptionDisabled ? field.isOptionDisabled(item) : field?.disabled?.()"
                    class="iot-platform-ui-form-field--{{ field.name?.() }}-select-option {{ field?.optionCssClassName?.() }}"
                    data-cy="iot-platform-ui-form-field-select-option"
                  >
                    @if (field?.selectDisplayWrapperRef?.()) {
                      <ng-container [ngTemplateOutlet]="field.selectDisplayWrapperRef?.()"
                                    [ngTemplateOutletContext]="{ item: item }" />
                    } @else {
                      {{ field?.displayBy?.(item) | translate }}
                    }
                  </mat-option>
                }
              </mat-select>
            }
            @case (FormControlType.AUTO_COMPLETE) {
              <!-- field?.autocomplete?.() can be undefined -->
              @if (field?.autocomplete?.() === false) {
                <input
                  (focus)="field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  [placeholder]="field?.placeholder?.()"
                  #inputText
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  matInput
                  [textMask]="field?.textMask?.()"
                  type="text"
                />
              } @else {
                <input
                  #inputAutoComplete
                  class="iot-platform-ui-form-field--{{ field.name?.() }} {{ field?.inputCssClassName?.() }}"
                  data-cy="form-control"
                  [maxlength]="field?.maxLength?.() ?? null"
                  [minlength]="field?.minLength?.() ?? null"
                  [pattern]="field?.pattern?.() ?? null"
                  [formControl]="control"
                  [required]="required()"
                  matInput
                  [matAutocomplete]="auto"
                  (focus)="filterAutocomplete(control?.getRawValue()); field?.onFocus?.($event)"
                  (click)="field?.onClick?.($event)"
                  [placeholder]="field?.placeholder?.()"
                />
              }

              <div matSuffix class="iot-platform-ui-form-field__autocomplete-clear-search-control" fxLayout="row"
                   fxLayoutGap="6px">
                @if (field?.showSpinner?.() || control?.pending) {
                  <mat-spinner class="iot-platform-ui-form-field__autocomplete-clear-search-control_spinner"
                               color="accent" diameter="16" mode="indeterminate" />
                }
                @if (control?.value !== undefined && control?.value !== null && control?.value !== '') {
                  <button
                    class="iot-platform-ui-form-field__autocomplete-clear-search-control_action"
                    data-cy="iot-platform-ui-form-field-autocomplete-clear-search-control-action"
                    (click)="clearAutocomplete($event)"
                    [disabled]="field?.disabled?.()"
                    aria-label="Clear"
                    mat-icon-button
                    type="button"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                }
              </div>

              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onAutoCompleteOptionsSelected($event)"
                [displayWith]="displayByFn"
                class="{{ field?.inputCssClassName?.() }}"
              >
                @for (item of autocompleteFilteredOptions(); track $index) {
                  <mat-option
                    (click)="field?.multiple?.() && $event.preventDefault(); $event.stopPropagation()"
                    [value]="field?.multiple?.() ? autocompleteSelectedItems() : item"
                    [matTooltip]="field?.tooltip?.enabled?.() ? field?.displayBy?.(item) : ''"
                    [disabled]="!field?.multiple?.() && field?.disabled?.()"
                    matTooltipClass="regular-tooltip"
                    class="iot-platform-ui-form-field__autocomplete-option iot-platform-ui-form-field--{{ field.name?.() }}-autocomplete-option {{
                    field?.inputCssClassName?.()
                  }}"
                    data-cy="iot-platform-ui-form-field-autocomplete-option"
                  >
                    @if (field?.selectDisplayWrapperRef?.()) {
                      <ng-container [ngTemplateOutlet]="field.selectDisplayWrapperRef?.()"
                                    [ngTemplateOutletContext]="{ item: item }" />
                    } @else if (field?.multiple?.()) {
                      <mat-checkbox
                        (click)="$event.stopPropagation(); toggleAutoCompleteSelection(item)"
                        [checked]="item.selected"
                        [disabled]="!item.selected && field?.disabled?.()"
                      >
                        {{ field?.displayBy?.(item) }}
                      </mat-checkbox>
                    } @else {
                      {{ field?.displayBy?.(item) }}
                    }
                  </mat-option>
                }
              </mat-autocomplete>
            }
          }

          @if ((field.type === FormControlType.TEXT || field.type === FormControlType.TEXT_AREA || field.type === FormControlType.PASSWORD) && control?.pending) {
            <div matSuffix>
              <mat-spinner color="accent" diameter="16" mode="indeterminate" />
            </div>
          }

          @if (control?.invalid && control?.hasError('required')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
          } @else if (control?.invalid && control?.hasError('maxlength')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.MAX_LENGTH' | translate: { value: control.getError('maxlength')?.requiredLength } }}</mat-error>
          } @else if (control?.invalid && control?.hasError('minlength')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.MIN_LENGTH' | translate: { value: control.getError('minlength')?.requiredLength } }}</mat-error>
          } @else if (control?.invalid && control?.hasError('max')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.MAX' | translate: { value: control.getError('max')?.max } }}</mat-error>
          } @else if (control?.invalid && control?.hasError('min')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.MIN' | translate: { value: control.getError('min')?.min } }}</mat-error>
          } @else if (control?.invalid && control?.hasError('pattern')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.PATTERN' | translate }}</mat-error>
          } @else if (control?.invalid && (control?.hasError('duplicateName') || control?.hasError('nameExists'))) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</mat-error>
          } @else if (control?.invalid && control?.hasError('link')) {
            <mat-error>{{ 'FORM.ERROR_MESSAGE.LINK' | translate }}</mat-error>
          } @else if (control?.invalid && field?.error?.enabled?.() && field?.error?.message?.()) {
            <mat-error>{{ field?.error?.message?.() | translate }}</mat-error>
          }

          @if (field?.prefix) {
            <mat-icon
              class="prefix-icon"
              style="margin-right: 5px"
              [matTooltip]="field.prefix?.tooltip?.() ?? '' | translate"
              matPrefix
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
            >{{ field.prefix?.icon?.() }}
            </mat-icon>
          }
          @if (field?.type === FormControlType.PASSWORD) {
            <mat-icon (click)="togglePassword()" onKeyUp class="suffix-icon" matSuffix style="margin-left: 5px"
            >{{ hidePassword() ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          }
          @if (field?.suffix) {
            <mat-icon
              class="suffix-icon"
              style="margin-left: 5px"
              [matTooltip]="field.suffix?.tooltip?.() ?? '' | translate"
              matSuffix
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
            >{{ field.suffix?.icon?.() }}
            </mat-icon>
          }

          @if (field?.hint?.()?.type?.() === FormControlHintType.TEXT) {
            <mat-hint align="end">
              {{ field.hint?.()?.text?.() | translate }}
            </mat-hint>
          } @else if (field?.hint?.()?.type?.() === FormControlHintType.MAX_LENGTH) {
            <mat-hint align="end"> {{ control?.getRawValue()?.length ?? 0 }} / {{ field.maxLength?.() }}</mat-hint>
          } @else if (field?.hint?.()?.type?.() === FormControlHintType.TEMPLATE_REF) {
            <mat-hint align="end">
              <ng-container [ngTemplateOutlet]="field.hint?.()?.templateRef?.()" />
            </mat-hint>
          }
        </mat-form-field>
      }

      @if (field?.undo) {
        @if (field?.undo.visible) {
          @if (field?.undo.visible?.()) {
            <ng-container [ngTemplateOutlet]="undoTmpl" />
          }
        } @else {
          <ng-container [ngTemplateOutlet]="undoTmpl" />
        }
      }
    </div>
  }
}

<ng-template #undoTmpl>
  <button
    (click)="onUndoBtnClicked()"
    [class]="'light-button regular-round-button iot-platform-ui-form-field__undo-button iot-platform-ui-form-field__undo-button--' + field()?.name?.()"
    [disabled]="field()?.undo?.disabled?.()"
    [matTooltip]="field()?.undo?.tooltip?.()! | translate"
    data-cy="iot-platform-ui-form-field-undo-button"
    fxFlex="24px"
    mat-mini-fab
    matTooltipClass="regular-tooltip"
    matTooltipPosition="below"
  >
    @if (!!field()?.undo?.svgIcon?.()) {
      <mat-icon class="iot-platform-ui-form-field__undo-button_icon" [svgIcon]="field()?.undo?.svgIcon?.()!" />
    } @else if (!!field()?.undo?.icon?.()) {
      <mat-icon class="iot-platform-ui-form-field__undo-button_icon">{{ field()?.undo?.icon?.() }}</mat-icon>
    }
  </button>
</ng-template>
