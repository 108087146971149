import { KeyValuePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { FormulaType } from '@iot-platform/models/common';
import {
  AssetVariable,
  AssetVariableAlgo,
  AssetVariableThreshold,
  AssetVariableThresholdRecord,
  THRESHOLD_COLORS,
  ThresholdType
} from '@iot-platform/models/i4b';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  imports: [
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    KeyValuePipe
  ],
  selector: 'iot4bos-ui-asset-variable-thresholds-form',
  templateUrl: './variable-thresholds-form.component.html',
  styleUrls: ['./variable-thresholds-form.component.scss']
})
export class VariableThresholdsFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() formula: FormulaType;
  @Input() assetVariable?: AssetVariable;
  @Input() algos?: AssetVariableAlgo[] = [];
  @Input() threshold?: AssetVariableThreshold;
  @Input() position = 1;
  @Input() maxPosition = 1;
  @Input() readonly = false;

  @Output() deleteThreshold: EventEmitter<void> = new EventEmitter();
  @Output() changeThreshold: EventEmitter<AssetVariableThreshold | null> = new EventEmitter();

  thresholdForm = new UntypedFormGroup({
    position: new UntypedFormControl(this.position, [Validators.required]),
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(20)]),
    value: new UntypedFormControl(null, [Validators.required]),
    lineColor: new UntypedFormControl(null, [Validators.required]),
    cellColor: new UntypedFormControl(null, [Validators.required]),
    algo: new UntypedFormControl(null, []),
    thresholdType: new UntypedFormControl(ThresholdType.STATIC)
  });
  ThresholdTypes = ThresholdType;

  lineColors = [...THRESHOLD_COLORS.lineColors];
  cellColors = [...THRESHOLD_COLORS.cellColors];

  isCalculationLoading = false;
  errorMessage: string | null = null;
  subscriptions: Subscription[] = [];

  constructor(private readonly assetVariableService: AssetVariablesService) {}

  defaultOrder = () => 0;

  get positionControl(): AbstractControl {
    return this.thresholdForm.get('position');
  }

  get nameControl(): AbstractControl {
    return this.thresholdForm.get('name');
  }

  get valueControl(): AbstractControl {
    return this.thresholdForm.get('value');
  }

  get lineColorControl(): AbstractControl {
    return this.thresholdForm.get('lineColor');
  }

  get cellColorControl(): AbstractControl {
    return this.thresholdForm.get('cellColor');
  }

  get algoControl(): AbstractControl {
    return this.thresholdForm.get('algo');
  }

  get thresholdTypeControl(): AbstractControl {
    return this.thresholdForm.get('thresholdType');
  }

  get hasValue(): boolean {
    return this.valueControl.value !== null && this.valueControl.value !== undefined;
  }

  get hasCalculateAttribute(): boolean {
    return !!this.threshold && !!this.threshold?.calculate;
  }

  get isDynamic(): boolean {
    return this.thresholdTypeControl.value === ThresholdType.DYNAMIC;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.thresholdForm.valueChanges.subscribe((changes) => {
        if (this.isFormValid()) {
          let formattedChanges: AssetVariableThreshold = {
            name: changes.name?.trim() ?? this.nameControl.value,
            value: changes.value !== null && changes.value !== undefined ? changes.value : this.valueControl.value,
            cellColor: changes.cellColor ?? this.cellColorControl.value,
            lineColor: changes.lineColor ?? this.lineColorControl.value,
            position: changes.position ?? this.positionControl.value
          };
          if (this.isDynamic) {
            formattedChanges = {
              ...formattedChanges,
              calculate: {
                formulaName: changes.algo?.name
              }
            };
          }
          this.changeThreshold.emit(formattedChanges);
        } else {
          this.changeThreshold.emit(null);
        }
      })
    );
    this.setDefaultColorByPosition(this.position);
    this.thresholdForm.patchValue({
      position: this.position,
      name: this.threshold?.name ?? `threshold${this.position}`,
      value: this.threshold?.value ?? undefined,
      lineColor: this.threshold?.lineColor ?? this.lineColorControl.value,
      cellColor: this.threshold?.cellColor ?? this.cellColorControl.value
    });
    this.setThresholdType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.position?.currentValue) {
      this.positionControl.setValue(changes.position.currentValue);

      if (changes.position?.previousValue && changes.position?.currentValue !== changes.position?.previousValue) {
        this.thresholdForm.markAsDirty();
      }
    }

    if (changes?.threshold?.currentValue) {
      this.thresholdForm.patchValue({
        position: this.position,
        name: changes.threshold.currentValue.name,
        value: changes.threshold.currentValue.value,
        lineColor: changes.threshold.currentValue.lineColor ?? this.lineColorControl.value,
        cellColor: changes.threshold.currentValue.cellColor ?? this.cellColorControl.value
      });
      this.setThresholdType();
    }

    if (changes?.readonly?.currentValue) {
      this.nameControl.disable();
      this.valueControl.disable();
      this.lineColorControl.disable();
      this.thresholdTypeControl.disable();
      this.algoControl.disable();
    }

    if (changes.algos?.currentValue) {
      this.algoControl.patchValue(
        changes.algos.currentValue.find(
          (algo: AssetVariableAlgo) =>
            algo.name.toLowerCase().toString().trim() === GetUtils.get(this.threshold, 'calculate.formulaName', '').toLowerCase().toString().trim()
        )
      );
      if (!changes.algos?.currentValue.length) {
        this.thresholdTypeControl.patchValue(ThresholdType.STATIC);
        this.onToggleThresholdType({ value: ThresholdType.STATIC }, true);
        this.thresholdTypeControl.disable();
      }
    }
  }

  setDefaultColorByPosition(position: number): void {
    this.thresholdForm.patchValue({
      cellColor: this.cellColors[this.maxPosition + position < this.cellColors.length ? this.cellColors.length - this.maxPosition + position - 1 : 0],
      lineColor: this.lineColors[this.maxPosition + position < this.lineColors.length ? this.lineColors.length - this.maxPosition + position - 1 : 0]
    });
  }

  setCellColor(): void {
    const lineColorIndex: number = this.lineColors.indexOf(this.lineColorControl.value);
    this.cellColorControl.setValue(this.cellColors[lineColorIndex]);
  }

  isFormValid(): boolean {
    if (this.readonly) {
      const isValid =
        !!this.positionControl.value &&
        this.hasValue &&
        !!this.lineColorControl.value &&
        !!this.cellColorControl.value &&
        (this.isDynamic ? !!this.algoControl.value : !!this.nameControl.value);
      return isValid;
    }
    return this.thresholdForm.valid;
  }

  setThresholdType(): void {
    const value = this.hasCalculateAttribute && !this.threshold?.isFromSourceVariable ? ThresholdType.DYNAMIC : ThresholdType.STATIC;
    this.thresholdTypeControl.patchValue(value);
    this.onToggleThresholdType({ value }, true);
    if (this.isDynamic && this.hasValue) {
      this.valueControl.disable();
    }
    if (this.threshold?.isFromSourceVariable || this.readonly) {
      this.thresholdTypeControl.disable();
    }
  }

  onAlgoSelectionChange(): void {
    this.valueControl.enable();
    this.valueControl.patchValue(undefined);
    this.setControlValidators(this.valueControl, [Validators.required]);
  }

  onToggleThresholdType({ value }, preventResetValue = false): void {
    if (!preventResetValue) {
      this.valueControl.patchValue(undefined);
    }
    if (!this.readonly) {
      this.valueControl.enable();
    }
    if (value === ThresholdType.DYNAMIC) {
      this.setControlValidators(this.nameControl, []);
      this.setControlValidators(this.algoControl, [Validators.required]);
    } else {
      this.setControlValidators(this.nameControl, [Validators.required]);
      this.setControlValidators(this.algoControl, []);
    }
    this.setControlValidators(this.valueControl, [Validators.required]);
    this.resetErrorMessage();
  }

  resetErrorMessage(): void {
    this.errorMessage = null;
  }

  calculateDynamicThreshold(): void {
    this.isCalculationLoading = true;
    this.resetErrorMessage();
    this.subscriptions.push(
      this.assetVariableService
        .calculateDynamicThreshold(this.assetVariable, this.algoControl.value)
        .pipe(
          finalize(() => {
            this.isCalculationLoading = false;
          })
        )
        .subscribe((record: AssetVariableThresholdRecord) => {
          if (record.value !== null) {
            this.valueControl.patchValue(record.value);
            this.setControlValidators(this.valueControl, []);
            this.valueControl.disable();
          } else if (record.errorCode) {
            this.errorMessage = record.errorCode;
          }
        })
    );
  }

  setControlValidators(control: AbstractControl, validators = []): void {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
