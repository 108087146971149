import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardLoaderModule, WidgetsModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';
import { HighchartsChartModule } from 'highcharts-angular';
import { DashboardsShellComponent } from './containers/dashboards-shell/dashboards-shell.component';

@NgModule({
  imports: [CommonModule, SharedModule, DragDropModule, WidgetsModule, HighchartsChartModule, CardLoaderModule, DashboardsShellComponent],
  exports: [DashboardsShellComponent]
})
export class IotPlatformDashboardsModule {}
