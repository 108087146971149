<mat-card class="dialog-card variable-chart">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ data?.title ? data.title : data.variables[0]?.name }}</h1>
      <span fxFlex></span>
      <div>
        <button
          (click)="toggleTableView()"
          [matTooltipClass]="'regular-tooltip'"
          [matTooltip]="(variableChart?.displayTable?.() ? 'VARIABLE_CHART.HIDE_TABLE_VIEW' : 'VARIABLE_CHART.DISPLAY_TABLE_VIEW') | translate"
          [ngClass]="{ 'display-table-active': variableChart?.displayTable?.() }"
          mat-icon-button
        >
          <mat-icon>table_chart</mat-icon>
        </button>
        @if (fullscreen()) {
          <button (click)="exitFullscreen()" class="fullscreen-button" mat-icon-button>
            <mat-icon class="material-icons"> fullscreen_exit</mat-icon>
          </button>
        } @else {
          <button (click)="enterFullscreen()" class="fullscreen-button" mat-icon-button>
            <mat-icon class="material-icons"> fullscreen</mat-icon>
          </button>
        }
        <button (click)="close()" cdkMonitorSubtreeFocus class="regular-round-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </mat-card-title>
  <iot-platform-feature-variable-chart [data]="data?.variables" [displayTable]="displayTable()" [options]="data?.options" [showCustomExportButton]="true" [variableType]="data.variableType" />
</mat-card>
