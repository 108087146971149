<div class="association-by-asset" fxLayout="column" fxLayoutGap="12px">
  @if (laoding()) {
    <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex />
  } @else {
    @if (association()?.assetGroups?.length) {
      @for (assetGroup of association()?.assetGroups; track assetGroup.id; let i = $index) {
        <div class="association-by-asset__asset-group" fxLayout="column" fxLayoutGap="12px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-icon class="association-by-asset__asset-group-icon" svgIcon="asset-group"></mat-icon>
            <span class="association-by-asset__name" (click)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_ASSET_GROUP, data: assetGroup.id })">
              {{ assetGroup.name | uppercase }} ({{ assetGroup.assets.length }})</span
            >
          </div>
          @for (asset of assetGroup?.assets; track asset.id) {
            <ng-container [ngTemplateOutlet]="assetTemplate" [ngTemplateOutletContext]="{ asset: asset }" />
          } @empty {
            <ng-container [ngTemplateOutlet]="noAssociationsTemplate" />
          }
        </div>
      }
    }

    @if (association()?.assets?.length) {
      @for (asset of association()?.assets; track asset.id) {
        <ng-container [ngTemplateOutlet]="assetTemplate" [ngTemplateOutletContext]="{ asset: asset }" />
      }
    }

    @if (association()?.devices?.length) {
      <ng-container [ngTemplateOutlet]="unassignedDevicesTemplate" [ngTemplateOutletContext]="{ devices: association()?.devices }" />
    }

    @if (!association()?.assetGroups?.length && !association()?.assets?.length && !association()?.devices?.length) {
      <ng-container [ngTemplateOutlet]="noAssociationsTemplate" />
    }
  }
</div>

<ng-template #assetTemplate let-asset="asset">
  <div class="association-by-asset__asset" [fxLayout]="isPageMode() ? 'row' : 'column'" fxLayout.lt-md="column" fxLayoutGap="8px">
    <iot4bos-ui-asset-card
      (clicked)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_ASSET, data: $event.id })"
      [asset]="asset"
      [ngClass]="{ 'association-by-asset__asset-card--selected': asset?.id === selectedItemId }"
      class="association-by-asset__asset-card"
      fxFlex
    />

    <section [fxFlex]="isPageMode() ? '50%' : '100%'" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px">
      @for (device of asset.devices; track device.id) {
        <iot4bos-ui-device-card
          (clicked)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_DEVICE, data: $event.id })"
          [device]="device"
          [width]="'50%'"
          class="association-by-asset__device"
          [ngClass]="{ 'association-by-asset__device--selected': device?.id === selectedItemId }"
          fxFlex
        />
      } @empty {
        <iot-platform-ui-default-card [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate" [backgroundClass]="'h-80'" fxFlex />
      }
    </section>
  </div>
</ng-template>

<ng-template #unassignedDevicesTemplate let-devices="devices">
  <div
    class="association-by-asset__asset"
    [fxLayout]="isPageMode() ? 'row' : 'column'"
    fxLayout.lt-md="column"
    [fxLayoutAlign]="isPageMode() ? 'space-between start' : 'start'"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="8px"
  >
    <iot-platform-ui-default-card
      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate"
      [backgroundClass]="'h-80'"
      [fxFlex]="isPageMode() ? '50%' : '100%'"
      fxFlex.lt-md="100%"
    />

    <section [fxFlex]="isPageMode() ? '50%' : '100%'" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px">
      @for (device of devices; track device.id) {
        <iot4bos-ui-device-card
          (clicked)="dispatchEvent.emit({ type: OVERVIEW_CARD_ACTIONS.NAVIGATE_TO_DEVICE, data: $event.id })"
          [device]="device"
          [width]="'50%'"
          class="association-by-asset__device"
          [ngClass]="{ 'association-by-asset__device--selected': device?.id === selectedItemId }"
          fxFlex
        />
      }
    </section>
  </div>
</ng-template>

<ng-template #noAssociationsTemplate>
  <div class="association-by-asset__asset" [fxLayout]="isPageMode() ? 'row' : 'column'" fxLayout.lt-md="column" fxLayoutGap="8px">
    <iot-platform-ui-default-card
      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate"
      [backgroundClass]="'h-80'"
      [fxFlex]="isPageMode() ? '50%' : '100'"
      fxFlex.lt-md="100%"
    />
    <iot-platform-ui-default-card
      [title]="'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate"
      [backgroundClass]="'h-80'"
      [fxFlex]="isPageMode() ? '50%' : '100%'"
      fxFlex.lt-md="100%"
    />
  </div>
</ng-template>
