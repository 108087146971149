import { Injectable } from '@angular/core';
import { GraphsService } from '@iot-platform/feature/graphs';
import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import * as fromNavigation from '../../../../containers/+state/reducers';
import { GraphsDbActions } from '../actions';

@Injectable()
export class GraphsEffects {
  loadGraphsBySiteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphsDbActions.loadGraphsBySiteId),
      switchMap((action) =>
        this.graphsService.getAll(action.siteId).pipe(
          map((response) => GraphsDbActions.loadGraphsBySiteIdSuccess({ response })),
          catchError((error) => of(GraphsDbActions.loadGraphsBySiteIdFailure({ error })))
        )
      )
    )
  );
  loadGraphById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphsDbActions.loadGraphById),
      concatLatestFrom(() => this.store.select(fromNavigation.getSite)),
      concatMap(([action, site]) =>
        this.graphsService.getById(site.id, action.graphId).pipe(
          map((response) => GraphsDbActions.loadGraphByIdSuccess({ response })),
          catchError((error) => of(GraphsDbActions.loadGraphByIdFailure({ error })))
        )
      )
    )
  );
  addGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphsDbActions.addGraph),
      concatLatestFrom(() => this.store.select(fromNavigation.getSite)),
      concatMap(([action, site]) =>
        this.graphsService.post(site.id, action.graph).pipe(
          map((response) => GraphsDbActions.addGraphSuccess({ response })),
          catchError((error) => of(GraphsDbActions.addGraphFailure({ error })))
        )
      )
    )
  );
  updateGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphsDbActions.updateGraph),
      concatLatestFrom(() => this.store.select(fromNavigation.getSite)),
      concatMap(([action, site]) =>
        this.graphsService.patch(site.id, action.graph).pipe(
          map((response) => GraphsDbActions.updateGraphSuccess({ response })),
          catchError((error) => of(GraphsDbActions.updateGraphFailure({ error })))
        )
      )
    )
  );
  deleteGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphsDbActions.deleteGraph),
      concatLatestFrom(() => this.store.select(fromNavigation.getSite)),
      concatMap(([action, site]) =>
        this.graphsService.delete(site.id, action.graph.id).pipe(
          map((response) => GraphsDbActions.deleteGraphSuccess({ response })),
          catchError((error) => of(GraphsDbActions.deleteGraphFailure({ error })))
        )
      )
    )
  );

  updateGraphsDisplayOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphsDbActions.updateGraphsDisplayOrder),
      concatLatestFrom(() => this.store.select(fromNavigation.getSite)),
      concatMap(([action, site]) =>
        this.graphsService.updateGraphsDisplayOrder(site.id as string, action.graphs).pipe(
          map((response) => GraphsDbActions.updateGraphsDisplayOrderSuccess({ response })),
          catchError((error) => of(GraphsDbActions.updateGraphsDisplayOrderFailure({ error })))
        )
      )
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GraphsDbActions.loadGraphsBySiteId,
          GraphsDbActions.loadGraphById,
          GraphsDbActions.addGraph,
          GraphsDbActions.updateGraph,
          GraphsDbActions.deleteGraph,
          GraphsDbActions.updateGraphsDisplayOrder
        ),
        tap((_) => {
          this.notificationService.displayLoader(true);
        })
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GraphsDbActions.loadGraphsBySiteIdSuccess,
          GraphsDbActions.loadGraphByIdSuccess,
          GraphsDbActions.addGraphSuccess,
          GraphsDbActions.updateGraphSuccess,
          GraphsDbActions.deleteGraphSuccess,
          GraphsDbActions.loadGraphsBySiteIdFailure,
          GraphsDbActions.loadGraphByIdFailure,
          GraphsDbActions.addGraphFailure,
          GraphsDbActions.updateGraphFailure,
          GraphsDbActions.deleteGraphFailure,
          GraphsDbActions.updateGraphsDisplayOrderFailure,
          GraphsDbActions.updateGraphsDisplayOrderSuccess
        ),
        tap((_) => {
          this.notificationService.displayLoader(false);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GraphsDbActions.addGraphSuccess, GraphsDbActions.updateGraphSuccess, GraphsDbActions.deleteGraphSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GraphsDbActions.loadGraphsBySiteIdFailure,
          GraphsDbActions.loadGraphByIdFailure,
          GraphsDbActions.addGraphFailure,
          GraphsDbActions.updateGraphFailure,
          GraphsDbActions.deleteGraphFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action.type);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private graphsService: GraphsService,
    private store: Store,
    private notificationService: NotificationService
  ) {}
}
