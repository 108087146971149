import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { CommonApiListResponse, Environment } from '@iot-platform/models/common';
import { Asset, AssetVariable, Device, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { Topic } from '@iot-platform/models/ocm';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventDetailPopupService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  getSiteById(siteId: string): Observable<Site | undefined> {
    return siteId ? this.httpClient.get<Site>(`${this.environment.api.url}${this.environment.api.endpoints?.sites}/${siteId}`) : of(undefined);
  }

  getAssetById(assetId: string | null): Observable<Asset | undefined> {
    return assetId ? this.httpClient.get<Asset>(`${this.environment.api.url}${this.environment.api.endpoints?.assets}/${assetId}`) : of(undefined);
  }

  getDeviceById(deviceId: string | null): Observable<Device | undefined> {
    return deviceId ? this.httpClient.get<Device>(`${this.environment.api.url}${this.environment.api.endpoints?.devices}/${deviceId}`) : of(undefined);
  }

  getAssetVariableById(assetVariableId: string | null): Observable<AssetVariable | undefined> {
    return assetVariableId
      ? this.httpClient.get<AssetVariable>(`${this.environment.api.url}${this.environment.api.endpoints?.assetVariables}/${assetVariableId}`)
      : of(undefined);
  }

  getDeviceVariableById(deviceVariableId: string | null): Observable<DeviceVariable | undefined> {
    return deviceVariableId
      ? this.httpClient.get<DeviceVariable>(`${this.environment.api.url}${this.environment.api.endpoints?.deviceVariables}/${deviceVariableId}`)
      : of(undefined);
  }

  getTopicsByEventId(eventId: string): Observable<Topic[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('eventId', eventId);
    return this.httpClient
      .get<CommonApiListResponse<Topic>>(`${this.environment.api.url}${this.environment.api.endpoints?.topics}`, { params })
      .pipe(map((data: CommonApiListResponse<Topic>) => data.content));
  }
}
