import { CdkStep, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, computed, DestroyRef, inject, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CardLoaderModule, ManageTagsFormComponent } from '@iot-platform/iot-platform-ui';
import { TagCategory } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { AssetImageFormComponent } from '../asset-image-form/asset-image-form.component';
import { AssetInfoFormComponent } from '../asset-info-form/asset-info-form.component';
import { AssetModelFormComponent } from '../asset-model-form/asset-model-form.component';

enum AssetCreationStep {
  MODEL = 'MODEL',
  INFORMATION = 'INFORMATION',
  TAGS = 'TAGS',
  IMAGE = 'IMAGE'
}

@Component({
  imports: [
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    CardLoaderModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatStepperModule,
    MatButtonModule,
    AssetModelFormComponent,
    AssetInfoFormComponent,
    ManageTagsFormComponent,
    AssetImageFormComponent
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ],
  selector: 'iot4bos-ui-create-asset-from-scratch-dialog',
  templateUrl: './create-asset-from-scratch-dialog.component.html',
  styleUrl: './create-asset-from-scratch-dialog.component.scss'
})
export class CreateAssetFromScratchDialogComponent {
  readonly data: { site: Site } = inject(MAT_DIALOG_DATA);
  readonly dialogRef: MatDialogRef<CreateAssetFromScratchDialogComponent> = inject(MatDialogRef<CreateAssetFromScratchDialogComponent>);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  form = new FormGroup({
    [AssetCreationStep.MODEL]: new UntypedFormControl(null, [Validators.required]),
    [AssetCreationStep.INFORMATION]: new UntypedFormControl(null, [Validators.required]),
    [AssetCreationStep.TAGS]: new UntypedFormControl(null, [Validators.required]),
    [AssetCreationStep.IMAGE]: new UntypedFormControl(null, [Validators.required])
  });
  steps: Signal<AssetCreationStep[]> = signal([AssetCreationStep.MODEL, AssetCreationStep.INFORMATION, AssetCreationStep.TAGS, AssetCreationStep.IMAGE]);

  modelControlChanges = toSignal(this.modelControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)));
  manageTagFormData = computed(() => {
    const entity = this.modelControlChanges()?.entity;
    return {
      concepts: ['asset'],
      selectedTags: [],
      objectName: null,
      currentEntityId: entity?.id,
      withChildren: false,
      joinable: true,
      withParents: true,
      enforceMandatoryCategories: true
    };
  });

  get model() {
    return this.modelControl.getRawValue()?.model;
  }

  get entity() {
    return this.modelControl.getRawValue()?.entity;
  }

  get site(): AbstractControl {
    return this.modelControl.getRawValue()?.site;
  }

  get modelControl() {
    return this.form.get(AssetCreationStep.MODEL);
  }

  get infoControl() {
    return this.form.get(AssetCreationStep.INFORMATION);
  }

  get tagsControl() {
    return this.form.get(AssetCreationStep.TAGS);
  }

  get imageControl() {
    return this.form.get(AssetCreationStep.IMAGE);
  }

  onStepperInteracted(event: CdkStep): void {
    event?.stepControl?.markAllAsTouched();
  }

  onChangedForm(step, event): void {
    this.form.controls[step].setValue(event);
  }

  save(): void {
    this.dialogRef.close({
      asset: {
        ...this.infoControl.getRawValue(),
        site: { ...this.site, entity: { ...this.entity } },
        imageUrl: this.imageControl.getRawValue(),
        tags: (this.tagsControl.value as TagCategory[]).map((cat) => cat.labels[0].id)
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  protected readonly AssetCreationStep = AssetCreationStep;
}
