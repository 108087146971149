import { I4bCellConcept } from '../enums';
import { I4BCell, I4BCellType, I4BColumnConfiguration, I4BColumnRowGroupConfiguration } from './i4b-column-configuration';

export class BasicColumnConfiguration implements I4BColumnConfiguration {
  type = I4BCellType.BASIC;
  width = 200;
  position = 'start center';
  cell: I4BCell = { type: I4BCellType.BASIC };
  id = null;
  name = null;
  sortProperty = this.name;
  translationKey: string | null = null;
  concept!: I4bCellConcept;
  isDefault = true;
  isConfigurable = true;
  isLink = false;
  marryChildren = false;
  rowGroupParams!: Partial<I4BColumnRowGroupConfiguration | { [key: string]: I4BColumnRowGroupConfiguration }> | null;

  constructor(customConfig?: Partial<BasicColumnConfiguration>) {
    if (customConfig) {
      this.id = customConfig.id;
      this.width = customConfig.width ?? this.width;
      this.name = customConfig.name ?? this.name;
      this.sortProperty = customConfig.sortProperty ?? this.sortProperty;
      this.concept = customConfig.concept ?? this.concept;
      this.translationKey = customConfig.translationKey ?? this.translationKey;
      this.cell = customConfig.cell ?? this.cell;
      this.rowGroupParams = customConfig?.rowGroupParams;
      this.marryChildren = customConfig.marryChildren !== undefined ? customConfig.marryChildren : this.marryChildren;
      this.isDefault = customConfig.isDefault !== undefined ? customConfig.isDefault : this.isDefault;
      this.isConfigurable = customConfig.isConfigurable !== undefined ? customConfig.isConfigurable : this.isConfigurable;
      this.isLink = customConfig.isLink !== undefined ? customConfig.isLink : this.isLink;
    }
  }
}
