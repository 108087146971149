import { inject, Injectable, Signal } from '@angular/core';
import { NavigationApi } from '../containers/+state/navigation.api';
import { AbstractAssociationAssetDeviceSettingsService } from '@iot-platform/iot4bos/feature/association-asset-device';
import { Site } from '@iot-platform/models/i4b';

@Injectable({
  providedIn: 'root'
})
export class AssociationAssetDeviceSettingsService extends AbstractAssociationAssetDeviceSettingsService {
  protected readonly navigationApi: NavigationApi = inject(NavigationApi);

  selectedSite: Signal<Site> = this.navigationApi.site;

  selectAsset(siteId: string, assetId: string): void {
    this.navigationApi.selectAsset(siteId, assetId);
  }

  selectDevice(siteId: string, deviceId: string): void {
    this.navigationApi.selectDevice(siteId, deviceId);
  }
}
