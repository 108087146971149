import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'iot4bos-ui-multi-select-chip',
    templateUrl: './multi-select-chip.component.html',
    styleUrls: ['./multi-select-chip.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectChipComponent),
            multi: true
        }
    ],
    standalone: false
})
export class MultiSelectChipComponent implements ControlValueAccessor {
  ctrl = new UntypedFormControl([]);

  @Input() title!: string;
  @Input() options?: { key: string; label: string }[] | null;

  get value(): string[] {
    return this.ctrl.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string[]) => {};

  onTouched = () => {};

  onChipAdded() {
    this.onChange(this.ctrl.value);
  }

  getLabel(key: string): string {
    return this.options?.find((i) => i.key === key)?.label ?? '';
  }

  onChipRemoved(toRemove: string) {
    const selectedList = this.ctrl.value.filter((v: string) => v !== toRemove);
    this.ctrl.setValue(selectedList);
    this.onChange(selectedList);
  }

  writeValue(value: string[]): void {
    this.ctrl.setValue(value);
    this.onChange(value);
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.ctrl.disable();
    }
  }
}
