import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '@iot-platform/models/common';
import { ContactNotification, ExportSpreadsheet } from '@iot-platform/models/i4b';

@Component({
    selector: 'iot4bos-ui-contact-associated-notifications-exports-popup',
    templateUrl: './contact-associated-notifications-exports-popup.component.html',
    styleUrls: ['./contact-associated-notifications-exports-popup.component.scss'],
    standalone: false
})
export class ContactAssociatedNotificationsExportsPopupComponent {
  associatedNotifications: ContactNotification[] = [];
  selectedNotification?: ContactNotification;
  selectedExportSpreadsheet?: ExportSpreadsheet;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { contact: Contact; displayNotifications: boolean; displayExportSpreadsheets: boolean }) {}

  selectNotification(notification: ContactNotification): void {
    this.selectedNotification = notification;
  }

  selectExportSpreadsheet(exportSpreadsheet: ExportSpreadsheet): void {
    this.selectedExportSpreadsheet = exportSpreadsheet;
  }
}
