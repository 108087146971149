export enum CountryFilterType {
  NAME = 'name',
  CCA2 = 'countryCode',
  CCA3 = 'countryCodeAlpha3',
  PHONE = 'phone',
  ALL = 'all'
}

export interface StateProvince {
  name: string;
}

export interface Country {
  name: string;
  countryCode: string;
  countryCodeAlpha3: string;
  phone: string;
  currency: string;
  stateProvinces: StateProvince[];
}
