export const ALPHA_CHARACTERS_REGEX = /[A-Za-z]/;
export const ESCAPE_SPACE_REGEX = /\s+/g;
export const IPV4_REGEX = new RegExp('^(?:(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)$');

export class StringUtils {
  static hasAlphaCharacters = (str: string | null = null): boolean => str && !!str.match(ALPHA_CHARACTERS_REGEX);
  static escapeSpaces = (str: string | null = null): string => (str ? str.replace(ESCAPE_SPACE_REGEX, '') : str);

  static padWith = (num: number, totalLength: number, str: string): string => String(num).padStart(totalLength, str);
  static capitalizeFirstCharacter = (str: string | null): string => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');

  static isValidIPv4 = (ip: string | null | undefined) => !!ip && IPV4_REGEX.test(ip);
}
