import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactsService } from '@iot-platform/data-access/contacts';
import { ContactFormComponent } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Contact, EmailTemplate, Filter } from '@iot-platform/models/common';
import { Asset, ContactNotification, Site } from '@iot-platform/models/i4b';
import { AssetsService } from '@iot-platform/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

type KeyLabel = {
  key: string;
  label: string;
};

@Component({
    selector: 'iot4bos-ui-notification-creation-form',
    templateUrl: './notification-creation-form.component.html',
    styleUrls: ['./notification-creation-form.component.scss'],
    standalone: false
})
export class NotificationCreationFormComponent implements OnInit, OnDestroy {
  notificationForm!: UntypedFormGroup;
  initialFormState!: string;

  emailTemplateList: EmailTemplate[] = [];
  assets: Asset[] = [];
  eventClasses: KeyLabel[] = [];
  eventSeverities: KeyLabel[] = [];
  showSpinner = true;

  dialogTitle: string = this.data.notification.id
    ? this.translateService.instant('SITES.NOTIFICATION_FORM.TITLE_UPDATE', { notificationName: this.data.notification.name })
    : this.translateService.instant('SITES.NOTIFICATION_FORM.TITLE_CREATE');

  contactCheckedTotal = 0;

  destroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ContactFormComponent>,
    private readonly translateService: TranslateService,
    private readonly contactsService: ContactsService,
    private readonly assetsService: AssetsService,
    @Inject(MAT_DIALOG_DATA) public data: { site: Site; contacts: Contact[]; notification: ContactNotification | Partial<ContactNotification> }
  ) {}

  get nameControl() {
    return this.notificationForm.get('name') as AbstractControl;
  }

  get statusControl() {
    return this.notificationForm.get('status') as AbstractControl;
  }

  get emailTemplateControl() {
    return this.notificationForm.get('emailTemplate') as AbstractControl;
  }

  get assetNamesControl() {
    return this.notificationForm.get('assetNames') as AbstractControl;
  }

  get eventClassesControl() {
    return this.notificationForm.get('eventClasses') as AbstractControl;
  }

  get eventSeveritiesControl() {
    return this.notificationForm.get('eventSeverities') as AbstractControl;
  }

  get currentFormState(): string {
    return JSON.stringify({ contactForm: this.notificationForm.value });
  }

  ngOnInit() {
    this.initForm();

    this.notificationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => (this.contactCheckedTotal = this.getContactIdsTotal()));

    this.assetsService
      .getManyBySiteId(this.data.site.id as string)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((assets) => {
        this.assets = assets;
        if (this.data.notification.id) {
          const notificationAssetNames = this.data.notification.filters?.filter((f: Filter) => f.criteriaKey === 'assetName').map((f) => f.value) ?? [];
          this.assetNamesControl.setValue([...notificationAssetNames]);
          this.initialFormState = this.currentFormState;
        }
      });
    this.getEventSeverities().subscribe((severities) => {
      this.eventSeverities = severities;
      const notificationEventSeverities = this.data.notification.filters?.filter((f: Filter) => f.criteriaKey === 'eventSeverity').map((f) => f.value) ?? [];
      this.eventSeveritiesControl.setValue([...notificationEventSeverities]);
      this.initialFormState = this.currentFormState;
    });
    this.getEventClassList().subscribe((eventClasses) => {
      this.eventClasses = eventClasses;
      const notificationEventClasses = this.data.notification.filters?.filter((f: Filter) => f.criteriaKey === 'eventClass').map((f) => f.value) ?? [];

      this.eventClassesControl.setValue([...notificationEventClasses]);
      this.initialFormState = this.currentFormState;
    });
    this.contactsService
      .getEmailTemplatesByEntityId(this.data.site.entity?.id as string)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((emailTemplates) => {
        this.showSpinner = false;
        this.emailTemplateList = emailTemplates.sort(SortUtil.sortByName);
        this.emailTemplateControl.setValue(
          this.emailTemplateList.find((t: EmailTemplate) => t.id === this.data.notification.emailTemplateId)?.id ??
            this.emailTemplateList.find((t) => t.name === 'default')?.id
        );
        this.initialFormState = this.currentFormState;
      });
  }

  initForm(): void {
    this.notificationForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.notification.id ? this.data.notification.name : '', [Validators.required, Validators.maxLength(50)]),
      status: new UntypedFormControl(this.data.notification.id ? this.data.notification.status === 'active' : true, [Validators.required]),
      emailTemplate: new UntypedFormControl('', [Validators.required]),
      assetNames: new UntypedFormControl([], [Validators.required]),
      eventClasses: new UntypedFormControl([], [Validators.required]),
      eventSeverities: new UntypedFormControl([], [Validators.required])
    });

    this.data.contacts.forEach((c) =>
      this.notificationForm.addControl(c.id as string, new UntypedFormControl(!!this.data.notification.contactIds?.find((id) => id === c.id)))
    );
  }

  formStateChanged(): boolean {
    return this.initialFormState !== this.currentFormState;
  }

  getNotificationFiltersFromFormControls(): Filter[] {
    return [
      ...(this.eventSeveritiesControl?.value ?? []).map((value: string) => ({ value, criteriaKey: 'eventSeverity' })),
      ...(this.eventClassesControl?.value ?? []).map((value: string) => ({ value, criteriaKey: 'eventClass' })),
      ...(this.assetNamesControl?.value ?? []).map((value: string) => ({ value, criteriaKey: 'assetName' }))
    ];
  }

  getContactIdsTotal(): number {
    return this.data.contacts.filter((c) => (this.notificationForm.get(c.id as string) as AbstractControl).value).length;
  }

  getContactIdsToSub(): string[] {
    return this.data.contacts
      .filter((c) => (this.notificationForm.get(c.id as string) as AbstractControl).value && !this.data.notification?.contactIds?.includes(c.id as string))
      .map((c) => c.id) as string[];
  }

  getContactIdsToUnsub(): string[] {
    return (this.data.notification?.contactIds?.filter((contactId) => !(this.notificationForm.get(contactId) as AbstractControl)?.value) as string[]) ?? [];
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newNotification = {
      ...this.data.notification,
      status: this.statusControl.value ? 'active' : 'inactive',
      name: this.nameControl.value,
      emailTemplateId: this.emailTemplateControl.value,
      filters: this.getNotificationFiltersFromFormControls()
    } as ContactNotification;

    this.dialogRef.close({ notification: newNotification, contactsToSub: this.getContactIdsToSub(), contactsToUnsub: this.getContactIdsToUnsub() });
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  private getEventClassList() {
    return this.contactsService.getClassList().pipe(map((arr: string[]) => arr.sort().map((item: string) => ({ key: item, label: item }) as KeyLabel)));
  }

  private getEventSeverities() {
    return this.contactsService
      .getSeverities()
      .pipe(
        map((arr: string[]) =>
          arr.sort().map((item) => ({ key: item, label: this.translateService.instant(`IOT_DICTIONARY.${item.toLowerCase()}`) }) as KeyLabel)
        )
      );
  }
}
