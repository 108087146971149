import { Component, inject, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ImageSelectorV2Component } from '@iot-platform/iot-platform-ui';
import { DevicesDirectoryService } from '@iot-platform/iot4bos/data-access/devices';
import { Device } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot4bos-ui-device-image-dialog',
  templateUrl: './device-image-dialog.component.html',
  styleUrls: ['./device-image-dialog.component.scss'],
  imports: [FlexLayoutModule, MatCardModule, MatToolbarModule, MatIconModule, MatButtonModule, TranslateModule, ImageSelectorV2Component]
})
export class DeviceImageDialogComponent {
  private readonly directoryService: DevicesDirectoryService = inject(DevicesDirectoryService);
  private readonly dialogRef: MatDialogRef<DeviceImageDialogComponent> = inject(MatDialogRef);
  public data: { device: Device } = inject(MAT_DIALOG_DATA);
  image: WritableSignal<string | null> = signal(this.data.device?.imageUrl ?? null);

  images = toSignal(this.directoryService.getDeviceImages());
  defaultImage = signal(this.directoryService.getDefaultDeviceImage());

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.image());
  }
}
