<div class="panel-header mb-4" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <h2 class="panel-title">{{ 'GRAPH.DASHBOARD.GRAPH' | translate | uppercase }}</h2>
  <hr class="regular-1px-line" fxFlex />
  <div [matTooltip]="'GRAPH.CREATE' | translate" class="panel-button app-round-button" matTooltipClass="regular-tooltip">
    <button (click)="addWidget.emit('graph')" [disabled]="!graphPermissions.create" mat-icon-button>
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
@if (widgets()?.length) {
  <iot-platform-ui-widgets-container
    (dispatchEvent)="onDispatchEvent($event)"
    (widgetsOrderChange)="onWidgetsOrderChange($event)"
    [widgets]="widgets()"
    [enableDragAndDrop]="enableDragAndDrop()"
  />
} @else {
  <div class="no-graph-message" fxLayout="row" fxLayoutAlign="center center">
    {{ 'GRAPH.DASHBOARD.NO_GRAPH_FOUND' | translate }}
  </div>
}
