<iot-platform-ui-toolbar-v2
  (dispatchToolbarEvent)="onToolbarEvent($event)"
  [buttonList]="businessProfilesButtonList"
  [isDataLoaded]="!businessProfilesLoading()"
  [name]="'ADMIN.BUSINESS_PROFILES.TOOLBAR_TITLE' | translate"
  [size]="toolbarSize"
  [total]="total()"
/>
<div class="bp-page">
  <div class="full-height-with-overflow" fxFlex="30">
    @if (!businessProfilesLoading() || selectedBusinessProfile()) {
      <iot4bos-ui-backoffice-filter-bp-by-name [searchString]="searchString()" (filter)="filter($event)" />
    }
    <iot4bos-ui-backoffice-business-profiles-list
      (selectBusinessProfile)="selectBusinessProfile($event)"
      [navigateOnClick]="true"
      [profiles]="filteredProfiles()"
      [selectedBusinessProfile]="selectedBusinessProfile()"
    />
  </div>
  <div class="full-width">
    @if (!selectedBusinessProfile()) {
      <span fxFlexFill class="regular-background no-profile" fxLayoutAlign="center center">{{
        'ADMIN.BUSINESS_PROFILES.DETAIL.SELECT_A_BUSINESS_PROFILES' | translate | uppercase
      }}</span>
    } @else {
      <div fxFlexFill class="regular-background h-full p-20" fxLayout="column">
        <mat-toolbar color="accent" fxLayoutAlign="space-between center">
          <h2 fxLayout="row" fxLayoutAlign="start center">
            {{ selectedBusinessProfile().name }}
            <span
              class="entity-name"
              fxLayoutAlign="start center"
              [ngClass]="{ 'entity-name--disabled': !canReadOrganizations }"
              (click)="canReadOrganizations && navigateToSelectedOrganization(selectedBusinessProfile().entityId)"
            >
              {{ selectedBusinessProfile().entityName }}
            </span>
          </h2>
          <span fxFlex></span>
          @if (canUpdateBusinessProfile) {
            <button
              [matTooltip]="'ADMIN.BUSINESS_PROFILES.DETAIL.EDIT_BUSINESS_PROFILE_NAME' | translate"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="below"
              mat-icon-button
              (click)="editBusinessProfile(selectedBusinessProfile())"
            >
              <mat-icon>edit</mat-icon>
            </button>
          }
          <div [matTooltip]="bpDeleteTooltip()" matTooltipClass="regular-tooltip" matTooltipPosition="below">
            @if (canDeleteBusinessProfile) {
              <button
                class="icon-disabled-white"
                [disabled]="bpDeleteButtonDisabled()"
                mat-icon-button
                (click)="deleteBusinessProfile(selectedBusinessProfile())"
              >
                <mat-icon>delete</mat-icon>
              </button>
            }
          </div>
        </mat-toolbar>
        <mat-tab-group fxFlex mat-align-tabs="left" mat-stretch-tabs color="accent">
          <mat-tab label="{{ 'ADMIN.BUSINESS_PROFILES.TABS.INFO' | translate | uppercase }}">
            <ng-template matTabContent>
              <iot4bos-ui-backoffice-business-profiles-detail
                [businessProfile]="selectedBusinessProfile()"
                [users]="members()"
                [roles]="roles()"
                [rolesPendingStatus]="rolesLoading()"
                [membersPendingStatus]="membersLoading()"
                [canCreateBusinessProfile]="canCreateBusinessProfile"
                [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                (addMemberToProfile)="onAddMembersToProfile()"
                (addRoleToProfile)="onAddRolesToProfile()"
              />
            </ng-template>
          </mat-tab>
          <mat-tab label="{{ 'ADMIN.BUSINESS_PROFILES.TABS.TAGS' | translate | uppercase }}">
            <ng-template matTabContent>
              <iot4bos-ui-backoffice-business-profile-tags
                [tags]="tags()"
                [tagsPendingStatus]="tagsLoading()"
                [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                (addTagsToProfile)="onAddTagsToProfile($event)"
              />
            </ng-template>
          </mat-tab>
          @if (displayFavoriteViewsTab()) {
            <mat-tab class="favorite-views-tab">
              <ng-template mat-tab-label>
                {{ 'ADMIN.BUSINESS_PROFILES.TABS.FAVORITE_VIEW_MANAGER' | translate | uppercase }}
                @if (favoriteViewsLoading()) {
                  <mat-progress-spinner [diameter]="14" mode="indeterminate" color="accent" style="margin-left: 8px" />
                } @else {
                  <span style="width: 20px"></span>
                }
              </ng-template>
              <ng-template matTabContent>
                <iot4bos-ui-backoffice-business-profile-favorite-views-tab
                  [masterViewList]="masterViews"
                  [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                  (addFavoriteView)="onAddFavoriteView($event)"
                  class="layout-master-view-engine-container p-4"
                  fxFlex
                />
              </ng-template>
            </mat-tab>
          }
          @if (displayGridsTab()) {
            <mat-tab class="grids-tab" label="{{ 'ADMIN.BUSINESS_PROFILES.TABS.GRID_MANAGER' | translate | uppercase }}">
              <ng-template matTabContent>
                <iot4bos-ui-backoffice-business-profile-grids-tab
                  [masterViewList]="masterViews"
                  [canUpdateBusinessProfile]="canUpdateBusinessProfile"
                  (addGrid)="onAddGrid($event)"
                  class="layout-master-view-engine-container p-4"
                  fxFlex
                />
              </ng-template>
            </mat-tab>
          }
        </mat-tab-group>
      </div>
    }
  </div>
</div>
