import { Component, effect, input, signal, Signal, WritableSignal } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinner, ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  imports: [MatProgressSpinner],
  selector: 'iot-platform-ui-spinner-background',
  templateUrl: './spinner-background.component.html',
  styleUrls: ['./spinner-background.component.scss'],
  host: {
    class: 'spinner-container',
    '[style.width.px]': 'diameter()',
    '[style.height.px]': 'diameter()',
    '[style.line-height.px]': 'diameter()'
  }
})
export class SpinnerBackgroundComponent {
  color = input<ThemePalette>('accent');
  diameter = input<number>();
  strokeWidth = input<number>();
  value = input<number>();

  progress: WritableSignal<number> = signal(50);
  mode: Signal<ProgressSpinnerMode> = signal('determinate');

  valueChangeEffect = effect(() => {
    const value = this.value();
    if (!value || Number.isNaN(value)) {
      this.progress.set(0);
    } else {
      this.progress.set(value);
    }
  });
}
