import { inject } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { DeviceEventsService } from '../../services/device-events.service';
import { DeviceEventsCommentsActions } from '../actions';
import { DeviceEventsFacade } from '../facades/device-events.facade';

const loadDeviceEventComments$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.loadComments),
      concatMap(({ event }) =>
        deviceEventsService.loadComments(event).pipe(
          map((comments: Log[]) => DeviceEventsCommentsActions.loadCommentsSuccess({ comments })),
          catchError((error) => of(DeviceEventsCommentsActions.loadCommentsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.addComment),
      concatMap(({ event, comment }) =>
        deviceEventsService.addComment(event.id, comment).pipe(
          map((addedComment: Log) => DeviceEventsCommentsActions.addCommentSuccess({ comment: addedComment, event })),
          catchError((error) => of(DeviceEventsCommentsActions.addCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const addCommentSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsFacade = inject(DeviceEventsFacade)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.addCommentSuccess),
      tap(({ event }) => deviceEventsFacade.updateEventInAllGrids({ ...event, totalComments: event.totalComments + 1 }))
    ),
  { functional: true, dispatch: false }
);

const editComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.editComment),
      concatMap(({ eventId, comment }) =>
        deviceEventsService.editComment(eventId, comment).pipe(
          map((editedComment: Log) => DeviceEventsCommentsActions.editCommentSuccess({ comment: editedComment })),
          catchError((error) => of(DeviceEventsCommentsActions.editCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteComment$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsService = inject(DeviceEventsService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.deleteComment),
      concatMap(({ event, commentId }) =>
        deviceEventsService.deleteComment(event.id, commentId).pipe(
          map((deletedCommentId: string) =>
            DeviceEventsCommentsActions.deleteCommentSuccess({
              commentId: deletedCommentId,
              event
            })
          ),
          catchError((error) => of(DeviceEventsCommentsActions.deleteCommentFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteCommentSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceEventsFacade = inject(DeviceEventsFacade)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.deleteCommentSuccess),
      tap(({ event }) =>
        deviceEventsFacade.updateEventInAllGrids({
          ...event,
          totalComments: event.totalComments > 0 ? event.totalComments - 1 : 0
        })
      )
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsCommentsActions.loadComments,
        DeviceEventsCommentsActions.addComment,
        DeviceEventsCommentsActions.editComment,
        DeviceEventsCommentsActions.deleteComment
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsCommentsActions.loadCommentsSuccess,
        DeviceEventsCommentsActions.loadCommentsFailure,
        DeviceEventsCommentsActions.addCommentSuccess,
        DeviceEventsCommentsActions.addCommentFailure,
        DeviceEventsCommentsActions.editCommentSuccess,
        DeviceEventsCommentsActions.editCommentFailure,
        DeviceEventsCommentsActions.deleteCommentSuccess,
        DeviceEventsCommentsActions.deleteCommentFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceEventsCommentsActions.addCommentSuccess, DeviceEventsCommentsActions.editCommentSuccess, DeviceEventsCommentsActions.deleteCommentSuccess),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DeviceEventsCommentsActions.loadCommentsFailure,
        DeviceEventsCommentsActions.addCommentFailure,
        DeviceEventsCommentsActions.editCommentFailure,
        DeviceEventsCommentsActions.deleteCommentFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const DeviceEventsCommentsEffects = {
  loadDeviceEventComments$,
  addComment$,
  addCommentSuccess$,
  editComment$,
  deleteComment$,
  deleteCommentSuccess$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
