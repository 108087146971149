<div class="stock-card">
  <div class="stock-card-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="stock-card-col" fxFlex="340px" fxFlex.lt-md="100%"></div>
    <div class="stock-card-col" fxFlex="100%">
      <span class="stock-card-name">{{ site?.name | infoDisplay }}</span>
      <div class="stock-card-content" fxLayout="row wrap">
        <ul class="stock-card-list" fxFlex="40%" fxFlex.lt-sm="100%">
          <li class="stock-card-item">
            <span class="stock-card-label">{{ 'SITES.CARD.TYPE' | translate }} : </span>
            <span> {{ 'SITES.CARD.TYPES.' + site.type | infoDisplay | translate }} </span>
          </li>
          <li class="stock-card-item">
            <span class="stock-card-label">{{ 'SITES.CARD.ENTITY' | translate }} : </span>
            <span> {{ site?.entity?.name | infoDisplay }} </span>
          </li>
        </ul>
        <div fxFlex="60%" fxFlex.lt-sm="100%">
          <div class="stock-card-total capitalize-first-letter">
            @if (totalDevicesLoading) {
              <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate"></mat-progress-spinner>
            } @else {
              <span class="fw-600"> {{ totalDevices | numberFormat }}</span>
            }
            {{ 'IOT_DICTIONARY.devices' | translate }}
          </div>
          @if (deviceCountByFamilyLoading && totalDevices > 0) {
            <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate"></mat-progress-spinner>
          }
          @if (totalDevices > 0 && !deviceCountByFamilyLoading) {
            <ul class="stock-card-types" fxLayout="row wrap">
              @for (item of deviceCountByFamily; track item) {
                <li fxFlex="25%">
                  @if (item.total > 0) {
                    <div>
                      <span class="fw-600">{{ item.total | numberFormat }}</span> {{ item.family }}
                    </div>
                  }
                </li>
              }
            </ul>
          }
        </div>
      </div>
    </div>
  </div>
</div>
