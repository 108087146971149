import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile, CommonApiListResponse, Organization } from '@iot-platform/models/common';
import * as momentTimezones from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// https://github.com/BrianKopp/ngrx-cognito
// https://www.integralist.co.uk/posts/cognito/
// https://aws-amplify.github.io/docs/js/angular#subscribe-to-authentication-state-changes
// https://blog.angularindepth.com/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6

@Injectable({
  providedIn: 'root'
})
export class AuthBusinessProfilesService {
  constructor(
    @Inject('environment') private environment,
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  public loadAccount(): Observable<any> {
    // In account we have : User infos, preferences, business profiles, privileges
    return this.httpClient.get(`${this.environment.api.url}/account`);
  }

  public selectBusinessProfile(profile: BusinessProfile) {
    const zone = profile?.timezoneDetails?.name ? momentTimezones().tz(profile.timezoneDetails.name).format('Z') : null;
    const bp: BusinessProfile = {
      ...profile,
      timezoneDetails: { ...profile?.timezoneDetails, offset: zone }
    } as BusinessProfile;
    this.storage.set(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY, JSON.stringify(bp));
    return of(profile);
  }

  public getBusinessProfileId() {
    return this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)
      ? JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).id
      : '';
  }

  public getEntityById(entityId: string): Observable<Organization> {
    return this.httpClient.get<Organization>(`${this.environment.api.url}${this.environment.api.endpoints.entities}/${entityId}`);
  }

  public getPerimeterEntities(): Observable<Organization[]> {
    return this.httpClient
      .get<CommonApiListResponse<Organization>>(`${this.environment.api.url}${this.environment.api.endpoints.entities}`)
      .pipe(map((response) => response.content));
  }

  private getBusinessProfileFromLocalStorage() {
    return JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY));
  }
}
