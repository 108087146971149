import { Pipe, PipeTransform } from '@angular/core';

/**
 * Prettifies an XML string by adding indentation and line breaks.
 *
 * @param xml - The XML string to prettify.
 * @param [indentation=2] - The number of spaces to use for indentation.
 * @returns - The prettified XML string.
 */
const prettifyXML = (xml: string, indentation = 2) => {
  try {
    const PADDING = ' '.repeat(indentation);
    const reg = /(>)(<)(\/*)/g;
    let formatted = '';
    let pad = 0;

    xml = xml.replace(reg, '$1\r\n$2$3');
    xml.split('\r\n').forEach((node: string) => {
      let indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad !== 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w([^>]*[^\\/])?>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      formatted += PADDING.repeat(pad) + node + '\r\n';
      pad += indent;
    });

    return formatted.trim();
  } catch (e) {
    return xml;
  }
};

@Pipe({
  name: 'prettifyXML',
  standalone: true
})
export class PrettifyXmlPipe implements PipeTransform {
  transform(content: string | null | undefined, indentation = 2) {
    if (content) {
      return prettifyXML(content, indentation);
    }
    return content;
  }
}
