<mat-sidenav-container (backdropClick)="onCloseComments()" fxFlex hasBackdrop="true">
  <mat-sidenav-content fxLayout="column">
    @if (displayFilterEngine) {
      <iot-platform-ui-filter-engine
        [masterView]="masterview()"
        [expanded]="true"
        [displayActionButtons]="false"
        [withFavoriteFilters]="true"
        [currentFavoriteView]="currentFavoriteView$ | async"
        [currentFilters]="currentFilters$ | async"
        [mode]="FilterEngineMode.FAVORITE"
        (applyFilters)="onApplyFilters($event)"
      />
    }

    <grid-engine-grid-manager
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      [currentFilters]="currentFilters$ | async"
      [gridSort]="gridSort$ | async"
      [grid]="grid"
      [userPermissions]="userPermissions()"
    />
  </mat-sidenav-content>

  <mat-sidenav #sidenav disableClose position="end">
    <iot4bos-ui-comments-panel
      (commentsEvent)="onCommentsEvent($event)"
      [canDeleteComment]="canUpdateEvent"
      [canEditComment]="canUpdateEvent"
      [comments]="comments()"
      [defaultConcept]="CONCEPT.EVENT"
      [filters]="commentsContexts"
      [loading]="commentsLoading()"
      [withFilters]="true"
      [withFooter]="canUpdateEvent"
      data-cy="iot4bos-ui-events-comments"
    />
  </mat-sidenav>
</mat-sidenav-container>
