import { CellClassParams, CellStyle, ValueGetterParams } from '@ag-grid-community/core';
import { get } from 'lodash';
import { BasicColumnConfiguration } from '../configurations/i4b-basic-column-configuration';
import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class I4BBasicColumn implements I4BColumn<I4BBasicColumnHeader, I4BColumnConfiguration, I4BColumnOptions> {
  columnId = '65b4f54c-5b50-11ec-80a5-0023a40210d0-basic';
  className = I4BBasicColumn;
  dataLibs: DataLibrary[] = [
    DataLibrary.SITES,
    DataLibrary.ASSETS,
    DataLibrary.DEVICES,
    DataLibrary.TAGS,
    DataLibrary.VARIABLES,
    DataLibrary.TECHNICAL,
    DataLibrary.CONNECTORS
  ];
  header: I4BBasicColumnHeader = new I4BBasicColumnHeader();
  configuration: I4BColumnConfiguration = new BasicColumnConfiguration({ isConfigurable: true, isDefault: false });
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = {
        ...this.header,
        ...customHeader,
        displayName: customOptions?.overrideHeaderName
          ? customOptions.overrideHeaderName
          : customHeader?.displayName
            ? customHeader.displayName
            : this.header.displayName
      };
    }

    if (customConfig) {
      this.configuration = {
        ...this.configuration,
        ...customConfig,
        width: customOptions?.width ? customOptions.width : customConfig.width ?? I4BBasicColumn.getDefaultSizeByCellType(customConfig.cell?.type)
      };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }

    const translatedValues = get(this.configuration, 'cell.options.translatedValues');
    if (translatedValues) {
      this.configuration.cell.valueGetter = (params: ValueGetterParams) => {
        const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
        const value = get(params, `data.${params.colDef.field}`);
        if (cellOptions?.translatedValues) {
          const options = get(cellOptions, `translatedValues[${value}]`);
          return options ? options.value : null;
        } else {
          return value;
        }
      };
      this.configuration.cell.cellStyle = (params: CellClassParams): CellStyle => {
        const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
        const value = get(params, `data.${params.colDef.field}`);
        const cssOptions: any = {};
        if (cellOptions?.textAlign) {
          cssOptions.textAlign = cellOptions.textAlign;
        }
        const translatedOptions = get(cellOptions, `translatedValues[${value}]`);
        if (translatedOptions?.backgroundColor) {
          cssOptions.backgroundColor = translatedOptions.backgroundColor;
        }
        if (translatedOptions?.color) {
          cssOptions.color = translatedOptions.color;
        }
        return cssOptions;
      };
    }
  }

  static getDefaultSizeByCellType(cellType?: I4BCellType): number {
    switch (cellType) {
      case I4BCellType.DATE:
      case I4BCellType.EVENT_DURATION_CELL:
        return 170;
      case I4BCellType.ICON:
      case I4BCellType.CYCLICAL_CALL_FAULT:
      case I4BCellType.COLOR_CELL:
        return 90;
      case I4BCellType.SEVERITY_CELL:
        return 130;
      case I4BCellType.ADDRESS_ACCURACY:
        return 160;
      case I4BCellType.ASSET_TEMPLATE:
        return 200;

      default:
        return 200;
    }
  }
}
