import { I4BCellType, I4BColumnConfiguration } from './configurations/i4b-column-configuration';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { ArrayOfObjectColumn } from './definitions';
import { I4BBasicColumn } from './definitions/i4b-basic.column';
import { I4BColumn } from './definitions/i4b-column';
import { I4BUsersButtonColumn } from './definitions/i4b-users-button.column';
import { I4bCellConcept } from './enums';
import { UsersGridData } from './models';
import { HeaderType, I4BColumnHeader } from './models/i4b-column-header.model';
import { I4BGridOptions } from './models/i4b-grid-options.model';
import { I4BGrid } from './models/i4b-grid.model';
import { I4BColumnOptions } from './options/i4b-column-options';

export const GET_DEFAULT_USERS_COLUMNS_FOR_USERS_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  new I4BBasicColumn(
    { displayName: 'FIRST NAME' },
    {
      id: 'firstname',
      width: 100,
      isDefault: true,
      sortProperty: 'firstname',
      concept: I4bCellConcept.USERS,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToUser' } }
    },
    { order: 1 }
  ),

  new I4BBasicColumn(
    { displayName: 'LAST NAME' },
    {
      id: 'lastname',
      width: 100,
      isDefault: true,
      sortProperty: 'lastname',
      concept: I4bCellConcept.USERS,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToUser' } }
    },
    { order: 2 }
  ),

  new I4BBasicColumn(
    { displayName: 'EMAIL' },
    {
      id: 'email',
      width: 150,
      isDefault: true,
      sortProperty: 'email',
      concept: I4bCellConcept.USERS,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 3 }
  ),

  new I4BBasicColumn(
    { displayName: 'SECONDARY EMAIL' },
    {
      id: 'secondaryEmail',
      isDefault: true,
      sortProperty: 'secondaryEmail',
      concept: I4bCellConcept.USERS,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 4 }
  ),

  new I4BBasicColumn(
    { displayName: 'PHONE NUMBER' },
    {
      id: 'phoneNumber',
      width: 100,
      isDefault: true,
      sortProperty: 'phoneNumber',
      concept: I4bCellConcept.USERS,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 5 }
  ),

  new ArrayOfObjectColumn(
    { displayName: 'ENTITIES' },
    {
      id: 'entities',
      width: 150,
      isDefault: true,
      concept: I4bCellConcept.USERS,
      cell: { type: I4BCellType.ARRAY_OF_OBJECTS, options: { attributes: ['name'] } }
    },
    { order: 6, sortable: false }
  ),

  new I4BBasicColumn(
    { displayName: 'ACCOUNT TYPE' },
    {
      id: 'isShared',
      width: 100,
      sortProperty: 'isShared',
      isDefault: true,
      concept: I4bCellConcept.USERS,
      cell: {
        type: I4BCellType.BASIC,
        options: {
          capitalize: true,
          translatedValues: {
            true: { value: 'Shared account' },
            false: { value: 'User account' }
          }
        }
      }
    },
    { order: 7 }
  ),

  new I4BBasicColumn(
    { displayName: 'STATUS' },
    {
      id: 'status',
      width: 50,
      sortProperty: 'status',
      isDefault: true,
      concept: I4bCellConcept.USERS,
      cell: {
        type: I4BCellType.BASIC,
        options: {
          capitalize: true,
          translatedValues: {
            Disabled: { color: '#262836', backgroundColor: 'rgba(255, 0, 99, 0.1)', value: 'Disabled' },
            Active: { color: '#262836', backgroundColor: 'rgba(164, 255, 0, 0.1)', value: 'Active' },
            Unconfirmed: { color: '#262836', backgroundColor: 'rgba(255, 158, 0, 0.1)', value: 'Unconfirmed' },
            Pending: { color: '#262836', backgroundColor: 'rgba(0, 121, 255, 0.1)', value: 'Pending' }
          }
        }
      }
    },
    { order: 8 }
  ),

  new I4BBasicColumn(
    { displayName: 'LAST ACTIVITY' },
    {
      id: 'lastActivity',
      width: 150,
      isDefault: true,
      concept: I4bCellConcept.USERS,
      sortProperty: 'lastActivity',
      cell: { type: I4BCellType.DATE }
    },
    { order: 9 }
  ),

  new I4BBasicColumn(
    {
      displayName: 'BUSINESS PROFILES',
      type: HeaderType.ICON,
      icon: 'business_profile'
    },
    {
      id: 'totalBusinessProfile',
      width: 50,
      isDefault: true,
      concept: I4bCellConcept.USERS,
      sortProperty: 'totalBusinessProfile',
      cell: { type: I4BCellType.BASIC }
    },
    { order: 10 }
  )
];

export class DefaultUsersGrid implements I4BGrid<I4BGridOptions, UsersGridData> {
  masterview = 'users';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = 'users';
  userId = '';
  data: UsersGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new I4BUsersButtonColumn() },
    gridSort: []
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];
}
