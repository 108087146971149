import { Directive, ElementRef, HostListener, inject, input, OnChanges } from '@angular/core';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';

export class TextMaskConfig {
  mask!: (string | RegExp)[] | ((raw: string) => (string | RegExp)[]) | false;
  guide?: boolean;
  placeholderChar?: string;
  pipe?: (
    conformedValue: string,
    config: TextMaskConfig & {
      currentCaretPosition: number;
      placeholder: string;
      previousConformedValue: string | undefined;
      rawValue: string;
    }
  ) => false | string | object;
  keepCharPositions?: boolean;
  showMask?: boolean;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[textMask]',
  exportAs: 'textMask'
})
export class InputMaskDirective implements OnChanges {
  private textMaskInputElement: any;
  private inputElement!: HTMLInputElement;
  private enableTextMask = false;
  private readonly elementRef: ElementRef = inject(ElementRef);
  textMask = input(
    {
      mask: [],
      guide: true,
      placeholderChar: '_',
      pipe: undefined,
      keepCharPositions: false
    } as TextMaskConfig,
    {
      transform: (value: Partial<TextMaskConfig>) => {
        this.enableTextMask = !!value;
        return {
          mask: value?.mask ?? [],
          guide: !!value?.guide,
          placeholderChar: value?.placeholderChar ?? '_',
          pipe: value?.pipe,
          keepCharPositions: !!value?.keepCharPositions,
          showMask: !!value?.showMask
        };
      }
    }
  );

  ngOnChanges() {
    if (this.enableTextMask) {
      this.setupMask(true);
      if (this.textMaskInputElement !== undefined) {
        this.textMaskInputElement.update(this.inputElement.value);
      }
    }
  }

  @HostListener('input', ['$event'])
  handleInput(value: any) {
    if (this.enableTextMask) {
      this.setupMask();
      if (this.textMaskInputElement !== undefined) {
        this.textMaskInputElement.update(value?.target?.value);
      }
    }
  }

  setupMask(create = false) {
    if (!this.inputElement) {
      if (this.elementRef.nativeElement.tagName.toUpperCase() === 'INPUT') {
        // `textMask` directive is used directly on an input element
        this.inputElement = this.elementRef.nativeElement;
      } else {
        // `textMask` directive is used on an abstracted input element, `md-input-container`, etc
        this.inputElement = this.elementRef.nativeElement.getElementsByTagName('INPUT')[0];
      }
    }

    if (this.inputElement && create) {
      this.textMaskInputElement = createTextMaskInputElement(
        Object.assign({
          ...this.textMask(),
          inputElement: this.inputElement
        })
      );
    }
  }
}
