import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { CardLoaderModule, DefaultCardComponent } from '@iot-platform/iot-platform-ui';
import { AssetCardComponent } from '@iot-platform/iot4bos/ui/asset';
import { DeviceCardComponent } from '@iot-platform/iot4bos/ui/device';
import { OverviewCardActions, OverviewCardEvent } from '@iot-platform/models/common';
import { AssociationByDevice, DisplayMode } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'iot4bos-association-by-device',
  imports: [FlexLayoutModule, TranslateModule, NgTemplateOutlet, DefaultCardComponent, CardLoaderModule, DeviceCardComponent, AssetCardComponent, NgClass],
  templateUrl: './association-by-device.component.html',
  styleUrl: './association-by-device.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssociationByDeviceComponent {
  private readonly router: Router = inject(Router);

  association = input<AssociationByDevice>();
  laoding = input<boolean>(true);
  displayMode = input<DisplayMode>(DisplayMode.DRAWER);
  dispatchEvent = output<OverviewCardEvent>();

  isPageMode: Signal<boolean> = computed(() => this.displayMode() === DisplayMode.PAGE);
  // eslint-disable-next-line no-useless-escape
  selectedItemId = this.router.routerState.snapshot.url.match(/\/(device|asset)\/([^\/]+)/)?.[2];
  OVERVIEW_CARD_ACTIONS = OverviewCardActions;
}
