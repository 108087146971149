import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, inject, input, model, signal, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AnalyticsService } from '@iot-platform/core';
import { MapGeoCoordinatesComponent } from '@iot-platform/iot-platform-maps';
import {
  ChipComponent,
  IotToolbarDispatchActionType,
  OverviewCardComponent,
  SectionHeaderAction,
  SectionHeaderEvent,
  TabsModule
} from '@iot-platform/iot-platform-ui';
import { AssociationAssetDeviceShellComponent } from '@iot-platform/iot4bos/feature/association-asset-device';
import { Entity, OverviewCard, OverviewCardActions, TagCategory } from '@iot-platform/models/common';
import { DisplayMode, Site } from '@iot-platform/models/i4b';
import { EntityDisplayPipe } from '@iot-platform/pipes';
import { EntitiesService } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SitesFacade } from '../../+state/facades/sites.facade';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { SitesCtaService } from '../../services/sites-cta.service';
import { SitesDirectoryService } from '../../services/sites-directory.service';

@Component({
  selector: 'iot4bos-ui-site-info',
  imports: [
    OverviewCardComponent,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    AssociationAssetDeviceShellComponent,
    TabsModule,
    NgClass,
    NgTemplateOutlet,
    MapGeoCoordinatesComponent,
    MatButtonToggleGroup,
    MatButtonToggle,
    FormsModule,
    MatIcon
  ],
  providers: [EntityDisplayPipe],
  templateUrl: './site-info.component.html'
})
export class SiteInfoComponent {
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  private readonly sitesFacade: SitesFacade = inject(SitesFacade);
  private readonly sitesDirectoryService: SitesDirectoryService = inject(SitesDirectoryService);
  private readonly sitesCtaService: SitesCtaService = inject(SitesCtaService);
  private readonly entitiesService: EntitiesService = inject(EntitiesService);
  public readonly dialog: MatDialog = inject(MatDialog);
  public readonly destroy: DestroyRef = inject(DestroyRef);

  analytic: AnalyticsService = new AnalyticsService('site_info_page');

  canUpdateSite = input<boolean>(false);
  canDeleteSite = input<boolean>(false);
  canCreateAsset = input<boolean>(false);
  canCreateDevice = input<boolean>(false);
  canReadAuditTrail = input<boolean>(false);
  canDisplayTags = input<boolean>(false);
  toggleMap = model<boolean>(false);

  site: Signal<Site> = this.navigationApi.site;
  siteLoading: Signal<boolean> = this.sitesFacade.loading;
  siteLoaded$: Observable<boolean> = this.sitesFacade.loaded$;
  siteTags: Signal<TagCategory[]> = this.navigationApi.siteTags;
  isTagsLoaded: Signal<boolean | undefined> = this.navigationApi.siteTagsLoaded;
  DisplayMode: Signal<DisplayMode> = signal(DisplayMode.PAGE);
  width = '50%';
  defaultImage = signal(this.sitesDirectoryService.getDefaultImage());
  initialTabIndex = signal<number>(0);
  infosTabOptions = signal({
    basicInfosTab: {
      visible: true,
      disabled: false
    }
  });
  entities = toSignal(
    this.siteLoaded$.pipe(
      filter((loaded) => loaded),
      switchMap(() => this.entitiesService.getAll()),
      takeUntilDestroyed(this.destroy)
    )
  );
  overviewCard: Signal<OverviewCard> = computed(() => {
    const site = this.site();
    const tags = this.siteTags();
    return this.getCardMetadata(site, tags);
  });
  createAssetFromScratchAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.CREATE,
    text: signal('ASSETS.SHELL.CREATE_ASSET'),
    disabled: computed(() => !this.canCreateAsset())
  });
  createAssetFromTemplateAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.CREATE_FROM_TEMPLATE,
    text: signal('ASSETS.SHELL.CREATE_ASSET_FROM_TEMPLATE'),
    disabled: computed(() => !this.canCreateAsset())
  });
  createAssetGroupAction: SectionHeaderAction = new SectionHeaderAction({
    action: IotToolbarDispatchActionType.CREATE_ASSET_GROUP,
    text: signal('SITES.ASSOCIATIONS.CREATE_ASSET_GROUP'),
    disabled: computed(() => !this.canCreateAsset())
  });
  sectionHeaderActions: Signal<SectionHeaderAction[]> = signal([
    this.createAssetFromScratchAction,
    this.createAssetFromTemplateAction,
    this.createAssetGroupAction
  ]);

  get isBasicInfoTabSelected() {
    return this.initialTabIndex() === 0;
  }

  onSelectedTabChange({ index }) {
    this.initialTabIndex.set(index);
  }

  editImageClicked() {
    this.analytic.log('tab_site_info_actions', 'open_edit_image');
    this.sitesCtaService.openImageEditionPopup(this.site(), this.siteTags());
  }

  onCardActions(actionType: OverviewCardActions): void {
    switch (actionType) {
      case OverviewCardActions.DELETE:
        this.analytic.log('tab_site_info_actions', 'open_delete_site');
        this.sitesCtaService.openDeleteSiteConfirmationDialog(this.site());
        break;
      case OverviewCardActions.DISPLAY_HISTORY:
        this.analytic.log('tab_site_info_actions', 'open_history');
        this.sitesCtaService.openAuditTrailDialog(this.site());
        break;
      default:
        break;
    }
  }

  onDispatchSectionHeaderEvent(event: SectionHeaderEvent): void {
    if (event.type === this.createAssetFromScratchAction.action) {
      this.sitesCtaService.openAssetCreationFromScratchDialog(this.site());
    } else if (event.type === this.createAssetFromTemplateAction.action) {
      this.sitesCtaService.openAssetCreationFromTemplateDialog(this.site());
    } else if (event.type === this.createAssetGroupAction.action) {
      this.sitesCtaService.openAssetGroupCreationDialog(this.site());
    }
  }

  private getCardMetadata(site: Site, tags: TagCategory[]): OverviewCard {
    return {
      name: site.name2 ? `${site.name} - ${site.name2}` : (site.name as string),
      justifyContent: true,
      actions: [
        {
          label: 'SITES.CARD.DELETE',
          type: OverviewCardActions.DELETE,
          disabled: !this.canDeleteSite() || !this.site()?.allAssetsDecommissioned || !this.site()?.allDevicesDecommissioned
        },
        {
          label: 'AUDIT_TRAIL.CTA.HISTORY',
          type: OverviewCardActions.DISPLAY_HISTORY,
          disabled: !this.canReadAuditTrail()
        }
      ],
      sections: [
        //  GENERAL SECTION
        {
          title: 'IOT_DICTIONARY.GENERAL',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isBasicInfoTabSelected,
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: !this.canUpdateSite(),
            dispatchEvent: () => {
              this.analytic.log('tab_site_info_actions', 'open_edit_site');
              this.sitesCtaService.editSiteGeneralInfo(site);
            }
          },
          properties: [
            {
              label: 'SITES.INFO_FORM.PARENT_ENTITY',
              value: this.getParentEntityName(site.entity as Entity),
              width: this.width
            },
            { label: 'IOT_DICTIONARY.ENTITY', value: site.entity?.name, width: this.width },
            { label: 'SITES.CARD.BUSINESS_ID', value: site.businessId, width: this.width },
            { label: 'SITES.CARD.TYPE', value: 'SITES.CARD.TYPES.' + site.type, width: this.width },
            { label: 'IOT_DICTIONARY.DESCRIPTION', innerHTMLValue: site?.description, width: '100%', cssClass: 'flex-col' }
          ]
        },
        //  LOCATION SECTION
        {
          title: 'IOT_DICTIONARY.LOCATION',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isBasicInfoTabSelected,
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: !this.canUpdateSite(),
            dispatchEvent: () => this.sitesCtaService.editSiteLocationInfo(site)
          },
          properties: [
            { label: 'SITES.INFO_FORM.ADDRESS_1', value: site.address?.address1, width: this.width },
            { label: 'SITES.INFO_FORM.ADDRESS_2', value: site.address?.address2, width: this.width },
            {
              label: 'SITES.CARD.CITY_ZIP_CODE',
              value: `${site.address?.city} ${site.address?.zipCode ? `(${site.address?.zipCode})` : ''}`,
              width: this.width
            },
            {
              label: 'SITES.INFO_FORM.COUNTRY',
              value: 'SITES.CARD.COUNTRIES.' + site.address?.country,
              width: this.width
            },
            { label: 'SITES.INFO_FORM.STATE', value: site.address?.state, width: this.width }
          ]
        },
        //  TAGS SECTION
        {
          title: 'IOT_DICTIONARY.TAGS',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isBasicInfoTabSelected,
          style: { 'margin-bottom': '10px' },
          action: {
            type: OverviewCardActions.EDIT,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: !this.canUpdateSite(),
            dispatchEvent: () => {
              this.analytic.log('tab_site_info_actions', 'open_manage_tags');
              this.sitesCtaService.openManageTagsDialog(site, tags);
            }
          },
          properties: this.isTagsLoaded()
            ? tags?.length > 0
              ? tags?.map((tag) => ({
                  componentRef: {
                    ref: ChipComponent,
                    inputs: {
                      chip: tag,
                      chipButton: { display: false },
                      isTag: true
                    }
                  },
                  width: 'none'
                }))
              : [{ value: 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG', width: '100%' }]
            : undefined
        }
      ]
    };
  }

  private getParentEntityName(entity: Entity): string | null {
    const entities = this.entities() ?? [];
    const fullEntity = entities.find((e) => e.id === entity?.id) as Entity;
    return entities.find((e) => e.id === fullEntity.parentId)?.name ?? null;
  }
}
