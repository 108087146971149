import { AfterViewInit, Component, DestroyRef, effect, inject, Injector, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormControlHintType, FormControlType, FormField, FormGroupComponent } from '@iot-platform/iot-platform-ui';
import { FormsHelpers, NameValidators } from '@iot-platform/iot-platform-utils';
import { Address, Country, Entity, StateProvince } from '@iot-platform/models/common';
import { Site, SiteType } from '@iot-platform/models/i4b';
import { EntitiesService } from '@iot-platform/shared';
import { SitesService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { combineLatest, finalize, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SitesDirectoryService } from '../../services/sites-directory.service';
import { SiteInfoFormControlNames } from './site-info-form.util';

@Component({
  selector: 'iot4bos-ui-site-info-form-dialog',
  templateUrl: './site-info-form-dialog.component.html',
  styleUrls: ['./site-info-form-dialog.component.scss'],
  imports: [FlexLayoutModule, MatCardModule, MatIcon, TranslateModule, MatToolbarModule, MatButtonModule, FormGroupComponent]
})
export class SiteInfoFormDialogComponent implements AfterViewInit {
  private readonly sitesService: SitesService = inject(SitesService);
  private readonly entitiesService: EntitiesService = inject(EntitiesService);
  data: { site: Site; selectedFields: SiteInfoFormControlNames[] } = inject(MAT_DIALOG_DATA);
  public dialogRef: MatDialogRef<SiteInfoFormDialogComponent> = inject(MatDialogRef<SiteInfoFormDialogComponent>);
  siteForm = new FormGroup({});
  initialFormState: unknown;
  currentSite: WritableSignal<Site> = signal(null);
  fields: WritableSignal<Partial<FormField>[]> = signal([]);
  loading: WritableSignal<boolean> = signal<boolean>(true);
  types: WritableSignal<SiteType[]> = signal([]);
  entities: WritableSignal<Entity[]> = signal([]);
  countries: WritableSignal<Country[]> = signal([]);
  states: WritableSignal<StateProvince[]> = signal([]);
  lookups: Signal<{
    types: SiteType[];
    countries: Country[];
    entities: Entity[];
  }>;
  nameMaxLength = signal(60);
  nameMinLength = signal(1);
  businessIdMaxLength = signal(30);
  addressMaxLength = signal(50);
  cityMaxLength = signal(30);
  zipCodeMaxLength = signal(10);
  descriptionMaxLength = signal(300);
  disableStateField = signal(true);
  protected readonly sitesDirectoryService: SitesDirectoryService = inject(SitesDirectoryService);
  protected readonly injector: Injector = inject(Injector);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.lookups = toSignal(this.getLookups().pipe(takeUntilDestroyed(this.destroyRef)));
    this.currentSite.set(this.data?.site);
    this.initLookupsEffect();
    this.initializeFields();
  }

  get selectedFields(): SiteInfoFormControlNames[] {
    return get(this.data, 'selectedFields', []);
  }

  get canSubmit() {
    return this.siteForm?.valid && JSON.stringify(this.siteForm?.getRawValue()) !== JSON.stringify(this.initialFormState);
  }

  get nameControl(): AbstractControl {
    return this.siteForm.get(SiteInfoFormControlNames.NAME);
  }

  get typeControl(): AbstractControl {
    return this.siteForm.get(SiteInfoFormControlNames.TYPE);
  }

  get countryControl(): AbstractControl {
    return this.siteForm.get(SiteInfoFormControlNames.COUNTRY);
  }

  get entityControl(): AbstractControl {
    return this.siteForm.get(SiteInfoFormControlNames.ENTITY);
  }

  get parentEntityControl(): AbstractControl {
    return this.siteForm.get(SiteInfoFormControlNames.PARENT_ENTITY);
  }

  get stateControl(): AbstractControl {
    return this.siteForm.get(SiteInfoFormControlNames.STATE);
  }

  get type() {
    const value = get(this.currentSite(), ['type'], null);
    return this.types().find((t: SiteType) => `${t}` === `${value}`);
  }

  get country() {
    const value = get(this.currentSite(), ['address', 'country'], null);
    return this.countries().find((c: Country) => `${c.countryCodeAlpha3}` === `${value}`);
  }

  get state() {
    const value = get(this.currentSite(), ['address', 'state'], null);
    return this.states().find((s: StateProvince) => `${s.name}` === `${value}`) ?? null;
  }

  get entity() {
    const value = get(this.currentSite(), 'entity', null);
    return this.entities().find((e: Entity) => `${e.id}` === `${value.id}`);
  }

  ngAfterViewInit() {
    this.initializeFormEffect();
  }

  onCountrySelectionChange(event) {
    this.countryControl.setErrors(null);
    this.countryControl.patchValue(event.option?.value ?? null);
    this.initStates();
  }

  onCountryValueChange(event) {
    this.countryControl.setErrors({ notACountry: true });
    this.onCountryReset();
  }

  onCountryReset(): void {
    this.states.set([]);
    this.stateControl.patchValue(null);
    this.disableStateField.set(true);
  }

  onEntitySelectionChange(event) {
    this.entityControl.setErrors(null);
    this.entityControl.patchValue(event.option?.value ?? null);
    this.parentEntityControl.patchValue(this.entities().find((e: Entity) => e.id === this.entityControl.getRawValue()?.parentId)?.name ?? null);
    this.nameControl.enable();
  }

  onEntityValueChange(event) {
    this.entityControl.setErrors({ notAnEntity: true });
    this.onEntityReset();
  }

  onEntityReset(): void {
    this.parentEntityControl.patchValue(null);
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (FormsHelpers.isFormValid(this.siteForm)) {
      const updatedSite = { ...this.siteForm.getRawValue() } as unknown as Partial<Site>;
      if (this.selectedFields.includes(SiteInfoFormControlNames.ENTITY)) {
        delete updatedSite[SiteInfoFormControlNames.PARENT_ENTITY];
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.ADDRESS1)) {
        delete updatedSite[SiteInfoFormControlNames.ADDRESS1];
        updatedSite.address = {
          ...updatedSite.address,
          address1: this.siteForm.get(SiteInfoFormControlNames.ADDRESS1)?.getRawValue()
        } as Address;
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.ADDRESS2)) {
        delete updatedSite[SiteInfoFormControlNames.ADDRESS2];
        updatedSite.address = {
          ...updatedSite.address,
          address2: this.siteForm.get(SiteInfoFormControlNames.ADDRESS2)?.getRawValue()
        } as Address;
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.CITY)) {
        delete updatedSite[SiteInfoFormControlNames.CITY];
        updatedSite.address = {
          ...updatedSite.address,
          city: this.siteForm.get(SiteInfoFormControlNames.CITY)?.getRawValue()
        } as Address;
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.STATE)) {
        delete updatedSite[SiteInfoFormControlNames.STATE];
        updatedSite.address = {
          ...updatedSite.address,
          state: this.siteForm.get(SiteInfoFormControlNames.STATE)?.getRawValue()?.name ?? null
        } as Address;
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.ZIP_CODE)) {
        delete updatedSite[SiteInfoFormControlNames.ZIP_CODE];
        updatedSite.address = {
          ...updatedSite.address,
          zipCode: this.siteForm.get(SiteInfoFormControlNames.ZIP_CODE)?.getRawValue()
        } as Address;
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.COUNTRY)) {
        delete updatedSite[SiteInfoFormControlNames.COUNTRY];
        updatedSite.address = {
          ...updatedSite.address,
          country: this.siteForm.get(SiteInfoFormControlNames.COUNTRY)?.getRawValue().countryCodeAlpha3
        } as Address;
      }
      if (this.selectedFields.includes(SiteInfoFormControlNames.DESCRIPTION)) {
        updatedSite.description = this.siteForm.get(SiteInfoFormControlNames.DESCRIPTION)?.getRawValue();
      }
      this.dialogRef.close(updatedSite);
    }
  }

  private getLookups(): Observable<{
    types: SiteType[];
    countries: Country[];
    entities: Entity[];
  }> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lookupList: Observable<any>[] = [];
    if (this.selectedFields.includes(SiteInfoFormControlNames.TYPE)) {
      lookupList.push(this.sitesDirectoryService.getAvailableTypesForEdition());
    } else {
      lookupList.push(of([]));
    }
    if (this.selectedFields.includes(SiteInfoFormControlNames.COUNTRY)) {
      lookupList.push(this.sitesDirectoryService.getCountries());
    } else {
      lookupList.push(of([]));
    }
    if (this.selectedFields.includes(SiteInfoFormControlNames.ENTITY)) {
      lookupList.push(this.entitiesService.getHierarchicallySortedEntities());
    } else {
      lookupList.push(of([]));
    }

    return combineLatest(lookupList).pipe(
      map(([types, countries, entities]) => ({ types, countries, entities })),
      finalize(() => this.loading.set(false))
    );
  }

  private initLookupsEffect() {
    effect(() => {
      const lookups = this.lookups();
      if (lookups) {
        this.types.set(lookups.types);
        this.typeControl?.setValue(this.type);
        this.countries.set(lookups.countries);
        this.countryControl?.setValue(this.country);
        this.entities.set(lookups.entities);
        this.entityControl?.setValue(this.entity);
        untracked(() => {
          this.initStates();
          this.parentEntityControl?.patchValue(this.entities().find((e: Entity) => e.id === this.entityControl?.getRawValue()?.parentId)?.name ?? null);
        });
      }
      this.initialFormState = this.siteForm.getRawValue();
    });
  }

  private initStates() {
    this.states.set(this.countryControl?.getRawValue()?.stateProvinces ?? []);
    this.stateControl?.patchValue(this.state);
    this.disableStateField.set(!this.countryControl?.getRawValue());
  }

  private initializeFields(): void {
    this.fields.set([
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.NAME),
        label: signal('SITES.INFO_FORM.NAME'),
        maxLength: this.nameMaxLength,
        minLength: this.nameMinLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(true),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.NAME2),
        label: signal('SITES.INFO_FORM.NAME2'),
        maxLength: this.nameMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.PARENT_ENTITY),
        label: signal('SITES.INFO_FORM.PARENT_ENTITY'),
        required: signal(false),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.AUTO_COMPLETE,
        name: signal(SiteInfoFormControlNames.ENTITY),
        label: signal('SITES.INFO_FORM.ENTITY'),
        required: signal(true),
        fxFlex: signal('50%'),
        items: this.entities,
        trackBy: (item: Entity) => item.id,
        displayBy: (item: Entity) => item?.label ?? '',
        selectionChange: (event) => this.onEntitySelectionChange(event),
        valueChange: (event) => this.onEntityValueChange(event),
        onReset: () => this.onEntityReset()
      },
      {
        type: FormControlType.DROP_DOWN_LIST,
        name: signal(SiteInfoFormControlNames.TYPE),
        label: signal('SITES.INFO_FORM.TYPE'),
        required: signal(true),
        fxFlex: signal('50%'),
        items: this.types,
        trackBy: (item) => item,
        displayBy: (item) => 'SITES.CARD.TYPES.' + item
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.BUSINESS_ID),
        label: signal('SITES.INFO_FORM.BUSINESS_ID'),
        maxLength: this.businessIdMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.ADDRESS1),
        label: signal('SITES.INFO_FORM.ADDRESS_1'),
        maxLength: this.addressMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.ADDRESS2),
        label: signal('SITES.INFO_FORM.ADDRESS_2'),
        maxLength: this.addressMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.AUTO_COMPLETE,
        name: signal(SiteInfoFormControlNames.COUNTRY),
        label: signal('SITES.INFO_FORM.COUNTRY'),
        required: signal(true),
        items: this.countries,
        showSpinner: this.loading,
        trackBy: (item: Country) => item?.countryCodeAlpha3,
        displayBy: (item: Country) => (item ? item.name : ''),
        selectionChange: (event) => this.onCountrySelectionChange(event),
        valueChange: (event) => this.onCountryValueChange(event),
        onReset: () => this.onCountryReset(),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.DROP_DOWN_LIST,
        name: signal(SiteInfoFormControlNames.STATE),
        label: signal('SITES.INFO_FORM.STATE'),
        required: signal(false),
        items: this.states,
        showSpinner: this.loading,
        fxFlex: signal('50%'),
        disabled: this.disableStateField,
        trackBy: (item: Country) => item?.name,
        displayBy: (item: Country) => (item ? item?.name : '')
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.CITY),
        label: signal('SITES.INFO_FORM.CITY'),
        maxLength: this.cityMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(true),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.TEXT,
        name: signal(SiteInfoFormControlNames.ZIP_CODE),
        label: signal('SITES.INFO_FORM.ZIP_CODE'),
        maxLength: this.zipCodeMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('50%')
      },
      {
        type: FormControlType.TEXT_AREA,
        name: signal(SiteInfoFormControlNames.DESCRIPTION),
        label: signal('SITES.INFO_FORM.DESCRIPTION'),
        maxLength: this.descriptionMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('100%')
      }
    ]);
  }

  private initializeFormEffect(): void {
    effect(
      () => {
        if (this.selectedFields.includes(SiteInfoFormControlNames.NAME)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.NAME,
            new FormControl<string>(get(this.currentSite(), 'name'), {
              validators: [Validators.maxLength(this.nameMaxLength()), Validators.required, Validators.pattern('\\S.*')],
              asyncValidators: [NameValidators.asyncUniqueNameValidatorByEntity(this.sitesService, get(this.currentSite(), 'name', ''))]
            })
          );
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.NAME2)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.NAME2,
            new FormControl<string>(get(this.currentSite(), 'name2'), {
              validators: [Validators.maxLength(this.nameMaxLength()), Validators.pattern('\\S.*')]
            })
          );
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.TYPE)) {
          this.siteForm.addControl(SiteInfoFormControlNames.TYPE, new FormControl<SiteType>(this.type, { validators: [Validators.required] }));
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.ENTITY)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.PARENT_ENTITY,
            new FormControl<Entity>({
              value: null,
              disabled: true
            })
          );
          this.siteForm.addControl(SiteInfoFormControlNames.ENTITY, new FormControl<Entity>(this.entity, { validators: [Validators.required] }));
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.BUSINESS_ID)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.BUSINESS_ID,
            new FormControl<string>(get(this.currentSite(), 'businessId'), { validators: [Validators.maxLength(this.businessIdMaxLength())] })
          );
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.ADDRESS1)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.ADDRESS1,
            new FormControl<string>(get(this.currentSite(), ['address', 'address1']), {
              validators: [Validators.maxLength(this.addressMaxLength())]
            })
          );
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.ADDRESS2)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.ADDRESS2,
            new FormControl<string>(get(this.currentSite(), ['address', 'address2'], ''), {
              validators: [Validators.maxLength(this.addressMaxLength())]
            })
          );
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.COUNTRY)) {
          this.siteForm.addControl(SiteInfoFormControlNames.COUNTRY, new FormControl<Country>(this.country, { validators: [Validators.required] }));
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.STATE)) {
          this.siteForm.addControl(SiteInfoFormControlNames.STATE, new FormControl<StateProvince>(this.state));
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.CITY)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.CITY,
            new FormControl<string>(get(this.currentSite(), ['address', 'city']), {
              validators: [Validators.maxLength(this.cityMaxLength())]
            })
          );
        }
        if (this.selectedFields.includes(SiteInfoFormControlNames.ZIP_CODE)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.ZIP_CODE,
            new FormControl<string | number>(get(this.currentSite(), ['address', 'zipCode'], ''), {
              validators: [Validators.maxLength(this.zipCodeMaxLength())]
            })
          );
        }

        if (this.selectedFields.includes(SiteInfoFormControlNames.DESCRIPTION)) {
          this.siteForm.addControl(
            SiteInfoFormControlNames.DESCRIPTION,
            new FormControl<string>(get(this.currentSite(), 'description'), {
              validators: [Validators.maxLength(this.descriptionMaxLength())]
            })
          );
        }
      },
      { injector: this.injector }
    );
  }
}
