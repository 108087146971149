import { Component, computed, DestroyRef, effect, inject, Injector, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { IotToolbarDefaultButton, IotToolbarDispatchActionType, IotToolbarMenuButton } from '@iot-platform/iot-platform-ui';
import {
  AbstractAuthFacade,
  BaseFacade,
  BusinessProfile,
  CommonApiRequest,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformResponse
} from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  template: '',
  standalone: false
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractMasterViewComponent<T> implements OnInit {
  loaded: WritableSignal<boolean> = signal<boolean>(false);
  totalItems: WritableSignal<number> = signal<number>(0);
  pagination: WritableSignal<Pagination> = signal<Pagination>({
    currentPage: 0,
    hasMore: false,
    maxCountReached: false,
    limit: 100,
    maxPage: 1,
    total: 0
  });
  platformResponse: WritableSignal<PlatformResponse> = signal<PlatformResponse>({
    total: 0,
    maxPage: 0,
    limit: 0,
    hasMore: false,
    data: [],
    currentPage: 0
  });

  filtersToDisplay: WritableSignal<Filter[]> = signal<Filter[]>([]);
  canClearAppliedFilters: WritableSignal<boolean> = signal<boolean>(false);
  currentFavoriteView: Signal<FavoriteView | null> = signal<FavoriteView | null>(null);
  filterEngineOpened: WritableSignal<boolean> = signal<boolean>(false);
  initialFilters: Signal<Filter[]> = signal([]);

  //
  excludedFilters: WritableSignal<Filter[]> = signal<Filter[]>([]);
  businessProfile: WritableSignal<BusinessProfile | null> = signal<BusinessProfile | null>(null);
  initialMasterViewDataLoaded: WritableSignal<boolean> = signal<boolean>(false);
  pageSizeOptions: WritableSignal<number[]> = signal<number[]>([10, 20, 30, 50, 100]);
  toolbarButtonList: WritableSignal<(IotToolbarDefaultButton | IotToolbarMenuButton)[]> = signal([]);
  //  These following fields are used in filter engine component
  clearAppliedFilters$: Observable<boolean> = toObservable(this.canClearAppliedFilters);
  currentFavoriteView$: Observable<FavoriteView | null> = toObservable(this.currentFavoriteView);
  currentFilters$: Observable<Filter[]> = toObservable(this.filtersToDisplay);
  protected authFacade: AbstractAuthFacade;
  protected facade: BaseFacade<T, Pagination, Filter>;
  // currentFilters MUST NOT be updated by the masterview, it's not its responsibility. Use facade.setFilters() to update
  currentFilters: Signal<Filter[]> = computed(() => this.facade.filters());
  protected readonly injector: Injector = inject(Injector);
  protected readonly store: Store = inject(Store);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit() {
    this.initInitialFilterEffect();
    this.setFiltersToDisplay();
    this.setLoaded();
    this.setTotalItems();
    this.setPagination();
    this.setPlatformResponse();
    this.initToolbarButtons();
    this.setBusinessProfile();
    this.loadMasterViewData();
    this.handleBusinessProfileChange();
  }

  onPageChange(pagination: Pagination): void {
    this.pagination.set(pagination);
    this.reLoadMasterView();
  }

  onShowFilter(): void {
    const filterEngineOpened = this.filterEngineOpened();
    this.filterEngineOpened.set(!filterEngineOpened);
  }

  onRefreshClicked(): void {
    this.reLoadMasterView();
  }

  clearAppliedFilters(): void {
    this.canClearAppliedFilters.set(true);
    this.facade.setFilters([]);
    this.filtersToDisplay.set([]);
  }

  onClearAppliedFiltersClicked(): void {
    this.clearAppliedFilters();
    this.reLoadMasterView();
  }

  onApplyFilters(filters: Filter[]): void {
    const excludedFilters = this.excludedFilters();
    const currentFilters: Filter[] = [...excludedFilters];
    filters.forEach((cf: Filter) => {
      if (!currentFilters.find((f: Filter) => f.criteriaKey === cf.criteriaKey && f.value === cf.value)) {
        currentFilters.push(cf);
      }
    });
    this.facade.setFilters([...currentFilters]);
    this.resetGridPage();
    this.reLoadMasterView();
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    switch (event.type) {
      case IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA.toString():
        this.onClearAppliedFiltersClicked();
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE.toString():
        this.onRefreshClicked();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE.toString():
        this.onShowFilter();
        break;
    }
  }

  abstract sortData(platformResponse: PlatformResponse): PlatformResponse;

  getRequest(page: number): CommonApiRequest {
    return {
      page,
      limit: this.pagination()?.limit,
      filters: this.currentFilters()
    };
  }

  reLoadMasterView(page = this.pagination()?.currentPage): void {
    this.facade.getAll(this.getRequest(page));
  }

  abstract onMasterViewEngineEvent(event: MasterViewEngineEvent): void;

  handleBusinessProfileChange() {
    this.authFacade.isBusinessProfileChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isBusinessProfileChanged: boolean) => {
      if (isBusinessProfileChanged) {
        this.clearAppliedFilters();
        this.resetGridPage();
        this.onPageChange(this.pagination());
      }
    });
  }

  private initInitialFilterEffect() {
    effect(
      () => {
        const initialFilters = this.initialFilters();
        if (initialFilters) {
          const filters = [...initialFilters];
          this.facade.setFilters(filters);
          this.initialMasterViewDataLoaded.set(false);
        }
      },
      { injector: this.injector }
    );
  }

  private resetGridPage() {
    this.pagination.update((pagination) => ({
      ...pagination,
      currentPage: 0
    }));
  }

  private loadMasterViewData() {
    effect(
      () => {
        const privileges = this.authFacade.privileges();
        const businessProfile = this.businessProfile();
        const initialMasterViewDataLoaded = this.initialMasterViewDataLoaded();
        if (privileges && businessProfile && !initialMasterViewDataLoaded) {
          const currentFilters = this.currentFilters();
          this.excludedFilters.set(currentFilters.filter((f: Filter) => f.isHidden));
          this.resetGridPage();
          this.reLoadMasterView(0);
          this.initialMasterViewDataLoaded.set(true);
        }
      },
      { injector: this.injector }
    );
  }

  private setLoaded() {
    effect(
      () => {
        const loaded = this.facade.loaded();
        this.loaded.set(loaded);
      },
      { injector: this.injector }
    );
  }

  private setTotalItems() {
    effect(
      () => {
        const total = this.facade.total();
        this.totalItems.set(total);
      },
      { injector: this.injector }
    );
  }

  private setPagination() {
    effect(
      () => {
        const pagination = this.facade.pagination();
        this.pagination.set(pagination);
      },
      { injector: this.injector }
    );
  }

  private setPlatformResponse() {
    effect(
      () => {
        const platformResponse = this.facade.platformResponse();
        this.platformResponse.set(this.sortData(platformResponse));
      },
      { injector: this.injector }
    );
  }

  private setFiltersToDisplay() {
    effect(
      () => {
        const filters = this.currentFilters();
        this.filtersToDisplay.set(filters.filter((f: Filter) => !f.isHidden));
      },
      { injector: this.injector }
    );
  }

  private initToolbarButtons() {
    effect(
      () => {
        const loaded = this.loaded();
        const filtersToDisplay = this.filtersToDisplay();
        this.toolbarButtonList.update((toolbarButtonList) => {
          toolbarButtonList.forEach((button: IotToolbarDefaultButton) => {
            if (button instanceof IotToolbarDefaultButton) {
              if (button.dispatchAction.type === IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA.toString()) {
                button.disabled = filtersToDisplay.length === 0;
              } else if (button.dispatchAction.type !== IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE.toString()) {
                button.disabled = !loaded;
              }
            }
          });
          return [...toolbarButtonList];
        });
      },
      { injector: this.injector }
    );
  }

  private setBusinessProfile() {
    effect(
      () => {
        const businessProfile = this.authFacade.selectedBusinessProfile();
        this.businessProfile.set(businessProfile);
      },
      { injector: this.injector }
    );
  }
}
