import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButton } from '@angular/material/button';
import { Event } from '@iot-platform/models/i4b';
import { noop, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AcknowledgeService } from './services/acknowledge.service';

@Component({
  selector: 'iot4bos-ui-acknowledgements',
  templateUrl: './acknowledgements.component.html',
  styleUrls: ['./acknowledgements.component.scss'],
  providers: [AcknowledgeService],
  imports: [DatePipe, FlexLayoutModule, MatButton]
})
export class AcknowledgementsComponent {
  private readonly ackService: AcknowledgeService = inject(AcknowledgeService);
  event: Signal<Event | undefined> = signal(undefined);
  error: WritableSignal<HttpErrorResponse | undefined> = signal(undefined);
  canAck: WritableSignal<boolean> = signal(false);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.event = toSignal(
      this.ackService.getEvent().pipe(
        tap((event) => {
          if (event) {
            this.canAck.set(true);
          }
        }),
        catchError((err) => {
          this.error.set(err);
          return of({} as Event);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
    );
  }

  acknowledge() {
    this.canAck.set(false);
    this.ackService
      .acknowledgeEvent(this.event() as Event)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => noop());
  }
}
