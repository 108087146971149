import { inject } from '@angular/core';
import { DeviceCallLog } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { DateFormatPipe } from '@iot-platform/pipes';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { filter, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DevicesService } from '../../services/devices.service';
import { DeviceCallLogsActions } from '../actions/device-call-logs.actions';
import { DeviceCallLogsFacade } from '../facades/device-call-logs.facade';
import { DevicesFacade } from '../facades/devices.facade';

const loadDeviceCallLogs$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogs),
      switchMap(({ device }) =>
        deviceService.getCallLogById(device.id as string).pipe(
          map((response: DeviceCallLog[]) => DeviceCallLogsActions.loadDeviceCallLogsSuccess({ response })),
          catchError((error) => of(DeviceCallLogsActions.loadDeviceCallLogsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceCallLogMessage$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogMessage),
      switchMap(({ device, deviceCallLog, download }) =>
        deviceService.getCallLogMessage(deviceCallLog.callDirection, device.identifier, deviceCallLog.timestamp).pipe(
          map((response: string) =>
            DeviceCallLogsActions.loadDeviceCallLogMessageSuccess({
              deviceCallLogMessage: response,
              download
            })
          ),
          catchError((error) => of(DeviceCallLogsActions.loadDeviceCallLogMessageFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceCallLogMessageSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogMessageSuccess),
      filter(({ download }) => !!download),
      map(({ deviceCallLogMessage }) => DeviceCallLogsActions.downloadDeviceCallLogMessage({ deviceCallLogMessage }))
    ),
  { functional: true }
);

const downloadDeviceCallLogMessage$ = createEffect(
  /* istanbul ignore next */
  (
    actions$ = inject(Actions),
    dateFormatPipe = inject(DateFormatPipe),
    devicesFacade = inject(DevicesFacade),
    deviceCallLogsFacade = inject(DeviceCallLogsFacade)
  ) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.downloadDeviceCallLogMessage),
      tap(({ deviceCallLogMessage }) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([JSON.stringify(deviceCallLogMessage, null, 2)], { type: 'application/json; charset=utf-8' }));
        link.download = `${devicesFacade.currentEntity()?.name}_${dateFormatPipe.transform(deviceCallLogsFacade.currentEntity()?.timestamp, 'yyyy-MM-dd_HH-mm-ss')}.json`;
        link.click();
      })
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogs),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogsSuccess, DeviceCallLogsActions.loadDeviceCallLogsFailure),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(DeviceCallLogsActions.loadDeviceCallLogsFailure, DeviceCallLogsActions.loadDeviceCallLogMessageFailure),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

export const DeviceCallLogsEffects = {
  loadDeviceCallLogs$,
  loadDeviceCallLogMessage$,
  showLoader$,
  hideLoader$,
  displayError$,
  loadDeviceCallLogMessageSuccess$,
  downloadDeviceCallLogMessage$
};
