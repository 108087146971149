/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { inject, Injector, ModuleWithProviders, NgModule, provideAppInitializer } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  customTranslateLoaderFactory,
  FOR_ROOT_OPTIONS_TOKEN,
  I18nOptions,
  ModuleOptions,
  provideI18nOptions,
  TranslateInitializerFactory
} from './i18n.config';

@NgModule({
  exports: [TranslateModule],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoaderFactory,
        deps: [HttpClient]
      },
      // TODO to be checked cause it causes some regressions
      // Should review all components that uses translateService.get and translateService.instant methods
      /*
        compiler: {
        provide: TranslateCompiler,
        useClass: CustomTranslateCompiler
      },*/
      isolate: false
    })
  ],
  providers: [
    TranslateService,
    provideAppInitializer(() => {
      const initializerFn = TranslateInitializerFactory(inject(TranslateService), inject(Injector));
      return initializerFn();
    }),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class I18nModule {
  static forRoot(options?: ModuleOptions): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options
        },
        {
          provide: I18nOptions,
          useFactory: provideI18nOptions,
          deps: [FOR_ROOT_OPTIONS_TOKEN]
        }
      ]
    };
  }
}
