import { NgClass, UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, effect, inject, input, signal, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CardLoaderModule } from '@iot-platform/iot-platform-ui';
import { Filter } from '@iot-platform/models/common';
import { Dashboard, DashboardWidget } from '@iot-platform/models/dashboards';
import { TranslateModule } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_Drilldown from 'highcharts/modules/drilldown';
import HC_export_data from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_NoData from 'highcharts/modules/no-data-to-display';
import { DashboardsHelper } from '../../helpers/dashboards.helper';
import { DashboardsService } from '../../services/dashboards.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_NoData(Highcharts);
HC_Drilldown(Highcharts);

@Component({
  imports: [FlexLayoutModule, CardLoaderModule, HighchartsChartModule, NgClass, TranslateModule, UpperCasePipe],
  selector: 'iot-platform-feature-dashboards-dashboards-shell',
  templateUrl: './dashboards-shell.component.html',
  styleUrl: './dashboards-shell.component.scss'
})
export class DashboardsShellComponent {
  filters: Signal<Filter[]> = input<Filter[]>([]);
  dashboard: Signal<Dashboard> = input<Dashboard>(undefined);

  Highcharts: typeof Highcharts = Highcharts;
  mainChartOptions: { [widgetId: string]: Highcharts.Options } = {};
  loading: WritableSignal<boolean> = signal(true);

  dashboardsService = inject(DashboardsService);
  helper = inject(DashboardsHelper);
  destroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      if (this.dashboard()) {
        this.loading.set(true);
        this.dashboardsService
          .getDashboardData(this.dashboard(), this.filters())
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((widgetData: DashboardWidget[]) => {
            this.loading.set(false);
            widgetData.forEach((widget) => (this.mainChartOptions[widget.id] = this.helper.getHighchartsOptions(widget)));
          });
      } else {
        this.loading.set(false);
      }
    });
  }
}
