import { VariableChartComponent } from '@iot-platform/feature/variable-chart';
import { Graph } from '@iot-platform/models/common';
import { TimeseriesWidgetOptions, WidgetInstance, WidgetOptions, WidgetTypes } from '@iot-platform/models/widgets';
import { get } from 'lodash';

export class GraphsHelpers {
  static buildWidget = (
    graph: Graph,
    graphPermissions: Partial<{
      create: boolean;
      read: boolean;
      update: boolean;
      delete: boolean;
    }>,
    options?: Partial<WidgetOptions>
  ): WidgetInstance => {
    const widget = new WidgetInstance({
      type: WidgetTypes[graph.type],
      data: graph,
      options: {
        ...WidgetInstance.getOptionsByType(graph, graphPermissions),
        ...options
      } as WidgetOptions
    });
    if (widget.type === WidgetTypes.line_chart) {
      (widget.options as TimeseriesWidgetOptions).componentRef = {
        ref: VariableChartComponent,
        inputs: {
          data: get(widget, ['options', 'variables']),
          options: widget.options,
          variableType: get(widget, ['options', 'variableType']),
          showCustomExportButton: true // this will hide highchart native export menu
        }
      };
    }
    return widget;
  };
}
