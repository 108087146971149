import { Component, effect, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@iot-platform/notification';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AUTH_CONFIG, AuthConfig } from '../../auth.config';
import { SsoCodeToTokensResponse } from '../../models/sso-code-to-tokens-response';
import { AuthService } from '../../services/auth.service';
import { AuthPageActions } from '../../state/actions/auth-page.actions';

@Component({
  imports: [FlexLayoutModule, MatProgressSpinner, TranslateModule, ReactiveFormsModule],
  selector: 'iot-platform-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss', '../../containers/login-shell/login-shell.component.scss', '../../scss/style.scss']
})
export class LoginCallbackComponent {
  tokens$: Observable<SsoCodeToTokensResponse>;
  tokens: Signal<SsoCodeToTokensResponse>;
  protected readonly authConfig: AuthConfig = inject(AUTH_CONFIG);
  protected notificationService: NotificationService = inject(NotificationService);
  protected authService: AuthService = inject(AuthService);
  protected translate: TranslateService = inject(TranslateService);
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected router: Router = inject(Router);
  protected store: Store = inject(Store);

  constructor() {
    this.tokens$ = this.route.queryParams.pipe(
      tap(() => this.notificationService.showLoader()),
      switchMap((queryParams) =>
        this.authService.getSsoTokensFromCode(queryParams?.code).pipe(
          catchError(() => {
            this.notificationService.hideLoader();
            this.router.navigate(['/']);
            const timeout = setTimeout(() => {
              this.notificationService.displayError(this.translate.instant('AUTHENTICATION_FAILED'));
              clearTimeout(timeout);
            }, 500);
            return of(null);
          })
        )
      ),
      finalize(() => this.notificationService.hideLoader())
    );
    this.tokens = toSignal(this.tokens$);

    effect(() => {
      const tokens = this.tokens();
      if (tokens) {
        const { idToken, accessToken, refreshToken, tokenType, expiresIn } = tokens;
        this.authService.storeSsoTokens({
          idToken,
          accessToken,
          tokenType,
          refreshToken,
          expiresIn: String(expiresIn)
        });
        this.store.dispatch(AuthPageActions.validateSsoTokens({ idToken, accessToken, refreshToken, expiresIn }));
      }
    });
  }
}
