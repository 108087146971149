import { Component, computed, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromAuth } from '@iot-platform/auth';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { DevicesFacade } from '@iot-platform/iot4bos/data-access/devices';
import { Filter, MasterViewEngineEvent } from '@iot-platform/models/common';
import { DevicesGridData, I4BGrid, I4BGridOptions } from '@iot-platform/models/grid-engine';

import { Device, Site } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DeviceDetailPopupComponent } from '../../../devices/components/device-detail-popup/device-detail-popup.component';
import { DeviceMoveToFormComponent } from '../../../devices/components/device-move-to-form/device-move-to-form.component';

@Component({
  selector: 'iot4bos-ui-stock-management-shell',
  templateUrl: './stock-management-shell.component.html',
  styleUrls: ['./stock-management-shell.component.scss'],
  standalone: false
})
export class StockManagementShellComponent implements OnInit, OnDestroy {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly store: Store = inject(Store);
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);
  analytic = new AnalyticsService('stock-site-page');
  origin = this.storage.get(LocalStorageKeys.STORAGE_ORIGIN_KEY);
  mvorigin = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
  activeItemId = this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY);
  activeSiteId = this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY);
  site: Site;
  deviceCountByFamily$: Observable<{ family: string; total: number }[]> = this.navigationApi.deviceCountByFamily$;
  deviceCountByFamilyLoading$: Observable<boolean> = this.navigationApi.deviceCountByFamilyLoading$;
  // Grid
  gridConcept = 'stock-site-devices';
  grid$: Observable<I4BGrid<I4BGridOptions, DevicesGridData> | undefined> = this.store.select(fromGrids.selectDefaultStockSiteDevicesGrid);
  gridTotalElements$: Observable<number> = this.grid$.pipe(map((grid) => grid?.data?.response.pagination.total ?? 0));
  gridSort$ = this.grid$.pipe(switchMap((grid) => (grid ? this.store.select(fromGrids.getSortByGrid(grid.id as string)) : of([]))));
  requestEndpoint = '/devices';
  currentFilters: Filter[] = [{ criteriaKey: 'siteId', value: this.activeSiteId }];
  userPermissions: Signal<{ key: string; value: boolean }[]> = computed(() => [
    {
      key: 'canUpdateDevice',
      value: this.devicesFacade.canUpdate()
    }
  ]);
  destroyed$: Subject<void> = new Subject();

  ngOnInit() {
    this.navigationApi.site$.pipe(takeUntil(this.destroyed$)).subscribe((site: Site) => (this.site = site));

    combineLatest([this.store.select(fromAuth.selectSelectedBusinessProfileForAccount), this.store.select(fromGrids.getAllGrids), this.grid$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([businessProfile]) => !!businessProfile)
      )
      .subscribe(([businessProfile, grids, grid]) => {
        if (businessProfile && grids && !grid) {
          this.store.dispatch(
            GridsDbActions.selectGridAndLoadData({
              gridId: 'default',
              masterview: this.gridConcept,
              filters: this.currentFilters,
              endPoint: this.requestEndpoint
            })
          );
        }
      });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'navigateToDevice':
        this.onOpenDeviceDetail(event.rawData);
        break;
      case 'open':
        this.openStockDetail(event.rawData);
        break;
      case 'moveTo':
        this.analytic.log('grid_actions', 'open_single_move_to');
        this.moveTo([event.rawData]);
        break;
      case 'bulkMoveTo':
        this.analytic.log('grid_actions', 'open_bulk_move_to');
        this.moveTo(event.rawData);
        break;
      default:
        break;
    }
  }

  openStockDetail(device: Device) {
    this.analytic.log('grid_actions', 'open_stock_detail');
    const deviceDetailDialog = this.dialog.open(DeviceDetailPopupComponent, {
      width: '900px',
      disableClose: false,
      data: { device }
    });

    deviceDetailDialog.componentInstance.selectSite.subscribe(() => {
      deviceDetailDialog.close();
    });
    deviceDetailDialog.componentInstance.selectDevice.subscribe((deviceSelected: Device) => {
      deviceDetailDialog.close();
      this.onOpenDeviceDetail(deviceSelected);
    });
  }

  onOpenDeviceDetail(device: Device) {
    this.navigationApi.selectDeviceAvecLeSite(device);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, this.mvorigin);
  }

  moveTo(devices: Device[]) {
    const dialogRef = this.dialog.open(DeviceMoveToFormComponent, {
      width: '800px',
      disableClose: true,
      data: { devices }
    });

    dialogRef.afterClosed().subscribe((devicesToUpdate: Device[]) => {
      if (devicesToUpdate) {
        this.analytic.log('grid_actions', 'move_to', `${devicesToUpdate.length} devices`);
        this.navigationApi.moveDevices(devicesToUpdate, this.site);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
