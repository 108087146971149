import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { I4BBasicColumn, I4BCalendarsButtonColumn, I4BColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { CalendarsGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const GET_DEFAULT_CALENDARS_COLUMNS_FOR_CALENDARS_VIEW = (): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => [
  new I4BBasicColumn(
    { displayName: 'NAME', type: HeaderType.BASIC },
    {
      id: 'name',
      isDefault: true,
      sortProperty: 'name',
      translationKey: 'IOT_DICTIONARY.NAME',
      concept: I4bCellConcept.CALENDARS,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigate' } }
    },
    { order: 1 }
  ),
  new I4BBasicColumn(
    { displayName: 'ENTITY', type: HeaderType.BASIC },
    {
      id: 'entity.name',
      isDefault: true,
      sortProperty: 'entity.name',
      translationKey: 'IOT_DICTIONARY.ENTITY',
      concept: I4bCellConcept.CALENDARS,
      cell: { type: I4BCellType.BASIC }
    },
    { order: 6 }
  ),

  new I4BBasicColumn(
    { displayName: 'CREATED BY', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_BY',
      id: 'createdBy',
      isDefault: true,
      sortProperty: 'createdBy',
      concept: I4bCellConcept.CALENDARS,
      width: 165,
      cell: {
        type: I4BCellType.USER_CELL
      }
    },
    {
      order: 7
    }
  )
];

export class DefaultCalendarsGrid implements I4BGrid<I4BGridOptions, CalendarsGridData> {
  masterview = 'calendars';
  isDefault = true;
  isUserDefault = false;
  isAppDefault = true;
  name = null;
  userId = null;
  data: CalendarsGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    buttonColumn: { enabled: true, className: new I4BCalendarsButtonColumn() },
    gridSort: []
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];
}
