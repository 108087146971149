import { Component, inject, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TagCategory } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { SiteImageFormComponent } from '../site-image-form/site-image-form.component';

@Component({
  selector: 'iot4bos-ui-site-image-form-dialog',
  templateUrl: './site-image-form-dialog.component.html',
  styleUrls: ['./site-image-form-dialog.component.scss'],
  imports: [FlexLayoutModule, SiteImageFormComponent, MatCardModule, MatIcon, TranslateModule, MatToolbarModule, MatButtonModule]
})
export class SiteImageFormDialogComponent {
  data: { site: Site; tags: TagCategory[] } = inject(MAT_DIALOG_DATA);
  public dialogRef: MatDialogRef<SiteImageFormDialogComponent> = inject(MatDialogRef<SiteImageFormDialogComponent>);

  image: WritableSignal<string | null> = signal(this.data.site?.imageUrl ?? null);

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.image());
  }
}
