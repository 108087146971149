<div [ngClass]="{ 'association-asset-device-shell--drawer': isDrawerMode() }" fxLayout="column">
  <div
    [ngClass]="{ 'association-asset-device-shell__header--sticky': isDrawerMode(), 'association-asset-device-shell__header--no-sticky': isPageMode() }"
    fxLayout="column"
  >
    @if (isDrawerMode()) {
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" fxLayoutGap="8px">
          <mat-icon>account_tree</mat-icon>
          <span> {{ 'IOT_DICTIONARY.ASSETS_DEVICES_ACCESS' | translate }}</span>
        </div>

        <button (click)="close()" class="regular-round-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    }

    <iot-platform-ui-section-header
      (dispatchEvent)="onDispatchSectionHeaderEvent($event)"
      [titleActions]="sectionHeaderTitleActions()"
      [title]="sectionHeaderTitle() | translate"
      [actionsType]="actionsType()"
      [actions]="headerActions()"
    />
  </div>

  <div class="association-asset-device-shell__filter" fxLayout="row" fxLayoutAlign="end center">
    <mat-checkbox [(ngModel)]="displayDecommissionedAssets" (change)="updateDisplayDecommissionedAssetsPreferences()"
      >{{ 'IOT_DICTIONARY.SHOW_DECOMMISSIONED_ASSETS' | translate }} ({{ decomissionnedAssetsCount() }})</mat-checkbox
    >
  </div>

  @if (preferences()?.siteAssociationDisplay === 'devices') {
    <iot4bos-association-by-device
      (dispatchEvent)="close(); onDispatchCardEvent($event)"
      [association]="filteredAssociationByDevice()"
      [laoding]="associationLoading()"
      [displayMode]="displayMode()"
    />
  } @else {
    <iot4bos-association-by-asset
      (dispatchEvent)="close(); onDispatchCardEvent($event)"
      [association]="filteredAssociationByAsset()"
      [laoding]="associationLoading()"
      [displayMode]="displayMode()"
    />
  }
</div>
