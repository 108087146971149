import { HttpContext, HttpContextToken, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject, combineLatest, EMPTY, mergeMap, Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, first, take } from 'rxjs/operators';

import { AuthService } from './services/auth.service';
import { AuthApiActions, AuthPageActions } from './state/actions';
import { AuthFacade } from './state/facades/auth.facade';

export const BYPASS_HTTP_CONTEXT_BUSINESS_PROFILE_ID_HEADER = new HttpContextToken<boolean>(() => false);

export const bypassHttpContextBusinessProfileIdHeader = () => new HttpContext().set(BYPASS_HTTP_CONTEXT_BUSINESS_PROFILE_ID_HEADER, true);

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private readonly authService: AuthService = inject(AuthService);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly store: Store = inject(Store);
  tokenRefreshed$ = new BehaviorSubject<string | Record<string, unknown> | null>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes('/oauth2/token')) {
      return next.handle(request);
    }
    return combineLatest([
      this.authFacade.loggedIn$,
      this.authFacade.loggedInWithSSO$,
      this.authFacade.idToken$,
      this.authFacade.selectedBusinessProfileId$,
      this.authFacade.refreshToken$,
      this.authFacade.ssoTokenExpiresAt$
    ]).pipe(
      first(),
      mergeMap(([loggedIn, loggedInWithSSO, idToken, businessProfileId, refreshToken, ssoTokenExpiresAt]) => {
        if (!loggedIn) {
          return next.handle(request);
        }
        if (loggedInWithSSO) {
          // Refresh token before calling request
          const isTokenExpired = this.authService.ssoTokenExpired(ssoTokenExpiresAt);
          if (isTokenExpired) {
            return this.handleRefreshSsoToken(refreshToken, businessProfileId, request, next);
          }
          return next.handle(this.setRequestHeaders(idToken, businessProfileId, request)).pipe(
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.error?.type === 'BusinessProfileNotCoveredByUserEntity') {
                  this.store.dispatch(AuthPageActions.signOut());
                }
                if (isTokenExpired && err.status === 401) {
                  return this.handleRefreshSsoToken(refreshToken, businessProfileId, request, next);
                }
              }
              return throwError(err);
            })
          );
        } else {
          // Refresh token before calling request
          const isTokenExpired = idToken && this.authService.tokenExpired(idToken);
          if (isTokenExpired) {
            return this.handleRefreshToken(businessProfileId, request, next);
          }
          return next.handle(this.setRequestHeaders(idToken, businessProfileId, request)).pipe(
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.error?.type === 'BusinessProfileNotCoveredByUserEntity') {
                  this.store.dispatch(AuthPageActions.signOut());
                }
                if (isTokenExpired && err.status === 401) {
                  return this.handleRefreshToken(businessProfileId, request, next);
                }
              }
              return throwError(err);
            })
          );
        }
      })
    );
  }

  private setRequestHeaders(idToken: string, businessProfileId: string, req: HttpRequest<any>): HttpRequest<any> {
    let request = req;
    if (!businessProfileId || req.context.get(BYPASS_HTTP_CONTEXT_BUSINESS_PROFILE_ID_HEADER)) {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`
        }
      });
    } else {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
          'business-profile-id': `${businessProfileId}`
        }
      });
    }
    return request;
  }

  private handleRefreshToken(businessProfileId: string, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isRefreshingToken) {
      return this.tokenRefreshed$.pipe(
        filter((t) => !!t),
        take(1),
        concatMap((idToken) => next.handle(this.setRequestHeaders(idToken as string, businessProfileId, req)))
      );
    }

    this.isRefreshingToken = true;

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    this.tokenRefreshed$.next(null);

    // Do refresh token
    return this.refreshToken(businessProfileId, req, next);
  }

  private refreshToken(businessProfileId: string, req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      concatMap((refreshed: CognitoUser) => {
        const refreshedToken = refreshed.getSignInUserSession()?.getIdToken().getJwtToken() ?? '';
        this.store.dispatch(AuthApiActions.refreshTokenSuccess({ refreshed }));
        this.tokenRefreshed$.next(refreshedToken);
        return next.handle(this.setRequestHeaders(refreshedToken, businessProfileId, req));
      }),
      catchError((error) => {
        // Disconnect the user and redirect to login page
        // in case we got an error while refreshing the token
        this.store.dispatch(AuthApiActions.refreshTokenFailure({ error }));
        this.store.dispatch(AuthPageActions.signOut());
        return EMPTY;
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      })
    );
  }

  private handleRefreshSsoToken(refreshToken: string, businessProfileId: string, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isRefreshingToken) {
      return this.tokenRefreshed$.pipe(
        filter((t) => !!t),
        take(1),
        concatMap((refreshed: { idToken: string }) => next.handle(this.setRequestHeaders(refreshed.idToken, businessProfileId, req)))
      );
    }

    this.isRefreshingToken = true;

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    this.tokenRefreshed$.next(null);

    // Do refresh token
    return this.refreshSsoToken(refreshToken, businessProfileId, req, next);
  }

  private refreshSsoToken(refreshToken: string, businessProfileId: string, req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshSsoTokens(refreshToken).pipe(
      concatMap(({ idToken, accessToken, tokenType, expiresIn }) => {
        this.store.dispatch(AuthApiActions.refreshSsoTokensSuccess({ idToken, accessToken, tokenType, expiresIn }));
        this.tokenRefreshed$.next({
          idToken
        });
        return next.handle(this.setRequestHeaders(idToken, businessProfileId, req));
      }),
      catchError((error) => {
        // Disconnect the user and redirect to login page
        // in case we got an error while refreshing the token
        this.store.dispatch(AuthApiActions.refreshSsoTokensFailure({ error }));
        this.store.dispatch(AuthPageActions.signOut());
        return EMPTY;
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      })
    );
  }
}
