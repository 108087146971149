import { Component, computed, model, Signal } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { AuthValidators } from '../../validators/auth.validator';

@Component({
  imports: [TranslateModule, MatIconModule],
  selector: 'iot-platform-login-password-rules',
  templateUrl: './login-password-rules.component.html',
  styleUrls: ['./login-password-rules.component.scss']
})
export class LoginPasswordRulesComponent {
  password = model<string>();
  errors: Signal<ValidationErrors | null> = computed(() => {
    const password = this.password();
    return password
      ? AuthValidators.checkPassword(password)
      : {
          format: {
            size: true,
            lowercase: true,
            uppercase: true,
            number: true,
            special: true
          }
        };
  });
}
