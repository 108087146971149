import { Component, EventEmitter, Inject, Output, Signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DevicesFacade } from '@iot-platform/iot4bos/data-access/devices';
import { TagCategory } from '@iot-platform/models/common';
import { Device, Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-device-detail-popup',
  templateUrl: './device-detail-popup.component.html',
  styleUrls: ['./device-detail-popup.component.scss'],
  standalone: false
})
export class DeviceDetailPopupComponent {
  tags: Signal<TagCategory[]> = this.devicesFacade.deviceTags;
  tagsLoading: Signal<boolean> = this.devicesFacade.deviceTagsLoading;
  site: Signal<Site | null> = this.devicesFacade.deviceSite;
  siteLoading: Signal<boolean> = this.devicesFacade.deviceSiteLoading;

  @Output() selectSite: EventEmitter<Site> = new EventEmitter<Site>();
  @Output() selectDevice: EventEmitter<Device> = new EventEmitter<Device>();

  constructor(
    private devicesFacade: DevicesFacade,
    @Inject(MAT_DIALOG_DATA) public data: { device: Device; canUpdateDevice: boolean }
  ) {
    this.devicesFacade.loadDeviceTags(this.data.device.id);
    this.devicesFacade.loadDeviceSite(this.data.device.site.id);
  }
}
