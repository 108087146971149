<mat-tab-group class="site-contacts--tab-group h-full" color="accent" mat-align-tabs="center">
  <mat-tab label="tab_contacts_contacts">
    <ng-template matTabLabel>
      <div fxLayout="row" fxLayoutAlign="start center">
        <span>{{ 'SITES.TABS.CONTACTS' | translate | uppercase }}</span
        >&nbsp;
        @if (!(contactsLoading$ | async)) {
          <span>({{ totalContacts$ | async }}) </span>
        } @else {
          <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" />
        }
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="site-contacts--tab-content max-w-4xl xl:max-w-4xl p-4 w-full-auto">
        <iot-platform-ui-panel
          (clicked)="handleContact('create')"
          [buttonTooltip]="'SITES.CONTACT_FORM.ADD' | translate"
          [hasButton]="canCreateContact() && (totalContacts$ | async) < maxContacts"
          [title]="'SITES.CONTACTS_TAB.PANEL_TITLE' | translate: { totalContacts: totalContacts$ | async, maxContacts: maxContacts } | uppercase"
          class="site-contacts--contacts-panel"
        >
          @for (contact of contacts$ | async; track trackByContactId($index, contact)) {
            <iot-platform-ui-contact-card
              fxFlex="31%"
              fxFlex.lt-md="100%"
              (configExport)="configureSpreadSheetExport(contact)"
              (configNotif)="configureNotification(contact)"
              (delete)="handleContact('delete', contact)"
              (openExportSpreadsheetDetail)="openAssociatedExportSpreadsheetsPopup(contact)"
              (openNotificationDetail)="openAssociatedNotificationsPopup(contact)"
              (toEdit)="handleContact('update', contact)"
              [canDelete]="canDeleteContact()"
              [canUpdate]="canUpdateContact()"
              [contact]="contact"
              [minimalDisplay]="false"
              class="site-contacts--contact-card"
              panelGrid
            />
          }
          @if (!(totalContacts$ | async) && canCreateContact()) {
            <iot-platform-ui-default-card
              (click)="handleContact('create')"
              [backgroundClass]="'h-100'"
              [title]="'SITES.CONTACT_FORM.ADD' | translate"
              [icon]="'add'"
              fxFlex="33%"
              fxFlex.lt-md="100%"
              panelGrid
            />
          }
        </iot-platform-ui-panel>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="tab_contacts_notifications">
    <ng-template matTabLabel>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
        <span>{{ 'SITES.TABS.NOTIFICATIONS' | translate | uppercase }}</span>
        @if (!(notificationsLoading$ | async)) {
          <span>({{ totalNotifications$ | async }}) </span>
        } @else {
          <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" />
        }
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="site-contacts--tab-content max-w-4xl xl:max-w-4xl p-4 w-full-auto">
        <iot-platform-ui-panel
          (clicked)="addNotification()"
          [buttonTooltip]="'SITES.NOTIFICATIONS_TAB.ADD' | translate"
          [hasButton]="canUpdateContact() && (totalNotifications$ | async) < maxNotifications"
          [title]="'SITES.NOTIFICATIONS_TAB.PANEL_TITLE' | translate: { totalNotifications: totalNotifications$ | async, maxNotifications } | uppercase"
          class="site-contacts--contacts-panel"
        />
        <iot4bos-ui-site-notifications
          (addNotification)="addNotification()"
          (configureNotification)="updateNotification($event)"
          (deleteNotification)="deleteNotification($event)"
          (selectNotification)="selectNotification($event)"
          [canUpdateContact]="canUpdateContact()"
          [notifications]="notifications$ | async"
          [selectedNotification]="selectedNotification$ | async"
        />
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="tab_contacts_exports">
    <ng-template matTabLabel>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
        <span>{{ 'SITES.TABS.EXPORT_SPREADSHEETS' | translate | uppercase }}</span>
        @if (!(exportsSpreadsheetsLoading$ | async)) {
          <span>({{ totalExportsSpreadsheets$ | async }}) </span>
        } @else {
          <mat-progress-spinner [diameter]="14" color="accent" mode="indeterminate" />
        }
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="site-contacts--tab-content max-w-4xl xl:max-w-4xl p-4 w-full-auto">
        <iot-platform-ui-panel
          (clicked)="addExportSpreadsheet()"
          [buttonTooltip]="'SITES.EXPORT_SPREADSHEETS_TAB.ADD' | translate"
          [hasButton]="canUpdateContact() && (totalExportsSpreadsheets$ | async) < maxExportsSpreadsheets"
          [title]="
            'SITES.EXPORT_SPREADSHEETS_TAB.PANEL_TITLE' | translate: { totalExports: totalExportsSpreadsheets$ | async, maxExportsSpreadsheets } | uppercase
          "
          class="site-contacts--contacts-panel"
        />
        <iot4bos-ui-site-export-spreadsheets
          (addExportSpreadsheet)="addExportSpreadsheet()"
          (configureExportSpreadsheet)="updateExportSpreadsheet($event)"
          (deleteExportSpreadsheet)="deleteExportSpreadsheet($event)"
          (selectExportSpreadsheet)="selectExportSpreadsheet($event)"
          [canUpdateContact]="canUpdateContact()"
          [exportSpreadsheets]="exportSpreadsheets$ | async"
          [selectedExportSpreadsheet]="selectedExportSpreadsheets$ | async"
          [site]="site()"
        />
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
