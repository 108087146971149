<div [class.minimalDisplay]="minimalDisplay()" [ngStyle.lt-md]="{ width: 'calc(100% - 3rem)' }" class="contact-card--container">
  <div class="full-height" fxLayout="row" fxLayoutGap="8px">
    <div class="contact-card--col" fxFlex="40px">
      <button class="user-initials" color="accent" mat-mini-fab>{{ initials }}</button>
    </div>
    <div class="contact-card--col" fxLayout="column" fxLayoutAlign="space-between start">
      <div class="contact-card--info" fxLayout="column" fxLayoutGap="4px">
        <div class="contact-card--info__name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <h3
            [matTooltip]="contact()?.firstname + ' ' + contact()?.lastname | infoDisplay"
            class="contact-card--title fw-600"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="above"
          >
            {{ contact()?.firstname + ' ' + contact()?.lastname | infoDisplay }}
          </h3>
          @if (contact()?.notificationSupport?.email) {
            <mat-icon
              class="notification-icon"
              fxLayoutAlign="start center"
              [matTooltip]="'IOT_DICTIONARY.EMAIL' | translate"
              matTooltipClass="regular_tooltip"
            >
              mail
            </mat-icon>
          }
          @if (contact()?.notificationSupport?.sms) {
            <mat-icon class="notification-icon" fxLayoutAlign="start center" [matTooltip]="'IOT_DICTIONARY.SMS' | translate" matTooltipClass="regular_tooltip">
              sms
            </mat-icon>
          }
        </div>
        <div class="contact-card--job capitalize-first-letter">{{ contact()?.function | infoDisplay }}</div>
        <div class="phone" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>phone</mat-icon>
          <div class="contact-card--item">{{ contact() | infoDisplay: 'pipe' : 'phone' : 'mobile' }}</div>
        </div>
        <div class="email full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>mail_outlined</mat-icon>
          @if (!contact()?.email) {
            <span>-</span>
          }
          <a
            [matTooltip]="contact()?.email"
            class="contact-card--item"
            href="mailto:{{ contact()?.email }}"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="above"
            >{{ contact()?.email }}</a
          >
        </div>
      </div>
      @if (!minimalDisplay()) {
        <div class="contact-card--notification-export-info" fxLayoutGap="4px">
          <span [ngClass]="{ clickable: !!contact()?.associatedNotifications?.length }" (click)="onNotificationsClicked()"
            >{{ 'CONTACT_CARD.NOTIFICATION_INFO' | translate }}{{ contact()?.associatedNotifications?.length | infoDisplay }}</span
          >
          <span>|</span>
          <span [ngClass]="{ clickable: !!contact()?.associatedExportSpreadsheets?.length }" (click)="onExportSpreadsheetsClicked()"
            >{{ 'CONTACT_CARD.EXPORT_SPREADSHEET_INFO' | translate }}{{ contact()?.associatedExportSpreadsheets?.length | infoDisplay }}</span
          >
        </div>
      }
      @if (canUpdate() || canDelete()) {
        <div class="contact-card--buttons app-round-button">
          <button mat-icon-button #contactCardMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="contactCardMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #contactCardMenu="matMenu">
            @if (canUpdate()) {
              <button mat-menu-item (click)="edit()">{{ 'SITES.CONTACT_FORM.EDIT' | translate }}</button>
            }
            @if (!minimalDisplay() && canUpdate()) {
              <button mat-menu-item (click)="configureNotif()">{{ 'SITES.CONTACT_FORM.CONFIGURE_NOTIF' | translate }}</button>
              <button mat-menu-item (click)="configureExport()">{{ 'SITES.CONTACT_FORM.CONFIGURE_EXPORT' | translate }}</button>
            }
            @if (canDelete()) {
              <button mat-menu-item (click)="onDelete()">{{ 'SITES.CONTACT_FORM.DELETE' | translate }}</button>
            }
          </mat-menu>
        </div>
      }
    </div>
  </div>
</div>
