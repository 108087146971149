import { NgComponentOutlet } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { WidgetInstance } from '@iot-platform/models/widgets';
import { get } from 'lodash';

@Component({
  imports: [MatCardModule, MatToolbarModule, FlexLayoutModule, NgComponentOutlet],
  selector: 'iot-platform-ui-dynamic-widget',
  templateUrl: './dynamic-widget.component.html',
  styleUrls: ['./dynamic-widget.component.scss']
})
export class DynamicWidgetComponent {
  widget = input<WidgetInstance>();
  widgetOptions = computed(() => get(this.widget(), ['options']));
}
