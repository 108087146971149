import { Component, inject, output, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, Device, DeviceVariable, Event, Log, Site } from '@iot-platform/models/i4b';
import { Topic } from '@iot-platform/models/ocm';
import { EventDetailPopupService } from './services/event-detail-popup.service';

@Component({
  selector: 'iot4bos-ui-event-detail-popup',
  templateUrl: './event-detail-popup.component.html',
  styleUrls: ['./event-detail-popup.component.scss'],
  standalone: false
})
export class EventDetailPopupComponent {
  public readonly data: { event: Signal<Event>; comments: Signal<Log[]>; commentsLoading: Signal<boolean>; canUpdateEvent: boolean } = inject(MAT_DIALOG_DATA);
  private readonly eventDetailPopupService: EventDetailPopupService = inject(EventDetailPopupService);

  eventSite: Signal<Site | undefined> = toSignal(this.eventDetailPopupService.getSiteById(this.data.event()?.context.site.id));
  eventAsset: Signal<Asset | undefined> = toSignal(this.eventDetailPopupService.getAssetById(this.data.event()?.context.asset.id));
  eventDevice: Signal<Device | undefined> = toSignal(this.eventDetailPopupService.getDeviceById(this.data.event()?.context.device.id));
  eventTags: Signal<TagCategory[]> = this.data.event()?.tags ? signal([...Object.values(this.data.event()?.tags)]) : signal([]);
  eventTopics: Signal<Topic[] | undefined> = toSignal(this.eventDetailPopupService.getTopicsByEventId(this.data.event()?.id));
  eventAssetVariable: Signal<AssetVariable | undefined> = toSignal(
    this.eventDetailPopupService.getAssetVariableById(this.data.event()?.context.assetVariable.id)
  );
  eventDeviceVariable: Signal<DeviceVariable | undefined> = toSignal(
    this.eventDetailPopupService.getDeviceVariableById(this.data.event()?.context.deviceVariable.id)
  );

  updateStatus = output<string>();
  addComment = output<string>();
  navigateToSite = output<Site>();
}
