import { Component, effect, OnDestroy, OnInit, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade, AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { CreateGraphDialogComponent } from '@iot-platform/feature/graphs';
import { VariableChartDialogComponent } from '@iot-platform/feature/variable-chart';
import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { BusinessProfile, Contact, Filter, Graph } from '@iot-platform/models/common';
import { Asset, AssetVariable, DeviceVariable, Site, SiteType } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { ContactsFacade } from '../../+state/facades/contacts.facade';
import { GraphsFacade } from '../../+state/facades/graphs.facade';
import { NavigationApi } from '../../../../containers/+state/navigation.api';

@Component({
  selector: 'iot4bos-ui-site-overview',
  templateUrl: './site-overview.component.html',
  styleUrls: ['./site-overview.component.scss'],
  standalone: false
})
export class SiteOverviewComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  analytic: AnalyticsService = new AnalyticsService('site_info_page');
  eventType = '';
  filteredBy = 'site';
  /* Graphs */
  graphLoading$: Observable<boolean> = this.graphsFacade.graphLoading$;
  allGraphs$: Observable<Graph[]> = this.graphsFacade.allGraphs$;
  /* Contacts */
  contacts: Signal<Contact[]> = this.contactsFacade.contacts;
  contactsLoading$: Observable<boolean> = this.contactsFacade.contactsLoading$;
  totalContacts$: Observable<number> = this.contactsFacade.totalContacts$;
  /* Filter Engine parameters for events */
  filterEngineOpened = true;
  clearAppliedFilters$ = of(false);
  currentFavoriteView$ = of(undefined);
  mandatoryFilters: Filter[] = [];
  //
  assets: Signal<Asset[]> = this.navigationApi.assets;
  site$: Observable<Site> = this.navigationApi.site$;
  site: Signal<Site> = this.navigationApi.site;
  isSiteOfTypeMobileFleet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /* permissions */
  canUpdateSite: boolean;
  canDeleteSite: boolean;
  canCreateAsset: boolean;
  canCreateDevice = false;
  canUpdateEvent: boolean;
  canReadEvent: boolean;
  canReadGraph: boolean;
  canReadContact: boolean;
  canCreateContact: boolean;
  canUpdateContact: boolean;
  canDeleteContact: boolean;
  canReadAuditTrail: boolean;
  userPermissions: {
    key: string;
    value: boolean;
  }[] = [];
  eventsPermissions: {
    key: string;
    value: boolean;
  }[] = [];
  // Data Guard
  dataGuardOpened = this.navigationApi.dataGuardOpened;
  selectedBusinessProfile: Signal<BusinessProfile> = this.authFacade.selectedBusinessProfile;

  constructor(
    private readonly navigationApi: NavigationApi,
    private readonly authorizationService: AuthorizationService,
    private readonly dialog: MatDialog,
    private readonly graphsFacade: GraphsFacade,
    private readonly contactsFacade: ContactsFacade,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authFacade: AuthFacade
  ) {
    this.canUpdateSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.UPDATE);
    this.canDeleteSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.DELETE);
    this.canCreateAsset = this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.CREATE);
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
    this.canReadGraph = this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.READ);
    this.canReadEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);
    this.canReadAuditTrail = this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);
    this.canReadContact = this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ);
    this.canCreateContact = this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.CREATE);
    this.canUpdateContact = this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.UPDATE);
    this.canDeleteContact = this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.DELETE);

    this.userPermissions = [
      { key: 'canUpdateEvent', value: this.canUpdateEvent },
      { key: 'canReadGraph', value: this.canReadGraph }
    ];
    this.eventsPermissions = [
      { key: 'canUpdateEvent', value: this.canUpdateEvent },
      { key: 'canReadGraph', value: false }
    ];

    this.loadSiteEffect();
  }

  ngOnInit() {
    this.site$.pipe(takeUntil(this.unsubscribe$)).subscribe((site: Site) => {
      if (!!site?.id) {
        const isSiteOfTypeMobileFleet = site.type === SiteType.MOBILE_FLEET;
        this.isSiteOfTypeMobileFleet$.next(isSiteOfTypeMobileFleet);
        this.canReadEvent = isSiteOfTypeMobileFleet ? false : this.canReadEvent;
        this.canReadGraph = isSiteOfTypeMobileFleet ? false : this.canReadGraph;
        this.canReadContact = isSiteOfTypeMobileFleet ? false : this.canReadContact;
        this.canUpdateSite = isSiteOfTypeMobileFleet ? false : this.canUpdateSite;
        this.canDeleteSite = isSiteOfTypeMobileFleet ? false : this.canDeleteSite;
        this.canReadAuditTrail = isSiteOfTypeMobileFleet ? false : this.canReadAuditTrail;
      }
    });
  }

  closeAllPanels(): void {
    this.navigationApi.toggleDataGuard(false);
  }

  onSelectedTabChange(event: MatTabChangeEvent): void {
    this.analytic.log('tab_change', `${event.tab.textLabel}`);
  }

  public onAddGraph(): void {
    this.analytic.log('tab_graph_actions', 'open_add_graph');
    this.dialog
      .open(CreateGraphDialogComponent, {
        width: '1000px',
        data: { site: this.site(), assets: this.assets() },
        disableClose: true
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((graphToAdd: Graph) => {
        if (graphToAdd) {
          this.analytic.log('tab_graph_actions', 'add_graph');
          this.graphsFacade.addGraph(graphToAdd);
        }
      });
  }

  public onOpenGraph(graph: Graph): void {
    this.analytic.log('tab_graph_actions', 'open_graph');
    const options = {
      chart: {
        legend: {
          enabled: true,
          labelFormat: (params: Record<string, any>) => `${params.asset.name} - ${params.name}`
        }
      }
    };
    this.openChart(graph.variables as (DeviceVariable | AssetVariable)[], 'assetVariable', graph.name, options);
  }

  public onEditGraph(graph: Graph): void {
    this.analytic.log('tab_graph_actions', 'open_edit_graph');
    this.dialog
      .open(CreateGraphDialogComponent, {
        width: '1000px',
        data: {
          graph,
          site: this.site(),
          assets: this.assets()
        },
        disableClose: true
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((graphToUpdate: Graph) => {
        if (graphToUpdate) {
          this.analytic.log('tab_graph_actions', 'update_graph');
          this.graphsFacade.updateGraph(graphToUpdate);
        }
      });
  }

  public onDeleteGraph(graph: Graph): void {
    this.analytic.log('tab_graph_actions', 'open_delete_graph');
    this.translateService
      .get('GRAPH.CONFIRM_DIALOG.DELETE_CONFIRM_MESSAGE', { value: graph.name.toUpperCase() })
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap((message: string) =>
          this.dialog
            .open(PopupComponent, {
              width: '500px',
              disableClose: true,
              data: { type: 'confirm', value: message }
            })
            .afterClosed()
            .pipe(map((confirmed) => confirmed))
        )
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.analytic.log('tab_graph_actions', 'delete_graph');
          this.graphsFacade.deleteGraph(graph);
        }
      });
  }

  onWidgetsOrderChange(graphsToUpdate: Graph[]): void {
    this.graphsFacade.updateGraphsDisplayOrder(graphsToUpdate);
  }

  openChart(variables: (DeviceVariable | AssetVariable)[], variableType: string, title?: string, options?: Record<string, any>) {
    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data: {
        variables,
        variableType,
        title,
        options
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadSiteEffect() {
    effect(() => {
      const selectedBusinessProfile = this.selectedBusinessProfile();
      const siteIdFromUrl = this.activatedRoute.snapshot.paramMap.get('siteId');
      const site = this.site();
      const canLoadSite = !site || site?.id?.toString() !== siteIdFromUrl?.toString();
      if (selectedBusinessProfile && canLoadSite) {
        this.navigationApi.openSiteDetail({ id: siteIdFromUrl } as Site);
      }
    });
  }
}
