import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogsPopupComponent } from '@iot-platform/audit-trail';
import { ManageTagsDialogComponent, PopupComponent } from '@iot-platform/iot-platform-ui';
import { CreateAssetGroupDialogComponent } from '@iot-platform/iot4bos/ui';
import { TagCategory } from '@iot-platform/models/common';
import { Asset, AssetGroup, Site } from '@iot-platform/models/i4b';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { SitesFacade } from '../+state/facades/sites.facade';
import { NavigationApi } from '../../../containers/+state/navigation.api';
import { AssetsFacade } from '../../assets/+state/facades/assets.facade';
import { AssetTemplateVariablesConfigurationFormComponent } from '../../assets/components/asset-template-variables-configuration-form/asset-template-variables-configuration-form.component';
import { CreateAssetFromScratchDialogComponent } from '../../assets/components/create-asset-from-scratch-dialog/create-asset-from-scratch-dialog.component';
import { CreateAssetFromTemplateDialogComponent } from '../../assets/components/create-asset-from-template-dialog/create-asset-from-template-dialog.component';
import { SiteImageFormDialogComponent } from '../components/site-image-form-dialog/site-image-form-dialog.component';
import { SiteInfoFormDialogComponent } from '../components/site-info-form-dialog/site-info-form-dialog.component';
import { SiteInfoFormControlNames } from '../components/site-info-form-dialog/site-info-form.util';

@Injectable({
  providedIn: 'root'
})
export class SitesCtaService {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  private readonly sitesFacade: SitesFacade = inject(SitesFacade);
  private readonly assetsFacade: AssetsFacade = inject(AssetsFacade);

  openManageTagsDialog(site: Site, tags: TagCategory[]): void {
    this.dialog
      .open(ManageTagsDialogComponent, {
        width: '1230px',
        disableClose: true,
        data: {
          concepts: ['site'],
          selectedTags: tags,
          objectName: site.name,
          currentEntityId: site.entity?.id,
          withChildren: false,
          joinable: true,
          withParents: true,
          enforceMandatoryCategories: true
        }
      })
      .afterClosed()
      .subscribe((updatedTags: TagCategory[]) => {
        if (updatedTags) {
          this.navigationApi.updateTagsBySiteId(site.id as string, updatedTags);
        }
      });
  }

  openImageEditionPopup(site: Site, tags: TagCategory[]): void {
    this.dialog
      .open(SiteImageFormDialogComponent, {
        width: '1000px',
        data: { site, tags },
        disableClose: true
      })
      .afterClosed()
      .pipe(filter((confirmation) => confirmation))
      .subscribe((imageUrl: string) => {
        this.navigationApi.updateSite({ ...site, imageUrl });
      });
  }

  openAuditTrailDialog(site: Site): void {
    this.dialog.open(LogsPopupComponent, {
      data: { concept: 'site', elementId: site.id as string, elementName: site.name },
      disableClose: true,
      width: '900px'
    });
  }

  openDeleteSiteConfirmationDialog(site: Site) {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: site.name }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.navigationApi.deleteSite(site);
        }
      });
  }

  editSiteGeneralInfo(site: Site): void {
    this.openEditSiteDialog(site, [
      SiteInfoFormControlNames.NAME,
      SiteInfoFormControlNames.NAME2,
      SiteInfoFormControlNames.ENTITY,
      SiteInfoFormControlNames.BUSINESS_ID,
      SiteInfoFormControlNames.DESCRIPTION,
      SiteInfoFormControlNames.TYPE
    ]);
  }

  editSiteLocationInfo(site: Site): void {
    this.openEditSiteDialog(site, [
      SiteInfoFormControlNames.ADDRESS1,
      SiteInfoFormControlNames.ADDRESS2,
      SiteInfoFormControlNames.CITY,
      SiteInfoFormControlNames.ZIP_CODE,
      SiteInfoFormControlNames.STATE,
      SiteInfoFormControlNames.COUNTRY
    ]);
  }

  openEditSiteDialog(site: Site, selectedFields: SiteInfoFormControlNames[]): void {
    this.dialog
      .open(SiteInfoFormDialogComponent, {
        width: '990px',
        disableClose: true,
        data: { site, selectedFields }
      })
      .afterClosed()
      .subscribe((siteToUpdate: Site) => {
        if (siteToUpdate) {
          this.navigationApi.updateSite({ ...site, ...siteToUpdate });
        }
      });
  }

  openAssetCreationFromScratchDialog(site: Site): void {
    this.dialog
      .open(CreateAssetFromScratchDialogComponent, {
        disableClose: true,
        data: { site },
        width: '1100px'
      })
      .afterClosed()
      .pipe(filter((result) => result?.asset))
      .subscribe((result: { asset: Asset }) => {
        this.navigationApi.addAsset(result.asset);
      });
  }

  openAssetGroupCreationDialog(site: Site): void {
    this.dialog
      .open(CreateAssetGroupDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { assetGroup: null, siteId: site.id as string }
      })
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe((result: AssetGroup) => {
        this.sitesFacade.addAssetGroup({ ...result, siteId: site.id as string });
      });
  }

  openAssetCreationFromTemplateDialog(site: Site): void {
    const creationDialog = this.dialog
      .open(CreateAssetFromTemplateDialogComponent, {
        disableClose: true,
        data: { site },
        width: '1100px'
      })
      .afterClosed()
      .pipe(filter((result) => result));

    // CREATE ASSET FROM TEMPLATE
    creationDialog.subscribe((result: { siteId: string; templateId: string; configureVariablesAfterCreation: boolean }) => {
      this.navigationApi.addAssetByTemplateId(result.templateId, result.siteId);
    });

    // CONFIGURE ASSET VARIABLES FROM TEMPLATE AFTER CREATION
    combineLatest([
      creationDialog.pipe(filter((result) => result.configureVariablesAfterCreation)),
      this.assetsFacade.assetCreationByTemplateStatuses$,
      this.navigationApi.assetVariables$,
      this.navigationApi.selectedAsset$
    ])
      .pipe(
        filter(
          ([_, assetCreationByTemplateStatuses, assetVariables, selectedAsset]) =>
            assetCreationByTemplateStatuses?.completed && !!assetVariables.length && selectedAsset?.id === assetVariables[0].asset?.id
        ),
        take(1),
        switchMap(([result, _, assetVariables, __]) =>
          this.dialog
            .open(AssetTemplateVariablesConfigurationFormComponent, {
              minWidth: '1300px',
              disableClose: true,
              data: { siteId: result.siteId, assetTemplateId: result.templateId, assetVariables }
            })
            .afterClosed()
        )
      )
      .subscribe((assetId: string) => {
        if (assetId) {
          this.navigationApi.loadAssetById(assetId);
          this.navigationApi.loadVariablesByAssetId(assetId);
        }
      });
  }
}
