import { Component, computed, DestroyRef, effect, inject, OnDestroy, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { DevicesFacade } from '@iot-platform/iot4bos/data-access/devices';
import { ChatEvent, IotAction } from '@iot-platform/models/common';
import { Asset, CommentContext, Concept, Device, DisplayMode, Log, Site } from '@iot-platform/models/i4b';
import { delay, map, mergeMap } from 'rxjs/operators';
import { NavigationApi } from '../+state/navigation.api';
import { AssetsFacade } from '../../features/assets/+state/facades/assets.facade';
import { SitesFacade } from '../../features/sites/+state/facades/sites.facade';

@Component({
  selector: 'iot4bos-ui-info-display',
  templateUrl: './info-display.component.html',
  styleUrls: ['./info-display.component.scss'],
  standalone: false
})
export class InfoDisplayComponent implements OnDestroy {
  private readonly router: Router = inject(Router);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);
  private readonly sitesFacade: SitesFacade = inject(SitesFacade);
  private readonly assetsFacade: AssetsFacade = inject(AssetsFacade);
  analytic: AnalyticsService = new AnalyticsService('site_asset_device_toolbar');
  assets: Asset[];
  devices: Device[];
  activeItemId: string;
  mvorigin = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
  activeSiteId = localStorage.getItem(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY);
  DISPLAY_MODE = DisplayMode;
  // Side panel
  displayAssociation: WritableSignal<boolean> = signal(false);
  displayComments: WritableSignal<boolean> = signal(false);
  // Comments
  @ViewChild('sidePanel', { static: true }) sidenav!: MatSidenav;
  comments: WritableSignal<Log[]> = signal([]);
  commentsLoading: WritableSignal<boolean> = signal(true);
  commentsContexts!: CommentContext[];
  currentConceptAndElement: WritableSignal<{
    concept: 'site' | 'asset' | 'device';
    element: Site | Asset | Device;
  } | null> = signal(null);
  canReadSite: Signal<boolean> = this.sitesFacade.canRead;
  public readonly navigationApi: NavigationApi = inject(NavigationApi);
  site: Signal<Site> = this.navigationApi.site;
  canReadDisplayedConcept: Signal<boolean> = computed(() => {
    const site = this.site();
    const asset = this.navigationApi.selectedAsset();
    const device = this.navigationApi.selectedDevice();
    if (site) {
      return this.canReadSite();
    }
    if (asset) {
      return this.assetsFacade.canRead();
    }
    if (device) {
      return this.devicesFacade.canRead();
    }
    return false;
  });

  constructor() {
    this.setDevices();
    this.loadDataIfNotLoaded();

    effect(() => {
      const currentConceptAndElement = this.currentConceptAndElement();
      if (currentConceptAndElement?.concept === Concept.SITE) {
        this.comments.set([...this.navigationApi.siteComments()]);
        this.commentsLoading.set(this.navigationApi.siteCommentsLoading());
      } else if (currentConceptAndElement?.concept === Concept.ASSET) {
        this.comments.set([...this.navigationApi.assetComments()]);
        this.commentsLoading.set(this.navigationApi.assetCommentsLoading());
      } else if (currentConceptAndElement?.concept === Concept.DEVICE) {
        this.comments.set([...this.navigationApi.deviceComments()]);
        this.commentsLoading.set(this.navigationApi.deviceCommentsLoading());
      }
    });
  }

  onReturnToOrigin(): void {
    this.analytic.log('toolbar_actions', 'return_to_mv');
    const origin: string = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
    if (origin) {
      this.router.navigateByUrl(origin.split('/').length > 1 ? `/${origin}` : `/${origin}s`, { state: { keepScrollPosition: true } });
    } else {
      history.go(-1);
    }
  }

  onClose() {
    this.navigationApi.clearSelectedItem(this.site());
  }

  refreshAsset(asset: Asset) {
    this.analytic.log('toolbar_actions', 'refresh_asset');
    this.navigationApi.selectAssetAvecLeSite(asset);
  }

  refreshSite(site: Site) {
    this.analytic.log('toolbar_actions', 'refresh_site');
    this.navigationApi.selectLeSite(site);
  }

  refreshDevice(device: Device) {
    this.analytic.log('toolbar_actions', 'refresh_device');
    this.navigationApi.selectDeviceAvecLeSite(device);
  }

  closeAllPanels(): void {
    this.displayAssociation.set(false);
    this.displayComments.set(false);
    this.navigationApi.toggleDataGuard(false);
  }

  onToggleAssociationPanel(): void {
    this.analytic.log('toolbar_actions', 'open_association_panel');
    this.displayAssociation.set(true);
  }

  onToggleDataGuard(forcedStatus?: boolean) {
    this.analytic.log('toolbar_actions', `open_data_guard`);
    this.navigationApi.toggleDataGuard(forcedStatus);
  }

  setCommentContext(payload: { concept: 'site' | 'asset' | 'device'; element: Site | Asset | Device }): void {
    if (payload.concept === 'asset') {
      this.commentsContexts = [
        { name: Concept.ASSET, checked: true, disabled: false },
        { name: Concept.SITE, checked: false, disabled: !this.canReadSite() }
      ];
    } else if (payload.concept === 'device') {
      this.commentsContexts = [
        { name: Concept.DEVICE, checked: true, disabled: false },
        { name: Concept.SITE, checked: false, disabled: !this.canReadSite() }
      ];
    } else {
      this.commentsContexts = [];
    }
  }

  onToggleCommentPanel(payload: { concept: 'site' | 'asset' | 'device'; element: Site | Asset | Device }): void {
    this.analytic.log('toolbar_actions', 'open_comment_panel', payload.concept);
    this.navigationApi.loadLogsByConcept(payload.concept, payload.element);
    this.currentConceptAndElement.set(payload);
    this.setCommentContext(payload);
    this.displayComments.set(true);
  }

  onCommentsEvent(event: ChatEvent): void {
    switch (event.name) {
      case IotAction.ADD:
        this.onAddComment(event.value as string);
        break;
      case IotAction.EDIT:
        this.onEditComment(event.value as Log);
        break;
      case IotAction.DELETE:
        this.onDeleteComment(event.value as string);
        break;
      case IotAction.CLOSE:
        this.onCloseComments();
        break;
      default:
        break;
    }
  }

  onAddComment(comment: string) {
    this.navigationApi.addComment(this.currentConceptAndElement()?.concept, this.currentConceptAndElement()?.element.id as string, comment);
  }

  onEditComment(comment: Log) {
    this.navigationApi.editComment(this.currentConceptAndElement()?.concept, this.currentConceptAndElement()?.element.id as string, comment);
  }

  onDeleteComment(commentId: string) {
    this.navigationApi.deleteComment(this.currentConceptAndElement()?.concept, this.currentConceptAndElement()?.element.id as string, commentId);
  }

  onCloseComments() {
    this.displayComments.set(false);
    this.sidenav.close();
  }

  ngOnDestroy() {
    this.navigationApi.clearData();
  }

  private setDevices() {
    this.navigationApi.devices$
      .pipe(
        mergeMap((devices: Device[]) =>
          this.navigationApi.site$.pipe(
            map((site: Site) => (site && site.type === 'stock' ? devices.filter((device: Device) => device.id === this.activeItemId) : devices))
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((devices: Device[]) => (this.devices = devices));
  }

  private loadDataIfNotLoaded() {
    this.navigationApi.siteLoaded.pipe(delay(200), takeUntilDestroyed(this.destroyRef)).subscribe((loaded) => {
      if (loaded === false && this.site() === null) {
        this.navigationApi.selectLeSite({
          id: this.activeSiteId,
          name: '',
          address: '',
          type: 'info'
        } as any);
      }
    });
  }
}
