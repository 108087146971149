import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContactsService } from '@iot-platform/data-access/contacts';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Contact, LastValue } from '@iot-platform/models/common';
import { EXPORT_SPREADSHEET_STATUS, ExportSpreadsheet, Site } from '@iot-platform/models/i4b';
import { BehaviorSubject } from 'rxjs';

interface FormattedAsset {
  id: string;
  name: string;
  variables: { id: string; name: string; lastValue: LastValue }[];
}
@Component({
    selector: 'iot4bos-ui-site-export-spreadsheets',
    templateUrl: './site-export-spreadsheets.component.html',
    styleUrls: ['./site-export-spreadsheets.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false
})
export class SiteExportSpreadsheetsComponent implements OnInit, OnChanges {
  @Input() exportSpreadsheets: ExportSpreadsheet[] = [];
  @Input() selectedExportSpreadsheet?: ExportSpreadsheet;
  @Input() canUpdateContact = false;
  @Input() displayCheckbox = false;
  @Input() currentContact?: Contact;
  @Input() site: Site;

  @Output() addExportSpreadsheet = new EventEmitter<void>();
  @Output() configureExportSpreadsheet = new EventEmitter<ExportSpreadsheet>();
  @Output() deleteExportSpreadsheet = new EventEmitter<ExportSpreadsheet>();
  @Output() selectExportSpreadsheet = new EventEmitter<ExportSpreadsheet>();
  @Output() checkExportSpreadsheet = new EventEmitter<{ checked: boolean; item: ExportSpreadsheet }>();

  contactsService = inject(ContactsService);

  formattedAssets: FormattedAsset[] = [];
  exportPreview?: { name: string; url: string };
  exportPreviewLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  EXPORT_SPREADSHEET_STATUS = EXPORT_SPREADSHEET_STATUS;
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  static getFormattedAssets(exportSpreadsheet: ExportSpreadsheet): FormattedAsset[] {
    return exportSpreadsheet.assetVariables
      .reduce((acc: FormattedAsset[], assetVariable) => {
        const matchingAssetId = acc.findIndex((asset) => asset.id === assetVariable.asset.id);
        if (!acc.length || matchingAssetId === -1) {
          acc.push({
            id: assetVariable.asset.id,
            name: assetVariable.asset.name,
            variables: [{ id: assetVariable.id, name: assetVariable.name, lastValue: assetVariable.lastValue }]
          });
        } else {
          acc[matchingAssetId].variables.push({ id: assetVariable.id, name: assetVariable.name, lastValue: assetVariable.lastValue });
        }
        return acc;
      }, [])
      .sort(SortUtil.sortByName);
  }

  ngOnInit(): void {
    if (this.exportSpreadsheets.length === 1) {
      this.selectExportSpreadsheet.emit(this.exportSpreadsheets[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('selectedExportSpreadsheet') && changes.selectedExportSpreadsheet.currentValue) {
      this.formattedAssets = SiteExportSpreadsheetsComponent.getFormattedAssets(changes.selectedExportSpreadsheet.currentValue);
      this.loadPreview(changes.selectedExportSpreadsheet.currentValue);
    }
  }

  loadPreview(exportSpreadsheet: ExportSpreadsheet) {
    const customExportSpreadsheet: ExportSpreadsheet = {
      ...exportSpreadsheet,
      assetVariableIds: exportSpreadsheet.assetVariables.map((v) => v.id)
    };
    this.exportPreviewLoading$.next(true);
    this.contactsService
      .getSpreadsheetExportPreview(customExportSpreadsheet, this.site?.id as string)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.exportPreview = response;
          this.exportPreviewLoading$.next(false);
        },
        error: () => this.exportPreviewLoading$.next(false),
        complete: () => this.exportPreviewLoading$.next(false)
      });
  }
}
