<mat-sidenav-container (backdropClick)="closeAllPanels()" fxFlex hasBackdrop="true">
  <mat-sidenav-content>
    <iot4bos-ui-breadcrumb
      (close)="onClose()"
      (refreshAsset)="refreshAsset($event)"
      (refreshDevice)="refreshDevice($event)"
      (refreshSite)="refreshSite($event)"
      (returnToOrigin)="onReturnToOrigin()"
      (toggleAssociationsPanel)="sidePanel.open(); onToggleAssociationPanel()"
      (toggleCommentPanel)="sidePanel.open(); onToggleCommentPanel($event)"
      (toggleDataGuard)="onToggleDataGuard()"
      [activeItemId]="activeItemId"
      [canReadDisplayedConcept]="canReadDisplayedConcept()"
      [mvorigin]="mvorigin"
      [selectedAsset]="navigationApi.selectedAsset()"
      [selectedDevice]="navigationApi.selectedDevice()"
      [site]="navigationApi.site()"
    >
    </iot4bos-ui-breadcrumb>

    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #sidePanel mode="over" position="end">
    @if (displayAssociation()) {
      <div fxFlex class="association-panel">
        <iot4bos-association-asset-device-shell
          fxFlex="40rem"
          fxFlex.lt-sm="100%"
          [displayMode]="DISPLAY_MODE.DRAWER"
          (dispatchEvent)="sidePanel.close(); displayAssociation.set(false)"
        />
      </div>
    }

    @if (displayComments()) {
      <iot4bos-ui-comments-panel
        data-cy="iot4bos-ui-events-comments"
        [comments]="comments()"
        [withFilters]="!!(navigationApi.selectedAsset$ | async) || !!(navigationApi.selectedDevice$ | async)"
        [filters]="commentsContexts"
        [withFooter]="canReadDisplayedConcept()"
        [canEditComment]="canReadDisplayedConcept()"
        [canDeleteComment]="canReadDisplayedConcept()"
        [defaultConcept]="currentConceptAndElement()?.concept"
        [loading]="commentsLoading()"
        (commentsEvent)="onCommentsEvent($event)"
      >
      </iot4bos-ui-comments-panel>
    }
  </mat-sidenav>
</mat-sidenav-container>
