import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssetVariableSelectorComponent, AssetVariableSelectorListComponent } from '@iot-platform/feature/graphs';

import { TranslateModule } from '@ngx-translate/core';
import { ExportSpreadsheetCreationFormComponent } from './export-spreadsheet-creation-form.component';

@NgModule({
  declarations: [ExportSpreadsheetCreationFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatCardModule,

    MatToolbarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    AssetVariableSelectorComponent,
    AssetVariableSelectorListComponent,
    MatSlideToggleModule,
    MatTabsModule
  ]
})
export class ExportSpreadsheetCreationFormModule {}
