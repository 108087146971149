import { CommonGenericModel } from './core';
import { Entity } from './entity.model';

export enum ConnectorCommand {
  BACKUP = 'backup',
  GET_ALARM = 'getalarm',
  REFRESH = 'refresh',
  SELFCONF = 'selfconf',
  THRESHOLD = 'threshold',
  UNBLOCK = 'unblock'
}

export const ConnectorCommands = [
  ConnectorCommand.BACKUP,
  ConnectorCommand.GET_ALARM,
  ConnectorCommand.REFRESH,
  ConnectorCommand.SELFCONF,
  ConnectorCommand.THRESHOLD,
  ConnectorCommand.UNBLOCK
];

export interface Connector extends CommonGenericModel {
  description?: string;
  entities?: Entity[];
  families?: string[];
  support?: string;
  supports?: string[];
  requestConfiguration?: { commands?: string[]; authentication?: string };
  configuration?: {
    url: string;
    sharedAuth: boolean;
  };
  contextEntity?: string;
  externalApiKeyIds?: string[];
  updatableAttributes?: string[];
}
