<div class="ag-header-group-cell-label">
  <div [ngClass]="{'skip-text-transform': params()?.skipTextTransform}"
       class="customHeaderLabel">{{ params()?.displayName | translate }}
  </div>
  @if (showExpandArrow()) {
    <div class="customExpandButton" [ngClass]="expandState()" (click)="expandOrCollapse()">
      <i class="fa fa-arrow-right"></i>
    </div>
  }
</div>
