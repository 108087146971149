<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ popupTitle | translate: { contactName: data.contact.firstname + ' ' + data.contact.lastname } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content p-20 contact-manage-notifications--content">
    <form [formGroup]="manageNotificationsOrExportsForm" fxLayout="column" fxLayoutGap="20px">
      <span>{{ selectionCountMessage | translate: { total: totalCheckedItems, max: '10' } }}</span>
      @if (data.notifications) {
        <iot4bos-ui-site-notifications
          (checkNotification)="onCheckChange($event)"
          (selectNotification)="selectNotification($event)"
          [currentContact]="data.contact"
          [displayCheckbox]="true"
          [notifications]="data.notifications"
          [selectedNotification]="selectedNotification"
        ></iot4bos-ui-site-notifications>
      }
      @if (data.exportSpreadsheets) {
        <iot4bos-ui-site-export-spreadsheets
          (checkExportSpreadsheet)="onCheckChange($event)"
          (selectExportSpreadsheet)="selectExport($event)"
          [currentContact]="data.contact"
          [displayCheckbox]="true"
          [exportSpreadsheets]="data.exportSpreadsheets"
          [selectedExportSpreadsheet]="selectedExportSpreadsheet"
        >
        </iot4bos-ui-site-export-spreadsheets>
      }
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" class="button-regular" color="accent" mat-raised-button>{{ 'IOT_DICTIONARY.SAVE' | translate }}</button>
  </mat-card-actions>
</mat-card>
