import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import * as fromNavigationUi from './navigation-ui.reducer';
//
export const navigationFeatureKey = 'navigation';

export interface NavigationState {
  [fromNavigationUi.navigationUiFeatureKey]: fromNavigationUi.State;
}

export interface State {
  [navigationFeatureKey]: NavigationState;
}

export function reducers(state: NavigationState | undefined, action: Action) {
  return combineReducers({
    [fromNavigationUi.navigationUiFeatureKey]: fromNavigationUi.reducer
  })(state, action);
}

export const selectNavigationState = createFeatureSelector<NavigationState>(navigationFeatureKey);
export const selectNavigationUiState = createSelector(selectNavigationState, (state: NavigationState) => state[fromNavigationUi.navigationUiFeatureKey]);

export const getSite = createSelector(selectNavigationUiState, fromNavigationUi.getSite);
export const getSiteLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getSiteLoaded);
export const getSiteTags = createSelector(selectNavigationUiState, fromNavigationUi.getSiteTags);
export const getSiteTagsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getSiteTagsLoaded);

export const getSelectedAsset = createSelector(selectNavigationUiState, fromNavigationUi.getSelectedAsset);
export const getSelectedDevice = createSelector(selectNavigationUiState, fromNavigationUi.getSelectedDevice);

export const getAssets = createSelector(selectNavigationUiState, fromNavigationUi.getAssets);
export const getAssetsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetsLoaded);
export const getDevices = createSelector(selectNavigationUiState, fromNavigationUi.getDevices);
export const getDevicesLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDevicesLoaded);
export const getDevicesByStock = createSelector(selectNavigationUiState, fromNavigationUi.getDevicesByStock);

export const getSiteId = createSelector(selectNavigationUiState, fromNavigationUi.getSiteId);
export const getActiveId = createSelector(selectNavigationUiState, fromNavigationUi.getActiveId);
export const getOrigin = createSelector(selectNavigationUiState, fromNavigationUi.getOrigin);
export const getPageType = createSelector(selectNavigationUiState, fromNavigationUi.getPageType);

export const getAssetLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetLoaded);
export const getAssetTags = createSelector(selectNavigationUiState, fromNavigationUi.getAssetTags);
export const getAssetTagsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetTagsLoaded);
export const getAssetVariables = createSelector(selectNavigationUiState, (state) => state.assetVariables.sort(SortUtil.sortByName));
export const getAssetVariablesLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getAssetVariablesLoaded);

export const getDeviceLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceLoaded);
export const getDeviceTags = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceTags);
export const getDeviceTagsLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceTagsLoaded);
export const getDeviceVariables = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceVariables);
export const getDeviceVariablesTableFilters = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceVariablesTableFilters);
export const getDeviceVariablesLoaded = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceVariablesLoaded);

export const getData = createSelector(selectNavigationUiState, (state: fromNavigationUi.State) => {
  const data: { site: Site; assets: Asset[]; devices: Device[] } = { site: state.site, assets: state.assets, devices: state.devices };
  return data;
});

export const selectIsSiteReadyFromDeletion = createSelector(getAssets, getDevices, (assets: Asset[], devices: Device[]) => {
  const assetsNotDecommissioned = assets.filter((a) => a.status.name !== 'decommissioned');
  const devicesNotDecommissioned = devices.filter((d) => d.status?.name !== 'decommissioned');

  return !assetsNotDecommissioned.length && !devicesNotDecommissioned.length;
});

/// //////////////////////////
//  **** Stock Sites ****  //
/// //////////////////////////

export const selectDeviceCountByFamily = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceCountByFamily);
export const selectDeviceCountByFamilyLoading = createSelector(selectNavigationUiState, fromNavigationUi.getDeviceCountByFamilyLoading);
