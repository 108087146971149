import { provideState } from '@ngrx/store';
import { deviceEventsCommentsFeature } from './device-events-comments.reducer';
import { deviceEventsFeature } from './device-events.reducer';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { DeviceEventsCommentsEffects } from '../effects/device-events-comments.effects';
import { DeviceEventsEffects } from '../effects/device-events.effects';

export function provideDeviceEventsCommentsState(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(deviceEventsCommentsFeature.name, deviceEventsCommentsFeature.reducer),
    provideEffects(DeviceEventsCommentsEffects)
  ]);
}

export function provideDeviceEventsState(): EnvironmentProviders {
  return makeEnvironmentProviders([provideState(deviceEventsFeature.name, deviceEventsFeature.reducer), provideEffects([DeviceEventsEffects])]);
}
