<iot4bos-ui-events-by-concept-grid
  [concept]="'site'"
  [displayFilterEngine]="true"
  [eventType]="eventType"
  [grid]="grid$ | async"
  [mandatoryFiltersInput]="mandatoryFilters"
  [masterviewName]="masterviewName"
  [origin]="origin"
  [permissions]="userPermissions"
/>
