import { Component, inject, Input, signal, WritableSignal } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { DeviceEventsGridData, I4BGrid, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Site } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { fromGrids } from '@iot-platform/grid-engine';
import { Observable } from 'rxjs';

@Component({
    selector: 'iot4bos-ui-site-events-tab',
    templateUrl: './site-events-tab.component.html',
    styleUrl: './site-events-tab.component.scss',
    standalone: false
})
export class SiteEventsTabComponent {
  private readonly store: Store = inject(Store);
  grid$: Observable<I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined>;
  masterviewName = '';
  mandatoryFilters: Filter[] = [];

  origin!: string;
  site: WritableSignal<Site | undefined> = signal(undefined);

  @Input() eventType = '';
  @Input() userPermissions: { key: string; value: boolean }[] = [];
  @Input() set selectedSite(value: Site) {
    this.site.set(value);
    if (this.site()) {
      this.mandatoryFilters = [{ criteriaKey: 'siteId', value: this.site().id, isHidden: true }];
      this.origin = `sites/${this.site().id}`;

      if (this.eventType === 'asset-events') {
        this.masterviewName = 'asset-events-by-site';
        this.grid$ = this.store.select(fromGrids.getDefaultAssetEventsBySiteGrid);
      } else if (this.eventType === 'device-events') {
        this.masterviewName = 'device-events-by-site';
        this.grid$ = this.store.select(fromGrids.getDefaultDeviceEventsBySiteGrid);
      }
    }
  }
}
