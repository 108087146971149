<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ dialogTitle | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content">
    <form [formGroup]="notificationForm">
      <mat-tab-group color="accent" mat-align-tabs="center">
        <mat-tab [label]="'IOT_DICTIONARY.PARAMETERS' | translate | uppercase">
          <div class="tab-container" fxLayout="column">
            <mat-slide-toggle color="accent" formControlName="status" labelPosition="after">
              <mat-label class="capitalize-first-letter">{{ 'IOT_DICTIONARY.ACTIVE_CAPITALIZE' | translate }} </mat-label>
            </mat-slide-toggle>
            <mat-form-field class="full-width form-field" color="accent">
              <mat-label>{{ 'SITES.NOTIFICATION_FORM.NAME' | translate }}</mat-label>
              <input #nameInput formControlName="name" matInput maxlength="50" required />
              <mat-hint align="end">{{ nameInput.value?.length || 0 }}/50</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width form-field" color="accent">
              <mat-label>{{ 'SITES.NOTIFICATION_FORM.EMAIL_TEMPLATE' | translate }}</mat-label>
              <mat-select formControlName="emailTemplate" required>
                @for (emailTemplate of emailTemplateList; track emailTemplate.id) {
                  <mat-option [value]="emailTemplate.id">
                    {{ emailTemplate.name }}
                  </mat-option>
                }
              </mat-select>
              @if (showSpinner) {
                <mat-spinner class="form-field_spinner" color="accent" diameter="16" mode="indeterminate"></mat-spinner>
              }
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>{{ 'SITES.NOTIFICATION_FORM.ASSETS_NAMES' | translate }}</mat-label>
              <mat-select formControlName="assetNames" multiple required>
                @for (asset of assets; track asset.id) {
                  <mat-option [value]="asset.name">
                    {{ asset.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <iot4bos-ui-multi-select-chip
              [options]="eventClasses"
              [title]="'SITES.NOTIFICATION_FORM.EVENT_CLASSES' | translate"
              formControlName="eventClasses"
            ></iot4bos-ui-multi-select-chip>
            <iot4bos-ui-multi-select-chip
              [options]="eventSeverities"
              [title]="'SITES.NOTIFICATION_FORM.EVENT_SEVERITIES' | translate"
              formControlName="eventSeverities"
            ></iot4bos-ui-multi-select-chip>
            <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
          </div>
        </mat-tab>
        <mat-tab [label]="'SITES.NOTIFICATION_FORM.RELATED_CONTACTS' | translate: { total: contactCheckedTotal, max: data.contacts.length } | uppercase">
          <div class="tab-container related-contacts-tab" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <span class="capitalize-first-letter related-contacts-label" fxFlex="30%">{{ 'IOT_DICTIONARY.NAME' | translate }}</span>
              <span class="capitalize-first-letter related-contacts-label">{{ 'SITES.NOTIFICATION_FORM.NOTIFICATION_SUPPORTS' | translate }}</span>
            </div>
            @for (contact of data.contacts; track contact) {
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-checkbox [formControlName]="contact.id" fxFlex="30%">{{ contact.firstname }}&nbsp;{{ contact.lastname }} </mat-checkbox>
                @if (contact.notificationSupport.email) {
                  <mat-icon
                    [matTooltipClass]="!contact.email ? 'warning-tooltip' : 'regular-tooltip capitalize-first-letter'"
                    [matTooltip]="(!contact.email ? 'SITES.NOTIFICATIONS_TAB.INVALID_EMAIL' : 'IOT_DICTIONARY.EMAIL') | translate"
                    [ngClass]="{ warning: !contact.email }"
                    >mail
                  </mat-icon>
                }
                @if (contact.notificationSupport.sms) {
                  <mat-icon
                    [matTooltipClass]="!contact.phone ? 'warning-tooltip' : 'regular-tooltip uppercase'"
                    [matTooltip]="(!contact.phone ? 'SITES.NOTIFICATIONS_TAB.INVALID_PHONE_NUMBER' : 'IOT_DICTIONARY.SMS') | translate"
                    [ngClass]="{ warning: !contact.phone }"
                    >sms
                  </mat-icon>
                }
              </div>
            }
            @if (!data.contacts?.length) {
              <span>{{ 'SITES.NOTIFICATION_FORM.NO_CONTACT' | translate }}</span>
            }
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="!notificationForm.valid" class="button-regular" color="accent" mat-raised-button>
      {{ ('IOT_DICTIONARY.' + data.notification.id ? 'SAVE' : 'CREATE') | translate }}
    </button>
  </mat-card-actions>
</mat-card>
