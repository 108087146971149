import { SortUtil } from '@iot-platform/iot-platform-utils';
import {
  AssetEventsGridData,
  AssetsGridData,
  DaliaDeviceTemplatesGridData,
  DaliaSensorsGridData,
  DeviceEventsGridData,
  DevicesGridData,
  FirmwaresGridData,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  KercomDevicesGridData,
  SitesGridData,
  XmqttDevicesGridData
} from '@iot-platform/models/grid-engine';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';

import * as fromGridsDb from './grids-db.reducer';

export const gridsFeatureKey = 'grids';

export interface GridsState {
  [fromGridsDb.gridsDbFeatureKey]: fromGridsDb.State;
}

export interface State {
  [gridsFeatureKey]: GridsState;
}

export function reducers(state: GridsState | undefined, action: Action) {
  return combineReducers({
    [fromGridsDb.gridsDbFeatureKey]: fromGridsDb.reducer
  })(state, action);
}

export const selectGridsState = createFeatureSelector<GridsState>(gridsFeatureKey);
export const selectGridsDbState = createSelector(selectGridsState, (state: GridsState) => state[fromGridsDb.gridsDbFeatureKey]);

export const {
  selectIds: getGridsIds,
  selectEntities: getGridsEntities,
  selectAll: getAllGrids,
  selectTotal: getTotalGrids
} = fromGridsDb.adapter.getSelectors(selectGridsDbState);

export const getSitesGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'SITES'.toLowerCase()));
export const getAssetsGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSETS'.toLowerCase()));
export const getDevicesGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'DEVICES'.toLowerCase()));
export const getDeviceEventsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DEVICE-EVENTS'.toLowerCase())
);

export const getAssetEventsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSET-EVENTS'.toLowerCase())
);

export const selectAssetTemplateGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSET-TEMPLATES'.toLowerCase())
);

export const selectUsersGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'USERS'.toLowerCase()));

export const selectConnectorsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'CONNECTORS'.toLowerCase())
);

export const selectPoEventRulesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'PO-EVENT-RULES'.toLowerCase())
);

export const selectEmailTemplatesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'EMAIL-TEMPLATES'.toLowerCase())
);

export const selectCalendarsGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'CALENDARS'.toLowerCase()));

export const getSelectedGridId = createSelector(selectGridsDbState, fromGridsDb.getSelectedGridId);
export const getSelectedGrid = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedGridByConcept = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedGridData = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId].data);
export const getSelectedItemIdInSelectedGrid = createSelector(selectGridsDbState, fromGridsDb.getSelectedGridId);
export const getSelectedIds = createSelector(selectGridsDbState, fromGridsDb.getSelectedIds);
export const getDefaultGrids = createSelector(selectGridsDbState, fromGridsDb.getDefaultGrids);
export const selectRefreshActivated = createSelector(selectGridsDbState, fromGridsDb.selectRefreshActivated);

export const selectSelectedMasterView = createSelector(selectGridsDbState, (state) => state.selectedMasterView);

export const selectRowGroupsState = createSelector(selectGridsDbState, (state) => get(state, 'rowGroupsState', {}));

export const selectSelectedGridByMasterView = createSelector(
  selectSelectedMasterView,
  getDefaultGrids,
  getGridsEntities,
  (masterview, defaultGrids, allGrids) => {
    const defaultGridId = get(defaultGrids, [masterview, 'gridId']);
    return get(allGrids, defaultGridId);
  }
);

//
export const getDefaultSitesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['sites', 'gridId'])) as I4BGrid<I4BGridOptions, SitesGridData> | undefined
);

export const getDefaultAssetsGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['assets', 'gridId'])) as I4BGrid<I4BGridOptions, AssetsGridData> | undefined
);

export const getDefaultDevicesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['devices', 'gridId'])) as I4BGrid<I4BGridOptions, DevicesGridData> | undefined
);

export const getDefaultDeviceEventsGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['device-events', 'gridId'])) as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined
);

export const getDefaultAssetEventsGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['asset-events', 'gridId'])) as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined
);

export const selectDefaultEmailTemplatesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) =>
  get(grids, get(defaultGrids, ['email-templates', 'gridId']))
);

export const getDefaultAssetTemplatesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) =>
  get(grids, get(defaultGrids, ['asset-templates', 'gridId']))
);

export const getDefaultUsersGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) =>
  get(grids, get(defaultGrids, ['users', 'gridId']))
);

export const selectDefaultConnectorsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) =>
  get(grids, get(defaultGrids, ['connectors', 'gridId']))
);

export const selectDefaultPoEventRulesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) =>
  get(grids, get(defaultGrids, ['po-event-rules', 'gridId']))
);

export const selectDefaultCalendarsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) =>
  get(grids, get(defaultGrids, ['calendars', 'gridId']))
);

/// ////////////////////////
export const selectDefaultGridByMasterview = (masterview: string) =>
  createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => get(grids, get(defaultGrids, [masterview, 'gridId'])));

export const selectTotalByGrid = (masterview: string) =>
  createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
    const gridId = get(defaultGrids, [masterview, 'gridId']);
    return get(grids, [gridId, 'data', 'response', 'pagination', 'total'], 0);
  });

export const selectDataLoadedByGrid = (masterview: string) =>
  createSelector(getDefaultGrids, getSelectedIds, (defaultGrids, selectedIds) => {
    if (defaultGrids && defaultGrids[masterview] && selectedIds) {
      return selectedIds[defaultGrids[masterview].gridId].dataLoaded;
    }
  });
/// ///////////////////////////

export const selectDefaultStockSiteDevicesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['stock-site-devices', 'gridId'])) as I4BGrid<I4BGridOptions, DevicesGridData> | undefined
);

export const selectDefaultActiveAssetEventsPopupGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['active-asset-events-popup', 'gridId'])) as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined
);

export const getDefaultAssetEventsByPERuleGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['asset-events-by-pe-rule', 'gridId'])) as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined
);

export const getDefaultDeviceEventsPERuleGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['device-events-by-pe-rule', 'gridId'])) as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined
);

export const getDefaultAssetEventsByTopicGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['asset-events-by-topic', 'gridId'])) as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined
);

export const getDefaultDeviceEventsByTopicGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['device-events-by-topic', 'gridId'])) as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined
);

export const getDefaultAssetEventsBySiteGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['asset-events-by-site', 'gridId'])) as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined
);

export const getDefaultDeviceEventsBySiteGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['device-events-by-site', 'gridId'])) as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined
);

export const getDefaultAssetEventsByAssetGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['asset-events-by-asset', 'gridId'])) as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined
);

export const getDefaultDeviceEventsByDeviceGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['device-events-by-device', 'gridId'])) as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined
);

export const getDataLoadingByGrid = (gridId: string) => createSelector(getSelectedIds, (ids) => ids[gridId].dataLoading);

export const getSortByGrid = (gridId: string) => createSelector(getGridsEntities, (ids) => (get(ids, gridId) ? ids[gridId].gridOptions.gridSort : []));

export const selectGridSortByGridId = createSelector(getSelectedGrid, getSelectedIds, (selectedGrid, selectedIds) =>
  get(selectedIds, [selectedGrid?.id, 'sort'], [])
);

export const selectSortByGrid = (masterview: string) =>
  createSelector(getDefaultGrids, getSelectedIds, (defaultGrids, grids) => {
    const gridId = get(defaultGrids, [masterview, 'gridId']);
    return get(grids, [gridId, 'sort'], []);
  });

export const getDataLoadedByGrid = (gridId: string) => createSelector(getSelectedIds, (ids) => get(ids, [gridId, 'dataLoaded'], true));

export const selectGridDataLoadedByGridId = createSelector(getSelectedGrid, getSelectedIds, (selectedGrid, ids) =>
  get(ids, [selectedGrid?.id, 'dataLoaded'], true)
);

export const selectGridDataLoadingByGridId = createSelector(getSelectedGrid, getSelectedIds, (selectedGrid, ids) =>
  get(ids, [selectedGrid?.id, 'dataLoading'], true)
);

export const getSelectedItemIdInGrid = (gridId: string) => createSelector(getSelectedIds, (ids) => ids[gridId].selectedItemId);

export const getSelectedItemInSelectedGrid = createSelector(getGridsEntities, getSelectedGridId, getSelectedIds, (entities, selectedGridId, selectedIds) => {
  if (selectedGridId && selectedIds && entities) {
    const selectedItemId = selectedIds[selectedGridId].selectedItemId;
    return entities[selectedGridId]?.data?.response?.data?.find((data) => data.id === selectedItemId);
  }
});

export const selectItemInGrid = (gridId: string, itemId: string) =>
  createSelector(getGridsEntities, (entities) => {
    if (entities) {
      return entities[gridId].data.response.data.filter((data) => data.id === itemId)[0];
    }
  });

export const selectSiteGridsConfiguration = createSelector(getDefaultSitesGrid, getSitesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectAssetGridsConfiguration = createSelector(getDefaultAssetsGrid, getAssetsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectDeviceGridsConfiguration = createSelector(getDefaultDevicesGrid, getDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectAssetEventGridsConfiguration = createSelector(getDefaultAssetEventsGrid, getAssetEventsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectDeviceEventGridsConfiguration = createSelector(getDefaultDeviceEventsGrid, getDeviceEventsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectAssetTemplateGridsConfiguration = createSelector(getDefaultAssetTemplatesGrid, selectAssetTemplateGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectUsersGridsConfiguration = createSelector(getDefaultUsersGrid, selectUsersGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectConnectorsGridsConfiguration = createSelector(selectDefaultConnectorsGrid, selectConnectorsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectPoEventRulesGridsConfiguration = createSelector(selectDefaultPoEventRulesGrid, selectPoEventRulesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectEmailTemplatesGridsConfiguration = createSelector(selectDefaultEmailTemplatesGrid, selectEmailTemplatesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

export const selectCalendarsGridsConfiguration = createSelector(selectDefaultCalendarsGrid, selectCalendarsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA devices selectors
 */
export const selectDaliaDevicesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-DEVICES'.toLowerCase())
);
export const selectDefaultDaliaDevicesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['dalia-devices', 'gridId'])) as I4BGrid<I4BGridOptions, DevicesGridData> | undefined
);
export const selectDaliaDevicesGridsConfiguration = createSelector(selectDefaultDaliaDevicesGrid, selectDaliaDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA firmwares selectors
 */
export const selectDaliaFirmwaresGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-FIRMWARES'.toLowerCase())
);
export const selectDefaultDaliaFirmwaresGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['dalia-firmwares', 'gridId'])) as I4BGrid<I4BGridOptions, FirmwaresGridData> | undefined
);
export const selectDaliaFirmwaresGridsConfiguration = createSelector(selectDefaultDaliaFirmwaresGrid, selectDaliaFirmwaresGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA sensors selectors
 */
export const selectDaliaSensorsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-SENSORS'.toLowerCase())
);
export const selectDefaultDaliaSensorsGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['dalia-sensors', 'gridId'])) as I4BGrid<I4BGridOptions, DaliaSensorsGridData> | undefined
);
export const selectDaliaSensorsGridsConfiguration = createSelector(selectDefaultDaliaSensorsGrid, selectDaliaSensorsGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * DALIA device templates selectors
 */
export const selectDaliaDeviceTemplatesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DALIA-DEVICE-TEMPLATES'.toLowerCase())
);
export const selectDefaultDaliaDeviceTemplatesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) =>
    get(grids, get(defaultGrids, ['dalia-device-templates', 'gridId'])) as I4BGrid<I4BGridOptions, DaliaDeviceTemplatesGridData> | undefined
);
export const selectDaliaDeviceTemplatesGridsConfiguration = createSelector(
  selectDefaultDaliaDeviceTemplatesGrid,
  selectDaliaDeviceTemplatesGrids,
  (defaultGrid, grids) => ({
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, DaliaDeviceTemplatesGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  })
);

/**
 * KERCOM devices selectors
 */
export const selectKercomDevicesGrids = createSelector(getAllGrids, (grids: I4BGrid<I4BGridOptions, KercomDevicesGridData>[]) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'KERCOM-DEVICES'.toLowerCase())
);
export const selectDefaultKercomDevicesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['kercom-devices', 'gridId'])) as I4BGrid<I4BGridOptions, KercomDevicesGridData> | undefined
);
export const selectKercomDevicesGridsConfiguration = createSelector(selectDefaultKercomDevicesGrid, selectKercomDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, KercomDevicesGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * XMQTT devices selectors
 */
export const selectXmqttDevicesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'XMQTT-DEVICES'.toLowerCase())
);
export const selectDefaultXmqttDevicesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['xmqtt-devices', 'gridId'])) as I4BGrid<I4BGridOptions, XmqttDevicesGridData> | undefined
);
export const selectXmqttDevicesGridsConfiguration = createSelector(selectDefaultXmqttDevicesGrid, selectXmqttDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));

/**
 * BAM devices selectors
 */
export const selectBamDevicesGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'BAM-DEVICES'.toLowerCase())
);
export const selectDefaultBamDevicesGrid = createSelector(
  getDefaultGrids,
  getGridsEntities,
  (defaultGrids, grids) => get(grids, get(defaultGrids, ['bam-devices', 'gridId'])) as I4BGrid<I4BGridOptions, XmqttDevicesGridData> | undefined
);
export const selectBamDevicesGridsConfiguration = createSelector(selectDefaultBamDevicesGrid, selectBamDevicesGrids, (defaultGrid, grids) => ({
  sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
  currentGrid: defaultGrid,
  isGridsLoading: false
}));
