<div fxLayout="row wrap"
     fxLayout.lt-sm="column"
     fxLayoutAlign="start center"
     fxLayoutAlign.lt-sm="start"
     fxLayoutGap="10px">
  @for (item of items?.(); track $index) {
    <mat-checkbox
      data-cy="iot-platform-ui-checkbox-input"
      class="iot-platform-ui-checkbox-input iot-platform-ui-checkbox-input--{{selectByFn(item)}}"
      style="line-height: 35px;"
      [fxFlex]="fxFlexOption?.() ? '0 1 calc('+fxFlexOption?.()+' - 10px)' :  'auto'"
      [fxFlex.lt-sm]="'100%'"
      [value]="selectByFn(item)"
      [checked]="isChecked(item)"
      (change)="onSelectionChange($event)"
      [disabled]="isOptionDisabled ? isOptionDisabled(item) : disabled?.()"
      [ngClass]="{ 'capitalize-first-letter': capitalized?.()}"
    >
      {{ displayByFn(item) | translate }}
    </mat-checkbox>
  }
</div>
