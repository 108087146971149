import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ContactsService } from '@iot-platform/data-access/contacts';
import { Contact, EmailTemplate, Filter } from '@iot-platform/models/common';
import { ContactNotification } from '@iot-platform/models/i4b';

@Component({
    selector: 'iot4bos-ui-site-notifications',
    templateUrl: './site-notifications.component.html',
    styleUrls: ['./site-notifications.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false
})
export class SiteNotificationsComponent implements OnInit, OnChanges {
  @Input() notifications: ContactNotification[] = [];
  @Input() selectedNotification?: ContactNotification;
  @Input() canUpdateContact = false;
  @Input() displayCheckbox = false;
  @Input() currentContact?: Contact;

  @Output() addNotification = new EventEmitter<void>();
  @Output() configureNotification = new EventEmitter<ContactNotification>();
  @Output() deleteNotification = new EventEmitter<ContactNotification>();
  @Output() selectNotification = new EventEmitter<ContactNotification>();
  @Output() checkNotification = new EventEmitter<{ checked: boolean; item: ContactNotification }>();

  selectedNotificationAssetNames = '';
  selectedNotificationEventClasses: string[] = [];
  selectedNotificationEventSeverities: string[] = [];

  templates: EmailTemplate[] = [];
  emailTemplate?: EmailTemplate;

  checkboxStatusByNotification!: { [key: string]: boolean };

  constructor(private readonly contactsService: ContactsService) {}

  static getSelectedNotificationAssetNames(notification: ContactNotification): string {
    return notification.filters
      ? notification.filters
          .filter((f: Filter) => f.criteriaKey === 'assetName')
          .map((f: Filter) => f.value)
          .sort()
          .join(' | ')
      : '';
  }

  static getSelectedNotificationFilter(notification: ContactNotification, filterCriteriaKey: 'eventClass' | 'eventSeverity'): string[] {
    return notification.filters
      ? notification.filters
          .filter((f: Filter) => f.criteriaKey === filterCriteriaKey)
          .map((f: Filter) => f.value)
          .sort()
      : [];
  }

  ngOnInit(): void {
    if (this.notifications.length === 1) {
      this.selectNotification.emit(this.notifications[0]);
    }

    if (this.notifications[0]?.entity.id) {
      this.contactsService.getEmailTemplatesByEntityId(this.notifications[0]?.entity.id).subscribe((templates) => {
        this.templates = templates;
        this.emailTemplate = this.selectedNotification?.emailTemplateId
          ? this.templates.find((t) => t.id === this.selectedNotification?.emailTemplateId)
          : ({ name: 'default' } as EmailTemplate);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selectedNotification') && changes.selectedNotification.currentValue) {
      this.selectedNotificationAssetNames = SiteNotificationsComponent.getSelectedNotificationAssetNames(changes.selectedNotification.currentValue);
      this.selectedNotificationEventClasses = SiteNotificationsComponent.getSelectedNotificationFilter(changes.selectedNotification.currentValue, 'eventClass');
      this.selectedNotificationEventSeverities = SiteNotificationsComponent.getSelectedNotificationFilter(
        changes.selectedNotification.currentValue,
        'eventSeverity'
      );

      this.emailTemplate = changes.selectedNotification.currentValue.emailTemplateId
        ? this.templates.find((t) => t.id === changes.selectedNotification.currentValue.emailTemplateId)
        : ({ name: 'default' } as EmailTemplate);
    }

    if (changes.hasOwnProperty('notifications') && changes.notifications.currentValue) {
      this.checkboxStatusByNotification = changes.notifications.currentValue.reduce(
        (
          acc: {
            [key: string]: boolean;
          },
          notif: ContactNotification
        ) => {
          acc[notif.id as string] = notif.contactIds.includes(this.currentContact?.id as string);
          return acc;
        },
        {}
      );
    }
  }
}
