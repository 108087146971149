<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'DEVICES.IMAGE_FORM.TITLE' | translate: { value: data.device?.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content p-20 image-dialog-height">
    <iot-platform-ui-image-selector-v2 [images]="images()" [defaultImage]="defaultImage()" [selectedImage]="image()" (selectImage)="image.set($event)" />
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'DEVICES.INFO_FORM.CANCEL' | translate }}</button>
    <button (click)="save()" [disabled]="image() === data.device?.imageUrl" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
