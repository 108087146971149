import { Component, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromAuth } from '@iot-platform/auth';
import { ContactsService } from '@iot-platform/data-access/contacts';
import { Contact } from '@iot-platform/models/common';
import {
  Asset,
  AssetVariable,
  EXPORT_SPREADSHEET_FREQUENCY as FREQUENCY,
  EXPORT_SPREADSHEET_MODEL as MODEL,
  EXPORT_SPREADSHEET_STATUS as STATUS,
  ExportSpreadsheet,
  Site
} from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'iot4bos-ui-export-spreadsheet-creation-form',
    templateUrl: './export-spreadsheet-creation-form.component.html',
    styleUrls: ['./export-spreadsheet-creation-form.component.scss'],
    standalone: false
})
export class ExportSpreadsheetCreationFormComponent implements OnInit, OnDestroy {
  exportForm!: UntypedFormGroup;
  initialFormState!: string;
  initialAssetVariablesState = '';
  destroyed$ = new Subject();
  FREQUENCY = FREQUENCY;
  MODEL = MODEL;
  timezone = '';
  exportSpreadsheet?: ExportSpreadsheet;
  downloadPreviewEnabled = true;
  hoursOfDayList = [...Array(24).keys()].map(this.padZero);
  daysOfMonthList = [...Array(28).keys()].map((i) => this.padZero(++i));
  daysOfWeekList = moment.weekdays();
  marginValues: number[] = Array.from(Array(11).keys()).map((i) => i * 10);

  eventsSubject$: Subject<AssetVariable[]> = new Subject<AssetVariable[]>();

  variablesLoading = signal(true);
  contactCheckedTotal = 0;

  popupTitle = this.data.exportSpreadsheet?.id
    ? this.translateService.instant('SITES.EXPORT_SPREADSHEET_FORM.TITLE_UPDATE', { exportName: this.data.exportSpreadsheet.name })
    : this.translateService.instant('SITES.EXPORT_SPREADSHEET_FORM.TITLE_CREATE');
  actionButtonLabel = this.translateService.instant('IOT_DICTIONARY.' + this.data.exportSpreadsheet?.id ? 'SAVE' : 'CREATE');

  constructor(
    private readonly store: Store,
    private readonly contactsService: ContactsService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    public readonly dialogRef: MatDialogRef<ExportSpreadsheetCreationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contacts: Contact[]; site: Site; assets: Asset[]; exportSpreadsheet?: ExportSpreadsheet }
  ) {}

  get currentFormState(): string {
    return JSON.stringify({
      name: this.name.value,
      description: this.description.value,
      frequency: this.frequency.value,
      status: this.status.value,
      model: this.model.value,
      hourOfDayControl: this.hourOfDayControl.value,
      dayOfWeekControl: this.dayOfMonthControl.value,
      dayOfMonthControl: this.dayOfMonthControl.value,
      contactIds: this.getSelectedContactIds(),
      margin: this.margin.value
    });
  }

  get assetVariables() {
    return this.exportForm.get('assetVariables') as AbstractControl;
  }

  get description() {
    return this.exportForm.get('description') as AbstractControl;
  }

  get name() {
    return this.exportForm.get('name') as AbstractControl;
  }

  get frequency() {
    return this.exportForm?.get('frequency') as AbstractControl;
  }

  get status() {
    return this.exportForm.get('status') as AbstractControl;
  }

  get model() {
    return this.exportForm.get('model') as AbstractControl;
  }

  get margin() {
    return this.exportForm.get('margin') as AbstractControl;
  }

  get hourOfDayControl() {
    return this.exportForm.get('hourOfDayControl') as AbstractControl;
  }

  get dayOfWeekControl() {
    return this.exportForm.get('dayOfWeekControl') as AbstractControl;
  }

  get dayOfMonthControl() {
    return this.exportForm.get('dayOfMonthControl') as AbstractControl;
  }

  ngOnInit() {
    this.exportSpreadsheet = this.data.exportSpreadsheet;
    this.setBusinessProfileTimezone();
    this.initForm();
    this.setFrequencyValidators();
    this.exportForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.contactCheckedTotal = this.getSelectedContactIds().length;
    });
    this.initialFormState = this.currentFormState;
  }

  setBusinessProfileTimezone() {
    this.store
      .select(fromAuth.selectSelectedBusinessProfileForAccount)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((bp) => {
        this.timezone = bp?.timezoneDetails?.offset ?? '';
      });
  }

  initForm(): void {
    this.exportForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.exportSpreadsheet?.name ?? '', [Validators.required]),
      description: new UntypedFormControl(this.exportSpreadsheet?.description ?? ''),
      assetVariables: new UntypedFormControl({ value: this.mapToAssetVarId(this.exportSpreadsheet?.assetVariables), disabled: true }, [Validators.required]),
      frequency: new UntypedFormControl(this.exportSpreadsheet?.frequency ?? FREQUENCY.EVERY_DAY, [Validators.required]),
      status: new UntypedFormControl(this.exportSpreadsheet ? this.exportSpreadsheet?.status === STATUS.ACTIVE : true),
      margin: new UntypedFormControl(this.exportSpreadsheet?.margin ?? 0, []),
      model: new UntypedFormControl(this.exportSpreadsheet?.model ?? MODEL.ONE_ASSET_PER_TAB, [Validators.required]),
      hourOfDayControl: new UntypedFormControl(this.exportSpreadsheet?.nextRun ? this.extractHourOfDay(this.exportSpreadsheet.nextRun) : null),
      dayOfWeekControl: new UntypedFormControl(this.exportSpreadsheet?.nextRun ? this.extractDayOfWeek(this.exportSpreadsheet.nextRun) : null),
      dayOfMonthControl: new UntypedFormControl(this.exportSpreadsheet?.nextRun ? this.extractDayOfMonth(this.exportSpreadsheet.nextRun) : null)
    });

    this.data.contacts.forEach((c) =>
      this.exportForm.addControl(c.id as string, new UntypedFormControl(!!this.data.exportSpreadsheet?.contactIds?.find((id) => id === c.id)))
    );
  }

  setFrequencyValidators() {
    this.frequency.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((frequency: string) => {
      switch (frequency) {
        case FREQUENCY.EVERY_4_HOURS:
        case FREQUENCY.EVERY_12_HOURS:
          this.clearValidatorsAndUpdate('dayOfWeekControl');
          this.clearValidatorsAndUpdate('dayOfMonthControl');
          this.clearValidatorsAndUpdate('hourOfDayControl');
          break;
        case FREQUENCY.EVERY_DAY:
          this.clearValidatorsAndUpdate('dayOfWeekControl');
          this.clearValidatorsAndUpdate('dayOfMonthControl');
          this.exportForm.get('hourOfDayControl')?.addValidators(Validators.required);
          break;
        case FREQUENCY.EVERY_WEEK:
          this.clearValidatorsAndUpdate('dayOfMonthControl');
          this.exportForm.get('hourOfDayControl')?.addValidators(Validators.required);
          this.exportForm.get('dayOfWeekControl')?.addValidators(Validators.required);
          break;
        case FREQUENCY.EVERY_MONTH:
          this.clearValidatorsAndUpdate('dayOfWeekControl');
          this.exportForm.get('hourOfDayControl')?.addValidators(Validators.required);
          this.exportForm.get('dayOfMonthControl')?.addValidators(Validators.required);
          break;
        default:
          this.clearValidatorsAndUpdate('hourOfDayControl');
          this.clearValidatorsAndUpdate('dayOfWeekControl');
          this.clearValidatorsAndUpdate('dayOfMonthControl');
      }
    });
  }

  save() {
    this.close(this.getSpreadsheetExport());
  }

  downloadPreview() {
    if (this.exportForm.valid) {
      this.notificationService.displayLoader(true);
      this.contactsService.getSpreadsheetExportPreview(this.getSpreadsheetExport(), this.data.site.id as string).subscribe(
        (response) => {
          if (response?.url) {
            window.open(new URL(response.url).href);
          } else {
            this.notificationService.displayError(this.translateService.instant('SITES.EXPORT_SPREADSHEET_FORM.ERROR_DOWNLOAD_PREVIEW'));
          }
        },
        () => this.notificationService.displayError(this.translateService.instant('SITES.EXPORT_SPREADSHEET_FORM.ERROR_DOWNLOAD_PREVIEW')),
        () => this.notificationService.displayLoader(false)
      );
    }
  }

  formStateChanged(): boolean {
    return this.initialFormState !== this.currentFormState || this.initialAssetVariablesState !== JSON.stringify(this.assetVariables.value);
  }

  validAssetVariables(): boolean {
    return !!this.assetVariables.value;
  }

  getAssetVariablesInitialState(state: string): any {
    this.initialAssetVariablesState = state;
  }

  removeAssetVariable(): void {
    this.eventsSubject$.next(this.assetVariables.value);
  }

  close(params?: any) {
    this.dialogRef.close(params);
  }

  getSelectedContactIds(): string[] {
    return this.data.contacts.filter((c) => (this.exportForm.get(c.id as string) as AbstractControl).value).map((c) => c.id as string);
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  private padZero(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  private extractHourOfDay(nextRun: string): string {
    return this.padZero(moment(nextRun).utcOffset(this.timezone).hours());
  }

  private extractDayOfWeek(nextRun: string): string {
    return moment(nextRun).weekday().toString();
  }

  private extractDayOfMonth(nextRun: string): string {
    return moment(nextRun).get('date').toString();
  }

  private mapToAssetVarId(assetVariables) {
    return assetVariables?.map((v) => ({ id: v.id }));
  }

  private clearValidatorsAndUpdate(key: string) {
    this.exportForm.get(key)?.clearValidators();
    this.exportForm.get(key)?.updateValueAndValidity();
  }

  private getNextRun() {
    const nextRun = moment().minutes(0).seconds(0).milliseconds(0).utcOffset(this.timezone);
    switch (this.frequency.value) {
      case FREQUENCY.EVERY_DAY:
        nextRun.set('hour', this.hourOfDayControl?.value);
        if (nextRun.diff(moment()) < 0) {
          nextRun.add(1, 'days');
        }
        break;
      case FREQUENCY.EVERY_WEEK:
        nextRun.set('hour', this.hourOfDayControl?.value).set('weekday', this.dayOfWeekControl?.value);
        if (nextRun.diff(moment()) < 0) {
          nextRun.add(1, 'weeks');
        }
        break;
      case FREQUENCY.EVERY_MONTH:
        nextRun.set('hour', this.hourOfDayControl?.value).set('date', this.dayOfMonthControl?.value);
        if (nextRun.diff(moment()) < 0) {
          nextRun.add(1, 'months');
        }
        break;
      default:
        return this.exportSpreadsheet?.nextRun;
    }

    return nextRun.toISOString();
  }

  private getSpreadsheetExport(): ExportSpreadsheet {
    return {
      id: this.exportSpreadsheet?.id ?? null,
      contactIds: this.getSelectedContactIds(),
      name: this.name?.value,
      description: this.description?.value,
      assetVariableIds: this.assetVariables?.value.map((v: AssetVariable) => v.id),
      frequency: this.frequency?.value,
      status: this.status?.value ? STATUS.ACTIVE : STATUS.INACTIVE,
      model: this.model?.value,
      nextRun: this.getNextRun(),
      margin: this.margin?.value
    } as ExportSpreadsheet;
  }
}
