<div class="site-info site-info__wrapper" fxLayout="column" fxLayoutGap="30px">
  <div [ngClass]="{ loading: siteLoading() }" class="site-info__card" fxFlex>
    @defer {
      <iot-platform-ui-tab-group
        (selectedTabChange)="onSelectedTabChange($event)"
        [selectedIndex]="initialTabIndex()"
        [withArrowIcon]="true"
        direction="vertical"
        fxFlex
        position="left"
        [shadow]="true"
      >
        <iot-platform-ui-tab-group-meta>
          <div fxLayout="column" fxLayoutAlign="center center">
            @if(toggleMap() && site()?.geolocation?.latitude && site()?.geolocation?.longitude) {
              <iot-platform-maps-geo-coordinates class="site-map"
                                                 concept="site"
                                                 [element]="site()"
                                                 [options]="{ matchScore: site()?.geolocation?.matchScore }"
                                                 [longitude]="site()?.geolocation?.longitude"
                                                 [latitude]="site()?.geolocation?.latitude" />
            } @else {
              <iot-platform-ui-tab-group-image
                (editImageClicked)="editImageClicked()"
                [canUpdateImage]="canUpdateSite()"
                [defaultImage]="defaultImage()"
                [image]="site()?.imageUrl"
              />
            }

            @if(!siteLoading()) {
              <mat-button-toggle-group [disabled]="!site()?.geolocation?.latitude && !site()?.geolocation?.longitude" class="toggle-map mt-2" [hideSingleSelectionIndicator]="true" [(ngModel)]="toggleMap">
                <mat-button-toggle [value]="false"><mat-icon>imagesmode</mat-icon></mat-button-toggle>
                <mat-button-toggle [value]="true"><mat-icon>pin_drop</mat-icon></mat-button-toggle>
              </mat-button-toggle-group>
            }
          </div>

        </iot-platform-ui-tab-group-meta>

        @if (infosTabOptions()?.basicInfosTab?.visible) {
          <iot-platform-ui-tab [disabled]="infosTabOptions()?.basicInfosTab?.disabled">
            <iot-platform-ui-tab-header [textLabel]="'SITES.CARD.TABS.BASIC_INFORMATION' | translate" />
            <iot-platform-ui-tab-content>
              <ng-container [ngTemplateOutlet]="siteCard" />
            </iot-platform-ui-tab-content>
          </iot-platform-ui-tab>
        }
      </iot-platform-ui-tab-group>
    }
  </div>

  <iot4bos-association-asset-device-shell
    (dispatchEvent)="onDispatchSectionHeaderEvent($event)"
    [displayMode]="DisplayMode()"
    [headerActions]="sectionHeaderActions()"
  />
</div>

<ng-template #siteCard>
  <iot-platform-ui-overview-card
    (cardButtonClicked)="onCardActions($event)"
    [loading]="siteLoading()"
    [overviewCard]="overviewCard()"
    class="device-info__overview-card"
    fxFlex
  />
</ng-template>
