import { BaseState, FavoriteView, Filter, INITIAL_BASE_STATE, Pagination, TagCategory } from '@iot-platform/models/common';
import { Device, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions';

export interface State extends BaseState<Device, Pagination, Filter> {
  currentFavoriteView: FavoriteView | null;
  tags: TagCategory[];
  tagsLoading: boolean;
  variables: DeviceVariable[];
  site: Site | null;
  siteLoading: boolean;
}

export const adapter: EntityAdapter<Device> = createEntityAdapter<Device>({
  selectId: (entity: Device) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  currentFavoriteView: null,
  tags: [],
  tagsLoading: false,
  variables: [],
  site: null,
  siteLoading: false
});

export const devicesFeature = createFeature({
  name: 'devices',
  reducer: createReducer(
    initialState,
    on(DevicesActions.loadDeviceSite, (state: State): State => ({ ...state, siteLoading: true })),
    on(DevicesActions.loadDeviceSiteSuccess, (state: State, { site }): State => ({ ...state, site, siteLoading: false })),
    on(DevicesActions.loadDeviceSiteFailure, (state: State): State => ({ ...state, siteLoading: false })),
    on(DevicesActions.loadDeviceTags, (state: State): State => ({ ...state, tagsLoading: true })),
    on(
      DevicesActions.loadDeviceTagsSuccess,
      DevicesActions.updateDeviceTagsSuccess,
      (state: State, { tags }): State => ({ ...state, tags, tagsLoading: false })
    ),
    on(DevicesActions.loadDeviceTagsFailure, DevicesActions.updateDeviceTagsSuccess, (state: State): State => ({ ...state, tagsLoading: false })),
    on(DevicesActions.loadDeviceVariablesSuccess, (state: State, { deviceVariables }): State => ({ ...state, variables: deviceVariables })),
    on(
      DevicesActions.loadDeviceByIdSuccess,
      DevicesActions.updateDeviceSuccess,
      DevicesActions.selectDeviceSuccess,
      DevicesActions.activateDeviceSuccess,
      DevicesActions.resetDeviceSuccess,
      (state: State, { device: entity }): State => ({ ...state, entity })
    ),
    on(
      DevicesActions.saveCurrentFavoriteView,
      (state: State, { currentFavoriteView }): State => ({
        ...state,
        currentFavoriteView,
        filters: currentFavoriteView?.filters ?? []
      })
    ),
    /* ------------- */
    //      UI
    /* ------------- */
    on(
      DevicesActions.loadDeviceById,
      DevicesActions.updateDevice,
      DevicesActions.deleteDevice,
      DevicesActions.activateDevice,
      DevicesActions.resetDevice,
      DevicesActions.sendCommand,
      (state: State): State => ({ ...state, loading: true, loaded: false })
    ),
    on(
      DevicesActions.loadDeviceByIdSuccess,
      DevicesActions.updateDeviceSuccess,
      DevicesActions.deleteDeviceSuccess,
      DevicesActions.activateDeviceSuccess,
      DevicesActions.resetDeviceSuccess,
      DevicesActions.sendCommandSuccess,
      (state: State): State => ({ ...state, loading: false, loaded: true })
    ),
    on(
      DevicesActions.loadDeviceByIdFailure,
      DevicesActions.updateDeviceFailure,
      DevicesActions.deleteDeviceFailure,
      DevicesActions.activateDeviceFailure,
      DevicesActions.resetDeviceFailure,
      DevicesActions.sendCommandFailure,
      (state: State): State => ({ ...state, loading: false, loaded: false })
    )
  )
});
