import { Injectable } from '@angular/core';
import { fromGrids, GridsDbActions } from '@iot-platform/grid-engine';
import { AssetVariable, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { fromFavoriteViews } from '@iot-platform/shared/components';
import { AssetVariablesService } from '@iot-platform/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as fromNavigation from '../../../../containers/+state/reducers';
import { AssetsActions, AssetVariablesActions } from '../actions';

@Injectable()
export class AssetVariablesEffects {
  addAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.addOne),
      concatMap((action) =>
        this.assetVariablesService.post(action.toAdd).pipe(
          map((added: AssetVariable) => AssetVariablesActions.addOneSuccess({ added })),
          catchError((error) => of(AssetVariablesActions.addOneFailure({ error })))
        )
      )
    )
  );

  loadVariablesByAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.getAll),
      switchMap((action) =>
        this.assetVariablesService.getManyByAssetId(action.assetId, 1000).pipe(
          map((allVariables: AssetVariable[]) => AssetVariablesActions.getAllSuccess({ allVariables })),
          catchError((error) => of(AssetVariablesActions.getAllFailure({ error })))
        )
      )
    )
  );

  updateAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.updateOne),
      concatMap((action) =>
        this.assetVariablesService.put(action.toUpdate).pipe(
          map((updated: AssetVariable) => AssetVariablesActions.updateOneSuccess({ updated })),
          catchError((error) => of(AssetVariablesActions.updateOneFailure({ error })))
        )
      )
    )
  );

  updateFollowedAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.updateFollowedAssetVariable),
      concatMap((action) =>
        this.assetVariablesService.put(action.toUpdate).pipe(
          map((updated: AssetVariable) => AssetVariablesActions.updateFollowedAssetVariableSuccess({ updated })),
          catchError((error) => of(AssetVariablesActions.updateFollowedAssetVariableFailure({ error })))
        )
      )
    )
  );

  updateAssetVariableThresholds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.updateThresholds),
      concatMap((action) =>
        this.assetVariablesService.updateThresholds(action.toUpdate).pipe(
          map((updated: AssetVariable) => AssetVariablesActions.updateThresholdsSuccess({ updated })),
          catchError((error) => of(AssetVariablesActions.updateThresholdsFailure({ error })))
        )
      )
    )
  );

  updateThresholdsSuccessThenReloadGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.updateThresholdsSuccess),
      withLatestFrom(this.store.select(fromGrids.getDefaultAssetsGrid), this.store.select(fromFavoriteViews.getFiltersForMasterViewAssets)),
      filter(([_, grid, __]) => !!grid),
      map(([_, grid, filters]) =>
        GridsDbActions.loadGridData({
          request: {
            filters,
            limit: grid?.data.response.pagination.limit,
            concept: grid?.masterview.toLowerCase(),
            page: grid?.data.response.pagination.currentPage,
            variables: grid?.gridOptions.variableNames,
            tags: grid?.gridOptions.tagIds,
            endPoint: grid?.gridOptions.endPoint
          }
        })
      )
    )
  );

  deleteAssetVariables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.deleteMany),
      concatMap((action) =>
        this.assetVariablesService.deleteAssetVariables(action.toDelete).pipe(
          map((response: I4BBulkOperationApiResponse) => AssetVariablesActions['deleteMany-']({ response })),
          catchError((error) => of(AssetVariablesActions.deleteManyFailure({ error })))
        )
      )
    )
  );

  breakFormula$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.breakFormula),
      concatMap((action) =>
        this.assetVariablesService.breakFormula(action.id).pipe(
          map((_: boolean) => AssetVariablesActions.breakFormulaSuccess()),
          catchError((error) => of(AssetVariablesActions.breakFormulaFailure({ error })))
        )
      )
    )
  );

  operationsThatReloadVariables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetsActions.updateOneFromMVSuccess,
        AssetVariablesActions.updateOneSuccess,
        AssetVariablesActions.updateFollowedAssetVariableSuccess,
        AssetVariablesActions.updateThresholdsSuccess,
        AssetVariablesActions['deleteMany-'],
        AssetVariablesActions['resetLastValues-'],
        AssetVariablesActions.breakFormulaSuccess
      ),
      concatLatestFrom(() => this.store.select(fromNavigation.getSelectedAsset)),
      map(([action, asset]) => {
        const assetId = get(asset, 'id', get(action, ['updated', 'asset', 'id']));
        return AssetVariablesActions.getAll({ assetId });
      })
    )
  );

  resetAssetVariableLastValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetVariablesActions.resetLastValues),
      switchMap((action) =>
        this.assetVariablesService.resetAssetVariablesLastValues(action.assetVariables).pipe(
          map((response) => AssetVariablesActions['resetLastValues-']({ response })),
          catchError((error) => of(AssetVariablesActions.resetLastValuesFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkActionsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetVariablesActions['deleteMany-'], AssetVariablesActions['resetLastValues-']),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetVariablesActions.addOneSuccess,
          AssetVariablesActions.updateOneSuccess,
          AssetVariablesActions.breakFormulaSuccess,
          AssetVariablesActions.deleteOneSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetVariablesActions.addOneFailure,
          AssetVariablesActions.getAllFailure,
          AssetVariablesActions.updateOneFailure,
          AssetVariablesActions.resetLastValuesFailure,
          AssetVariablesActions.breakFormulaFailure,
          AssetVariablesActions.deleteOneFailure,
          AssetVariablesActions.deleteManyFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetVariablesActions.addOne,
          AssetVariablesActions.updateOne,
          AssetVariablesActions.deleteOne,
          AssetVariablesActions.deleteMany,
          AssetVariablesActions.getAll,
          AssetVariablesActions.resetLastValues,
          AssetVariablesActions.breakFormula
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetVariablesActions.addOneSuccess,
          AssetVariablesActions.addOneFailure,
          AssetVariablesActions.getAllSuccess,
          AssetVariablesActions.getAllFailure,
          AssetVariablesActions.updateOneSuccess,
          AssetVariablesActions.updateOneFailure,
          AssetVariablesActions.deleteOneSuccess,
          AssetVariablesActions.deleteOneFailure,
          AssetVariablesActions['deleteMany-'],
          AssetVariablesActions.deleteManyFailure,
          AssetVariablesActions['resetLastValues-'],
          AssetVariablesActions.resetLastValuesFailure,
          AssetVariablesActions.breakFormulaSuccess,
          AssetVariablesActions.breakFormulaFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly assetVariablesService: AssetVariablesService,
    private readonly notificationService: NotificationService
  ) {}
}
