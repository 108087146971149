import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class IdColumn extends I4BBasicColumn {
  columnId = 'cb4617f6-5b50-11ec-80a5-0023a40210d0-id';
  dataLibs: [DataLibrary.SITES, DataLibrary.ASSETS, DataLibrary.DEVICES];
  className = IdColumn;
  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        ...customHeader,
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
            ? customHeader?.displayName
            : 'ID COLUMN DEFAULT'
      },
      {
        ...customConfig,
        cell: customConfig.cell ? customConfig.cell : { type: I4BCellType.BASIC },
        id: customConfig.id ? customConfig.id : 'id',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ID',
        isDefault: false,
        isConfigurable: true,
        isLink: false,
        width: customOptions?.width ? customOptions.width : customConfig.width ? customConfig.width : 180
      },
      { ...customOptions, customId: customConfig.id ? customConfig.id : 'id' }
    );
  }
}
