<mat-form-field class="wrapper" color="accent">
  <mat-label>{{ title }} *</mat-label>
  <mat-select (selectionChange)="onChipAdded()" [formControl]="ctrl" multiple>
    @for (option of options; track option.key) {
      <mat-option [value]="option.key">{{ option.label }}</mat-option>
    }
  </mat-select>
</mat-form-field>
<div [ngClass]="{ 'chip-list-padding': ctrl.value.length }">
  <mat-select-trigger>
    <mat-chip-set aria-orientation="horizontal">
      @for (chip of ctrl.value; track chip) {
        <mat-chip-option (removed)="onChipRemoved(chip)" [removable]="true" [selectable]="false">
          {{ getLabel(chip) }}
          <mat-icon class="icon material-icons" matChipRemove>highlight_off</mat-icon>
        </mat-chip-option>
      }
    </mat-chip-set>
  </mat-select-trigger>
</div>
