import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Country, TagCategory } from '@iot-platform/models/common';
import { SiteType } from '@iot-platform/models/i4b';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SitesDirectoryService {
  private readonly http: HttpClient = inject(HttpClient);

  getImages(): Observable<string[]> {
    return this.http.get<string[]>('assets/data/sites-gallery.json');
  }

  getFilteredImagesByTag(tagCategories: TagCategory[]): Observable<string[]> {
    return this.getImages().pipe(
      map((images: string[]) =>
        images.filter((imagePath: string) => {
          const bLTag: string = tagCategories.find((cat) => cat.name?.toUpperCase() === 'BUSINESS_LINE')?.labels?.[0]?.name || '';
          const imageName = imagePath.split('/').pop() as string;
          if (imageName.split('_')[0] === bLTag) {
            return true;
          } else return imageName === 'site_default.png' || imageName === 'site_empty.png';
        })
      )
    );
  }

  getDefaultImage(): string {
    return 'assets/images/site/site_default.png';
  }

  getAvailableTypesForEdition(): Observable<string[]> {
    return of([SiteType.PRODUCTION_SITE, SiteType.CUSTOMER_SITE]);
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`assets/data/countries.json`);
  }
}
