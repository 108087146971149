import { CommonApiResponse, CommonIndexedPagination, DeviceCallLog } from '@iot-platform/models/common';
import {
  HeaderType,
  I4BBasicColumn,
  I4BButtonColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridFilterType,
  I4BGridOptions
} from '@iot-platform/models/grid-engine';

export class DeviceCallLogsGridData implements I4BGridData {
  response!: CommonApiResponse<DeviceCallLog, CommonIndexedPagination>;
}

export const DEVICE_CALL_LOGS_MASTER_VIEW_NAME = 'device-call-logs';

export class DeviceCallLogsGrid implements I4BGrid<I4BGridOptions, DeviceCallLogsGridData> {
  masterview = DEVICE_CALL_LOGS_MASTER_VIEW_NAME;
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = 'device-call-logs';
  userId: string | null = null;
  data: DeviceCallLogsGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 1000,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    gridSort: [{ colId: 'group', sort: 'asc', sortIndex: 0 }],
    buttonColumn: {
      enabled: true,
      className: new I4BButtonColumn(
        {
          type: HeaderType.CALL_TO_ACTION,
          displayName: 'BUTTON',
          icon: ''
        },
        {
          concept: I4bCellConcept.DEVICE_CALL_LOGS,
          isConfigurable: false,
          isDefault: true,
          isLink: false,
          position: '',
          sortProperty: '',
          width: 10,
          id: 'button',
          cell: {
            type: I4BCellType.CALL_TO_ACTION,
            bulkActions: [],
            disableConditions: [],
            visibleConditions: [],
            enableConditions: [],
            singleActions: [
              {
                order: 0,
                key: 'downloadMessage',
                label: 'DEVICES.CALL_LOG.TABLE_CONTENT.BUTTONS.DOWNLOAD_MESSAGE',
                enableConditions: [],
                visibleConditions: []
              }
            ]
          }
        },
        { order: 1000 }
      )
    }
  };

  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATE_TIME', type: HeaderType.BASIC },
      {
        id: 'timestamp',
        isDefault: true,
        sortProperty: 'timestamp',
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        cell: { type: I4BCellType.DATE },
        filterParams: {
          enabled: true
        }
      },
      { order: 0 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DIRECTION', type: HeaderType.BASIC },
      {
        id: 'callDirection',
        isDefault: true,
        sortProperty: 'callDirection',
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.SET_COLUMN_FILTER
        }
      },
      { order: 1 }
    ),
    new I4BBasicColumn(
      { displayName: 'Command', type: HeaderType.BASIC },
      {
        id: 'command',
        isDefault: true,
        sortProperty: 'command',
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.SET_COLUMN_FILTER
        }
      },
      { order: 2 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_ID', type: HeaderType.BASIC },
      {
        id: 'connectorId',
        isDefault: true,
        sortProperty: 'connectorId',
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.SET_COLUMN_FILTER
        }
      },
      { order: 3 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATA', type: HeaderType.BASIC },
      {
        id: 'data',
        isDefault: true,
        sortProperty: 'data',
        width: 120,
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.NUMBER_COLUMN_FILTER
        }
      },
      { order: 4 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.EVENTS', type: HeaderType.BASIC },
      {
        id: 'events',
        isDefault: true,
        sortProperty: 'events',
        width: 130,
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.NUMBER_COLUMN_FILTER
        }
      },
      { order: 5 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DURATION', type: HeaderType.BASIC },
      {
        id: 'callDuration',
        isDefault: true,
        sortProperty: 'callDuration',
        width: 160,
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.NUMBER_COLUMN_FILTER
        }
      },
      { order: 6 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.STATUS', type: HeaderType.BASIC },
      {
        id: 'callStatus',
        isDefault: true,
        sortProperty: 'callStatus',
        width: 130,
        cell: { type: I4BCellType.BASIC },
        concept: I4bCellConcept.DEVICE_CALL_LOGS,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.SET_COLUMN_FILTER
        }
      },
      { order: 7 }
    )
  ];
}
