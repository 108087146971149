import { HttpClient } from '@angular/common/http';
import { inject, Injectable, ProviderToken } from '@angular/core';
import { Environment } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root'
})
export class DeviceOperatorInfoService {
  protected readonly environment: Environment = inject('environment' as unknown as ProviderToken<Environment>);
  protected readonly http: HttpClient = inject(HttpClient);

  getOperatorInfo(deviceIdentifier: string): Observable<any> {
    return this.http.get<string>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceDetails}/${deviceIdentifier}${this.environment.api.endpoints.operatorInfo}`
    );
  }
}
