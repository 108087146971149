import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { IotMapFacade } from '@iot-platform/iot-platform-maps';
import { StringUtils } from '@iot-platform/iot-platform-utils';
import { DevicesService, DeviceVariablesService } from '@iot-platform/iot4bos/data-access/devices';
import { DeviceCommandsStatuses, DeviceLastCommandStepStatus, TagCategory } from '@iot-platform/models/common';
import { Device, DeviceVariable, I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatuses, Site } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { FavoriteViewsActions } from '@iot-platform/shared/components';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { get } from 'lodash';
import * as moment from 'moment';
import { catchError, concatMap, filter, map, of, switchMap, tap } from 'rxjs';
import { DevicesActions } from '../actions/devices.actions';
import { DevicesFacade } from '../facades/devices.facade';

const loadDeviceById$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceById),
      concatMap(({ deviceId }) =>
        devicesService.getById(deviceId).pipe(
          map((device: Device) => DevicesActions.loadDeviceByIdSuccess({ device })),
          catchError((error) => of(DevicesActions.loadDeviceByIdFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceSite$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceSite),
      concatMap(({ siteId }) =>
        devicesService.getSiteById(siteId).pipe(
          map((site: Site) => DevicesActions.loadDeviceSiteSuccess({ site })),
          catchError((error) => of(DevicesActions.loadDeviceSiteFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const selectDevice$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.selectDevice),
      concatMap(({ device }) =>
        devicesService.saveTableState(device).pipe(
          map((selectedDevice: Device) => DevicesActions.selectDeviceSuccess({ device: selectedDevice })),
          catchError((error) => of(DevicesActions.selectDeviceFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateDevice$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.updateDevice),
      concatMap(({ device }) =>
        devicesService.updateDevice(device).pipe(
          map((updatedDevice: Device) => DevicesActions.updateDeviceSuccess({ device: updatedDevice })),
          catchError((error) => of(DevicesActions.updateDeviceFailure({ error })))
        )
      )
    ),
  { functional: true }
);

// TODO: TO REFACTOR
const moveDevices$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.moveDevices),
      concatMap(({ devices, originSite }) =>
        devicesService.bulkUpdateDevices(devices).pipe(
          concatMap((results: { devices: Device[]; errors: HttpErrorResponse[] }) => {
            if (results.devices.length > 0 && results.errors.length > 0) {
              if (!originSite) {
                return [
                  DevicesActions.moveDevicesSuccess({ devices: results.devices, currentSite: devices[0].site as Site }),
                  DevicesActions.moveDevicesFailure({ errors: results.errors })
                ];
              } else {
                return [
                  DevicesActions.moveDevicesSuccess({
                    devices: results.devices,
                    currentSite: devices[0].site as Site,
                    originSite
                  }),
                  DevicesActions.moveDevicesFailure({ errors: results.errors })
                ];
              }
            } else if (results.devices.length > 0 && results.errors.length === 0) {
              if (!originSite) {
                return [
                  DevicesActions.moveDevicesSuccess({
                    devices: results.devices,
                    currentSite: devices[0].site as Site
                  })
                ];
              } else {
                return [
                  DevicesActions.moveDevicesSuccess({
                    devices: results.devices,
                    currentSite: devices[0].site as Site,
                    originSite
                  })
                ];
              }
            } else {
              return [DevicesActions.moveDevicesFailure({ errors: results.errors })];
            }
          })
        )
      )
    ),
  { functional: true }
);

const activateDevice$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.activateDevice),
      concatMap(({ device }) =>
        devicesService.activateDevice(device).pipe(
          map((activattedDevice: Device) => DevicesActions.activateDeviceSuccess({ device: activattedDevice })),
          catchError((error) => of(DevicesActions.activateDeviceFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const activateDeviceSuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(DevicesActions.activateDeviceSuccess),
      tap(({ device }) => devicesFacade.updateDeviceInCurrentGrid(device))
    ),
  { functional: true, dispatch: false }
);

// TODO: TO REFACTOR
const resetDevice$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.resetDevice),
      concatMap(({ device }) =>
        devicesService.resetDevice(device).pipe(
          switchMap((resetedDevice: Device) => [
            DevicesActions.resetDeviceSuccess({ device: resetedDevice }),
            DevicesActions.updateDeviceSuccess({ device: resetedDevice }),
            DevicesActions.loadDeviceVariables({ deviceId: resetedDevice.id as string }),
            DevicesActions.loadDeviceTags({ deviceId: resetedDevice.id as string })
          ]),
          catchError((error) => of(DevicesActions.resetDeviceFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteDevice$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.deleteDevice),
      concatMap(({ device }) =>
        devicesService.delete(device).pipe(
          map((deletedDevice: Device) => DevicesActions.deleteDeviceSuccess({ device: deletedDevice })),
          catchError((error) => of(DevicesActions.deleteDeviceFailure({ error })))
        )
      )
    ),
  { functional: true }
);

// TODO: TO REFACTOR
const sendCommand$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.sendCommand),
      concatMap(({ device, command }) =>
        devicesService.sendCommand(device.id as string, command).pipe(
          switchMap(() => {
            const cmd: DeviceCommandsStatuses = { refresh: undefined, selfconf: undefined };
            cmd[command.command] = { status: DeviceLastCommandStepStatus.COMMAND_SENT, timestamp: moment.now() };
            return [
              DevicesActions.sendCommandSuccess(),
              DevicesActions.loadDeviceByIdSuccess({
                device: {
                  ...device,
                  commandsStatuses: cmd
                }
              })
            ];
          }),
          catchError((error) => of(DevicesActions.sendCommandFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkSendCommand$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.bulkSendCommand),
      concatMap(({ devicesIds, command }) =>
        devicesService.bulkSendCommand(devicesIds, command.command).pipe(
          map((response: I4BBulkOperationApiResponse) => DevicesActions.bulkSendCommandSuccess({ response })),
          catchError((error) => of(DevicesActions.bulkSendCommandFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceTags$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceTags),
      concatMap(({ deviceId }) =>
        devicesService.getTagsByDeviceId(deviceId).pipe(
          map((tags: TagCategory[]) => DevicesActions.loadDeviceTagsSuccess({ tags })),
          catchError((error) => of(DevicesActions.loadDeviceTagsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const updateDeviceTags$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.updateDeviceTags),
      concatMap(({ deviceId, tags }) =>
        devicesService.putTagsByDeviceId(deviceId, tags).pipe(
          map((response: TagCategory[]) => DevicesActions.updateDeviceTagsSuccess({ tags: response })),
          catchError((error) => of(DevicesActions.updateDeviceTagsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const bulkOperationOnTagByDevicesIds$ = createEffect(
  (actions$ = inject(Actions), devicesService = inject(DevicesService)) =>
    actions$.pipe(
      ofType(DevicesActions.bulkOperationOnTag),
      concatMap((action) =>
        devicesService.bulkOperationOnTag(action.bulkOperationType, action.devicesIds, action.tagLabelId).pipe(
          map((response: I4BBulkOperationApiResponse) =>
            DevicesActions[`bulk${StringUtils.capitalizeFirstCharacter(action.bulkOperationType)}Tag-`]({ response })
          ),
          catchError((error) => of(DevicesActions[`bulk${StringUtils.capitalizeFirstCharacter(action.bulkOperationType)}TagFailure`]({ error })))
        )
      )
    ),
  { functional: true }
);

const loadDeviceVariables$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceVariablesService = inject(DeviceVariablesService)) =>
    actions$.pipe(
      ofType(DevicesActions.loadDeviceVariables),
      concatMap(({ deviceId }) =>
        deviceVariablesService.getDeviceVariables(deviceId).pipe(
          map((deviceVariables: DeviceVariable[]) => DevicesActions.loadDeviceVariablesSuccess({ deviceVariables })),
          catchError((error) => of(DevicesActions.loadDeviceVariablesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const deleteDeviceVariables$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceVariablesService = inject(DeviceVariablesService)) =>
    actions$.pipe(
      ofType(DevicesActions.deleteDeviceVariables),
      concatMap(({ deviceVariables }) =>
        deviceVariablesService.bulkDeleteDeviceVariables(deviceVariables).pipe(
          switchMap((response: I4BBulkOperationApiResponse) => [
            DevicesActions.deleteDeviceVariablesSuccess({ response }),
            DevicesActions.loadDeviceVariables({ deviceId: deviceVariables[0].device.id as string })
          ]),
          catchError((error) => of(DevicesActions.deleteDeviceVariablesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const resetDeviceVariablesLastValues$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), deviceVariablesService = inject(DeviceVariablesService)) =>
    actions$.pipe(
      ofType(DevicesActions.resetDeviceVariablesLastValues),
      concatMap(({ deviceVariables }) =>
        deviceVariablesService.bulkResetDeviceVariablesLastValues(deviceVariables).pipe(
          switchMap((response: I4BBulkOperationApiResponse) => [
            DevicesActions.resetDeviceVariablesLastValuesSuccess({ response }),
            DevicesActions.loadDeviceVariables({ deviceId: deviceVariables[0].device.id as string })
          ]),
          catchError((error) => of(DevicesActions.resetDeviceVariablesLastValuesFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const displaySuccessAfterBulkActions$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.bulkSendCommandSuccess,
        DevicesActions['bulkAddTag-'],
        DevicesActions['bulkRemoveTag-'],
        DevicesActions['bulkReplaceTag-'],
        DevicesActions.deleteDeviceVariablesSuccess,
        DevicesActions.resetDeviceVariablesLastValuesSuccess
      ),
      tap(({ response, type }) => notificationService.displaySuccess(type + ' [ ' + I4BBulkOperationApiResponseStatuses[response.status] + ' ] '))
    ),
  { functional: true, dispatch: false }
);

const reloadDevices$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), devicesFacade = inject(DevicesFacade)) =>
    actions$.pipe(
      ofType(
        DevicesActions.moveDevicesSuccess,
        DevicesActions.bulkSendCommandSuccess,
        DevicesActions['bulkAddTag-'],
        DevicesActions['bulkRemoveTag-'],
        DevicesActions['bulkReplaceTag-']
      ),
      tap(() => devicesFacade.reloadGrid())
    ),
  { functional: true, dispatch: false }
);

const setCurrentFavoriteView$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), mapFacade = inject(IotMapFacade)) =>
    actions$.pipe(
      ofType(FavoriteViewsActions.setCurrentFavoriteView),
      filter(({ masterView }) => masterView === 'devices'),
      tap(({ favoriteView, masterView }) =>
        mapFacade.getAll({
          concept: masterView,
          displayMode: 'default',
          filters: get(favoriteView, 'filters', [])
        })
      )
    ),
  { functional: true, dispatch: false }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.loadDeviceById,
        DevicesActions.updateDevice,
        DevicesActions.moveDevices,
        DevicesActions.activateDevice,
        DevicesActions.resetDevice,
        DevicesActions.deleteDevice,
        DevicesActions.sendCommand,
        DevicesActions.bulkSendCommand,
        DevicesActions.loadDeviceTags,
        DevicesActions.updateDeviceTags,
        DevicesActions.bulkOperationOnTag,
        DevicesActions.deleteDeviceVariables,
        DevicesActions.resetDeviceVariablesLastValues
      ),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.loadDeviceByIdSuccess,
        DevicesActions.loadDeviceByIdFailure,
        DevicesActions.updateDeviceSuccess,
        DevicesActions.updateDeviceFailure,
        DevicesActions.moveDevicesSuccess,
        DevicesActions.moveDevicesFailure,
        DevicesActions.activateDeviceSuccess,
        DevicesActions.activateDeviceFailure,
        DevicesActions.resetDeviceSuccess,
        DevicesActions.resetDeviceFailure,
        DevicesActions.deleteDeviceSuccess,
        DevicesActions.deleteDeviceFailure,
        DevicesActions.sendCommandSuccess,
        DevicesActions.sendCommandFailure,
        DevicesActions.bulkSendCommandSuccess,
        DevicesActions.bulkSendCommandFailure,
        DevicesActions.loadDeviceTagsSuccess,
        DevicesActions.loadDeviceTagsFailure,
        DevicesActions.updateDeviceTagsSuccess,
        DevicesActions.updateDeviceTagsFailure,
        DevicesActions['bulkAddTag-'],
        DevicesActions.bulkAddTagFailure,
        DevicesActions['bulkRemoveTag-'],
        DevicesActions.bulkRemoveTagFailure,
        DevicesActions['bulkReplaceTag-'],
        DevicesActions.bulkReplaceTagFailure,
        DevicesActions.deleteDeviceVariablesSuccess,
        DevicesActions.deleteDeviceVariablesFailure,
        DevicesActions.resetDeviceVariablesLastValuesSuccess,
        DevicesActions.resetDeviceVariablesLastValuesFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displaySuccess$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.updateDeviceSuccess,
        DevicesActions.moveDevicesSuccess,
        DevicesActions.activateDeviceSuccess,
        DevicesActions.resetDeviceSuccess,
        DevicesActions.deleteDeviceSuccess,
        DevicesActions.sendCommandSuccess,
        DevicesActions.updateDeviceTagsSuccess
      ),
      tap((action: Action) => notificationService.displaySuccess(action.type))
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        DevicesActions.loadDeviceByIdFailure,
        DevicesActions.loadDeviceSiteFailure,
        DevicesActions.updateDeviceFailure,
        DevicesActions.moveDevicesFailure,
        DevicesActions.activateDeviceFailure,
        DevicesActions.resetDeviceFailure,
        DevicesActions.deleteDeviceFailure,
        DevicesActions.sendCommandFailure,
        DevicesActions.bulkSendCommandFailure,
        DevicesActions.loadDeviceTagsFailure,
        DevicesActions.updateDeviceTagsFailure,
        DevicesActions.bulkAddTagFailure,
        DevicesActions.bulkRemoveTagFailure,
        DevicesActions.bulkReplaceTagFailure,
        DevicesActions.loadDeviceVariablesFailure,
        DevicesActions.resetDeviceVariablesLastValuesFailure,
        DevicesActions.deleteDeviceVariablesFailure
      ),
      tap((action: Action) => notificationService.displayError(action))
    ),
  { functional: true, dispatch: false }
);

export const DevicesEffects = {
  loadDeviceById$,
  loadDeviceSite$,
  selectDevice$,
  updateDevice$,
  moveDevices$,
  activateDevice$,
  activateDeviceSuccess$,
  deleteDevice$,
  resetDevice$,
  sendCommand$,
  bulkSendCommand$,
  loadDeviceTags$,
  updateDeviceTags$,
  bulkOperationOnTagByDevicesIds$,
  loadDeviceVariables$,
  deleteDeviceVariables$,
  resetDeviceVariablesLastValues$,
  reloadDevices$,
  setCurrentFavoriteView$,
  displaySuccessAfterBulkActions$,
  showLoader$,
  hideLoader$,
  displaySuccess$,
  displayError$
};
