import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

// TODO this a duplicates from "@iot-platform/iot4bos/data-access/devices" to be refactored
@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly httpClient: HttpClient = inject(HttpClient);

  getById(deviceId: string): Observable<Device> {
    return this.httpClient.get<Device>(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}`);
  }
}
