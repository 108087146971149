import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { FilterEngineModule } from '@iot-platform/iot-platform-ui';
import { provideConfigureProductsFormSettings, provideVariableCreateFormSettings } from '@iot-platform/iot4bos/ui/asset';
import { Affiliate, Filter } from '@iot-platform/models/common';
import { NumberFormatPipe } from '@iot-platform/pipes';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEffects } from './containers/+state/effects/navigation.effects';
import { NavigationApi } from './containers/+state/navigation.api';
import * as fromNavigation from './containers/+state/reducers';
import { AppShellModule } from './containers/app-shell/app-shell.module';
import { AcknowledgementsComponent } from './features/acknowledgements/acknowledgements.component';
import { ConfigureProductsFormService } from './features/assets/services/configure-products-form.service';
import { VariableCreateFormService } from './features/assets/services/variable-create-form.service';
import { SchedulersEffects } from './features/schedulers/+state/effects/schedulers.effects';
import { SitesModule } from './features/sites/sites.module';
import { FavoriteFilterEngineService } from './services/favorite-filter-engine.service';
import { GridAssetVariableConfigureReadPopupService } from './services/grid-asset-variable-configure-read-popup.service';
import { AssociationAssetDeviceSettingsService } from './services/assocation-asset-device-settings.service';
import { provideAssociationAssetDeviceSettings } from '@iot-platform/iot4bos/feature/association-asset-device';

const iot4bosRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/app-shell/app-shell.module').then((m) => m.AppShellModule),
    pathMatch: 'full'
  }
];

const COMMON_DEVICE_FILTERS_OPTIONS = {
  deviceAffiliate: {
    displayWrapper: (element: Filter | Affiliate | undefined) => {
      if ((element as Filter)?.value) {
        return (element as Filter).value.id + ' : ' + (element as Filter).value.name;
      } else if ((element as Affiliate)?.id) {
        return (element as Affiliate).id + ' : ' + (element as Affiliate).name;
      }
      return '';
    }
  },
  deviceCommunicationOperator: {
    dataTransform: (data: any) => data.map((v: any) => ({ id: v, name: v }))
  }
};

@NgModule({
  imports: [
    AppShellModule,
    SitesModule,
    RouterModule.forChild(iot4bosRoutes),
    StoreModule.forFeature(fromNavigation.navigationFeatureKey, fromNavigation.reducers),
    EffectsModule.forFeature([NavigationEffects, SchedulersEffects]),
    ScrollingModule,
    NumberFormatPipe,
    FilterEngineModule.withSettings({
      favoriteFilterEngineService: FavoriteFilterEngineService,
      options: {
        devices: {
          device: { ...COMMON_DEVICE_FILTERS_OPTIONS }
        },
        ['scheduler-devices']: {
          device: { ...COMMON_DEVICE_FILTERS_OPTIONS }
        }
      }
    }),
    GridEngineModule.withSettings({
      assetVariableConfigureReadPopupService: GridAssetVariableConfigureReadPopupService
    }),
    AcknowledgementsComponent
  ],
  providers: [
    TranslateService,
    NavigationApi,
    provideVariableCreateFormSettings({
      variableCreateFormService: VariableCreateFormService
    }),
    provideConfigureProductsFormSettings({
      configureProductsFormService: ConfigureProductsFormService
    }),
    provideAssociationAssetDeviceSettings({
      associationAssetDeviceSettingsService: AssociationAssetDeviceSettingsService
    })
  ]
})
export class Iot4bosUiModule {}
