<div class="image-selector" data-cy="iot-platform-ui-image-selector-v2" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
  <div fxFlex="30" fxShow="false" fxShow.gt-sm class="image-selector__preview-container">
    @if (selectedImage(); as image) {
      <img [src]="image" alt="" class="image-selector__preview" #imageRef (error)="onImageError()" />
    } @else {
      <span class="image-selector__preview-placeholder">{{ 'IMAGE_SELECTOR.SELECT_IMAGE' | translate }}</span>
    }
  </div>

  <div fxFlex class="image-selector__gallery">
    <mat-grid-list (window:resize)="onResize()" [cols]="gridColNumber()" gutterSize="10px" rowHeight="6:4">
      @for (image of images(); track image) {
        <mat-grid-tile class="image-selector__grid-tile">
          <img [src]="image" (click)="onSelect(image)" (keyup)="onSelect(image)" class="image-selector__minia" alt="" />
          @if (image.toLowerCase() === selectedImage()?.toLowerCase()) {
            <mat-icon class="image-selector__check-icon">check_circle</mat-icon>
          }
        </mat-grid-tile>
      }
    </mat-grid-list>
  </div>
</div>
