import { Component, computed, effect, inject, input, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SELECT_CONFIG, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

import { BaseUser, BusinessProfile } from '@iot-platform/models/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  imports: [MatTooltip, FlexLayoutModule, MatSelectModule, MatMenuModule, MatDivider, MatIcon, MatButtonModule, TranslateModule, ReactiveFormsModule],
  selector: 'iot-platform-feature-app-shell-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ]
})
export class HeaderComponent {
  logo = input<string>();
  // eslint-disable-next-line @angular-eslint/no-input-rename
  user = input<BaseUser | null>(null, { alias: 'currentUser' });
  // eslint-disable-next-line @angular-eslint/no-input-rename
  userLoading = input<boolean>(false, { alias: 'currentUserLoading' });
  businessProfiles = input<BusinessProfile[]>([]);
  selectedBusinessProfile = input<BusinessProfile | null>(null);
  allowSwitchBusinessProfile = input<boolean>(false);
  allowRedirectToMyProfile = input<boolean>(true);
  selectedProfileControl: FormControl<BusinessProfile> = new FormControl<BusinessProfile>(null);
  logout = output<boolean>();
  logoClicked = output<boolean>();
  menuClicked = output<void>();
  businessProfileListClicked = output<void>();
  myProfile = output<void>();
  changeBusinessProfile = output<BusinessProfile>();
  public translateService: TranslateService = inject(TranslateService);
  public storage: LocalStorageService = inject(LocalStorageService);

  selectedProfile: Signal<BusinessProfile> = computed(() => {
    const businessProfiles = this.businessProfiles();
    const selectedBP = this.selectedBusinessProfile();
    return businessProfiles?.find((bp) => bp.id === selectedBP?.id) ?? selectedBP;
  });

  timezone: Signal<string> = computed(() => {
    const selectedProfile = this.selectedProfile();
    let returnValue = '';
    if (selectedProfile) {
      const tzName = selectedProfile.timezoneDetails?.name ?? 'NEED UPDATE';
      const tzOffset = selectedProfile.timezoneDetails?.offset ?? '';
      returnValue = `${tzName} ${tzOffset}`.trim();
      const elBp = { ...selectedProfile, timezone: tzOffset, timezoneDetails: { name: tzName, offset: tzOffset } };
      this.storage.set(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY, JSON.stringify(elBp));
    }
    return returnValue;
  });

  initials: Signal<string> = computed(() => {
    const user = this.user();
    return user ? `${user.firstname.slice(0, 1).toUpperCase()}${user.lastname.slice(0, 1).toUpperCase()}` : '';
  });

  constructor() {
    effect(() => {
      const allowSwitchBusinessProfile = this.allowSwitchBusinessProfile();
      const userLoading = this.userLoading();
      if (!allowSwitchBusinessProfile || userLoading) {
        this.selectedProfileControl.disable();
      } else {
        this.selectedProfileControl.enable();
      }
    });

    effect(() => {
      const selectedProfile = this.selectedProfile();
      const timeout = setTimeout(() => {
        this.selectedProfileControl.setValue(selectedProfile);
        clearTimeout(timeout);
      }, 50);
    });
  }

  onLogout() {
    this.logout.emit(true);
  }

  onLogoClick() {
    this.logoClicked.emit(true);
  }

  onToggleMenuClick() {
    this.menuClicked.emit();
  }

  switchBusinessProfile(profile: MatSelectChange) {
    this.changeBusinessProfile.emit(profile.value as BusinessProfile);
  }

  onMyProfileClick() {
    this.myProfile.emit();
  }

  onBusinessProfileListClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.businessProfileListClicked.emit();
  }
}
