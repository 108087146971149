import { Component, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [FlexLayoutModule, TranslateModule],
  selector: 'iot-platform-ui-page-title-card',
  templateUrl: './page-title-card.component.html',
  styleUrls: ['./page-title-card.component.scss']
})
export class PageTitleCardComponent {
  label = input<string>();
}
