import { Component, input, output, Signal, signal, viewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [FlexLayoutModule, MatGridListModule, MatIcon, TranslateModule],
  selector: 'iot-platform-ui-image-selector-v2',
  templateUrl: './image-selector-v2.component.html',
  styleUrls: ['./image-selector-v2.component.scss']
})
export class ImageSelectorV2Component {
  images = input<string[]>([]);
  selectedImage = input<string | null>(null);
  defaultImage = input<string | null>(null);

  selectImage = output<string>();

  imageRef: Signal<HTMLImageElement | undefined> = viewChild('imageRef');

  gridColNumber = signal(ImageSelectorV2Component.getColumnNumber());

  onSelect(image: string): void {
    this.selectImage.emit(image);
  }

  onResize(): void {
    this.gridColNumber.set(ImageSelectorV2Component.getColumnNumber());
  }

  onImageError(): void {
    (this.imageRef() as HTMLImageElement).src = this.defaultImage() ?? '';
    this.selectImage.emit(this.defaultImage());
  }

  static getColumnNumber(): number {
    const width = window.innerWidth;
    if (width <= 380) {
      return 1;
    } else if (width <= 600) {
      return 2;
    } else if (width <= 750) {
      return 3;
    } else {
      return 4;
    }
  }
}
