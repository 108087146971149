import { NgClass, NgComponentOutlet, NgStyle, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, computed, inject, input, model, OnDestroy, output, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NumberUtils } from '@iot-platform/iot-platform-utils';
import { OverviewCard, OverviewCardActions, OverViewCardSection } from '@iot-platform/models/common';
import { InfoDisplayPipe, LinkifyPipe } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { SkeletonComponent } from '../skeleton';
import { OverviewCardStore } from './overview-card.store';

@Component({
  imports: [
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    InfoDisplayPipe,
    DetailPopupModule,
    MatButtonModule,
    CardLoaderModule,
    MatExpansionModule,
    MatTooltipModule,
    NgTemplateOutlet,
    UpperCasePipe,
    NgStyle,
    NgClass,
    NgComponentOutlet,
    MatDividerModule,
    SkeletonComponent,
    LinkifyPipe
  ],
  selector: 'iot-platform-ui-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent implements OnDestroy {
  overviewCard = model.required<OverviewCard>();
  loading = input<boolean>(false);

  cardButtonClicked = output<OverviewCardActions>();
  canDisplayCTA: Signal<boolean>;
  randomSkeletonsSizes: Signal<number[]> = computed(() => Array.from({ length: NumberUtils.getRandomInt(1, 3) }, () => NumberUtils.getRandomInt(30, 70)));
  protected overviewCardStore: OverviewCardStore = inject(OverviewCardStore);

  constructor() {
    this.canDisplayCTA = computed(() => {
      const overviewCard = this.overviewCard();
      const visibleActions = get(overviewCard, 'actions', [])?.filter(
        (action) => (action?.visible !== undefined && action.visible) || action?.visible === undefined
      );
      return visibleActions.length > 0;
    });
  }

  onSectionExpandChange(section: OverViewCardSection, index: number, expanded: boolean): void {
    this.overviewCardStore.expandChange({
      key: this.overviewCard()?.expansionPanelsOptions?.key as string,
      entityId: this.overviewCard()?.expansionPanelsOptions?.entityId as string,
      section,
      index,
      expanded
    });
    section?.expandedChange?.(index, expanded);
  }

  getSectionExpandedByIndex = (section: OverViewCardSection, index: number) =>
    this.overviewCardStore.getPanelExpandedByIndex(
      this.overviewCard()?.expansionPanelsOptions?.key as string,
      this.overviewCard()?.expansionPanelsOptions?.entityId as string,
      section,
      index,
      section?.expanded
    );

  ngOnDestroy() {
    if (this.overviewCard()?.expansionPanelsOptions?.clearOnDestroy) {
      this.overviewCardStore.clear(this.overviewCard()?.expansionPanelsOptions?.key as string);
    }
  }
}
