import { ValueGetterParams } from '@ag-grid-community/core';
import * as moment from 'moment';
import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums';
import { DataLibrary } from '../enums/data-type.enum';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class EventDurationColumn extends I4BBasicColumn {
  columnId = '772bc694-5c9e-11ec-87a5-0093a40210d1-event-duration';
  className = EventDurationColumn;
  dataLibs = [DataLibrary.ASSET_EVENTS, DataLibrary.DEVICE_EVENTS];

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        ...customHeader,
        displayName: customOptions?.overrideHeaderName ?? customHeader?.displayName ?? 'EVENT DURATION',
        type: HeaderType.BASIC
      },
      {
        cell: {
          type: I4BCellType.EVENT_DURATION_CELL
        },
        id: 'occurrenceTime',
        concept: I4bCellConcept.EVENTS,
        isDefault: true,
        isConfigurable: true,
        isLink: false,
        width: customOptions?.width,
        ...customConfig
      },
      { ...customOptions, customId: customConfig?.id }
    );

    this.configuration.cell.valueGetter = (params: ValueGetterParams) => {
      const status = params.data.status;
      const severity = params.data.severity;
      const startDate: moment.Moment = moment(params.data?.occurrenceTime).clone();
      let endDate: moment.Moment = moment().clone();
      if (status === 'closed') {
        endDate = moment(params.data?.close?.datetime).clone();
      }
      const duration: moment.Duration = moment.duration(endDate.diff(startDate));
      return {
        compareValue: duration.asHours(),
        days: Math.floor(duration.asDays()),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
        color: status === 'closed' ? 'black' : this.getColor(severity, duration)
      };
    };
    this.configuration.comparator = ({ compareValue: valueA }, { compareValue: valueB }) => {
      if (valueA === valueB) {
        return 0;
      }
      return valueA > valueB ? 1 : -1;
    };
  }

  getColor(severity: string, duration: moment.Duration) {
    let color = 'black';

    switch (severity) {
      case 'minor':
      case 'unknown':
        color = 'black';
        break;
      case 'medium':
        if (duration.asHours() > 72) {
          color = 'yellow';
          break;
        }
        break;
      case 'major':
        if (duration.asHours() > 48) {
          color = 'orange';
          break;
        }
        break;
      case 'critical':
        if (duration.asHours() > 24) {
          color = 'red';
          break;
        }
        break;
      default:
        color = 'black';
        break;
    }
    return color;
  }
}
