<section class="device-info-toolbar" data-cy="iot-platform-ui-device-info-toolbar" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
  <div class="device-info-toolbar__count" fxLayoutAlign="start center">
    @if (displayLoader()) {
      <mat-spinner [diameter]="20" color="accent"></mat-spinner>
    } @else {
      <span>{{ totalFiltered() }} / {{ total() }}</span>
    }
  </div>
  @if (toolbarForm()) {
    <form [formGroup]="toolbarForm()" fxLayoutAlign="center center" fxLayoutGap="16px" fxLayout="row wrap">
      @if (withNameFilter()) {
        <mat-form-field class="device-info-toolbar__filter" color="accent">
          <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_NAME' | translate }}</mat-label>
          <input (change)="onFilterNameValueChange($event)" (keyup.enter)="onFilterNameValueChange($event)" formControlName="name" matInput type="text" />
        </mat-form-field>
      }
      @if (withDescriptionFilter()) {
        <mat-form-field class="device-info-toolbar__filter" color="accent">
          <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_DESCRIPTION' | translate }}</mat-label>
          <input (change)="onFilterDescriptionValueChange($event)" formControlName="description" matInput type="text" />
        </mat-form-field>
      }
      @if (withGroupFilter()) {
        <mat-form-field color="accent" class="device-info-toolbar__filter">
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_GROUP' | translate }}</mat-label>
          <mat-select (selectionChange)="onGroupSelectionChange($event)" formControlName="group">
            @for (group of deviceVariableGroups(); track group.key) {
              <mat-option [value]="group.key">{{ group.key | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      @if (withUnitFilter()) {
        <mat-form-field color="accent" class="device-info-toolbar__filter">
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_UNIT' | translate }}</mat-label>
          <mat-select (selectionChange)="onUnitSelectionChange($event)" formControlName="unit">
            @for (unit of variableUnits(); track unit) {
              <mat-option [value]="unit">{{ unit }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      @if (withFileFilter()) {
        <mat-form-field color="accent" class="device-info-toolbar__filter">
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_FILE' | translate }}</mat-label>
          <mat-select (selectionChange)="onFileSelectionChange($event)" formControlName="file">
            @for (file of deviceVariableFiles(); track file.key) {
              <mat-option [value]="file.key">{{ file.key | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      @if (withLinkedFilter()) {
        <mat-form-field color="accent" class="device-info-toolbar__filter">
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_LINKED' | translate }}</mat-label>
          <mat-select (selectionChange)="onLinkedSelectionChange($event)" formControlName="linked">
            @for (link of LINK_OPTIONS; track link) {
              <mat-option [value]="link">{{ link }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </form>
  }
  <button (click)="clear()" color="accent" mat-button>{{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}</button>
</section>
