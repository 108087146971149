import { inject } from '@angular/core';
import { AssociationByAsset, AssociationByDevice } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatMap, forkJoin, pipe, tap } from 'rxjs';
import { AssociationAssetDeviceService } from '../../services';

type State = {
  associationByAsset: AssociationByAsset | null;
  associationByDevice: AssociationByDevice | null;
  loading: boolean;
};

const initialState: State = {
  associationByAsset: null,
  associationByDevice: null,
  loading: true
};

export const AssociationAssetDeviceStore = signalStore(
  withState(initialState),
  withMethods((store, associationAssetDeviceService = inject(AssociationAssetDeviceService), notificationService = inject(NotificationService)) => ({
    loadAssociations: rxMethod<string>(
      pipe(
        tap(() => patchState(store, { loading: true })),
        concatMap((siteId) =>
          forkJoin([associationAssetDeviceService.getAssociationByAsset(siteId), associationAssetDeviceService.getAssociationByDevice(siteId)]).pipe(
            tapResponse({
              next: ([associationByAsset, associationByDevice]: [AssociationByAsset, AssociationByDevice]) =>
                patchState(store, { associationByAsset, associationByDevice, loading: false }),
              error: (error) => {
                notificationService.displayError(error);
                return patchState(store, { loading: false });
              }
            })
          )
        )
      )
    )
  }))
);
